import queryString, { ParsedQuery } from "query-string";
import { ExecutionFilter, FilterType } from "graphql/schema";
import {
  ColumnDefinition,
  ColumnFilterType,
} from "components/executions/list/ExecutionColumnDefinitions";

export const saveFilters = (key: string, filters: ExecutionFilter[]) => {
  window.localStorage.setItem(key, JSON.stringify(filters));
};

export function loadFilters(
  key: string,
  defaultFilters: ExecutionFilter[],
  filterableColumns: ColumnDefinition[],
  searchParams: ParsedQuery<string>,
) {
  const saved = window.localStorage.getItem(key);

  const filters: ExecutionFilter[] = saved ? JSON.parse(saved) : defaultFilters;

  const searchParamFilters = getFiltersFromSearchParameters(
    searchParams,
    filterableColumns,
  );
  if (searchParamFilters?.length) {
    return searchParamFilters;
  }

  return filters;
}

export function getFilterTypes(filterType?: ColumnFilterType): FilterType[] {
  if (!filterType) {
    return [];
  }

  switch (filterType) {
    case "boolean":
      return ["IS_TRUE", "IS_FALSE"];
    case "number":
      return [
        "GREATER_THAN",
        "LESS_THAN",
        "GREATER_THAN_OR_EQUAL",
        "LESS_THAN_OR_EQUAL",
        "EQUALS",
        "NOT_EQUALS",
      ];
    case "string":
      return ["EQUALS", "NOT_EQUALS", "CONTAINS", "NOT_CONTAINS"];
    case "datetime":
      return ["GREATER_THAN", "LESS_THAN"];
    case "runtime":
      return ["EQUALS", "GREATER_THAN_DAYS", "LESS_THAN_DAYS"];
    case "options":
      return ["EQUALS", "NOT_EQUALS"];
  }
}

// TODO: create unit tests for all these utils using jest
function getFiltersFromSearchParameters(
  searchParams: ParsedQuery<string>,
  filterableColumns: ColumnDefinition[],
) {
  const filters: ExecutionFilter[] = [];

  filterableColumns.forEach((col) => {
    const param = searchParams[col.column];

    if (Array.isArray(param)) {
      param.forEach((filterParameter) => {
        const filter = getFilterFromSearchParameter(filterParameter, col);

        if (filter) {
          filters.push(filter);
        }
      });
    } else if (param) {
      const filter = getFilterFromSearchParameter(param, col);

      if (filter) {
        filters.push(filter);
      }
    }
  });

  return filters;
}

/**
 *
 * @param filterParameter The value taken from the url parameters, should be CONDITION_VALUE
 * @param column The ColumnDefinition to match the filter parameter with
 */
function getFilterFromSearchParameter(
  filterParameter: string,
  column: ColumnDefinition,
): ExecutionFilter | undefined {
  const filter = getFilterConditionFromSearchParameter(filterParameter);

  // do this extra check because of the difference between community bots and the other filters of this column
  if (
    filter &&
    column.column === "STARTED_AT" &&
    getFilterTypes("runtime").includes(filter.type) &&
    !isNaN(Number(filter.value))
  ) {
    return {
      filterKey: column.column,
      filterType: filter.type,
      value: filter.value,
    };
  }

  // if unable to parse the filter parameter to get the condition and value, or an incorrect condition was used for the selected column
  if (
    !filter ||
    !getFilterTypes(column.filterType).includes(filter.type) ||
    (isNaN(Number(filter.value)) &&
      (column.filterType === "number" || column.filterType === "runtime")) ||
    (!filter.value.length && column.filterType !== "boolean")
  ) {
    return undefined;
  }

  if (column.filterType === "number" && isNaN(Number(filter.value))) {
    return undefined;
  }

  return {
    filterKey: column.column,
    filterType: filter.type,
    value: filter.value,
  };
}

export const FILTER_TYPES: FilterType[] = [
  "CONTAINS",
  "EQUALS",
  "GREATER_THAN",
  "GREATER_THAN_DAYS",
  "GREATER_THAN_OR_EQUAL",
  "IS_FALSE",
  "IS_TRUE",
  "LESS_THAN",
  "LESS_THAN_DAYS",
  "LESS_THAN_OR_EQUAL",
  "NOT_CONTAINS",
  "NOT_EQUALS",
];

function getFilterConditionFromSearchParameter(
  filterParameter: string,
): { type: FilterType; value: string } | undefined {
  const splitIndex = filterParameter.indexOf(":");

  if (splitIndex === -1) {
    return;
  }

  const condition = filterParameter.slice(0, splitIndex);
  const value = filterParameter.slice(splitIndex + 1);

  if (!FILTER_TYPES.includes(condition as FilterType)) {
    return undefined;
  }

  return {
    type: condition as FilterType,
    value,
  };
}

export function getSearchParamsFromFilter(filters: ExecutionFilter[]) {
  const searchParams = queryString.parse("");

  filters.forEach((filter) => {
    const param = searchParams[filter.filterKey];
    if (!param) {
      searchParams[filter.filterKey] = `${filter.filterType}:${filter.value}`;
    } else if (Array.isArray(param)) {
      searchParams[filter.filterKey] = [`${filter.filterType}:${filter.value}`];
    } else {
      searchParams[filter.filterKey] = [
        param,
        `${filter.filterType}:${filter.value}`,
      ];
    }
  });

  return searchParams;
}
