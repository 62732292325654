import React from "react";
import { Trans } from "react-i18next";
import { TFunction } from "i18next";
import { PreloadedScript } from "__generated__/graphql";
import { ExecutionColumn } from "graphql/schema";
import {
  ColumnDefinition,
  exProp,
  exZeroesProp,
  msProp,
  clProp,
} from "components/executions/list/ExecutionColumnDefinitions";
import ExecutionStatus from "components/executions/list/ExecutionStatus";
import ExecutionScript from "components/executions/list/ExecutionScript";
import ExecutionScriptRevision from "components/executions/list/ExecutionScriptRevision";
import ExecutionTags from "components/executions/list/ExecutionTags";
import ExecutionValueCurrency from "components/executions/list/ExecutionValueCurrency";
import ExecutionSubscriptionStatus from "components/executions/list/ExecutionSubscriptionStatus";
import ColorSpan from "core/element/ColorSpan";
import { percentageValue, trimToTwoDecimals } from "helpers/numbers";
import { titleCase } from "helpers/strings";
import { getDateTime, getRoundedDate } from "helpers/dates";
import { formatExchange } from "helpers/formLabelUtils";

export const EXECUTION_STATUSES = [
  "PENDING",
  "RUNNING",
  "ENDED",
  "FAILED",
  "DELETING",
  "STOPPING",
  "STOPPING_CLOSEMARKET",
  "STOPPING_NEXTCLOSE",
  "LIQUIDATED",
];
export const EXECUTION_TYPES = [
  "BACKTEST",
  "PAPERTRADE",
  "LIVE",
  "SYNDICATION",
  "WEBHOOK",
];
export const EXCHANGES = [
  "BINANCE",
  "BINANCE_COIN_FUTURES",
  "BINANCE_FUTURES",
  "BITMEX",
  "BYBIT",
  "BYBIT_LINEAR",
  "COINBASE",
  "CRYPTO_DOT_COM",
  "HUOBI_COIN_SWAPS",
  "KRAKEN",
];
export const SUBSCRIPTION_STATUSES = [
  "ACTIVE",
  "PENDING",
  "CANCELLED",
  "NOT_SUBSCRIBED",
];

// only have setup scripts for now
export const PRELOADED_SCRIPTS = [
  PreloadedScript.SetupLong,
  PreloadedScript.SetupShort,
  PreloadedScript.SetupSpot,
];

export const COLUMN_DEFINITIONS: Record<ExecutionColumn, ColumnDefinition> = {
  CREATION_DATE: {
    className: "Creation Date",
    getLabels: (t: TFunction) => ({ header: t("common.creation_date") }),
    propertyFn: (e) => (
      <div title={getDateTime(e.createdAt)}>{getDateTime(e.createdAt)}</div>
    ),
    column: "CREATION_DATE",
    filterType: "datetime",
    canMove: true,
    canToggle: true,
    width: "135px",
  },
  SCRIPT_NAME: {
    className: "Name",
    getLabels: (t: TFunction) => ({ header: t("common.name") }),
    propertyFn: (
      e,
      _a,
      _onShowActionModal,
      isPackListOpen,
      onUpdateOpenPacks,
    ) => (
      <ExecutionScript
        scriptDetails={e.scriptDetails}
        scriptName={e.scriptName ?? e.scriptDetails?.name ?? e.name ?? ""}
        algorithmSettings={e.algorithmSettings}
        id={e.id}
        favorite={e.favorite}
        execution={e}
        isPackListOpen={isPackListOpen}
        onUpdateOpenPacks={onUpdateOpenPacks}
      />
    ),
    column: "SCRIPT_NAME",
    filterType: "string",
    canMove: false,
    canToggle: false,
    width: "350px",
  },
  SCRIPT_VERSION: {
    className: "Strategy Version",
    getLabels: (t: TFunction) => ({ header: t("common.strategy_version") }),
    propertyFn: (e) => (
      <ExecutionScriptRevision
        version={e.scriptDetails?.version}
        isLatest={e.scriptDetails?.isLatest}
        createdAt={e.scriptDetails?.createdAt}
      />
    ),
    column: "SCRIPT_VERSION",
    canMove: true,
    canToggle: true,
    width: "100px",
    unsortable: true,
  },
  EXECUTION_NUMBER: {
    className: "Run #",
    getLabels: (t: TFunction) => ({ header: t("terminal.run_number") }),
    propertyFn: (e) => (
      <div title={e.runNumber} className="execution-num">
        {e.runNumber}
      </div>
    ),
    column: "EXECUTION_NUMBER",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "60px",
  },
  TYPE: {
    className: "Type",
    getLabels: (t: TFunction) => ({ header: t("common.type") }),
    propertyFn: (e) => <div title={titleCase(e.type)}>{titleCase(e.type)}</div>,
    column: "TYPE",
    filterType: "options",
    filterOptions: EXECUTION_TYPES,
    canMove: true,
    canToggle: true,
    width: "65px",
  },
  EXCHANGE: {
    className: "Exchange",
    getLabels: (t: TFunction) => ({ header: t("common.exchange") }),
    propertyFn: (e) => (
      <span title={formatExchange(e.exchange)}>
        {formatExchange(e.exchange)}
      </span>
    ),
    column: "EXCHANGE",
    filterType: "options",
    filterOptions: EXCHANGES,
    canMove: true,
    canToggle: true,
    width: "75px",
  },
  CURRENCY_PAIR: {
    className: "Trading Pair",
    getLabels: (t: TFunction) => ({ header: t("common.trading_pair") }),
    propertyFn: (e) => (
      <span>
        {e.multiCoinCurrency
          ? `Multiple / ${e.multiCoinCurrency}`
          : e.currencyPair.replace("_", " / ")}
      </span>
    ),
    column: "CURRENCY_PAIR",
    filterType: "string",
    canMove: true,
    canToggle: true,
    width: "95px",
  },
  CANDLE_SIZE: {
    className: "Candle",
    getLabels: (t: TFunction) => ({ header: t("common.candle") }),
    propertyFn: exProp("candleSize"),
    column: "CANDLE_SIZE",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "65px",
  },
  PROFITABILITY: {
    className: "Profit/Loss %",
    getLabels: (t: TFunction) => ({ header: t("common.profit_loss_percent") }),
    propertyFn: clProp("profitability", percentageValue),
    column: "PROFITABILITY",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "105px",
  },
  PERCENT_PROFITABLE_TRADES: {
    className: "Profitable Trades",
    getLabels: (t: TFunction) => ({
      header: t("common.profitable_trades"),
      tooltip: t("message.profitable_trades_information"),
    }),
    propertyFn: msProp("percentProfitableTrades", percentageValue, true),
    column: "PERCENT_PROFITABLE_TRADES",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "125px",
  },
  ABSOLUTE_PROFIT: {
    className: "Profit/Loss",
    getLabels: (t: TFunction) => ({ header: t("common.profit_loss") }),
    propertyFn: (e) => (
      <ExecutionValueCurrency
        value={e.measurements.absoluteProfit}
        currency={e.currencyPairDetails?.settleCurrency}
        colorSign={true}
        noGradiant={true}
      />
    ),
    column: "ABSOLUTE_PROFIT",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "130px",
  },
  AVG_POSITION_PRICE: {
    className: "Average Position Price",
    getLabels: (t: TFunction) => ({
      header: t("common.average_position_price"),
      tooltip: t("message.average_position_price"),
    }),
    propertyFn: (e) => (
      <ExecutionValueCurrency
        value={e.measurements.avgPositionPrice}
        currency={e.currencyPairDetails?.quote}
        colorSign={false}
        hidden={!!e.multiCoinCurrency}
      />
    ),
    column: "AVG_POSITION_PRICE",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "160px",
  },
  MAX_DRAWDOWN: {
    className: "MDD",
    getLabels: (t: TFunction) => ({
      header: t("common.mdd"),
      tooltip: t("message.mdd_information"),
    }),
    propertyFn: msProp("maxDrawdown", percentageValue, false),
    column: "MAX_DRAWDOWN",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "65px",
  },
  NUMBER_OF_TRADES: {
    className: "# Trades",
    getLabels: (t: TFunction) => ({
      header: t("common.number_trades"),
      tooltip: t("message.total_amount_of_trades"),
    }),
    propertyFn: msProp("numberOfTrades"),
    column: "NUMBER_OF_TRADES",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "80px",
  },
  PROFIT_FACTOR: {
    className: "Profit Factor",
    getLabels: (t: TFunction) => ({
      header: t("common.profit_factor"),
      tooltip: t("message.gross_profits_divided_by_gross_losses"),
    }),
    propertyFn: msProp("profitFactor", trimToTwoDecimals),
    column: "PROFIT_FACTOR",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "100px",
  },
  STATUS: {
    className: "Status",
    getLabels: (t: TFunction) => ({ header: t("common.status") }),
    propertyFn: (e) => <ExecutionStatus execution={e} />,
    column: "STATUS",
    filterType: "options",
    filterOptions: EXECUTION_STATUSES,
    canMove: true,
    canToggle: false,
    width: "85px",
  },
  FAVORITE: {
    className: "Favorite",
    getLabels: (t: TFunction) => ({ header: t("common.favorite") }),
    hidden: true,
    propertyFn: () => null,
    column: "FAVORITE",
    filterType: "boolean",
    neverVisible: true,
  },
  LEVERAGE: {
    className: "Long Leverage",
    getLabels: (t: TFunction) => ({
      header: t("common.long_leverage"),
      tooltip: t("message.long_leverage_information"),
    }),
    propertyFn: exZeroesProp("leverage"),
    column: "LEVERAGE",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "120px",
  },
  LEVERAGE_SHORT: {
    className: "Short Leverage",
    getLabels: (t: TFunction) => ({
      header: t("common.short_leverage"),
      tooltip: t("message.short_leverage_information"),
    }),
    propertyFn: exZeroesProp("leverageShort"),
    column: "LEVERAGE_SHORT",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "120px",
  },
  AVG_MONTHLY_PROFIT: {
    className: "Average Monthly Profit",
    getLabels: (t: TFunction) => ({
      header: t("common.average_monthly_profit"),
    }),
    propertyFn: msProp("avgMonthlyProfit", percentageValue),
    column: "AVG_MONTHLY_PROFIT",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "175px",
  },
  AVG_WIN_MONTH: {
    className: "Average Win Month",
    getLabels: (t: TFunction) => ({
      header: t("common.average_win_month"),
      tooltip: t("message.average_gain_for_profitable_months"),
    }),
    propertyFn: msProp("avgWinMonth", percentageValue),
    column: "AVG_WIN_MONTH",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "140px",
  },
  AVG_LOSE_MONTH: {
    className: "Average Lose Month",
    getLabels: (t: TFunction) => ({
      header: t("common.average_lose_month"),
      tooltip: t("message.average_loss_for_unprofitable_months"),
    }),
    propertyFn: msProp("avgLoseMonth", percentageValue),
    column: "AVG_LOSE_MONTH",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "145px",
  },
  PERC_PROFITABLE_MONTHS: {
    className: "Profitable Months",
    getLabels: (t: TFunction) => ({
      header: t("common.profitable_months"),
      tooltip: t("message.percent_profitable_months"),
    }),
    propertyFn: msProp("percProfitableMonths", percentageValue),
    column: "PERC_PROFITABLE_MONTHS",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "135px",
  },
  TAG_ID: {
    className: "Tags",
    getLabels: (t: TFunction) => ({ header: t("common.tags") }),
    propertyFn: (e) => (
      <ExecutionTags executionId={e.id} executionTags={e.tags} />
    ),
    column: "TAG_ID",
    filterType: "options",
    isTag: true,
    canMove: true,
    canToggle: true,
    width: "200px",
    unsortable: true,
  },
  ALLOCATION: {
    className: "Initial Allocation",
    getLabels: (t: TFunction) => ({ header: t("common.initial_allocation") }),
    propertyFn: (e) => (
      <ExecutionValueCurrency
        value={e.allocation}
        currency={e.currencyPairDetails?.settleCurrency}
        colorSign={false}
      />
    ),
    column: "ALLOCATION",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "125px",
  },
  POSITION_AMOUNT: {
    className: "Position Amount",
    getLabels: (t: TFunction) => ({
      header: t("common.position_amount"),
      tooltip: t("message.open_position_total_value"),
    }),
    propertyFn: (e) => (
      <ExecutionValueCurrency
        value={e.measurements.positionAmount}
        currency={e.currencyPairDetails?.positionCurrency}
        colorSign={false}
        hidden={!!e.multiCoinCurrency}
      />
    ),
    column: "POSITION_AMOUNT",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "125px",
  },
  POSITION_ABSOLUTE_PROFIT: {
    className: "Position Profit",
    getLabels: (t: TFunction) => ({
      header: t("common.position_profit"),
      tooltip: t("message.open_position_profit_loss"),
    }),
    propertyFn: (e) => (
      <ExecutionValueCurrency
        value={e.measurements.positionAbsoluteProfit}
        currency={e.currencyPairDetails?.settleCurrency}
        colorSign={true}
      />
    ),
    column: "POSITION_ABSOLUTE_PROFIT",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "125px",
  },
  POSITION_PROFIT_LOSS: {
    className: "Position Profit %",
    getLabels: (t: TFunction) => ({
      header: t("common.position_profit_percent"),
      tooltip: t("message.open_position_percent_profit"),
    }),
    propertyFn: clProp("positionProfitLoss", percentageValue),
    column: "POSITION_PROFIT_LOSS",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "125px",
  },
  STARTED_AT: {
    className: "Run Start Date",
    getLabels: (t: TFunction) => ({ header: t("common.run_start_date") }),
    propertyFn: (e) => (
      <div
        title={
          e.rangeStart
            ? getDateTime(e.rangeStart)
            : e.startedAt
            ? getDateTime(e.startedAt)
            : "--"
        }
      >
        {e.rangeStart
          ? getRoundedDate(e.rangeStart)
          : e.startedAt
          ? getDateTime(e.startedAt)
          : "--"}
      </div>
    ),
    column: "STARTED_AT",
    canMove: true,
    canToggle: true,
    width: "135px",
  },
  STARTED_AT_DATE: {
    className: "Run Start Date",
    getLabels: (t: TFunction) => ({ header: t("common.days_running") }),
    propertyFn: () => null,
    column: "STARTED_AT",
    filterType: "runtime",
    neverVisible: true,
  },
  ENDED_AT: {
    className: "Run End Date",
    getLabels: (t: TFunction) => ({ header: t("common.run_end_date") }),
    propertyFn: (e) => (
      <div
        title={
          e.rangeEnd
            ? getDateTime(e.rangeEnd)
            : e.endedAt
            ? getDateTime(e.endedAt)
            : "--"
        }
      >
        {e.rangeEnd
          ? getRoundedDate(e.rangeEnd)
          : e.endedAt
          ? getDateTime(e.endedAt)
          : "--"}
      </div>
    ),
    column: "ENDED_AT",
    canMove: true,
    canToggle: true,
    width: "135px",
  },
  BALANCE: {
    className: "Balance",
    getLabels: (t: TFunction) => ({
      header: t("terminal.balance"),
      tooltip: t("message.run_total_funds_information"),
    }),
    propertyFn: (e) => (
      <ExecutionValueCurrency
        value={e.measurements.balance}
        currency={e.currencyPairDetails?.settleCurrency}
        colorSign={false}
      />
    ),
    column: "BALANCE",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "150px",
  },
  IS_PACK: {
    className: "Strategy Pack",
    getLabels: (t: TFunction) => ({ header: t("terminal.strategy_pack") }),
    hidden: true,
    propertyFn: () => null,
    column: "IS_PACK",
    filterType: "boolean",
    neverVisible: true,
  },
  AUTO_REBALANCE: {
    className: "Rebalancing",
    getLabels: (t: TFunction) => ({
      header: t("common.rebalancing"),
      tooltip: t("message.auto_rebalance_information"),
    }),
    propertyFn: (e) => <span>{e.autoRebalance ? "On" : "--"}</span>,
    column: "AUTO_REBALANCE",
    filterType: "boolean",
    canMove: true,
    canToggle: true,
    width: "90px",
  },
  ARCHIVED: {
    className: "Archived",
    getLabels: (t: TFunction) => ({ header: t("common.archived") }),
    hidden: true,
    propertyFn: () => null,
    column: "ARCHIVED",
    filterType: "boolean",
    neverVisible: true,
  },
  RISK_SCORE: {
    className: "Risk Score",
    getLabels: (t: TFunction) => ({
      header: t("common.risk_score"),
      tooltip: (
        <div>
          <div>{t("message.average_monthly_profit_for_mdd")}</div>
          <Trans
            i18nKey="message.risk_score_information"
            components={[
              <div key="multiplication" className="multiplication">
                x
              </div>,
              <div key="squared" className="squared">
                2
              </div>,
            ]}
          />
        </div>
      ),
    }),
    propertyFn: msProp("riskScore", percentageValue),
    column: "RISK_SCORE",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "100px",
  },
  IS_SUBSCRIPTION: {
    className: "Is Subscription",
    getLabels: (t: TFunction) => ({ header: t("terminal.is_subscription") }),
    hidden: true,
    propertyFn: () => null,
    column: "IS_SUBSCRIPTION",
    filterType: "boolean",
    neverVisible: true,
  },
  SYNDICATION_ID: {
    className: "Syndication",
    getLabels: (t: TFunction) => ({ header: t("common.bot") }),
    hidden: true,
    propertyFn: () => null,
    column: "SYNDICATION_ID",
    filterType: "options",
    neverVisible: true,
  },
  SUBSCRIPTION_STATUS: {
    className: "Subscription Status",
    getLabels: (t: TFunction) => ({ header: t("common.subscription_status") }),
    propertyFn: (e) => <ExecutionSubscriptionStatus execution={e} />,
    column: "SUBSCRIPTION_STATUS",
    filterType: "options",
    filterOptions: SUBSCRIPTION_STATUSES,
    canMove: true,
    canToggle: false,
    width: "150px",
  },
  VISIBLE_SYNDICATION: {
    className: "Is Visible Syndication",
    getLabels: (t: TFunction) => ({
      header: t("terminal.is_visible_bot"),
      tooltip: t("message.visible_bot_information"),
    }),
    propertyFn: (e) => (
      <span>
        {e.syndicationSettings
          ? e.syndicationSettings?.accessControl === "ALLOW_ALL_USERS"
            ? "Yes"
            : "No"
          : "--"}
      </span>
    ),
    column: "VISIBLE_SYNDICATION",
    filterType: "boolean",
    canMove: true,
    canToggle: true,
    width: "165px",
  },
  BUY_HOLD_RETURN: {
    className: "Buy&Hold Return",
    getLabels: (t: TFunction) => ({
      header: t("common.buy_and_hold_return"),
      tooltip: t("message.holding_profit_loss"),
    }),
    propertyFn: msProp("buyHoldReturn", percentageValue, true, true),
    column: "BUY_HOLD_RETURN",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "125px",
  },
  MIN_ALLOCATION: {
    className: "Minimum Allocated",
    getLabels: (t: TFunction) => ({ header: t("common.minimum_allocation") }),
    hidden: true,
    propertyFn: () => null,
    column: "MIN_ALLOCATION",
    filterType: "number",
    neverVisible: true,
    canMove: true,
  },
  TOTAL_ALLOCATED: {
    className: "Total Allocated",
    getLabels: (t: TFunction) => ({ header: t("common.total_allocation") }),
    hidden: true,
    propertyFn: () => null,
    column: "TOTAL_ALLOCATED",
    filterType: "number",
    neverVisible: true,
    canMove: true,
  },
  MONTHLY_FEE_USD: {
    className: "Monthly Fee",
    getLabels: (t: TFunction) => ({ header: t("common.monthly_fee") }),
    hidden: true,
    propertyFn: () => null,
    column: "MONTHLY_FEE_USD",
    filterType: "number",
    neverVisible: true,
    canMove: true,
  },
  ALLOCATION_FEE_PERC: {
    className: "Allocation Fee",
    getLabels: (t: TFunction) => ({ header: t("common.allocation_fee") }),
    hidden: true,
    propertyFn: () => null,
    column: "ALLOCATION_FEE_PERC",
    filterType: "number",
    neverVisible: true,
    canMove: true,
  },
  SUCCESS_FEE_PERC: {
    className: "Success Fee",
    getLabels: (t: TFunction) => ({ header: t("common.success_fee") }),
    hidden: true,
    propertyFn: () => null,
    column: "SUCCESS_FEE_PERC",
    filterType: "number",
    neverVisible: true,
    canMove: true,
  },
  ACTIVE_SUBSCRIBERS: {
    className: "Active Subscribers",
    getLabels: (t: TFunction) => ({ header: t("common.active_subscribers") }),
    propertyFn: (e) => (
      <span>{e.syndicationSettings?.activeSubscribersCount ?? "--"}</span>
    ),
    column: "ACTIVE_SUBSCRIBERS",
    canMove: true,
    canToggle: true,
    width: "115px",
    unsortable: true,
  },
  NAME: {
    className: "Name",
    getLabels: (t: TFunction) => ({ header: t("common.name") }),
    hidden: true,
    propertyFn: (e) => (
      <span>{e.scriptName ?? e.scriptDetails?.name ?? e.name ?? ""}</span>
    ),
    column: "NAME",
    filterType: "string",
    neverVisible: true,
    width: "250px",
  },
  IS_MULTI_COIN_PACK: {
    className: "Is Multi Coin Pack",
    getLabels: (t: TFunction) => ({ header: t("terminal.is_multi_coin_pack") }),
    hidden: true,
    propertyFn: () => null,
    column: "IS_MULTI_COIN_PACK",
    filterType: "boolean",
    neverVisible: true,
  },
  MULTI_COIN_PACK: {
    className: "Multi Coin Pack",
    getLabels: (t: TFunction) => ({ header: t("terminal.multi_coin_pack") }),
    hidden: true,
    propertyFn: () => null,
    column: "MULTI_COIN_PACK",
    filterType: "string",
    neverVisible: true,
  },
  SHARPE_RATIO: {
    className: "Sharpe Ratio",
    getLabels: (t: TFunction) => ({
      header: t("terminal.sharpe_ratio"),
      tooltip: t("message.sharpe_ratio_information"),
    }),
    propertyFn: msProp("sharpeRatio", trimToTwoDecimals),
    column: "SHARPE_RATIO",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "115px",
  },
  SORTINO_RATIO: {
    className: "Sortino Ratio",
    getLabels: (t: TFunction) => ({
      header: t("terminal.sortino_ratio"),
      tooltip: t("message.sortino_ratio_information"),
    }),
    propertyFn: msProp("sortinoRatio", trimToTwoDecimals),
    column: "SORTINO_RATIO",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "115px",
  },
  TOTAL_REALIZED_GAIN: {
    className: "Total Realized Gain",
    getLabels: (t: TFunction) => ({
      header: t("terminal.total_realized_gain"),
    }),
    propertyFn: (e) => (
      <ExecutionValueCurrency
        value={e.measurements.totalRealizedGain}
        currency={e.currencyPairDetails?.settleCurrency}
        colorSign={true}
        noGradiant={true}
      />
    ),
    column: "TOTAL_REALIZED_GAIN",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "135px",
  },
  TOTAL_REALIZED_LOSS: {
    className: "Total Realized Loss",
    getLabels: (t: TFunction) => ({
      header: t("terminal.total_realized_loss"),
    }),
    propertyFn: (e) => (
      <ExecutionValueCurrency
        value={e.measurements.totalRealizedLoss}
        currency={e.currencyPairDetails?.settleCurrency}
        colorSign={true}
        noGradiant={true}
      />
    ),
    column: "TOTAL_REALIZED_LOSS",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "135px",
  },
  CONSISTENCY_SCORE: {
    className: "Consistency Score",
    getLabels: (t: TFunction) => ({
      header: t("terminal.consistency_score"),
      tooltip: (
        <div>
          <div>{t("message.consistency_score_information")}</div>
          <div>
            <p>
              <Trans
                i18nKey="message.consistency_score_low_percentage_information"
                components={[
                  <ColorSpan
                    key="consistency_score_low_percentage_information"
                    color="meteor"
                  >
                    -
                  </ColorSpan>,
                ]}
              />
            </p>
            <p>
              <Trans
                i18nKey="message.consistency_score_medium_percentage_information"
                components={[
                  <ColorSpan
                    key="consistency_score_medium_percentage_information"
                    color="pear"
                  >
                    -
                  </ColorSpan>,
                ]}
              />
            </p>
            <p>
              <Trans
                i18nKey="message.consistency_score_high_percentage_information"
                components={[
                  <ColorSpan
                    key="consistency_score_high_percentage_information"
                    color="pastelGreen"
                  >
                    -
                  </ColorSpan>,
                ]}
              />
            </p>
          </div>
        </div>
      ),
    }),
    propertyFn: (e) => {
      const consistencyScore = e.measurements?.consistencyScore;

      return (
        <ColorSpan
          color={
            !consistencyScore
              ? "offWhite"
              : consistencyScore >= 60
              ? "pastelGreen"
              : consistencyScore >= 35
              ? "pear"
              : consistencyScore >= 25
              ? "meteor"
              : "offWhite"
          }
        >
          {consistencyScore ?? "--"}
        </ColorSpan>
      );
    },
    column: "CONSISTENCY_SCORE",
    filterType: "number",
    canMove: true,
    canToggle: true,
    width: "135px",
  },
  CREATOR_NAME: {
    className: "Allocation Fee",
    getLabels: (t: TFunction) => ({ header: t("common.creator") }),
    hidden: true,
    propertyFn: () => null,
    column: "CREATOR_NAME",
    filterType: "string",
    neverVisible: true,
    canMove: true,
  },
  IS_PRELOADED_SCRIPT: {
    className: "Is Preloaded Script",
    getLabels: (t: TFunction) => ({ header: t("terminal.is_setup_script") }),
    hidden: true,
    propertyFn: () => null,
    column: "IS_PRELOADED_SCRIPT",
    filterType: "boolean",
    neverVisible: true,
  },
  PRELOADED_SCRIPT: {
    className: "Preloaded Script",
    getLabels: (t: TFunction) => ({ header: t("terminal.setup_script") }),
    hidden: true,
    propertyFn: () => null,
    column: "PRELOADED_SCRIPT",
    filterType: "options",
    neverVisible: true,
    filterOptions: PRELOADED_SCRIPTS,
  },
  IS_BASKET: {
    className: "IS_BASKET",
    getLabels: () => ({ header: "" }),
    hidden: true,
    propertyFn: () => null,
    column: "IS_BASKET",
    filterType: "boolean",
    neverVisible: true,
  },
};
