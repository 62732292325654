import React, { ReactNode } from "react";
import { StyleSheet, css } from "aphrodite";
import colors from "styles/colors";

type Props = Readonly<{
  href?: string;
  target?: string;
  rel?: string;
  color?:
    | "offLightGray"
    | "lightGrayUnderline"
    | "orange"
    | "offWhiteUnderline"
    | "redErrorUnderline";
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  children: ReactNode;
}>;

function ColorAnchor({
  href,
  target,
  rel,
  color = "offLightGray",
  onClick,
  children,
}: Props) {
  return (
    <a
      className={css(styles.anchor, styles[color])}
      onClick={onClick}
      href={href}
      target={target}
      rel={rel}
    >
      {children}
    </a>
  );
}

const styles = StyleSheet.create({
  anchor: {
    ":hover": {
      textDecoration: "underline",
    },
    "@media (min-width: 768px)": {
      ":hover": {
        textDecoration: "underline",
      },
    },
    "@media (max-width: 767.98px)": {
      textDecoration: "underline",
    },
  },
  offLightGray: {
    color: colors.offLightGray,
  },
  orange: {
    color: colors.orange,
  },
  offWhiteUnderline: {
    color: colors.offWhite,
    textDecoration: "underline",
  },
  lightGrayUnderline: {
    color: colors.lightGray,
    textDecoration: "underline",
  },
  redErrorUnderline: {
    color: colors.redError,
    textDecoration: "underline",
  },
});

export default ColorAnchor;
