import React from "react";
import { ID, Execution, ScriptDetails } from "graphql/schema";
import { useExecutionContext } from "contexts/executions/ExecutionContext";
import ExecutionSubscriptionName from "components/executions/list/ExecutionSubscriptionName";
import ExecutionScript from "components/executions/list/ExecutionScript";
import { MY_BOTS_SUBSCRIPTIONS_SUBTAB } from "helpers/navigation";

type Props = Readonly<{
  scriptName: string;
  scriptDetails?: ScriptDetails;
  id: ID;
  algorithmSettings: string;
  favorite?: boolean;
  execution?: Execution;
  isExpanded?: boolean;
}>;

function ExecutionNameContainer({
  scriptName,
  scriptDetails,
  id,
  algorithmSettings,
  favorite,
  execution,
  isExpanded,
}: Props) {
  const { creationPath } = useExecutionContext();

  // if on bot subscription table
  if (creationPath === MY_BOTS_SUBSCRIPTIONS_SUBTAB) {
    return <ExecutionSubscriptionName executionId={id} />;
  }

  return (
    <ExecutionScript
      scriptDetails={scriptDetails}
      scriptName={scriptName}
      algorithmSettings={algorithmSettings}
      id={id}
      favorite={favorite}
      execution={execution}
      isPackListOpen={isExpanded}
    />
  );
}

export default ExecutionNameContainer;
