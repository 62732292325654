import React, { createContext, useState, useCallback, useContext } from "react";
import invariant from "invariant";
import {
  Exchange,
  CurrencyPair,
  ID,
  Currency,
  ExecutionStatus,
} from "../graphql/schema";
import { useBoolean } from "../helpers/hooks";
import { logEvent } from "../helpers/analytics";

export type CheckedExecutionDetails = {
  id: ID;
  status?: ExecutionStatus;
  leverage?: number;
  leverageShort?: number;
  exchange?: Exchange;
  currencyPair?: CurrencyPair;
  multiCoinPackCurrency?: Currency;
};

export type CheckedIdRecord = Record<ID, CheckedExecutionDetails>;

export type CheckedType = "SELECT" | "CREATE_PACK" | "SYNDICATION";

export type MultiCoinPackExchangeAndCurrency = {
  exchange: Exchange;
  settleCurrency: Currency;
  initialCurrencyPair: CurrencyPair;
};

export const EMPTY_OBJ: CheckedIdRecord = {};

export const MAXIMUM_PACK_CURRENCY_PAIR_SIZE = 10;

type Callback = () => void;

interface Context {
  checkedList: CheckedIdRecord;
  toggleChecked: (details: CheckedExecutionDetails) => void;
  updateCheckedList: (ids: CheckedIdRecord) => void;
  isCheckedList: boolean;
  enableCheckedList: Callback;
  disableCheckedList: Callback;
  isCheckedMVTs: boolean;
  enableCheckedMVTs: Callback;
  disableCheckedMVTs: Callback;
  executionsCount: number;
  setExecutionsCount: (ex: number) => void;
  checkedMVTs: CheckedIdRecord;
  toggleCheckedMVTs: (details: CheckedExecutionDetails) => void;
  updateCheckedMVTs: (ids: CheckedIdRecord) => void;
  multivariantsCount: number;
  setMultivariantsCount: (mvt: number) => void;
  exeCheckedType: CheckedType;
  setCheckedType: (type: CheckedType) => void;
  checkedForPack: CheckedIdRecord;
  updateCheckedForPack: (ids: CheckedIdRecord) => void;
  checkedForSyndication: CheckedExecutionDetails | undefined;
  updateCheckedForSyndication: (
    details: CheckedExecutionDetails | undefined,
  ) => void;
  isCreateDropdownOpen: boolean;
  openDropdown: () => void;
  closeDropdown: () => void;
  enableCheckedSelectList: () => void;
  removeCheckedInComposer: (id: string) => void;
  multiCoinPackExchangeAndCurrency:
    | MultiCoinPackExchangeAndCurrency
    | undefined;
  setMultiCoinPackExchangeAndCurrency: (
    exchangeAndCuurency: MultiCoinPackExchangeAndCurrency | undefined,
  ) => void;
  multiCoinPackCurrencyPairs: Record<CurrencyPair, number>;
  setMultiCoinPackCurrencyPairs: (
    currencyPairs: Record<CurrencyPair, number>,
  ) => void;
  canAddToMultiCoinPack: (
    exchange: Exchange,
    settleCurrency: Currency,
    currencyPair: CurrencyPair,
  ) => boolean;
  updateMultiCoinPackCurrencyPairs: (
    checked: boolean,
    exchange: Exchange,
    settleCurrency: Currency,
    currencyPair: CurrencyPair,
  ) => void;
}

export const MultiSelectContext = createContext<Context | undefined>(undefined);

export function useMultiSelectContext() {
  const context = useContext(MultiSelectContext);
  invariant(
    context != null,
    "Component is not a child of MultiSelectContext provider",
  );
  return context;
}

export const MultiSelectContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [checkedList, updateChecked] = useState<CheckedIdRecord>(EMPTY_OBJ);
  const [isCheckedList, enableCheckedList, disableCheckedList] =
    useBoolean(false);

  const [checkedMVTs, updateCheckedMVTs] = useState(EMPTY_OBJ);
  const [isCheckedMVTs, enableCheckedMVTs, disableCheckedMVTs] =
    useBoolean(false);
  const [executionsCount, setExecutionsCount] = useState<number>(0);
  const [multivariantsCount, setMultivariantsCount] = useState<number>(0);
  const [exeCheckedType, setCheckedType] = useState<CheckedType>("SELECT");
  const [checkedForPack, updateCheckedForPack] = useState(EMPTY_OBJ);
  const [checkedForSyndication, updateCheckedForSyndication] =
    useState<CheckedExecutionDetails>();
  const [isCreateDropdownOpen, openDropdown, closeDropdown] = useBoolean(false);
  const [
    multiCoinPackExchangeAndCurrency,
    setMultiCoinPackExchangeAndCurrency,
  ] = useState<MultiCoinPackExchangeAndCurrency>();
  const [multiCoinPackCurrencyPairs, setMultiCoinPackCurrencyPairs] = useState<
    Record<CurrencyPair, number>
  >({});

  const canAddToMultiCoinPack = useCallback(
    (
      exchange: Exchange,
      settleCurrency: Currency,
      currencyPair: CurrencyPair,
    ) => {
      if (
        !multiCoinPackExchangeAndCurrency ||
        (multiCoinPackExchangeAndCurrency &&
          exchange === multiCoinPackExchangeAndCurrency.exchange &&
          settleCurrency === multiCoinPackExchangeAndCurrency.settleCurrency &&
          (multiCoinPackCurrencyPairs[currencyPair] ||
            (!multiCoinPackCurrencyPairs[currencyPair] &&
              Object.keys(multiCoinPackCurrencyPairs).length <
                MAXIMUM_PACK_CURRENCY_PAIR_SIZE)))
      ) {
        return true;
      } else {
        return false;
      }
    },
    [multiCoinPackExchangeAndCurrency, multiCoinPackCurrencyPairs],
  );

  const updateMultiCoinPackCurrencyPairs = useCallback(
    (
      checked: boolean,
      exchange: Exchange,
      settleCurrency: Currency,
      currencyPair: CurrencyPair,
    ) => {
      if (
        checked &&
        !canAddToMultiCoinPack(exchange, settleCurrency, currencyPair)
      ) {
        return;
      }

      const currencyPairs = { ...multiCoinPackCurrencyPairs };

      if (checked) {
        if (currencyPairs[currencyPair]) {
          currencyPairs[currencyPair]++;
        } else {
          currencyPairs[currencyPair] = 1;
        }
      } else if (!checked && currencyPairs[currencyPair] === 1) {
        delete currencyPairs[currencyPair];
      } else if (!checked && currencyPairs[currencyPair]) {
        currencyPairs[currencyPair]--;
      }

      if (Object.keys(currencyPairs).length === 0) {
        setMultiCoinPackExchangeAndCurrency(undefined);
      }

      setMultiCoinPackCurrencyPairs(currencyPairs);
    },
    [
      canAddToMultiCoinPack,
      multiCoinPackCurrencyPairs,
      setMultiCoinPackCurrencyPairs,
      setMultiCoinPackExchangeAndCurrency,
    ],
  );

  const toggle = useCallback(
    (checkedList: CheckedIdRecord, details: CheckedExecutionDetails) => {
      const copiedList: CheckedIdRecord = Object.keys(checkedList).reduce(
        (cur, id) => {
          return Object.assign(cur, { [id]: checkedList[id] });
        },
        {},
      );

      if (copiedList[details.id] || !details) {
        delete copiedList[details.id];
      } else {
        copiedList[details.id] = details;
      }
      return copiedList;
    },
    [],
  );

  const toggleChecked = useCallback(
    (details: CheckedExecutionDetails) => {
      // don't let the user create a pack with more than 50 tests or check more than 1 strategy pack
      if (
        checkedList[details.id] &&
        Object.keys(checkedList).length >= 50 &&
        exeCheckedType === "CREATE_PACK"
      ) {
        return;
      }

      if (exeCheckedType === "CREATE_PACK") {
        updateCheckedForPack(toggle({ ...checkedForPack }, details));
      } else if (exeCheckedType === "SYNDICATION") {
        updateCheckedForSyndication(
          checkedForSyndication ? undefined : details,
        );
      } else {
        updateChecked(toggle({ ...checkedList }, details));
      }
    },
    [
      checkedList,
      toggle,
      exeCheckedType,
      checkedForPack,
      checkedForSyndication,
    ],
  );

  const updateCheckedList = useCallback((ids: CheckedIdRecord) => {
    updateChecked(ids);
  }, []);

  const toggleCheckedMVTs = useCallback(
    (details: CheckedExecutionDetails) => {
      updateCheckedMVTs(toggle({ ...checkedMVTs }, details));
    },
    [checkedMVTs, toggle],
  );

  const enableCheckedSelectList = useCallback(() => {
    enableCheckedList();
    setCheckedType("SELECT");
    logEvent("EnableMultiSelectForExecutionsList");
  }, [enableCheckedList]);

  const removeCheckedInComposer = useCallback(
    (id: string) => {
      if (exeCheckedType === "CREATE_PACK") {
        updateCheckedForPack(toggle({ ...checkedForPack }, { id }));
      } else if (exeCheckedType === "SYNDICATION") {
        updateCheckedForSyndication(undefined);
      }
    },
    [exeCheckedType, toggle, checkedForPack],
  );

  return (
    <MultiSelectContext.Provider
      value={{
        checkedList,
        toggleChecked,
        updateCheckedList,
        isCheckedList,
        enableCheckedList,
        disableCheckedList,
        isCheckedMVTs,
        enableCheckedMVTs,
        disableCheckedMVTs,
        executionsCount,
        setExecutionsCount,
        multivariantsCount,
        setMultivariantsCount,
        checkedMVTs,
        toggleCheckedMVTs,
        updateCheckedMVTs,
        exeCheckedType,
        setCheckedType,
        checkedForPack,
        updateCheckedForPack,
        checkedForSyndication,
        updateCheckedForSyndication,
        isCreateDropdownOpen,
        openDropdown,
        closeDropdown,
        enableCheckedSelectList,
        removeCheckedInComposer,
        multiCoinPackExchangeAndCurrency,
        setMultiCoinPackExchangeAndCurrency,
        multiCoinPackCurrencyPairs,
        setMultiCoinPackCurrencyPairs,
        canAddToMultiCoinPack,
        updateMultiCoinPackCurrencyPairs,
      }}
    >
      {children}
    </MultiSelectContext.Provider>
  );
};
