import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import App from "./App";
import "./i18n/i18n"; // import i18n (needs to be bundled))
import * as serviceWorker from "./serviceWorker";

Modal.setAppElement("#root");

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
