import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import invariant from "invariant";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { Asset, Credential, Exchange } from "graphql/schema";
import {
  GetCredentialsData,
  GET_CREDENTIALS_WITH_FUNDS,
} from "graphql/queries";
import { useAccountSettingsContext } from "./AccountSettingsContext";
import { useUserContext } from "./UserContext";

export type RollupCredential = {
  id: Credential["id"];
  name: Credential["name"];
  assets: Credential["assets"];
};

function combineAssetValue(firstAsset: Asset, secondAsset: Asset): Asset {
  return {
    currency: firstAsset.currency,
    amount: (Number(firstAsset.amount) + Number(secondAsset.amount)).toString(),
    usdValue: (
      Number(firstAsset.usdValue) + Number(secondAsset.usdValue)
    ).toString(),
    btcValue: (
      Number(firstAsset.btcValue) + Number(secondAsset.btcValue)
    ).toString(),
  };
}

function combineAssetsValue(assets: Asset[], rollupAssets: Asset[]) {
  assets.forEach((asset) => {
    const index = rollupAssets.findIndex(
      (rollupAsset) => rollupAsset.currency === asset.currency,
    );
    if (index === -1) {
      rollupAssets.push(asset);
    } else {
      rollupAssets[index] = combineAssetValue(rollupAssets[index], asset);
    }
  });

  return rollupAssets;
}

function totalCredentials(cred: Credential, target: RollupCredential) {
  target.assets.allocated = combineAssetsValue(
    cred.assets.allocated,
    target.assets.allocated,
  );
  target.assets.free = combineAssetsValue(cred.assets.free, target.assets.free);
  target.assets.total = combineAssetsValue(
    cred.assets.total,
    target.assets.total,
  );

  return target;
}

function calculateRollupCredential(
  credentials: Credential[],
  t: TFunction,
): RollupCredential {
  const totalAssets: RollupCredential = {
    id: "",
    name: t("terminal.total_assets"),
    assets: { total: [], allocated: [], free: [], updatedAt: "" },
  };
  return credentials.reduce(
    (prev, cur) => totalCredentials(cur, prev),
    totalAssets,
  );
}

interface UnavailableCredential {
  id: string;
  name: string;
  status: "FAILING" | "DISCONNECTED";
  exchange: Exchange;
}

interface Context {
  credentials: Credential[];
  rollupCredential: RollupCredential;
  loading: boolean;
  refetch: () => void;
  unavailable: UnavailableCredential[];
  setUnavailable: (unavailable: UnavailableCredential[]) => void;
}

export const CredentialContext = createContext<Context | undefined>(undefined);

export function useCredentialContext() {
  const context = useContext(CredentialContext);
  invariant(
    context != null,
    "Component is not a child of CredentialContext provider",
  );
  return context;
}

export function CredentialContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation();

  const { updatedCredentials, setUpdatedCredentials } =
    useAccountSettingsContext();
  const { isLoggedIn } = useUserContext();

  const [unavailable, setUnavailable] = useState<UnavailableCredential[]>([]);

  const { data, loading, refetch } = useQuery<GetCredentialsData>(
    GET_CREDENTIALS_WITH_FUNDS,
    {
      skip: !isLoggedIn,
    },
  );
  const credentials = useMemo(() => data?.credentials ?? [], [data]);
  const rollupCredential = useMemo(
    () => calculateRollupCredential(credentials, t),
    [credentials, t],
  );

  useEffect(() => {
    setUnavailable(
      credentials
        .filter(
          (credential: Credential) =>
            credential.status === "FAILING" ||
            credential.status === "DISCONNECTED",
        )
        .map(
          (credential: Credential) =>
            ({
              id: credential.id,
              name: credential.name,
              status: credential.status,
              exchange: credential.exchange,
            } as UnavailableCredential),
        ),
    );
  }, [credentials]);

  useEffect(() => {
    if (updatedCredentials) {
      setUpdatedCredentials(false);
      refetch();
    }
  }, [updatedCredentials, setUpdatedCredentials, refetch]);

  return (
    <CredentialContext.Provider
      value={{
        credentials,
        rollupCredential,
        loading,
        refetch,
        unavailable,
        setUnavailable,
      }}
    >
      {children}
    </CredentialContext.Provider>
  );
}
