import React from "react";
import gql from "graphql-tag";
import { StyleSheet, css } from "aphrodite";
import { useQuery } from "@apollo/client";
import {
  ExecutionSubscriptionNameQuery,
  ExecutionSubscriptionNameQueryVariables,
} from "__generated__/graphql";
import { useExecutionContext } from "contexts/executions/ExecutionContext";

const QUERY = gql`
  query ExecutionSubscriptionName($botId: ID!, $executionId: ID!) {
    execution(id: $botId) {
      subscription(id: $executionId) {
        shareToken
        id
        ownerNickname
      }
    }
  }
`;

type Props = Readonly<{
  executionId: string;
}>;

function ExecutionSubscriptionName({ executionId }: Props) {
  const { syndicationId } = useExecutionContext();

  const { data } = useQuery<
    ExecutionSubscriptionNameQuery,
    ExecutionSubscriptionNameQueryVariables
  >(QUERY, {
    variables: {
      botId: syndicationId ?? "",
      executionId,
    },
    skip: !syndicationId,
  });

  return (
    <span className={css(styles.name)}>
      {data?.execution?.subscription?.ownerNickname}
    </span>
  );
}

const styles = StyleSheet.create({
  name: {
    display: "flex",
    overflow: "hidden",
    padding: "7px 0",
  },
});

export default ExecutionSubscriptionName;
