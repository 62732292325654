import React from "react";
import { useRouteMatch } from "react-router-dom";
import { ID } from "graphql/schema";
import { BATCHTEST_SUBTAB } from "helpers/navigation";

interface Props {
  scriptVersionSequence?: number;
  multivariantVersionSequence?: number;
}

const ExecutionNumber = ({
  scriptVersionSequence,
  multivariantVersionSequence,
}: Props) => {
  const mvtExecution = useRouteMatch<{ executionId?: ID }>({
    path: `/${BATCHTEST_SUBTAB}/:multivariantId([0-9]+)/:executionId([0-9]+)?`,
  });

  let zeroes = "";
  let executionNumber = "--";
  if (multivariantVersionSequence) {
    const maxZeroes = 3 - `${multivariantVersionSequence}`.length;
    for (let i = 0; i < Math.max(maxZeroes, 0); i++) {
      zeroes += "0";
    }
    executionNumber =
      zeroes +
      `${multivariantVersionSequence}.${scriptVersionSequence ?? "--"}`;
  } else if (multivariantVersionSequence === 0) {
    executionNumber = zeroes + `--.${scriptVersionSequence}`;
    // TODO: Eliminate use of non-null assertion
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } else if (scriptVersionSequence! > 0) {
    const maxZeroes = mvtExecution
      ? 6 - `${scriptVersionSequence}`.length
      : 3 - `${scriptVersionSequence}`.length;
    for (let i = 0; i < Math.max(maxZeroes, 0); i++) {
      zeroes += "0";
    }
    executionNumber = zeroes + `${scriptVersionSequence}`;
  }

  return (
    <div title={executionNumber} className="execution-num">
      {executionNumber}
    </div>
  );
};

export default React.memo(ExecutionNumber);
