import React, { useCallback } from "react";
import classnames from "classnames";
import { useModal } from "react-modal-hook/dist";
import { Trans, useTranslation } from "react-i18next";
import { Execution } from "graphql/schema";
import Modal from "core/modal/Modal";
import LinkButton from "core/forms/LinkButton";
import { titleCase } from "helpers/strings";
import closeImg from "images/close.svg";
import downloadImg from "images/download-simple.svg";
import "./executiondownloadmodal.scss";
import { WithPermission } from "components/settings/hoc/WithPermission";
import { UserPermissions } from "contexts/UserContext";

type TradesDownloadButton = {
  href: string;
  title: string;
};

type DownloadOptionProps = Readonly<{
  title: string;
  description: string;
  downloadLinks: TradesDownloadButton[];
  lastItem?: boolean;
}>;

function DownloadOption({
  title,
  description,
  downloadLinks,
  lastItem,
}: DownloadOptionProps) {
  return (
    <div className={classnames("download-option", { "hide-border": lastItem })}>
      <div className="download-option__info">
        <div className="download-option__info--title">{title}</div>
        <div className="download-option__info--description">{description}</div>
      </div>
      {downloadLinks.map((link, i) => (
        <a
          key={i}
          href={link.href}
          className="download-option--button button button--primary"
        >
          <img src={downloadImg} alt="Download" />
          <div>{link.title}</div>
        </a>
      ))}
    </div>
  );
}

type Props = Readonly<{
  execution: Execution;
  disabled?: boolean;
  className?: string;
  spanClassName?: string;
  closeMenu: () => void;
}>;

function ExecutionDownloadModal({
  execution,
  disabled = false,
  className,
  spanClassName,
  closeMenu,
}: Props) {
  const { t } = useTranslation();

  const [show, hide] = useModal(
    () => (
      <Modal isOpen onRequestClose={hide} className="confirmation-modal">
        <div className="form form--dark">
          <img className="close" src={closeImg} alt="Close" onClick={hide} />
          <span className="header">{t("terminal.download_trades")}</span>
          {execution.syndicationId ? (
            <>
              {/* <DownloadOption
                title={t("terminal.live_run_trades")}
                description={t("message.live_bot_trades")}
                downloadLinks={[
                  {
                    href: `${process.env.REACT_APP_API_URL}/executions/${execution.id}/trades.json`,
                    title: t("terminal.download"),
                  },
                ]}
              />
              <DownloadOption
                title={t("terminal.bot_live_trades")}
                description={t("message.live_bot_trades_since_start")}
                downloadLinks={[
                  {
                    href: `${process.env.REACT_APP_API_URL}/executions/public/${execution.syndicationId}/trades.json`,
                    title: t("terminal.download"),
                  },
                ]}
              />
              <DownloadOption
                title={t("terminal.bot_backtest_trades")}
                description={t("message.backtest_bot_trades")}
                downloadLinks={[
                  {
                    href: `${process.env.REACT_APP_API_URL}/syndications/backtest/public/${execution.syndicationId}/trades.json`,
                    title: t("terminal.download"),
                  },
                ]}
                lastItem
              /> */}
            </>
          ) : execution.type === "SYNDICATION" ? (
            <></>
          ) : (
            <>
              <DownloadOption
                title={`${titleCase(execution.type)} trades`}
                description={`The trades that your ${execution.type.toLowerCase()} run has made.`}
                downloadLinks={[
                  {
                    href: `${process.env.REACT_APP_API_URL}/executions/public/${execution.id}/trades.json`,
                    title: t("terminal.redacted_file"),
                  },
                  {
                    href: `${process.env.REACT_APP_API_URL}/executions/${execution.id}/trades.json`,
                    title: t("terminal.full_details"),
                  },
                ]}
              />
              <div className="footer">
                <Trans
                  i18nKey="message.redacted_trade_file_information"
                  components={[
                    <span
                      key="redacted_trade_file_information"
                      className="bold"
                    ></span>,
                  ]}
                />
              </div>
            </>
          )}
          <LinkButton className="close-button button" onClick={hide}>
            {t("common.close")}
          </LinkButton>
        </div>
      </Modal>
    ),
    [execution, t],
  );

  const onClick = useCallback(
    (e) => {
      e.stopPropagation();

      if (disabled) {
        return;
      }

      show();
      closeMenu();
    },
    [disabled, show, closeMenu],
  );

  return (
    <WithPermission permission={UserPermissions.DOWNLOAD_TRADES}>
      <LinkButton
        onClick={onClick}
        className={className ?? classnames("share", { disabled })}
      >
        <span
          className={spanClassName ?? "action-option"}
          title={
            disabled
              ? t("error.disabled_download_active_trade")
              : execution.name
          }
        >
          {t("terminal.download_trades")}
        </span>
      </LinkButton>
    </WithPermission>
  );
}

export default React.memo(ExecutionDownloadModal);
