import React, { createContext, useCallback, useContext, useState } from "react";
import invariant from "invariant";
import { ExecutionFilter } from "../graphql/schema";
import { logEvent } from "../helpers/analytics";

interface Context {
  prefilter: ExecutionFilter | undefined;
  updatePrefilter: (value?: string) => void;
}

function loadPrefilter(key: string) {
  const saved = window.localStorage.getItem(key);
  if (saved) {
    return JSON.parse(saved);
  }
  return undefined;
}

export const PrefilterContext = createContext<Context | undefined>(undefined);

export function usePrefilterContext() {
  const context = useContext(PrefilterContext);
  invariant(
    context != null,
    "Component is not a child of PrefilterContext provider",
  );
  return context;
}

export function PrefilterContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [prefilter, setPrefilter] = useState<ExecutionFilter | undefined>(
    loadPrefilter("prefilter"),
  );

  const updatePrefilter = useCallback(
    (value?: string) => {
      if (prefilter?.value === value || !value) {
        setPrefilter(undefined);
        localStorage.removeItem("prefilter");
      } else if (value) {
        const filter: ExecutionFilter = {
          filterKey: "TYPE",
          filterType: "EQUALS",
          value,
        };
        setPrefilter(filter);
        localStorage.setItem("prefilter", JSON.stringify(filter));
      }
      logEvent("UpdateHigherLevelFilter", { value: value ?? "ALL" });
    },
    [prefilter, setPrefilter],
  );

  return (
    <PrefilterContext.Provider
      value={{
        prefilter,
        updatePrefilter,
      }}
    >
      {children}
    </PrefilterContext.Provider>
  );
}
