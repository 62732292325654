import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import invariant from "invariant";
import { useTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import { ExecutionStage, ID } from "../graphql/schema";
import { useMultiSelectContext } from "./MultiSelectContext";
import { UserPermissions, useUserContext } from "./UserContext";
import { ITertiaryNavigationProps } from "../core/navigation/TertiaryNavigation";
import { useStateForCB } from "../helpers/hooks";
import { CognitoLoginState } from "helpers/cognitoUtils";
import {
  BATCHTEST_SUBTAB,
  CANDIDATES_SUBTAB,
  COMMUNITY_BOTS_SUBTAB,
  COMMUNITY_SCRIPTS_SUBTAB,
  COMMUNITY_TRADERS_SUBTAB,
  DEFAULT_COMMUNITY_BOTS_SUBTAB,
  DEFAULT_MY_BOTS_SUBTAB,
  EXPERIMENTS_SUBTAB,
  LIVE_BOTS_SUBTAB,
  LIVE_SETUPS_SUBTAB,
  MY_BOTS_SUBSCRIPTIONS_SUBTAB,
  MY_BOTS_SUBTAB,
  MY_PUBLIC_SCRIPTS_SUBTAB,
  MY_SCRIPTS_SUBTAB,
  PAPER_TRADES,
  RELEASE_CANDIDATES_SUBTAB,
  SCRIPTS_TAB,
  SETUPS_TAB,
  TOP_TRADERS_SUBTAB,
} from "helpers/navigation";
import newCreateImg from "../images/new-script.svg";

export const LIVE_SUB_TAB_STORAGE_KEY = "powerbar-live-sub-tab";
export const TESTS_SUB_TAB_STORAGE_KEY = "powerbar-tests-sub-tab";
export const SCRIPTS_SUB_TAB_STORAGE_KEY = "powerbar-scripts-sub-tab";
export const BOTS_SUB_TAB_STORAGE_KEY = "powerbar-bots-sub-tab";
export const TRADERS_SUB_TAB_STORAGE_KEY = "powerbar-traders-sub-tab";

function getLiveNavigationItems(
  t: TFunction,
  experimentNewRuns: number,
  candidateNewRuns: number,
  prereleaseNewRuns: number,
) {
  return [
    {
      title: (
        <div className="stage stage--experiments">
          <span className="stage--desktop">{t("common.paper_trades")}</span>
          <span className="stage--mobile experiments">PT</span>
        </div>
      ),
      path: `/${PAPER_TRADES}`,
      stage: "stage stage--experiments",
      newTests: experimentNewRuns === 0 ? null : experimentNewRuns,
      id: "experiments",
    },
  ];
}

function getTestNavigationItems(
  t: TFunction,
  experimentNewRuns: number,
  candidateNewRuns: number,
  prereleaseNewRuns: number,
) {
  return [
    {
      title: (
        <div>
          <span className="stage--desktop">{t("common.batch_testing")}</span>
          <span className="stage--mobile experiments">{t("common.bt")}</span>
        </div>
      ),
      path: `/${BATCHTEST_SUBTAB}`,
      action: () =>
        localStorage.setItem(TESTS_SUB_TAB_STORAGE_KEY, `/${BATCHTEST_SUBTAB}`),
      id: "multivariants",
    },
    {
      title: (
        <div className="stage stage--experiments">
          <span className="stage--desktop">{t("terminal.experiments")}</span>
          <span className="stage--mobile experiments">3</span>
        </div>
      ),
      path: `/${EXPERIMENTS_SUBTAB}`,
      action: () =>
        localStorage.setItem(
          TESTS_SUB_TAB_STORAGE_KEY,
          `/${EXPERIMENTS_SUBTAB}`,
        ),
      stage: "stage stage--experiments",
      newTests: experimentNewRuns === 0 ? null : experimentNewRuns,
      id: "experiments",
    },
    {
      title: (
        <div className="stage stage--candidates">
          <span className="stage--desktop">{t("terminal.candidates")}</span>
          <span className="stage--mobile">2</span>
        </div>
      ),
      path: `/${CANDIDATES_SUBTAB}`,
      action: () =>
        localStorage.setItem(
          TESTS_SUB_TAB_STORAGE_KEY,
          `/${CANDIDATES_SUBTAB}`,
        ),
      stage: "stage stage--candidates",
      newTests: candidateNewRuns === 0 ? null : candidateNewRuns,
      id: "candidates",
    },
    {
      title: (
        <div className="stage stage--release-candidates">
          <span className="stage--desktop">
            {t("terminal.release_candidates")}
          </span>
          <span className="stage--mobile">1</span>
        </div>
      ),
      path: `/${RELEASE_CANDIDATES_SUBTAB}`,
      action: () =>
        localStorage.setItem(
          TESTS_SUB_TAB_STORAGE_KEY,
          `/${RELEASE_CANDIDATES_SUBTAB}`,
        ),
      stage: "stage stage--release-candidates",
      newTests: prereleaseNewRuns === 0 ? null : prereleaseNewRuns,
      id: "release-candidates",
    },
  ];
}

function getScriptNavigationItems(
  t: TFunction,
  isLoggedIn: boolean,
  hasCommunityPermission: boolean,
) {
  if (!isLoggedIn) {
    return [
      // {
      //   title: t("common.community"),
      //   path: "/scripts/community",
      //   action: () =>
      //     localStorage.setItem(
      //       SCRIPTS_SUB_TAB_STORAGE_KEY,
      //       "/scripts/community",
      //     ),
      //   id: "community",
      // },
    ];
  }

  if (hasCommunityPermission) {
    return [
      {
        title: t("terminal.my_scripts"),
        path: `/${MY_SCRIPTS_SUBTAB}`,
        action: () =>
          localStorage.setItem(
            SCRIPTS_SUB_TAB_STORAGE_KEY,
            `/${MY_SCRIPTS_SUBTAB}`,
          ),
        id: "myscripts",
      },
      {
        title: t("common.community"),
        path: `/${COMMUNITY_SCRIPTS_SUBTAB}`,
        action: () =>
          localStorage.setItem(
            SCRIPTS_SUB_TAB_STORAGE_KEY,
            `/${COMMUNITY_SCRIPTS_SUBTAB}`,
          ),
        id: "community",
      },
      {
        title: t("terminal.my_public_scripts"),
        path: `/${MY_PUBLIC_SCRIPTS_SUBTAB}`,
        action: () =>
          localStorage.setItem(
            SCRIPTS_SUB_TAB_STORAGE_KEY,
            `/${MY_PUBLIC_SCRIPTS_SUBTAB}`,
          ),
        id: "mypublic",
      },
    ];
  }

  return [
    {
      title: t("terminal.my_scripts"),
      path: `/${MY_SCRIPTS_SUBTAB}`,
      action: () =>
        localStorage.setItem(
          SCRIPTS_SUB_TAB_STORAGE_KEY,
          `/${MY_SCRIPTS_SUBTAB}`,
        ),
      id: "myscripts",
    },
    {
      title: t("terminal.my_public_scripts"),
      path: `/${MY_PUBLIC_SCRIPTS_SUBTAB}`,
      action: () =>
        localStorage.setItem(
          SCRIPTS_SUB_TAB_STORAGE_KEY,
          `/${MY_PUBLIC_SCRIPTS_SUBTAB}`,
        ),
      id: "mypublic",
    },
  ];
}

export function getBotsNavigationItems(t: TFunction, botCount?: number) {
  const navigationItems = [
    {
      title: t("common.community"),
      path: `/${COMMUNITY_BOTS_SUBTAB}`,
      action: () =>
        localStorage.setItem(
          BOTS_SUB_TAB_STORAGE_KEY,
          `/${COMMUNITY_BOTS_SUBTAB}`,
        ),
      id: "community",
    },
  ];

  if (botCount && botCount > 0) {
    navigationItems.unshift({
      title: t("terminal.my_monetized_bots"),
      path: `/${MY_BOTS_SUBTAB}`,
      action: () =>
        localStorage.setItem(BOTS_SUB_TAB_STORAGE_KEY, `/${MY_BOTS_SUBTAB}`),
      id: "mybots",
    });
  }

  return navigationItems;
}

function getTradersNavigationItems(t: TFunction) {
  return [
    {
      title: t("common.community"),
      path: `/${COMMUNITY_TRADERS_SUBTAB}`,
      action: () =>
        localStorage.setItem(
          TRADERS_SUB_TAB_STORAGE_KEY,
          `/${COMMUNITY_TRADERS_SUBTAB}`,
        ),
      id: "community",
    },
    {
      title: t("terminal.top_most_popular_number", { number: 10 }),
      path: `/${TOP_TRADERS_SUBTAB}`,
      action: () =>
        localStorage.setItem(
          TRADERS_SUB_TAB_STORAGE_KEY,
          `/${TOP_TRADERS_SUBTAB}`,
        ),
      id: "top",
    },
  ];
}

function getSetupsNavigationItems(t: TFunction) {
  return [
    {
      title: t("terminal.start"),
      path: `/${SETUPS_TAB}`,
      id: "start",
    },
  ];
}

export function useNavButtons() {
  const { t } = useTranslation();

  const { isLoggedIn, setLoginModalState } = useUserContext();
  const { isCreateDropdownOpen, openDropdown, closeDropdown } =
    useMultiSelectContext();

  const onCreateClick = useCallback(() => {
    if (!isLoggedIn) {
      setLoginModalState(CognitoLoginState.LOG_IN);
      return;
    }
    if (isCreateDropdownOpen) {
      closeDropdown();
    } else {
      openDropdown();
    }
  }, [
    isLoggedIn,
    isCreateDropdownOpen,
    closeDropdown,
    openDropdown,
    setLoginModalState,
  ]);

  return useMemo<ITertiaryNavigationProps["buttons"]>(
    () => [
      {
        title: (
          <div>
            <img className="create-button" src={newCreateImg} alt="Create" />
          </div>
        ),
        path: `/${PAPER_TRADES}`,
        action: onCreateClick,
        tooltip: t("common.create"),
        dataFor: "nav-item-create-button",
        className: "icon-button",
      },
      {
        title: (
          <div>
            <img className="create-button" src={newCreateImg} alt="Create" />
          </div>
        ),
        path: `/${EXPERIMENTS_SUBTAB}`,
        action: onCreateClick,
        tooltip: t("common.create"),
        dataFor: "nav-item-create-button",
        className: "icon-button",
      },
      {
        title: t("common.go_back"),
        path: `/${BATCHTEST_SUBTAB}/:id`,
        to: `/${BATCHTEST_SUBTAB}`,
      },
      {
        title: (
          <div>
            <img className="create-button" src={newCreateImg} alt="Create" />
          </div>
        ),
        path: `/${BATCHTEST_SUBTAB}`,
        action: onCreateClick,
        tooltip: t("common.create"),
        dataFor: "nav-item-create-button",
        className: "icon-button",
      },
      {
        title: t("common.go_back"),
        path: `/${MY_BOTS_SUBSCRIPTIONS_SUBTAB}/:id`,
        to: `/${MY_BOTS_SUBTAB}`,
      },
      {
        title: (
          <div>
            <img className="create-button" src={newCreateImg} alt="Create" />
          </div>
        ),
        path: `/${LIVE_BOTS_SUBTAB}`,
        action: onCreateClick,
        tooltip: t("common.create"),
        dataFor: "nav-item-create-button",
        className: "icon-button",
      },
      {
        title: (
          <div>
            <img className="create-button" src={newCreateImg} alt="Create" />
          </div>
        ),
        path: `/${LIVE_SETUPS_SUBTAB}`,
        action: onCreateClick,
        tooltip: t("common.create"),
        dataFor: "nav-item-create-button",
        className: "icon-button",
      },
      {
        title: (
          <div>
            <img className="create-button" src={newCreateImg} alt="Create" />
          </div>
        ),
        path: `/${CANDIDATES_SUBTAB}`,
        action: onCreateClick,
        tooltip: t("common.create"),
        dataFor: "nav-item-create-button",
        className: "icon-button",
      },
      {
        title: (
          <div>
            <img className="create-button" src={newCreateImg} alt="Create" />
          </div>
        ),
        path: `/${RELEASE_CANDIDATES_SUBTAB}`,
        action: onCreateClick,
        tooltip: t("common.create"),
        dataFor: "nav-item-create-button",
        className: "icon-button",
      },
      {
        title: (
          <div>
            <img className="create-button" src={newCreateImg} alt="Create" />
          </div>
        ),
        path: `/${SCRIPTS_TAB}`,
        action: onCreateClick,
        tooltip: t("common.create"),
        dataFor: "nav-item-create-button",
        className: "icon-button",
      },
    ],
    [onCreateClick, t],
  );
}

type NavItemDefs = ITertiaryNavigationProps["items"];
type IconItemBase = {
  alt?: string;
  classNames?: string[];
  iconSrc: string;
  title?: string;
};
type ActionIconItem = IconItemBase & {
  type: "action";
  action: () => void;
  path?: undefined;
};
type LinkIconItem = IconItemBase & {
  type: "link";
  action?: undefined;
  path: string;
};

type IconItem = ActionIconItem | LinkIconItem;

type SubscriptionItem = {
  id: ID;
  name: string;
};

type DefaultBotTab =
  | typeof DEFAULT_COMMUNITY_BOTS_SUBTAB
  | typeof DEFAULT_MY_BOTS_SUBTAB;

interface Context {
  liveNavItems: NavItemDefs;
  testNavItems: NavItemDefs;
  scriptNavItems: NavItemDefs;
  botsNavItems: NavItemDefs;
  tradersNavItems: NavItemDefs;
  setupsNavItems: NavItemDefs;
  // NOTE: Secondary is not used yet, but would be nice to refactor the current display to a unified state with this.
  secondaryNavItems?: NavItemDefs;
  setSecondaryNavItems: (items?: NavItemDefs) => void;
  sideNavItems?: NavItemDefs;
  sideNavIcons?: IconItem[];
  sideNavRightItems?: NavItemDefs;
  sideNavClasses?: string[];
  mainNavClasses?: string[];
  sideNavMinimizeCB?: () => void;
  setSideNavItems: (items?: NavItemDefs) => void;
  setSideNavIcons: (icons?: IconItem[]) => void;
  setSideNavRightItems: (items?: NavItemDefs) => void;
  setMainNavClasses: (classes?: string[]) => void;
  setSideNavClasses: (classes?: string[]) => void;
  setSideNavMinimizeCB: (cb?: () => void) => void;
  selectedScriptId: string | undefined;
  setSelectedScriptId: (scriptId: string | undefined) => void;
  experimentNewRuns: ID[];
  setExperimentNewRuns: (newRuns: ID[]) => void;
  candidateNewRuns: ID[];
  setCandidateNewRuns: (newRuns: ID[]) => void;
  prereleaseNewRuns: ID[];
  setPrereleaseNewRuns: (newRuns: ID[]) => void;
  syndicationNewRuns: ID[];
  setSyndicationNewRuns: (newRuns: ID[]) => void;
  secondaryNavIcons?: IconItem[];
  setSecondaryNavIcons: (icons?: IconItem[]) => void;
  newSubscription: SubscriptionItem | undefined;
  setNewSubscription: (newId: SubscriptionItem | undefined) => void;
  newSubscriptionStep: number;
  setNewSubscriptionStep: (step: number) => void;
  onAddNewRun: (updatedStage: ExecutionStage | "SYNDICATION", id: ID) => void;
  onRemoveNewRun: (stage: ExecutionStage, id: ID) => void;
  defaultBotsTab: DefaultBotTab;
  setDefaultBotsTab: (tabUrl: DefaultBotTab) => void;
}

export const NavigationContext = createContext<Context | undefined>(undefined);

export function useNavigationContext() {
  const context = useContext(NavigationContext);
  invariant(
    context != null,
    "Component is not a child of NavigationContext provider",
  );
  return context;
}

export function NavigationContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation();

  const { isLoggedIn, permissions } = useUserContext();

  const [selectedScriptId, setSelectedScriptId] = useState<
    string | undefined
  >();
  const [secondaryNavItems, setSecondaryNavItems] = useState<
    NavItemDefs | undefined
  >();
  const [sideNavItems, setSideNavItems] = useState<NavItemDefs | undefined>();
  const [sideNavIcons, setSideNavIcons] = useState<IconItem[] | undefined>();
  const [sideNavRightItems, setSideNavRightItems] = useState<
    NavItemDefs | undefined
  >();
  const [sideNavClasses, setSideNavClasses] = useState<string[] | undefined>();
  const [mainNavClasses, setMainNavClasses] = useState<string[] | undefined>();
  const [sideNavMinimizeCB, setSideNavMinimizeCB] = useStateForCB<
    (() => void) | undefined
  >(undefined);
  const [experimentNewRuns, setExperimentNewRuns] = useState<ID[]>(
    JSON.parse(localStorage.getItem("experimentNewRuns") ?? "[]"),
  );
  const [candidateNewRuns, setCandidateNewRuns] = useState<ID[]>(
    JSON.parse(localStorage.getItem("candidateNewRuns") ?? "[]"),
  );
  const [prereleaseNewRuns, setPrereleaseNewRuns] = useState<ID[]>(
    JSON.parse(localStorage.getItem("prereleaseNewRuns") ?? "[]"),
  );
  const [syndicationNewRuns, setSyndicationNewRuns] = useState<ID[]>(
    JSON.parse(localStorage.getItem("syndicationNewRuns") ?? "[]"),
  );
  const [secondaryNavIcons, setSecondaryNavIcons] = useState<
    IconItem[] | undefined
  >();

  // to display the congratulations modal after subscribing to a syndication
  const [newSubscription, setNewSubscription] = useState<
    SubscriptionItem | undefined
  >();
  const [newSubscriptionStep, setNewSubscriptionStep] = useState<number>(0);

  const [defaultBotsTab, setDefaultBotsTab] = useState<DefaultBotTab>(
    DEFAULT_MY_BOTS_SUBTAB,
  );

  const liveNavItems = useMemo(
    () =>
      getLiveNavigationItems(
        t,
        experimentNewRuns.length,
        candidateNewRuns.length,
        prereleaseNewRuns.length,
      ),
    [t, experimentNewRuns, candidateNewRuns, prereleaseNewRuns],
  );
  const testNavItems = useMemo(
    () =>
      getTestNavigationItems(
        t,
        experimentNewRuns.length,
        candidateNewRuns.length,
        prereleaseNewRuns.length,
      ),
    [experimentNewRuns, candidateNewRuns, prereleaseNewRuns, t],
  );

  const scriptNavItems = useMemo(() => {
    const hasCommunityPermission = permissions.includes(
      UserPermissions.STRATEGY_LISTENING,
    );
    return getScriptNavigationItems(t, isLoggedIn, hasCommunityPermission);
  }, [isLoggedIn, t, permissions]);
  const botsNavItems = useMemo(() => getBotsNavigationItems(t), [t]);
  const tradersNavItems = useMemo(() => getTradersNavigationItems(t), [t]);
  const setupsNavItems = useMemo(() => getSetupsNavigationItems(t), [t]);

  const onAddNewRun = useCallback(
    (updatedStage: ExecutionStage | "SYNDICATION", id: ID) => {
      if (updatedStage === "CANDIDATES") {
        const updatedNewRuns = candidateNewRuns.concat(id);
        localStorage.setItem(
          "candidateNewRuns",
          JSON.stringify(updatedNewRuns),
        );
        setCandidateNewRuns(updatedNewRuns);
      } else if (updatedStage === "RELEASE_CANDIDATES") {
        const updatedNewRuns = prereleaseNewRuns.concat(id);
        localStorage.setItem(
          "prereleaseNewRuns",
          JSON.stringify(updatedNewRuns),
        );
        setPrereleaseNewRuns(updatedNewRuns);
      } else if (updatedStage === "SYNDICATION") {
        const updatedNewRuns = syndicationNewRuns.concat(id);
        localStorage.setItem(
          "syndicationNewRuns",
          JSON.stringify(updatedNewRuns),
        );
        setSyndicationNewRuns(updatedNewRuns);
      }
    },
    [
      candidateNewRuns,
      setCandidateNewRuns,
      prereleaseNewRuns,
      setPrereleaseNewRuns,
      syndicationNewRuns,
      setSyndicationNewRuns,
    ],
  );

  const onRemoveNewRun = useCallback(
    (stage: ExecutionStage, id: ID) => {
      if (stage === "CANDIDATES") {
        const updatedNewRuns = candidateNewRuns.filter((runId) => runId !== id);
        localStorage.setItem(
          "candidateNewRuns",
          JSON.stringify(updatedNewRuns),
        );
        setCandidateNewRuns(updatedNewRuns);
      } else if (stage === "RELEASE_CANDIDATES") {
        const updatedNewRuns = prereleaseNewRuns.filter(
          (runId) => runId !== id,
        );
        localStorage.setItem(
          "prereleaseNewRuns",
          JSON.stringify(updatedNewRuns),
        );
        setPrereleaseNewRuns(updatedNewRuns);
      }
    },
    [
      candidateNewRuns,
      setCandidateNewRuns,
      prereleaseNewRuns,
      setPrereleaseNewRuns,
    ],
  );

  const contextObj = {
    liveNavItems,
    testNavItems,
    scriptNavItems,
    botsNavItems,
    tradersNavItems,
    setupsNavItems,
    secondaryNavItems,
    setSecondaryNavItems,
    sideNavItems,
    setSideNavItems,
    sideNavIcons,
    setSideNavIcons,
    sideNavRightItems,
    setSideNavRightItems,
    sideNavClasses,
    setSideNavClasses,
    sideNavMinimizeCB,
    setSideNavMinimizeCB,
    selectedScriptId,
    setSelectedScriptId,
    mainNavClasses,
    setMainNavClasses,
    experimentNewRuns,
    setExperimentNewRuns,
    candidateNewRuns,
    setCandidateNewRuns,
    prereleaseNewRuns,
    setPrereleaseNewRuns,
    syndicationNewRuns,
    setSyndicationNewRuns,
    secondaryNavIcons,
    setSecondaryNavIcons,
    newSubscription,
    setNewSubscription,
    newSubscriptionStep,
    setNewSubscriptionStep,
    onAddNewRun,
    onRemoveNewRun,
    defaultBotsTab,
    setDefaultBotsTab,
  };

  return (
    <NavigationContext.Provider value={contextObj}>
      {children}
    </NavigationContext.Provider>
  );
}
