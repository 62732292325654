import React, { useCallback } from "react";
import { useModal } from "react-modal-hook/dist";
import { useTranslation } from "react-i18next";
import { ID } from "../../../graphql/schema";
import LinkButton from "../../../core/forms/LinkButton";
import ExecutionShareModal from "./ExecutionShareModal";

type Props = Readonly<{
  id: ID;
  shareToken?: string;
  className?: string;
  spanClassName?: string;
  closeMenu?: () => void;
}>;

function ExecutionShare({
  id,
  shareToken,
  className,
  spanClassName,
  closeMenu,
}: Props) {
  const { t } = useTranslation();

  const [show, hide] = useModal(
    () => <ExecutionShareModal hide={hide} id={id} shareToken={shareToken} />,
    [id, shareToken],
  );

  const onClick = useCallback(
    (e) => {
      show();
      if (closeMenu) closeMenu();
      e.stopPropagation();
    },
    [show, closeMenu],
  );

  return (
    <LinkButton onClick={onClick} className={className ?? "share"}>
      <span className={spanClassName ?? "action-option"}>
        {t("terminal.share_run")}
      </span>
    </LinkButton>
  );
}

export default React.memo(ExecutionShare);
