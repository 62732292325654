import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

export enum CognitoLoginState {
  TOTP_MFA_NEEDED,
  SMS_MFA_NEEDED,
  NEW_PASSWORD_REQUIRED,
  MFA_NEEDS_SETUP,
  SUCCESS,
  USER_NOT_CONFIRMED,
  FORGOT_PASSWORD,
  INCORRECT_PASSWORD,
  USER_NOT_FOUND,
  GENERAL_FAILURE,
  USER_PAGE,
  CHANGE_PASSWORD,
  SIGN_UP,
  LOG_IN,
  MIGRATED_USER,
}

export const CognitoSignInResponseCodeMapping: Map<string, CognitoLoginState> =
  new Map([
    // User has MFA enabled, we need to retrieve the code [TOTP/SMS] from the user
    ["SOFTWARE_TOKEN_MFA", CognitoLoginState.TOTP_MFA_NEEDED],
    ["SMS_MFA", CognitoLoginState.SMS_MFA_NEEDED],
    // New user that need to reset their password
    ["NEW_PASSWORD_REQUIRED", CognitoLoginState.NEW_PASSWORD_REQUIRED],
    // User needs to setup their TOTP method
    ["MFA_SETUP", CognitoLoginState.MFA_NEEDS_SETUP],
    ["SUCCESS", CognitoLoginState.SUCCESS],
    // User hasn't completed signup process and hasn't verified their account
    // Not needed in our case, at least for now, since we still depend on inviting
    // users from Cognito console
    ["UserNotConfirmedException", CognitoLoginState.USER_NOT_CONFIRMED],
    // The users password was reset from Cognito console, or the user forgot their password
    ["PasswordResetRequiredException", CognitoLoginState.FORGOT_PASSWORD],
    ["NotAuthorizedException", CognitoLoginState.INCORRECT_PASSWORD],
    ["UserNotFoundException", CognitoLoginState.USER_NOT_FOUND],
    ["GENERAL_FAILURE", CognitoLoginState.GENERAL_FAILURE],
  ]);

export enum MFAType {
  TOTP = "TOTP",
  SMS = "SMS",
  NO_MFA = "NOMFA",
}

export interface CognitoSignInResponse {
  responseCode: CognitoLoginState;
  user: CognitoUser | undefined;
}

export async function signIn(
  username: string,
  password: string,
): Promise<CognitoSignInResponse> {
  try {
    await signOut().catch(() => {});
    const user = await Auth.signIn(username, password);
    const challengeName: string = user.challengeName;

    if (CognitoSignInResponseCodeMapping.has(challengeName)) {
      return {
        responseCode: CognitoSignInResponseCodeMapping.get(
          challengeName,
        ) as CognitoLoginState,
        user,
      };
    } else {
      return { responseCode: CognitoLoginState.SUCCESS, user };
    }
  } catch (err: any) {
    if (CognitoSignInResponseCodeMapping.has(err.code)) {
      return {
        responseCode: CognitoSignInResponseCodeMapping.get(
          err.code,
        ) as CognitoLoginState,
        user: undefined,
      };
    } else {
      return {
        responseCode: CognitoLoginState.GENERAL_FAILURE,
        user: undefined,
      };
    }
  }
}

export function signOut() {
  // https://github.com/aws-amplify/amplify-js/issues/3540
  return Auth.signOut({ global: true }).catch((e) => {
    // TODO: Fix eslint error
    // eslint-disable-next-line no-console
    console.warn("Retrying sign out", e);
    return Auth.signOut();
  });
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return await Auth.currentAuthenticatedUser().then((user) => {
    return Auth.changePassword(user, oldPassword, newPassword);
  });
}

export async function forgotPassword(username: string) {
  return Auth.forgotPassword(username);
}

export async function forgotPasswordSubmit(
  username: string,
  code: string,
  newPassword: string,
) {
  return Auth.forgotPasswordSubmit(username, code, newPassword);
}

export async function completeNewPassword(
  user: CognitoUser | undefined,
  newPassword: string,
) {
  return Auth.completeNewPassword(user, newPassword, {});
}

export async function verifyUserAttribute(attr: string) {
  return Auth.verifyCurrentUserAttribute(attr);
}

export async function verifyUserAttributeSubmit(attr: string, code: string) {
  return Auth.verifyCurrentUserAttributeSubmit(attr, code);
}

export async function getCurrentAuthenticatedUser(bypassCache: boolean) {
  return Auth.currentAuthenticatedUser({ bypassCache });
}

export async function getCurrentSession() {
  return Auth.currentSession();
}

export async function setupTOTP(user: CognitoUser | undefined) {
  return Auth.setupTOTP(user);
}

export async function verifyTotpToken(
  user: CognitoUser | undefined,
  code: string,
) {
  return Auth.verifyTotpToken(user, code);
}

export async function setPreferredMFA(
  user: CognitoUser | undefined,
  mfaType: MFAType,
) {
  return Auth.setPreferredMFA(user, mfaType);
}

export async function getPreferredMFA(user: CognitoUser | undefined) {
  return Auth.getPreferredMFA(user);
}

export function confirmSignIn(
  user: CognitoUser | undefined,
  code: string,
  mfaType?: "SMS_MFA" | "SOFTWARE_TOKEN_MFA" | null,
) {
  return Auth.confirmSignIn(user, code, mfaType);
}

export function signUp(
  email: string,
  password: string,
  companyName: string,
  firstName: string,
  lastName: string,
  userName: string,
  country: string,
  currency: string,
  searchParams: URLSearchParams,
) {
  return Auth.signUp({
    username: email,
    password,
    attributes: {
      email: email,
      "custom:company_name": companyName,
      "custom:first_name": firstName,
      "custom:last_name": lastName,
      "custom:user_name": userName,
      "custom:country": country,
      "custom:currency": currency,
      "custom:referral_id": searchParams.get("fpr") ?? "",
    },
    clientMetadata: {
      utm_id: searchParams.get("utm_id") ?? "",
      utm_source: searchParams.get("utm_source") ?? "",
      utm_medium: searchParams.get("utm_medium") ?? "",
      utm_campaign: searchParams.get("utm_campaign") ?? "",
      utm_term: searchParams.get("utm_term") ?? "",
      utm_content: searchParams.get("utm_content") ?? "",
      url_params: searchParams.toString(),
    },
  });
}

export function confirmSignUp(
  username: string,
  code: string,
  searchParams: URLSearchParams,
) {
  return Auth.confirmSignUp(username, code, {
    clientMetadata: {
      utm_id: searchParams.get("utm_id") ?? "",
      utm_source: searchParams.get("utm_source") ?? "",
      utm_medium: searchParams.get("utm_medium") ?? "",
      utm_campaign: searchParams.get("utm_campaign") ?? "",
      utm_term: searchParams.get("utm_term") ?? "",
      utm_content: searchParams.get("utm_content") ?? "",
      url_params: searchParams.toString(),
    },
  });
}

export function resendSignup(username: string) {
  return Auth.resendSignUp(username);
}

export function clearAllCookies() {
  const allCookies = document.cookie.split(";");

  for (let i = 0; i < allCookies.length; i++) {
    const cookie = allCookies[i];
    document.cookie = cookie
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  }
}

/**
 * Attempt to refresh the current session. The result returned is wheter
 * or not the refresh was successful.
 * This should generally be called after an authentication error to see if the cause was expired cognito tokens.
 */
export async function trySessionRefresh(): Promise<boolean> {
  try {
    const session = await Auth.currentSession();
    return session.isValid();
  } catch (e) {
    // Generally means there was no active session, so nothing was refreshed.
    return false;
  }
}

export async function hasCognitoIdentity() {
  try {
    const user = await getCurrentAuthenticatedUser(false);

    return user != null;
  } catch (e) {
    return false;
  }
}
