import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import invariant from "invariant";
import { useQuery, useMutation } from "@apollo/client";
import { ID, Tag } from "../graphql/schema";
import {
  CREATE_TAG,
  DELETE_TAG,
  UPDATE_TAG,
  CREATE_EXECUTION_TAG,
  CREATE_MULTIVARIANT_TAG,
  CREATE_SCRIPT_TAG,
} from "../graphql/mutations";
import { GetMyTagsData, GET_MY_TAGS } from "../graphql/queries";
import { useAlertContext } from "./AlertContext";
import { useUserContext } from "./UserContext";

interface Context {
  tags: Tag[];
  addTag: (name: string) => Promise<Tag | undefined>;
  removeTag: (id: ID) => void;
  modifyTag: (id: ID, name: string, color: string) => void;
  addExecutionTag: (tagId: ID, executionId: ID) => void;
  addTagToExecution: (name: string, executionId: ID) => void;
  addMultivariantTag: (tagId: ID, multivariantId: ID) => void;
  addTagToMultivariant: (name: string, multivariantId: ID) => void;
  addScriptTag: (tagId: ID, scriptId: ID) => void;
  addTagToScript: (name: string, scriptId: ID) => void;
  loadingCreatedTag: boolean;
  loadingExecutionTag: boolean;
  loadingMultivariantTag: boolean;
  loadingScriptTag: boolean;
}

export const COLORS = [
  "#855B4A",
  "#5C7261",
  "#5D4A85",
  "#A83B90",
  "#855B4A",
  "#01545A",
  "#4676BD",
  "#BA6E12",
  "#0753C5",
  "#B8851E",
  "#809C55",
  "#626262",
];

export const TagsContext = createContext<Context | undefined>(undefined);

export function useTagsContext() {
  const context = useContext(TagsContext);
  invariant(
    context != null,
    "Component is not a child of TagsContext provider",
  );
  return context;
}

export function TagsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation();

  const { addError } = useAlertContext();
  const { isLoggedIn } = useUserContext();

  const { data, refetch } = useQuery<GetMyTagsData>(GET_MY_TAGS, {
    skip: !isLoggedIn,
  });

  const [createTag, { loading: loadingCreatedTag }] = useMutation(CREATE_TAG);
  const [deleteTag] = useMutation(DELETE_TAG);
  const [updateTag] = useMutation(UPDATE_TAG);
  const [createExecutionTag, { loading: loadingExecutionTag }] =
    useMutation(CREATE_EXECUTION_TAG);
  const [createMultivariantTag, { loading: loadingMultivariantTag }] =
    useMutation(CREATE_MULTIVARIANT_TAG);
  const [createScriptTag, { loading: loadingScriptTag }] =
    useMutation(CREATE_SCRIPT_TAG);

  const tags = useMemo(() => data?.tags ?? [], [data?.tags]);

  const selectColor = useCallback(() => {
    // the default color if there are no tags
    if (!tags.length) return "#855B4A";

    const colors: Record<string, number> = {};

    for (const tag of tags) {
      if (colors[tag.color]) {
        colors[tag.color]++;
      } else {
        colors[tag.color] = 1;
      }
    }

    for (const color of COLORS) {
      if (!colors[color]) {
        return color;
      }
    }

    let leastColor = "#855B4A";
    let leastColorAmount = colors["#855B4A"];
    for (const color in colors) {
      if (colors[color] < leastColorAmount) {
        leastColor = color;
        leastColorAmount = colors[color];
      }
    }

    return leastColor;
  }, [tags]);

  const addTag = useCallback(
    async (name: string) => {
      try {
        const color = selectColor();
        const creation = await createTag({
          variables: {
            name,
            color,
          },
        });

        if (creation?.data?.createTag) {
          refetch();
        } else {
          addError(t("error.failed_to_create_tag"));
        }
        return creation?.data?.createTag;
      } catch (e) {
        addError(t("error.failed_to_create_tag"));
        return;
      }
    },
    [createTag, refetch, selectColor, addError, t],
  );

  const addExecutionTag = useCallback(
    (tagId: ID, executionId: ID) => {
      try {
        return createExecutionTag({
          variables: {
            executionId,
            tagId,
          },
        });
      } catch (e) {
        addError(t("error.failed_to_create_tag"));
        return Promise.resolve(true);
      }
    },
    [createExecutionTag, addError, t],
  );

  const addTagToExecution = useCallback(
    (name: string, executionId: ID) => {
      try {
        const color = selectColor();
        return createTag({
          variables: {
            name,
            color,
          },
        }).then((res) => {
          if (res?.data?.createTag) {
            refetch();
            addExecutionTag(res.data.createTag.id, executionId);
          } else {
            addError(t("error.failed_to_create_tag"));
          }
        });
      } catch (e) {
        addError(t("error.failed_to_create_tag"));
        return Promise.resolve(true);
      }
    },
    [createTag, addExecutionTag, addError, selectColor, refetch, t],
  );

  const addMultivariantTag = useCallback(
    (tagId: ID, multivariantId: ID) => {
      try {
        return createMultivariantTag({
          variables: {
            multivariantId,
            tagId,
          },
        });
      } catch (e) {
        addError(t("error.failed_to_create_tag"));
        return Promise.resolve(true);
      }
    },
    [createMultivariantTag, addError, t],
  );

  const addTagToMultivariant = useCallback(
    (name: string, multivariantId: ID) => {
      try {
        const color = selectColor();
        return createTag({
          variables: {
            name,
            color,
          },
        }).then((res) => {
          if (res?.data?.createTag) {
            refetch();
            addMultivariantTag(res.data.createTag.id, multivariantId);
          } else {
            addError(t("error.failed_to_create_tag"));
          }
        });
      } catch (e) {
        addError(t("error.failed_to_create_tag"));
        return Promise.resolve(true);
      }
    },
    [createTag, addMultivariantTag, addError, selectColor, refetch, t],
  );

  const addScriptTag = useCallback(
    (tagId: ID, scriptId: ID) => {
      try {
        return createScriptTag({
          variables: {
            scriptId,
            tagId,
          },
        });
      } catch (e) {
        addError(t("error.failed_to_create_tag"));
        return Promise.resolve(true);
      }
    },
    [createScriptTag, addError, t],
  );

  const addTagToScript = useCallback(
    (name: string, scriptId: ID) => {
      try {
        const color = selectColor();
        return createTag({
          variables: {
            name,
            color,
          },
        }).then((res) => {
          if (res?.data?.createTag) {
            refetch();
            addScriptTag(res.data.createTag.id, scriptId);
          } else {
            addError(t("error.failed_to_create_tag"));
          }
        });
      } catch (e) {
        addError(t("error.failed_to_create_tag"));
        return Promise.resolve(true);
      }
    },
    [createTag, addScriptTag, addError, selectColor, refetch, t],
  );

  const removeTag = useCallback(
    (id: ID) => {
      try {
        return deleteTag({
          variables: {
            id,
          },
        }).then((res) => {
          if (res?.data?.deleteTag) {
            refetch();
          } else {
            addError(t("error.failed_to_remove_tag"));
          }
        });
      } catch (e) {
        addError(t("error.failed_to_remove_tag"));
        return Promise.resolve(true);
      }
    },
    [deleteTag, refetch, addError, t],
  );

  const modifyTag = useCallback(
    (id: ID, name: string, color: string) => {
      try {
        return updateTag({
          variables: {
            id,
            name,
            color,
          },
        }).then((res) => {
          if (res?.data?.updateTag) {
            refetch();
          } else {
            addError(t("error.failed_to_modify_tag"));
          }
        });
      } catch (e) {
        addError(t("error.failed_to_modify_tag"));
        return Promise.resolve(true);
      }
    },
    [updateTag, refetch, addError, t],
  );

  return (
    <TagsContext.Provider
      value={{
        tags,
        addTag,
        removeTag,
        modifyTag,
        addExecutionTag,
        addTagToExecution,
        addMultivariantTag,
        addTagToMultivariant,
        addScriptTag,
        addTagToScript,
        loadingCreatedTag,
        loadingExecutionTag,
        loadingMultivariantTag,
        loadingScriptTag,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
}
