import React, { ReactNode, createContext, useState, useMemo } from "react";
import { ExecutionSort, ScriptSort } from "../graphql/schema";
import { isNotPositive } from "../helpers/numbers";

export type AdvancedSearchTab = "USERS" | "SYNDICATIONS" | "SCRIPTS";
export type SyndicationCategory =
  | "FREE"
  | "TOP_PERFORMERS"
  | "LOWEST_RISK"
  | "BITCOIN"
  | "SLOW_AND_STEADY"
  | "YOLO"
  | "ALTCOINS"
  | "MOST_POPULAR";
export type CreatorCategory = "MOST_POPULAR";

interface Context {
  searchKeywords: string;
  setSearchKeywords: (keywords: string) => void;

  appliedFilters: Record<string, any>;
  setFilters: (filters: Record<string, any>) => void;
  filtersCount: number;

  tab: AdvancedSearchTab;
  setTab: (tab: AdvancedSearchTab) => void;
  performanceRange: number;
  setPerformanceRange: (range: number) => void;
  syndicationSort: ExecutionSort;
  setSyndicationSort: (sort: ExecutionSort) => void;
  scriptSort: ScriptSort;
  setScriptSort: (sort: ScriptSort) => void;

  syndicationCategory: SyndicationCategory | undefined;
  setCategory: (category: SyndicationCategory | undefined) => void;
  creatorCategory: CreatorCategory | undefined;
  setCreatorCategory: (category: CreatorCategory | undefined) => void;
}

const AdvancedSearchContext = createContext<undefined | Context>(undefined);

interface Props {
  children: ReactNode;
}

export function AdvancedSearchContextProvider({ children }: Props) {
  const cachedFilters: Record<string, any> | null = localStorage.getItem(
    "appliedAdvancedSearchFilters",
  )
    ? // TODO: Eliminate use of non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      JSON.parse(localStorage.getItem("appliedAdvancedSearchFilters")!)
    : null;

  const [searchKeywords, setSearchKeywords] = useState<string>(
    cachedFilters?.searchKeywords ?? "",
  );

  const [appliedFilters, setFilters] = useState<Record<string, any>>(
    cachedFilters ?? { withLeverage: true },
  );

  const [tab, setTab] = useState<AdvancedSearchTab>(
    localStorage.getItem("advancedSearchTab") === "USERS" ||
      localStorage.getItem("advancedSearchTab") === "SYNDICATIONS" ||
      localStorage.getItem("advancedSearchTab") === "SCRIPTS"
      ? (localStorage.getItem("advancedSearchTab") as AdvancedSearchTab)
      : "USERS",
  );
  const [performanceRange, setPerformanceRange] = useState<number>(
    localStorage.getItem("syndicationPerformanceRange")
      ? Number(localStorage.getItem("syndicationPerformanceRange"))
      : -1,
  );
  const [syndicationSort, setSyndicationSort] = useState<ExecutionSort>(
    localStorage.getItem("advancedSearchSyndicationSort")
      ? // TODO: Eliminate use of non-null assertion
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        JSON.parse(localStorage.getItem("advancedSearchSyndicationSort")!)
      : { sortDirection: "DESC", sortKey: "STARTED_AT" },
  );
  const [scriptSort, setScriptSort] = useState<ScriptSort>(
    localStorage.getItem("advancedSearchScriptSort")
      ? // TODO: Eliminate use of non-null assertion
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        JSON.parse(localStorage.getItem("advancedSearchScriptSort")!)
      : { sortDirection: "DESC", sortKey: "NAME" },
  );

  const filtersCount = useMemo(() => {
    let count = 0;
    if (appliedFilters.searchKeywords?.length) {
      count++;
    }
    if (tab !== "SCRIPTS") {
      if (appliedFilters.exchanges?.length) {
        count++;
      }
      if (appliedFilters.currencyPair) {
        count++;
      }
      if (
        appliedFilters.minRuntime?.length &&
        !isNotPositive(appliedFilters.minRuntime)
      ) {
        count++;
      }
      if (
        appliedFilters.maxRuntime?.length &&
        !isNotPositive(appliedFilters.maxRuntime)
      ) {
        count++;
      }
      if (
        appliedFilters.minMonthlyFee &&
        !isNotPositive(appliedFilters.minMonthlyFee)
      ) {
        count++;
      }
      if (
        appliedFilters.maxMonthlyFee &&
        !isNotPositive(appliedFilters.maxMonthlyFee)
      ) {
        count++;
      }
      if (
        appliedFilters.minAllocationFee &&
        !isNotPositive(appliedFilters.minAllocationFee)
      ) {
        count++;
      }
      if (
        appliedFilters.maxAllocationFee &&
        !isNotPositive(appliedFilters.maxAllocationFee)
      ) {
        count++;
      }
      if (
        appliedFilters.minSuccessFee &&
        !isNotPositive(appliedFilters.minSuccessFee)
      ) {
        count++;
      }
      if (
        appliedFilters.maxSuccessFee &&
        !isNotPositive(appliedFilters.maxSuccessFee)
      ) {
        count++;
      }
      if (
        appliedFilters.profitLoss === "low" ||
        appliedFilters.profitLoss === "mid" ||
        appliedFilters.profitLoss === "high"
      ) {
        count++;
      }
    } else {
      if (appliedFilters.language) {
        count++;
      }
    }
    return count;
  }, [tab, appliedFilters]);

  const [syndicationCategory, setCategory] = useState<
    SyndicationCategory | undefined
  >();
  const [creatorCategory, setCreatorCategory] = useState<
    CreatorCategory | undefined
  >();

  return (
    <AdvancedSearchContext.Provider
      value={{
        searchKeywords,
        setSearchKeywords,

        appliedFilters,
        setFilters,
        filtersCount,

        tab,
        setTab,
        performanceRange,
        setPerformanceRange,
        syndicationSort,
        setSyndicationSort,
        scriptSort,
        setScriptSort,

        syndicationCategory,
        setCategory,
        creatorCategory,
        setCreatorCategory,
      }}
    >
      {children}
    </AdvancedSearchContext.Provider>
  );
}

export default AdvancedSearchContext;
