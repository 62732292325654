import React, {
  createContext,
  useState,
  useReducer,
  useCallback,
  useContext,
} from "react";
import invariant from "invariant";
import { ColumnDef } from "@tanstack/react-table";
import { ApolloError, gql, useQuery } from "@apollo/client";
import {
  MultivariantColumnContextProviderQuery,
  MultivariantColumnContextProviderQueryVariables,
  MultivariantFilter,
  MultivariantSort as MultivariantSortType,
  MultivariantsTable_MultivariantFragment,
} from "__generated__/graphql";
import { MultivariantColumn, MultivariantSort } from "graphql/schema";
import { MultivariantColumnDefinition } from "./MultivariantColumns";
import { useUserContext } from "contexts/UserContext";
import { DEFAULT_PAGE_SIZE } from "contexts/executions/ExecutionContext";
import { useFilterContext } from "contexts/executions/FilterContext";
import MultivariantsTable from "components/multivariants/MultivariantsTable";

export const MULTIVARIANT_COLUMN_CONTEXT_PROVIDER_QUERY = gql`
  query MultivariantColumnContextProvider(
    $first: Int
    $after: String
    $filters: [MultivariantFilter]
    $sort: MultivariantSort
  ) {
    me {
      batchTests(first: $first, after: $after, filters: $filters, sort: $sort) {
        edges {
          cursor
          node {
            ...MultivariantsTable_multivariant
          }
        }
        pageInfo {
          hasNextPage
        }
      }
      batchTestsCount(filters: $filters)
    }
  }
  ${MultivariantsTable.fragments.multivariants}
`;

interface Context {
  columnDefs: MultivariantColumnDefinition[];
  setColumnDefs: (columns: MultivariantColumnDefinition[]) => void;
  sort: MultivariantSort;
  toggleSort: (sortKey: MultivariantColumn) => void;
  tableColumns: ColumnDef<MultivariantsTable_MultivariantFragment>[];
  batchtests: MultivariantsTable_MultivariantFragment[];
  batchtestsCount: number;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
  onFetchMore: () => void;
}

type Props = Readonly<{
  children: React.ReactNode;
  tableColumns: ColumnDef<MultivariantsTable_MultivariantFragment>[];
  columnDefintions: MultivariantColumnDefinition[];
}>;

const getToggledSort = (
  previousSort: MultivariantSort,
  sortKey: MultivariantColumn,
): MultivariantSort => {
  if (
    previousSort.sortDirection === "ASC" ||
    previousSort.sortKey !== sortKey
  ) {
    localStorage.setItem(
      "multivariantSort",
      JSON.stringify({ sortKey: sortKey, sortDirection: "DESC" }),
    );
    return { sortKey: sortKey, sortDirection: "DESC" };
  } else if (previousSort.sortDirection === "DESC") {
    localStorage.setItem(
      "multivariantSort",
      JSON.stringify({ sortKey: sortKey, sortDirection: "ASC" }),
    );
    return { sortKey: sortKey, sortDirection: "ASC" };
  } else {
    return { sortKey: "CREATION_DATE", sortDirection: "DESC" };
  }
};

export const MultivariantColumnContext = createContext<Context | undefined>(
  undefined,
);

export function useMultivariantColumnContext() {
  const context = useContext(MultivariantColumnContext);
  invariant(
    context != null,
    "Component is not a child of MultivariantColumnContext provider",
  );
  return context;
}

export const MultivariantColumnContextProvider = ({
  children,
  columnDefintions,
  tableColumns,
}: Props) => {
  const { isLoggedIn } = useUserContext();
  const { filters } = useFilterContext();

  const [columnDefs, setColumnDefsInner] = useState(columnDefintions);

  const [sort, toggleSort] = useReducer(
    getToggledSort,
    localStorage.getItem("multivariantSort")
      ? JSON.parse(localStorage.getItem("multivariantSort") ?? "")
      : { sortKey: "CREATION_DATE", sortDirection: "DESC" },
  );

  const setColumnDefs = useCallback(
    (columns: MultivariantColumnDefinition[]) => {
      setColumnDefsInner(columns);
      localStorage.setItem("multivariantColumns", JSON.stringify(columns));
    },
    [],
  );

  const { data, loading, error, fetchMore, refetch } = useQuery<
    MultivariantColumnContextProviderQuery,
    MultivariantColumnContextProviderQueryVariables
  >(MULTIVARIANT_COLUMN_CONTEXT_PROVIDER_QUERY, {
    variables: {
      first: DEFAULT_PAGE_SIZE,
      sort: sort as MultivariantSortType,
      filters: filters as MultivariantFilter[],
    },
    skip: !isLoggedIn,
    notifyOnNetworkStatusChange: true,
  });

  const onFetchMore = useCallback(() => {
    const length = data?.me?.batchTests?.edges?.length;

    if (length && data?.me?.batchTests?.pageInfo?.hasNextPage && !loading) {
      fetchMore({
        variables: {
          first: DEFAULT_PAGE_SIZE,
          after: data?.me?.batchTests?.edges?.[length - 1]?.cursor,
          sort,
          filters,
        },
      });
    }
  }, [data, loading, filters, sort, fetchMore]);

  const batchtests = (data?.me?.batchTests?.edges?.map((edge) => edge?.node) ??
    []) as MultivariantsTable_MultivariantFragment[];

  return (
    <MultivariantColumnContext.Provider
      value={{
        columnDefs,
        setColumnDefs,
        sort,
        toggleSort,
        tableColumns,
        batchtests,
        batchtestsCount: data?.me?.batchTestsCount ?? 0,
        loading,
        error,
        refetch,
        onFetchMore,
      }}
    >
      {children}
    </MultivariantColumnContext.Provider>
  );
};
