import React, { useCallback, useRef } from "react";
import { useModal } from "react-modal-hook/dist";
import StopExecutionsModal from "./StopExecutionsModal";
import { OnConfirmFn } from "../../../core/modal/ConfirmationModal";

function useStopExecutionsModal() {
  const onConfirmRef = useRef(() => {});
  const idsRef = useRef<number[]>([]);
  const totalSelectedRef = useRef<number>(0);

  const [show, hide] = useModal(() => {
    return (
      <StopExecutionsModal
        isOpen
        ids={idsRef.current}
        totalSelected={totalSelectedRef.current}
        onConfirm={onConfirmRef.current}
        onClose={hide}
      />
    );
  }, [idsRef]);

  return useCallback(
    (onConfirm: OnConfirmFn, ids: number[], totalSelected: number) => {
      onConfirmRef.current = onConfirm;
      idsRef.current = ids;
      totalSelectedRef.current = totalSelected;
      show();
    },
    [show],
  );
}

export default useStopExecutionsModal;
