import React from "react";
import {
  ColumnDef,
  ColumnOrderState,
  flexRender,
  getCoreRowModel,
  TableOptions,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import { StyleSheet, css } from "aphrodite";
import colors from "styles/colors";

type Props<D> = Readonly<{
  /**
   * MUST be memoized by parent to prevent unnecessary re-renders
   */
  columns: ColumnDef<D>[];
  /**
   * MUST be memoized by parent to prevent unnecessary re-renders
   */
  data: TableOptions<D>["data"];
  selectedRowId?: string;
  columnVisibility?: VisibilityState;
  columnOrder?: ColumnOrderState;
  onRowClick?: (data: D) => void;
}>;

/**
 * This component emulates the layout behavior of a plain html table, but adds
 * infinite scroll support for loading large data sets just-in-time as they
 * are scrolled into view.
 *
 * Furthermore, content is virtualized with react-virtual so that we only render
 * rows that are visible in the client's viewport.
 */
export default function InfiniteTableSubRows<D>(props: Props<D>) {
  const {
    data,
    columns,
    selectedRowId,
    columnVisibility,
    columnOrder,
    onRowClick,
  } = props;

  const table = useReactTable({
    columns,
    data,
    state: {
      columnVisibility,
      columnOrder,
    },
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  return (
    <>
      {rows.map((row) => {
        return (
          <tr
            key={row.id}
            className={css(
              styles[
                selectedRowId === (row.original as any)?.id ? "activeRow" : "tr"
              ],
              row.index % 2 === 1 && styles.oddRow,
            )}
            onClick={() => onRowClick?.(row.original)}
          >
            {row.getVisibleCells().map((cell) => {
              const isRightAligned =
                cell.column.columnDef.meta?.rightAligned === true;
              const overflowHidden =
                cell.column.columnDef.meta?.overflow !== true;
              return (
                <td
                  key={cell.id}
                  className={css(
                    styles.td,
                    isRightAligned && styles.rightAligned,
                    overflowHidden && styles.overflowHidden,
                  )}
                  style={{ width: cell.column.getSize() }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
}

const styles = StyleSheet.create({
  tr: {
    cursor: "pointer",
    position: "relative",
    ":hover": {
      backgroundColor: colors.charcoal,
    },
  },
  oddRow: {
    backgroundColor: colors.oddRowColor,
  },
  activeRow: {
    cursor: "pointer",
    position: "relative",
    backgroundColor: colors.darkGray,
  },
  td: {
    padding: "0 6px",
    border: `1px solid ${colors.offBlack}`,
    fontWeight: 400,
    whiteSpace: "nowrap",
  },
  overflowHidden: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  rightAligned: {
    textAlign: "right",
  },
});
