import React, { useCallback, useState, useEffect } from "react";
import AutosizeInput from "react-input-autosize";
import { useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import { Tag } from "__generated__/graphql";
import { useTagsContext } from "contexts/TagsContext";
import { useUserContext } from "contexts/UserContext";
import Panel from "core/panels/Panel";
import { useBoolean } from "helpers/hooks";
import closeDarkImg from "images/close-dark.svg";
import closeImg from "images/close.svg";
import checkmarkImg from "images/checkmark.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CircularProgress from "@material-ui/core/CircularProgress";
import colors from "styles/colors";
import { ID } from "graphql/schema";

type Props = Readonly<{
  containerType?: "column" | "field";
  loading?: boolean;
  label?: string;
  selectedTags: Tag[];
  setSelectedTags: (tags: Tag[]) => void;
  onAddTag?: (tagId: ID) => void;
  onRemoveTag?: (tagId: ID) => void;
}>;

function TagsField({
  containerType = "field",
  loading = false,
  label,
  selectedTags,
  setSelectedTags,
  onAddTag,
  onRemoveTag,
}: Props) {
  const { t } = useTranslation();

  const { tags, loadingCreatedTag, removeTag, addTag } = useTagsContext();
  const { isLoggedIn } = useUserContext();

  const [canUpdateTags, setCanUpdate, setCannotUpdate] = useBoolean(false);
  const [tagName, setTagName] = useState("");
  const [visibleUserTags, setUserTags] = useState(tags ?? []);
  const [tagIdToRemove, setTagToRemove] = useState<string>();

  const [isHovering, setIsHovering, setIsNotHovering] = useBoolean(false);

  useEffect(() => {
    if (tags) {
      setUserTags(tags);
    }
  }, [tags, setUserTags]);

  useEffect(() => {
    setUserTags(
      tags.filter((tag) =>
        tag.name.toLowerCase().includes(tagName.toLowerCase()),
      ),
    );
  }, [tagName, tags]);

  const enableEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCanUpdate();
  };

  const onSubmitTag = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Enter" &&
      e.currentTarget.value?.length &&
      !tags.find(
        (tag) =>
          tag.name.toLowerCase() === e.currentTarget.value?.toLowerCase(),
      )
    ) {
      const newTag = await addTag(e.currentTarget.value);
      if (newTag) {
        setSelectedTags(
          selectedTags.concat({
            id: newTag.id,
            name: newTag.name,
            color: newTag.color,
          }),
        );

        onAddTag?.(newTag.id);
      }

      setTagName("");
    }
  };

  const onTagNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value?.length <= 50) {
      setTagName(e.target.value);
    }
  };

  const onAddTagClick = (e: React.MouseEvent, tag: Tag) => {
    e.stopPropagation();

    // to prevent adding the same tag multiple times
    if (!selectedTags.find((t: Tag) => t.id === tag.id)) {
      setSelectedTags(
        selectedTags.concat({ id: tag.id, name: tag.name, color: tag.color }),
      );

      onAddTag?.(tag.id);
    }
  };

  const onRemoveTagClick = (e: React.MouseEvent, tagId: string) => {
    e.stopPropagation();

    // to prevent the user from attempting to removing the same tag after already removing it
    setSelectedTags(selectedTags.filter((tag: Tag) => tag.id !== tagId));

    onRemoveTag?.(tagId);
  };

  const onRemoveUserTagClick = (e: React.MouseEvent, tagId: string) => {
    e.stopPropagation();
    setTagToRemove(tagId);
  };

  const onConfirmRemoveUserTag = () => {
    if (tagIdToRemove) {
      // to prevent the user from attempting to removing the same tag after already removing it
      setSelectedTags(
        selectedTags.filter((tag: Tag) => tag.id !== tagIdToRemove),
      );
      setUserTags(
        visibleUserTags.filter((tag: Tag) => tag.id !== tagIdToRemove),
      );

      onRemoveTag?.(tagIdToRemove) ?? removeTag(tagIdToRemove);
      setTagToRemove(undefined);
    }
  };

  const onCancelRemoveUserTag = () => {
    setTagToRemove(undefined);
  };

  const onClickAway = useCallback(() => {
    setCannotUpdate();
    setTagToRemove(undefined);
  }, [setCannotUpdate, setTagToRemove]);

  const onEscapeKeyPress = useCallback(
    (e) => {
      if (e.key === "Escape") {
        onClickAway();
      }
    },
    [onClickAway],
  );

  useEffect(() => {
    window.addEventListener("keydown", onEscapeKeyPress);
    return () => {
      window.removeEventListener("keydown", onEscapeKeyPress);
    };
  }, [onEscapeKeyPress]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div
      className={css(
        styles[
          containerType === "field"
            ? "tagsFieldContainer"
            : "tagsColumnContainer"
        ],
        containerType === "column" && canUpdateTags && styles.selectedContainer,
      )}
      onMouseOver={setIsHovering}
      onMouseLeave={setIsNotHovering}
    >
      {label && (
        <div
          className={css(
            styles.label,
            (!!selectedTags.length || canUpdateTags || !!tagName.length) &&
              styles.focusedLabel,
          )}
        >
          {label}
        </div>
      )}
      <ClickAwayListener onClickAway={onClickAway}>
        {canUpdateTags ? (
          <div
            className={css(styles.editableTagsContainer)}
            onClick={enableEdit}
          >
            <Panel
              position="center"
              className={css(styles.editableTagsPanel)}
              innerClassName={css(styles.editableTagsPanelInner)}
            >
              <div className={css(styles.tagsField)}>
                {selectedTags.map((tag: Tag) => (
                  <div
                    key={tag.id}
                    className={css(styles.tag)}
                    style={{ background: tag.color }}
                    onClick={(e) => onRemoveTagClick(e, tag.id)}
                  >
                    {tag.name}
                    <img
                      className={css(styles.tagIcon)}
                      src={closeDarkImg}
                      alt="Close"
                    />
                  </div>
                ))}
                {loading ? (
                  <CircularProgress size={12} className={css(styles.loading)} />
                ) : (
                  <AutosizeInput
                    autoFocus
                    className={css(styles.tagInput)}
                    onKeyPress={onSubmitTag}
                    value={tagName}
                    onChange={onTagNameChange}
                  />
                )}
              </div>
            </Panel>
            <Panel
              position="center"
              className={css(styles.userTagsContainer)}
              innerClassName={css(styles.editableTagsPanelInner)}
            >
              <div className={css(styles.userTags)}>
                <div className={css(styles.tagMessage)}>
                  {tags.length
                    ? t("message.select_option_or_create")
                    : t("message.create_tag_and_press_enter")}
                </div>
                {visibleUserTags.map((tag: Tag) => (
                  <div key={tag.id} className={css(styles.tagContainer)}>
                    <span
                      className={css(styles.tag)}
                      style={{ background: tag.color }}
                      onClick={(e) => onAddTagClick(e, tag)}
                    >
                      {tag.name}
                      <img
                        className={css(styles.tagIcon)}
                        src={closeDarkImg}
                        alt="Close"
                        onClick={(e) => onRemoveUserTagClick(e, tag.id)}
                      />
                    </span>
                    {tag.id === tagIdToRemove && (
                      <div className={css(styles.removeButton)}>
                        <span>{t("message.delete_tag_question")}</span>
                        <img
                          className={css(styles.removeButtonIcon)}
                          onClick={onConfirmRemoveUserTag}
                          src={checkmarkImg}
                          alt="confirm"
                        />
                        <img
                          className={css(styles.removeButtonIcon)}
                          onClick={onCancelRemoveUserTag}
                          src={closeImg}
                          alt="close"
                        />
                      </div>
                    )}
                  </div>
                ))}
                {loadingCreatedTag && (
                  <CircularProgress size={12} className={css(styles.loading)} />
                )}
              </div>
            </Panel>
          </div>
        ) : (
          <div
            className={css(styles.tagsField, styles.unfocusedTagsField)}
            onClick={enableEdit}
          >
            {selectedTags.map((tag: Tag) => (
              <div
                key={tag.id}
                className={css(styles.tag)}
                style={{ background: tag.color }}
              >
                {tag.name}
              </div>
            ))}
            <div className={css(styles.userTagInput)}>{tagName}</div>
            {containerType === "column" &&
              !selectedTags.length &&
              !tagName.length &&
              isHovering && (
                <div className={css(styles.userTagInput)}>
                  {t("message.click_to_create_tag")}
                </div>
              )}
          </div>
        )}
      </ClickAwayListener>
    </div>
  );
}

const styles = StyleSheet.create({
  tagsColumnContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  selectedContainer: {
    zIndex: 1,
  },
  tagsFieldContainer: {
    position: "relative",
    height: "30px",
    border: `1px solid ${colors.steel}`,
    borderRadius: "5px",
  },
  label: {
    position: "absolute",
    fontWeight: 400,
    fontSize: "12px",
    color: colors.offWhite,
    top: "9px",
    left: "9px",
    userSelect: "none",
    pointerEvents: "none",
    transition:
      "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
  },
  focusedLabel: {
    transform: " translate3d(-5px, -16px, 0) scale(0.85)",
    background: colors.offBlack,
  },
  editableTagsContainer: {
    display: "flex",
    flexDirection: "column",
    height: "200px",
  },
  editableTagsPanel: {
    height: "182px",
  },
  editableTagsPanelInner: {
    margin: "0",
  },
  tagsField: {
    display: "inline-flex",
    width: "100%",
    height: "32px",
    boxSizing: "border-box",
    padding: "0 10px",
    cursor: "text",
  },
  unfocusedTagsField: {
    overflow: "hidden",
  },
  tagContainer: {
    marginBottom: "5px",
  },
  tag: {
    fontWeight: 600,
    fontSize: "14px",
    height: "20px",
    lineHeight: "20px",
    borderRadius: "5px",
    margin: "auto 5px auto 0",
    padding: "1px 5px 2px",
    boxSizing: "border-box",
    cursor: "pointer",
  },
  tagIcon: {
    marginLeft: "5px",
  },
  loading: {
    color: colors.lightGray,
    margin: "auto 0",
  },
  tagInput: {
    flex: "1",
    background: "unset",
    ":nth-child(1n) > input": {
      height: "32px",
      minWidth: "100%",
      background: "unset",
      border: "unset",
      padding: "0",
      fontWeight: 400,
      fontSize: "14px",
      color: colors.lightGray,
    },
  },
  userTagsContainer: {
    position: "absolute",
    top: "31px",
    width: "100%",
    height: "150px",
    background: colors.black,
  },
  userTags: {
    padding: "6px 10px 5px",
  },
  tagMessage: {
    fontWeight: 500,
    fontSize: "14px",
    color: colors.lightGray,
  },
  removeButton: {
    display: "flex",
    fontWeight: 600,
    fontSize: "11px",
  },
  removeButtonIcon: {
    marginLeft: "5px",
    cursor: "pointer",
  },
  userTagInput: {
    fontWeight: 400,
    fontSize: "14px",
    margin: "auto 0",
  },
  columnInfo: {
    display: "none",
  },
});

export default TagsField;
