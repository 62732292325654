export const externalRedirect = (url: string, options = {} as any) => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (options.skipBeforeUnload) {
    window.onbeforeunload = null;
  }
  const link = document.createElement("a");
  link.href = url;
  link.target = (!(isMobile && isSafari) && options.target) || "_self";

  link.click();
};
