import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Modal from "core/modal/Modal";
import LinkButton from "core/forms/LinkButton";
import Spinner from "core/spinner/Spinner";

type Props = Readonly<{
  label: React.ReactNode;
  isOpen: boolean;
  loading: boolean;
  fullOptions?: boolean;
  onStop: (
    status: "STOPPING_CLOSEMARKET" | "STOPPING_NEXTCLOSE" | "STOPPING",
  ) => void;
  onClose: () => void;
}>;

function StopModal({
  label,
  isOpen,
  loading,
  fullOptions = false,
  onStop,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const stopMarket = useCallback(async () => {
    onStop("STOPPING_CLOSEMARKET");
  }, [onStop]);
  const stopNextClose = useCallback(async () => {
    onStop("STOPPING_NEXTCLOSE");
  }, [onStop]);
  const stopLeavePosition = useCallback(async () => {
    onStop("STOPPING");
  }, [onStop]);

  return (
    <Modal
      isOpen={isOpen}
      label={t("terminal.stop_execution")}
      className="confirmation-modal"
      onRequestClose={onClose}
    >
      <div className="form form--dark">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <span className="header">{t("message.are_you_sure")}</span>
            <span className="label">{label}</span>
            <div className="confirm__prompt">
              {fullOptions ? (
                <>
                  <LinkButton
                    className="button button--secondary bottom-gap"
                    onClick={stopNextClose}
                  >
                    {t("terminal.pause_after_next_close")}
                  </LinkButton>
                  <LinkButton
                    className="button button--secondary bottom-gap"
                    onClick={stopMarket}
                  >
                    {t("terminal.pause_now_and_market_close")}
                  </LinkButton>
                  <LinkButton
                    className="button button--secondary bottom-gap"
                    onClick={stopLeavePosition}
                  >
                    {t("terminal.pause_now_and_leave_open")}
                  </LinkButton>
                </>
              ) : (
                <LinkButton
                  className="button button--secondary bottom-gap"
                  onClick={stopMarket}
                >
                  {t("terminal.pause_now")}
                </LinkButton>
              )}
              <LinkButton className="button button--primary" onClick={onClose}>
                {t("common.cancel")}
              </LinkButton>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default StopModal;
