import React from "react";
import { StyleSheet, css } from "aphrodite";
import { CurrencyPair } from "graphql/schema";
import { useExecutionContext } from "contexts/executions/ExecutionContext";
import {
  CheckedIdRecord,
  MAXIMUM_PACK_CURRENCY_PAIR_SIZE,
  MultiCoinPackExchangeAndCurrency,
  useMultiSelectContext,
} from "contexts/MultiSelectContext";
import { useComposerStateContext } from "contexts/ComposerStateContext";
import LinkButton from "core/forms/LinkButton";
import imgBoxChecked from "images/box-checked.svg";
import imgBoxCheckedBlue from "images/box-checked-blue.svg";
import imgBoxCheckedGreen from "images/box-checked-green.svg";
import imgBoxUnchecked from "images/box-unchecked.svg";
import colors from "styles/colors";

function ExecutionCheckboxHeader() {
  const { executions } = useExecutionContext();
  const {
    exeCheckedType,
    checkedList,
    checkedForPack,
    checkedForSyndication,
    setMultiCoinPackExchangeAndCurrency,
    setMultiCoinPackCurrencyPairs,
    updateCheckedList,
    updateCheckedForPack,
    updateCheckedForSyndication,
  } = useMultiSelectContext();
  const { isMultiCoinPack } = useComposerStateContext();

  const checkedRecords =
    exeCheckedType === "CREATE_PACK" ? checkedForPack : checkedList;
  const isChecked = !!Object.keys(checkedRecords).length;

  const toggleAllClick = () => {
    // toggle all checkbox for syndication is only used to uncheck the current checked row since
    // the checked list is only used to check one strategy pack row at a time
    if (exeCheckedType === "SYNDICATION") {
      updateCheckedForSyndication(undefined);
      return;
    }

    const newCheckedList: CheckedIdRecord = { ...checkedRecords };

    const multiCoinCurrencyPairs: Record<CurrencyPair, number> = {};
    let multiCoinPackExchangeAndCurrency:
      | MultiCoinPackExchangeAndCurrency
      | undefined = undefined;

    // to uncheck all executions within a strategy pack
    if (isChecked) {
      updateCheckedForPack({});
      updateCheckedList({});
      setMultiCoinPackExchangeAndCurrency(undefined);
      setMultiCoinPackCurrencyPairs({});
      return;
    }

    for (const execution of executions) {
      // so checking all executions for strategy pack creation do not check rows that are subscriptions
      if (!isChecked && exeCheckedType === "CREATE_PACK") {
        // don't let the user create a pack with more than 50 tests
        if (
          Object.keys(newCheckedList).filter((id) => newCheckedList[id])
            .length >= 50
        ) {
          break;
        } else if (
          !execution.isPack &&
          !execution.syndicationId &&
          (!isMultiCoinPack ||
            (isMultiCoinPack &&
              (!multiCoinPackExchangeAndCurrency ||
                ((multiCoinCurrencyPairs[execution.currencyPair] ||
                  (!multiCoinCurrencyPairs[execution.currencyPair] &&
                    Object.keys(multiCoinCurrencyPairs).length <
                      MAXIMUM_PACK_CURRENCY_PAIR_SIZE)) &&
                  multiCoinPackExchangeAndCurrency &&
                  multiCoinPackExchangeAndCurrency.exchange ===
                    execution.exchange &&
                  multiCoinPackExchangeAndCurrency.settleCurrency ===
                    execution.currencyPairDetails?.settleCurrency))))
        ) {
          if (isMultiCoinPack) {
            multiCoinCurrencyPairs[execution.currencyPair] =
              (multiCoinCurrencyPairs[execution.currencyPair] ?? 0) + 1;

            if (!multiCoinPackExchangeAndCurrency) {
              multiCoinPackExchangeAndCurrency = {
                exchange: execution.exchange,
                settleCurrency:
                  execution.currencyPairDetails?.settleCurrency ?? "",
                initialCurrencyPair: execution.currencyPair,
              };
            }
          }
          newCheckedList[execution.id] = {
            id: execution.id,
            status: execution.status,
            exchange: execution.exchange,
            currencyPair: execution.currencyPair,
            multiCoinPackCurrency: execution.multiCoinCurrency ?? undefined,
            leverage: execution.leverage,
            leverageShort: execution.leverageShort,
          };
        }
      } else {
        newCheckedList[execution.id] = {
          id: execution.id,
          status: execution.status,
          exchange: execution.exchange,
          currencyPair: execution.currencyPair,
          multiCoinPackCurrency: execution.multiCoinCurrency ?? undefined,
          leverage: execution.leverage,
          leverageShort: execution.leverageShort,
        };
      }
    }

    if (exeCheckedType === "CREATE_PACK") {
      updateCheckedForPack(newCheckedList);

      if (isMultiCoinPack) {
        setMultiCoinPackExchangeAndCurrency(multiCoinPackExchangeAndCurrency);
        setMultiCoinPackCurrencyPairs(multiCoinCurrencyPairs);
      }
    } else {
      updateCheckedList(newCheckedList);
    }
  };

  return (
    <LinkButton
      onClick={toggleAllClick}
      stopPropagation={true}
      className={css(styles.checkboxContainer)}
    >
      <img
        alt="Checked Box"
        className={css(
          styles.checkbox,
          exeCheckedType === "CREATE_PACK" && !isChecked && styles.packCheckbox,
          exeCheckedType === "SYNDICATION" &&
            !checkedForSyndication &&
            styles.monetizeCheckbox,
        )}
        src={
          isChecked ||
          (checkedForSyndication && exeCheckedType === "SYNDICATION")
            ? exeCheckedType === "SELECT"
              ? imgBoxChecked
              : exeCheckedType === "CREATE_PACK"
              ? imgBoxCheckedBlue
              : imgBoxCheckedGreen
            : imgBoxUnchecked
        }
        width={14}
        height={14}
      />
    </LinkButton>
  );
}

const styles = StyleSheet.create({
  checkboxContainer: {
    marginTop: "3px",
    marginLeft: "-4px",
  },
  disabled: {
    opacity: 0,
  },
  checkbox: {
    border: `1px solid ${colors.offBlack}`,
    ":hover": {
      border: `1px solid ${colors.iron}`,
    },
  },
  packCheckbox: {
    border: `1px solid ${colors.pine}`,
    ":hover": {
      border: `1px solid ${colors.palePine}`,
    },
  },
  monetizeCheckbox: {
    border: `1px solid ${colors.flatGreen}`,
    ":hover": {
      border: `1px solid ${colors.lightGreen}`,
    },
  },
});

export default ExecutionCheckboxHeader;
