import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { STOP_EXECUTIONS } from "graphql/mutations";
import { useAlertContext } from "contexts/AlertContext";
import { logEvent } from "helpers/analytics";
import StopModal from "./StopModal";

type Props = Readonly<{
  ids: number[];
  totalSelected: number;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}>;

function StopExecutionsModal({
  ids,
  totalSelected,
  isOpen,
  onConfirm,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const { addError } = useAlertContext();

  const [stopExecutions, { loading }] = useMutation(STOP_EXECUTIONS, {
    onCompleted: (data) => {
      onConfirm();
      onClose();
      const stopStatus = data?.stopExecutions?.executions?.length
        ? data?.stopExecutions?.executions[0].status
        : undefined;
      logEvent("StopExecutions", { stopStatus, stopCount: ids.length });
    },
    onError: () => {
      addError(t("error.stop_execution"));
    },
  });

  const stop = useCallback(
    async (
      stopStatus: "STOPPING_CLOSEMARKET" | "STOPPING_NEXTCLOSE" | "STOPPING",
    ) => {
      await stopExecutions({ variables: { in: { ids, stopStatus } } });
    },
    [ids, stopExecutions],
  );

  return (
    <StopModal
      fullOptions
      isOpen={isOpen}
      label={
        <div>
          <div>
            {t("message.pausing_selected_runs_count", {
              count: totalSelected,
              selected: ids.length,
            })}
          </div>
          <div>
            {t("message.handle_open_positions_prompt", { count: ids.length })}
          </div>
        </div>
      }
      loading={loading}
      onStop={stop}
      onClose={onClose}
    />
  );
}

export default StopExecutionsModal;
