import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import invariant from "invariant";
import { ExecutionFilter, FilterPreset } from "../../graphql/schema";
import { ColumnDefinition } from "../../components/executions/list/ExecutionColumnDefinitions";
import { useSelectedExecutionContext } from "../SelectedExecutionContext";
import { useBoolean } from "../../helpers/hooks";
import { logEvent } from "../../helpers/analytics";

export const DEFAULT_PAGE_SIZE = 20;

interface IFilterContext {
  columnDefs: ColumnDefinition[];
  filters: ExecutionFilter[];
  setFilters: (filters: ExecutionFilter[]) => void;
  addFilter: (filter: ExecutionFilter) => void;
  removeFilter: (index: number) => void;
  onSelectPreset: (preset: FilterPreset) => void;
  selectedAddFilterColumn: ColumnDefinition | undefined;
  setSelectedAddFilterColumn: (column: ColumnDefinition | undefined) => void;
  skipPresetsQuery: boolean;
  visibleFilters: boolean;
  showFilters: () => void;
  hideFilters: () => void;
}

type Props = Readonly<{
  children: React.ReactNode;
  columnDefs: ColumnDefinition[];
  defaultFilters?: ExecutionFilter[];
  onFilterChange?: (filters: ExecutionFilter[]) => void;
  skipPresetsQuery?: boolean;
}>;

export const FilterContext = createContext<IFilterContext | undefined>(
  undefined,
);

export function useFilterContext() {
  const context = useContext(FilterContext);
  invariant(
    context != null,
    "Component is not a child of FilterContext provider",
  );
  return context;
}

export function FilterContextProvider({
  children,
  columnDefs,
  defaultFilters,
  onFilterChange,
  skipPresetsQuery = false,
}: Props) {
  const history = useHistory();

  const { setIsArchivedLiveTests } = useSelectedExecutionContext();

  const [filters, setFiltersInner] = useState<ExecutionFilter[]>(
    defaultFilters || [],
  );
  const setFilters = useCallback(
    (filters: ExecutionFilter[]) => {
      history.replace({ search: "" });
      setFiltersInner(filters);
    },
    [history],
  );

  const [visibleFilters, showFilters, hideFilters] = useBoolean(false);

  const addFilter = useCallback(
    (filter: ExecutionFilter) => {
      logEvent("AddedFilter");
      setFilters([...filters, filter]);
    },
    [filters, setFilters],
  );

  const removeFilter = useCallback(
    (index: number) => {
      logEvent("RemovedFilter");
      // need to copy the array for hooks to work properly
      const updated = filters.slice(0);
      updated.splice(index, 1);
      setFilters(updated);
    },
    [filters, setFilters],
  );

  const onSelectPreset = useCallback(
    (preset: FilterPreset) => {
      logEvent("OpenedFilterPreset");
      const presetFilters = JSON.parse(preset.filters);
      const selectedFilters: ExecutionFilter[] = Array.isArray(presetFilters)
        ? presetFilters
        : [presetFilters];
      setFilters(
        selectedFilters.filter((filter) =>
          columnDefs.some((col) => col.column === filter.filterKey),
        ),
      );
      localStorage.setItem("filterPresetId", preset.id);
    },
    [columnDefs, setFilters],
  );

  useEffect(() => {
    if (onFilterChange && filters) onFilterChange(filters);
  }, [filters, onFilterChange]);

  const [selectedAddFilterColumn, setSelectedAddFilterColumn] = useState<
    ColumnDefinition | undefined
  >(undefined);

  useEffect(() => {
    setIsArchivedLiveTests(
      !!filters.find(
        (filter) =>
          filter.filterKey === "ARCHIVED" && filter.filterType === "IS_TRUE",
      ),
    );
  }, [setIsArchivedLiveTests, filters]);

  return (
    <FilterContext.Provider
      value={{
        columnDefs,
        filters,
        setFilters,
        addFilter,
        removeFilter,
        onSelectPreset,
        selectedAddFilterColumn,
        setSelectedAddFilterColumn,
        skipPresetsQuery,
        visibleFilters,
        showFilters,
        hideFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}
