import { TFunction } from "i18next";
import { titleCase } from "./strings";
import {
  Currency,
  CurrencyPairDetails,
  ExecutionStatus,
  FilterType,
  SyndicationSubscriptionStatus,
} from "../graphql/schema";
import {
  InvoiceStatus,
  PaymentSourceStatus,
  SubscriptionCreationFailureReason,
  SubscriptionUserStatus,
  UserSubscriptionStatus,
} from "../graphql/schema/payments";
import { SidebarPage } from "../contexts/AccountSettingsContext";
import { Exchange, ExecutionType, ScriptLanguage } from "__generated__/graphql";

export function formatScriptLanguage(language: ScriptLanguage, t: TFunction) {
  switch (language) {
    case ScriptLanguage.Pine:
      return t("common.pine_script");
    case ScriptLanguage.Groovy:
    case ScriptLanguage.Groovyjr:
      return t("common.tuned_script");
    case ScriptLanguage.Bytecode:
      throw new Error("Language not supported");
  }
}

export function formatTestType(testType: ExecutionType, t: TFunction) {
  switch (testType) {
    case "BACKTEST":
      return t("message.show_backtests");
    case "PAPERTRADE":
      return t("message.show_paper_trade");
    default:
      throw new Error("Test type not supported");
  }
}

export const formatExchange = (exchange?: string) => {
  switch (exchange) {
    case "BINANCE":
      return "Binance Spot";
    case "BINANCE_FUTURES":
      return "Binance Futures";
    case "BINANCE_COIN_FUTURES":
      return "Binance Coin Futures";
    case "BYBIT":
      return "Bybit Inverse Futures";
    case "BYBIT_LINEAR":
      return "Bybit USDT Futures";
    case "BITMEX":
      return "BitMEX";
    case "FTX_FUTURES":
      return "FTX Futures";
    case "COINBASE":
      return "Coinbase Pro";
    case "KRAKEN":
      return "Kraken Spot";
    case "HUOBI_COIN_SWAPS":
      return "Huobi Coin-M Swaps";
    case "CRYPTO_DOT_COM":
      return "Crypto.com";
    default:
      return "";
  }
};

export function isSpotExchange(exchange?: Exchange) {
  return (
    exchange === Exchange.Binance ||
    exchange === Exchange.Coinbase ||
    exchange === Exchange.CryptoDotCom ||
    exchange === Exchange.Kraken
  );
}

export const isSettleBTC = (currencyPair?: Currency) => {
  return currencyPair === "BTC" || currencyPair === "XBT";
};

export const isActiveUserSubscription = (
  userStatus: SubscriptionUserStatus | undefined,
) => userStatus === "SUBSCRIBED" || userStatus === "NO_RENEWAL";

export const isActiveSubscription = (
  subscriptionStatus: UserSubscriptionStatus | undefined,
) =>
  subscriptionStatus === "ACTIVE" ||
  subscriptionStatus === "NEXT_CYCLE_CANCELLATION_PENDING" ||
  subscriptionStatus === "REBILL_PENDING";

export const isPendingSubscription = (
  subscriptionStatus: UserSubscriptionStatus | undefined,
) => subscriptionStatus === "INITIAL_PAYMENT_PENDING";

export const isFailedSubscription = (
  subscriptionStatus: UserSubscriptionStatus | undefined,
) =>
  subscriptionStatus === "INITIAL_FULFILLMENT_FAILURE" ||
  subscriptionStatus === "INITIAL_PAYMENT_DENIED" ||
  subscriptionStatus === "INITIAL_PAYMENT_FAILED";

export const getCurrencyPairAssets = (
  currencyPairDetails?: CurrencyPairDetails,
  multiCoinCurrency?: Currency,
) => {
  if (multiCoinCurrency) {
    return ["MULTIPLE", multiCoinCurrency];
  } else if (currencyPairDetails) {
    if (currencyPairDetails.settleCurrency !== currencyPairDetails.quote) {
      return [currencyPairDetails.base, currencyPairDetails.quote];
    } else {
      return [
        currencyPairDetails.positionCurrency,
        currencyPairDetails.settleCurrency,
      ];
    }
  } else {
    return [];
  }
};

export function getTranslatedInvoiceStatus(
  status: InvoiceStatus,
  t: TFunction,
) {
  switch (status) {
    case "CANCELLED":
      return t("common.cancelled");
    case "CLOSED":
      return t("common.closed");
    case "EXPIRED":
      return t("common.expired");
    case "FORGIVEN":
      return t("common.forgiven");
    case "OPEN":
      return t("common.pending");
    case "OVERDUE":
      return t("common.overdue");
    case "PAID":
      return t("common.paid");
    case "PENDING_MANUAL_APPROVAL":
      return t("common.pending_manual_approval");
    case "REJECTED":
      return t("common.rejected");
    default:
      return titleCase(status);
  }
}

export function getTranslatedSyndicationSubscriptionStatus(
  status: SyndicationSubscriptionStatus,
  t: TFunction,
) {
  switch (status) {
    case "CANCELLED":
      return t("common.cancelled");
    case "ACTIVE":
      return t("common.active");
    case "NOT_SUBSCRIBED":
      return t("common.expired");
    case "PENDING":
      return t("common.pending");
    default:
      return titleCase(status);
  }
}

export function getTranslatedFilterType(filterType: FilterType, t: TFunction) {
  switch (filterType) {
    case "CONTAINS":
      return t("common.contains");
    case "EQUALS":
      return t("common.equals");
    case "GREATER_THAN":
      return t("common.greater_than");
    case "GREATER_THAN_DAYS":
      return t("common.greater_than_days");
    case "GREATER_THAN_OR_EQUAL":
      return t("common.greater_than_or_equal");
    case "IS_FALSE":
      return t("common.is_false");
    case "IS_TRUE":
      return t("common.is_true");
    case "LESS_THAN":
      return t("common.less_than");
    case "LESS_THAN_DAYS":
      return t("common.less_than_days");
    case "LESS_THAN_OR_EQUAL":
      return t("common.less_than_or_equal");
    case "NOT_CONTAINS":
      return t("common.not_contains");
    case "NOT_EQUALS":
      return t("common.not_equals");
  }
}

export function getTranslatedSubscriptionCreationFailureReason(
  t: TFunction,
  reason?: SubscriptionCreationFailureReason,
) {
  switch (reason) {
    case "INTERNAL_ERROR":
      return t("error.something_unexpected_happened");
    case "PAYMENT_CANCELLED":
      return t("error.payment_cancelled");
    case "PAYMENT_DENIED":
      return t("error.payment_denied");
    case "PAYMENT_EXPIRED":
      return t("error.payment_expired");
    case "PAYMENT_FAILED":
      return t("error.payment_failed");
    default:
      return t("common.failed");
  }
}

export function getTranslatedFailedPaymentStatusReason(
  t: TFunction,
  reason?: string,
) {
  switch (reason) {
    case "CANCELLED":
      return t("error.payment_cancelled");
    case "DENIED":
      return t("error.payment_denied");
    case "EXPIRED":
      return t("error.payment_expired");
    case "FAILED":
      return t("error.payment_failed");
    default:
      return t("error.failed_to_pay_invoice");
  }
}

export function getTranslatedExecutionStatus(
  status: ExecutionStatus,
  t: TFunction,
) {
  switch (status) {
    case "CANCELLED":
      return t("common.cancelled");
    case "DELETING":
      return t("common.deleting");
    case "ENDED":
      return t("common.ended");
    case "FAILED":
      return t("common.failed");
    case "LIQUIDATED":
      return t("common.liquidated");
    case "PENDING":
      return t("common.pending");
    case "RUNNING":
      return t("common.running");
    case "STOPPING":
      return t("common.stopping");
    case "STOPPING_NEXTCLOSE":
      return t("message.stopping_on_next_close");
    case "STOPPING_CLOSEMARKET":
      return t("message.stopping_with_market_close");
  }
}

export function getTranslatedExecutionPositionState(
  t: TFunction,
  positionState?: string,
) {
  switch (positionState) {
    case "OPEN":
      return t("common.open");
    case "LONG":
      return t("common.long");
    case "SHORT":
      return t("common.short");
    case "CLOSED":
    default:
      return t("common.running");
  }
}

export function getTranslatedSidebarPage(sidebar: SidebarPage, t: TFunction) {
  switch (sidebar) {
    case "billing":
      return t("common.billing");
    case "email_preferences":
      return t("common.email_preferences");
    case "exchanges":
      return t("common.exchanges");
    case "general":
      return t("common.general");
    case "referrals":
      return t("common.referrals");
    case "security":
      return t("common.security");
    case "subscriptions":
      return t("common.subscriptions");
    case "plans":
      return t("common.subscriptions");
  }
}

export function getTranslatedPaymentSourceStatus(
  t: TFunction,
  status?: PaymentSourceStatus,
) {
  switch (status) {
    case "ACTIVE":
      return t("common.active_uppercase");
    case "EXPIRED":
      return t("common.expired_uppercase");
    case "FAILED":
    default:
      return t("common.failed_uppercase");
  }
}
