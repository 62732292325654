import React, { useCallback, useMemo } from "react";
import { VisibilityState } from "@tanstack/react-table";
import gql from "graphql-tag";
import {
  ExecutionsInfiniteTable_ExecutionFragment,
  ExecutionSort,
} from "__generated__/graphql";
import { ExecutionColumn, ExecutionType } from "graphql/schema";
import {
  DEFAULT_PAGE_SIZE,
  useExecutionContext,
} from "contexts/executions/ExecutionContext";
import { SYNDICATION_SUBSCRIPTIONS_COLUMN_DEFS } from "contexts/executions/SyndicationSubscriptionsContextProvider";
import { useMultiSelectContext } from "contexts/MultiSelectContext";
import { useFilterContext } from "contexts/executions/FilterContext";
import { ExecutionExtraColumn } from "contexts/executions/ExecutionTableColumns";
import ExecutionsSubRows from "components/executions/list/ExecutionsSubRows";
import InfiniteTable from "core/lists/InfiniteTable";
import {
  LIVE_SETUPS_SUBTAB,
  MY_BOTS_SUBSCRIPTIONS_SUBTAB,
} from "helpers/navigation";

ExecutionsInfiniteTable.fragments = {
  execution: gql`
    fragment ExecutionsInfiniteTable_execution on Execution {
      id
      name
      isPack
      packPercentage
      status
      candleType
      type
      exchange
      currencyPair
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      multiCoinCurrency
      multiCoinPackExecutions {
        currency
        percentage
      }
      candleSize @include(if: $useCandleSize)
      measurements {
        absoluteProfit @include(if: $useAbsoluteProfit)
        numberOfTrades @include(if: $useNumberOfTrades)
        maxDrawdown @include(if: $useMaxDrawdown)
        percentProfitableTrades @include(if: $usePercentProfitableTrades)
        profitability @include(if: $useProfitability)
        avgPositionPrice @include(if: $useAvgPositionPrice)
        avgMonthlyProfit @include(if: $useAvgMonthlyProfit)
        avgWinMonth @include(if: $useAvgWinMonth)
        avgLoseMonth @include(if: $useAvgLoseMonth)
        percProfitableMonths @include(if: $usePercProfitableMonths)
        positionAmount @include(if: $usePositionAmount)
        positionAbsoluteProfit @include(if: $usePositionAbsoluteProfit)
        positionProfitLoss @include(if: $usePositionProfitLoss)
        balance @include(if: $useBalance)
        riskScore @include(if: $useRiskScore)
        avgBarsInTrade
        profitFactor
        avgTradePrice
        lastTick
        positionState
        buyHoldReturn @include(if: $useBuyHoldReturn)
        sharpeRatio @include(if: $useSharpeRatio)
        sortinoRatio @include(if: $useSortinoRatio)
        totalRealizedGain @include(if: $useTotalRealizedGain)
        totalRealizedLoss @include(if: $useTotalRealizedLoss)
        consistencyScore @include(if: $useConsistencyScore)
      }
      favorite
      error
      runtimeSeconds
      shareToken
      scriptDetails {
        id
        scriptId
        revisionId
        parameters {
          fieldName
          readableName
        }
        name
        version @include(if: $useScriptVersion)
        isLatest @include(if: $useScriptVersion)
        createdAt @include(if: $useScriptVersion)
      }
      algorithmSettings @include(if: $useScriptName)
      createdAt @include(if: $useCreatedAt)
      leverage
      leverageShort
      runNumber
      multivariantIterationId
      scriptVersionSequence
      tags @include(if: $useTags) {
        id
        name
        color
      }
      allocation @include(if: $useAllocation)
      rangeStart @include(if: $useStartedAt)
      startedAt @include(if: $useStartedAt)
      rangeEnd @include(if: $useEndedAt)
      endedAt @include(if: $useEndedAt)
      autoRebalance @include(if: $useAutoRebalance)
      stage
      syndicationId
      syndicationSettings @include(if: $isBot) {
        maxAllocation
        minAllocation
        description
        isVisible
        allowList {
          nickname
          canRemove
        }
        accessControl
        shareToken
        activeSubscribersCount @include(if: $useActiveSubscribersCount)
      }
      syndication {
        name
      }
      stopLossPercentage
      stopLossTrailing
      takeProfitPercentage
      takeProfitTrailingPercentage
      notes
      owner {
        nickname
      }
      preloadedScript
      maxBuy
      packExecutionsCount(filters: $filters)
    }
  `,
};

type Props = Readonly<{
  executions: ExecutionsInfiniteTable_ExecutionFragment[];
  selectedRowId?: string;
}>;

function ExecutionsInfiniteTable({ executions, selectedRowId }: Props) {
  const {
    sort,
    columnDefs,
    tableColumns,
    creationPath,
    loadingExecutions,
    onFetchMore,
    toggleSort,
    onExecutionClick,
    setColumnDefs,
  } = useExecutionContext();
  const { isCheckedList } = useMultiSelectContext();
  const { setSelectedAddFilterColumn } = useFilterContext();

  const columns = useMemo(
    () =>
      creationPath === MY_BOTS_SUBSCRIPTIONS_SUBTAB
        ? SYNDICATION_SUBSCRIPTIONS_COLUMN_DEFS
        : columnDefs.filter(
            (c) =>
              !c.hidden &&
              (creationPath !== LIVE_SETUPS_SUBTAB ||
                (creationPath === LIVE_SETUPS_SUBTAB &&
                  c.column !== "PERCENT_PROFITABLE_TRADES" &&
                  c.column !== "SCRIPT_VERSION")),
          ),
    [creationPath, columnDefs],
  );

  const { columnVisibility, columnOrder } = useMemo(() => {
    const columnVisibility: VisibilityState = {};

    columnVisibility[ExecutionExtraColumn.Checkbox] = isCheckedList;

    const columnOrder = columns.map((column) => {
      columnVisibility[column.column] = !column.hidden;
      return column.column;
    });

    return {
      columnVisibility,
      columnOrder: isCheckedList
        ? [ExecutionExtraColumn.Checkbox, ...columnOrder]
        : columnOrder,
    };
  }, [columns, isCheckedList]);

  const getSubRows = useCallback(
    (data: ExecutionsInfiniteTable_ExecutionFragment) => {
      if (data.isPack) {
        return (
          <ExecutionsSubRows
            id={data.id}
            selectedRowId={selectedRowId}
            columnVisibility={columnVisibility}
            columnOrder={columnOrder}
          />
        );
      }
    },
    [selectedRowId, columnVisibility, columnOrder],
  );

  const onColumnResize = useCallback(
    (size: number, id: string) => {
      const index = columns.findIndex((col) => col.column === id);

      if (index === -1) {
        return;
      }

      columns[index].width = `${size}px`;

      setColumnDefs(columns);
    },
    [columns, setColumnDefs],
  );

  return (
    <InfiniteTable<ExecutionsInfiniteTable_ExecutionFragment>
      columns={tableColumns}
      data={executions}
      isFetching={loadingExecutions}
      fetchNextPage={onFetchMore}
      hasNextPage={true}
      selectedRowId={selectedRowId}
      columnVisibility={columnVisibility}
      columnOrder={columnOrder}
      overscanLength={
        executions.length < DEFAULT_PAGE_SIZE ? executions.length : 5
      }
      sort={sort as ExecutionSort}
      setSortKey={(key) => toggleSort(key as ExecutionColumn)}
      onRowClick={(execution) => {
        // sub-execution not supported yet
        onExecutionClick(
          execution.id,
          execution.name ?? undefined,
          execution.type as ExecutionType,
          execution.status,
        );
      }}
      getRowCanExpand={(row) => row.original.isPack}
      getSubRows={getSubRows}
      getSubRowsCount={(data) => data.packExecutionsCount ?? 0}
      onColumnResize={onColumnResize}
      onColumnFilterClick={(id) => {
        const column = columns.find((col) => col.column === id);

        if (!column) {
          return;
        }

        setSelectedAddFilterColumn(column);
      }}
    />
  );
}

export default ExecutionsInfiniteTable;
