import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import { usePageNavigatorContext } from "contexts/PageNavigatorContext";
import { useBoolean } from "helpers/hooks";
import {
  INVEST_TAB,
  isOnInvestPage,
  // isOnPortfolioPage,
  isOnTerminalPage,
  // PORTFOLIO_PAGE,
  SETUPS_TAB,
} from "helpers/navigation";
import arrowsImg from "images/two-arrows-left.svg";
import tunedImg from "images/tuned-logo.svg";
import flowerImg from "images/flower.svg";
import flowerColorImg from "images/flower-linear-gradient.svg";
// import diamondImg from "images/diamond.svg";
// import diamondColorImg from "images/diamond-linear-gradient.svg";
import terminalImg from "images/terminal.svg";
import terminalColorImg from "images/terminal-linear-gradient.svg";
import colors from "styles/colors";

type Props = Readonly<{
  children: React.ReactNode;
}>;

function SideNavigationPanel({ children }: Props) {
  const { t } = useTranslation();

  const history = useHistory();

  const { isOpen, close } = usePageNavigatorContext();

  const onLinkClick = (link: string) => {
    history.push(`/${link}`);
    close();
  };

  return (
    <div className={css(styles.page)}>
      <div
        className={css(styles.container, styles[isOpen ? "visible" : "hidden"])}
      >
        <img
          className={css(styles.close)}
          src={arrowsImg}
          alt="Close"
          onClick={close}
          width={12}
          height={12}
        />
        <div className={css(styles.links)}>
          {/* <NavigationLink
            isActive={!!isOnPortfolioPage(history.location.pathname)}
            icon={
              isOnPortfolioPage(history.location.pathname)
                ? diamondColorImg
                : diamondImg
            }
            label={t("common.portfolio")}
            onClick={() => onLinkClick(PORTFOLIO_PAGE)}
          /> */}
          <NavigationLink
            isActive={!!isOnTerminalPage(history.location.pathname)}
            icon={
              isOnTerminalPage(history.location.pathname)
                ? terminalColorImg
                : terminalImg
            }
            label={t("terminal.terminal")}
            onClick={() => onLinkClick(SETUPS_TAB)}
          />
          <NavigationLink
            isActive={!!isOnInvestPage(history.location.pathname)}
            icon={
              isOnInvestPage(history.location.pathname)
                ? flowerColorImg
                : flowerImg
            }
            label={t("common.invest")}
            onClick={() => onLinkClick(INVEST_TAB)}
          />
        </div>
        <a
          className={css(styles.tunedLogo)}
          href={`https://www.tuned.com/${history.location.search}`}
        >
          <img src={tunedImg} alt="Tuned" height={11} />
        </a>
      </div>
      <div className={css(styles.mainPage)}>{children}</div>
    </div>
  );
}

type LinkProps = Readonly<{
  isActive: boolean;
  icon: string;
  label: string;
  onClick: () => void;
}>;

function NavigationLink({ isActive, icon, label, onClick }: LinkProps) {
  const [isHovering, setIsHovering, setIsNotHovering] = useBoolean(false);

  return (
    <div
      className={css(
        styles.link,
        styles[
          isActive ? "activeLink" : isHovering ? "hoveringLink" : "inactiveLink"
        ],
      )}
      onClick={onClick}
      onMouseOver={setIsHovering}
      onMouseLeave={setIsNotHovering}
    >
      <img className={css(styles.linkIcon)} src={icon} alt={label} width={20} />
      <span className={css(styles.linkLabel)}>{label}</span>
    </div>
  );
}

const styles = StyleSheet.create({
  page: {
    display: "flex",
    height: "100%",
    width: "100%",
  },
  mainPage: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  container: {
    position: "relative",
    display: "flex",
    flexShrink: 0,
    flexDirection: "column",
    background: "black",
    width: 130,
    height: "100%",
    zIndex: 5,
    paddingLeft: "20px",
    boxSizing: "border-box",
    "@media (max-width: 767.98px)": {
      position: "absolute",
    },
  },
  visible: {
    transition: "linear 0.1s",
  },
  hidden: {
    marginLeft: "-130px",
    transition: "linear 0.1s",
  },
  close: {
    position: "absolute",
    top: 13,
    right: 13,
    cursor: "pointer",
  },
  links: {
    padding: "70px 0",
  },
  link: {
    display: "flex",
    marginBottom: "30px",
    cursor: "pointer",
    color: colors.offWhite,
    flexWrap: "wrap",
    overflowWrap: "break-word",
    width: "100%",
  },
  linkIcon: {
    margin: "auto 10px auto 0",
  },
  linkLabel: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "14px",
    margin: "auto auto auto 0",
    maxWidth: "100%",
  },
  activeLink: {
    color: colors.lightGray,
  },
  inactiveLink: {},
  hoveringLink: {
    filter: "grayscale(100%) brightness(232%)",
  },
  tunedLogo: {
    margin: "auto auto 15px 0",
  },
});

export default SideNavigationPanel;
