import React from "react";
import { ActionDefinition } from "./ExecutionActionDefinitions";
import { Execution, ID } from "../../../graphql/schema";

export interface ExecutionActionProps {
  execution: Execution;
  definition: ActionDefinition;
  onShowModal?: (name: string, cb: () => void, execution?: Execution) => void; // Only used when definition requests it
  closeMenu?: () => void;
  onUpdateOpenPacks?: (id: ID, remove: boolean) => void;
}

const ExecutionAction: React.FunctionComponent<ExecutionActionProps> = ({
  execution,
  definition: { action, isActiveFn, actionFn, buttonRender },
  onShowModal,
  closeMenu,
  onUpdateOpenPacks,
}) => {
  if (isActiveFn(execution) === false) return null;

  let clickHandler;
  if (onShowModal === undefined) {
    clickHandler = () => {
      if (execution.isPack) {
        actionFn(execution, onUpdateOpenPacks);
      } else {
        actionFn(execution);
      }
      if (closeMenu) closeMenu();
    };
  } else {
    clickHandler = () => {
      const onComplete = () => {
        if (execution.isPack) {
          actionFn(execution, onUpdateOpenPacks);
        } else {
          actionFn(execution);
        }
      };
      onShowModal(action, onComplete, execution);
      if (closeMenu) closeMenu();
    };
  }

  return buttonRender(clickHandler);
};

export default React.memo(ExecutionAction);
