import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import ColorAnchor from "core/element/ColorAnchor";
import chatBubbleImg from "images/chat-bubble-light-yellow.svg";
import playImg from "images/play-pale-orange.svg";
import colors from "styles/colors";
import { logEvent } from "helpers/analytics";

export type ExchangeLinks = {
  video?: string;
  guide: string;
};

type Props = Readonly<{
  links: ExchangeLinks;
}>;

function ExchangeInfoLinks({ links }: Props) {
  const { t } = useTranslation();

  return (
    <div className={css(styles.guide)}>
      {links.video && (
        <div
          className={css(styles.guideVideo)}
          onClick={() => {
            window.open(links.video, "_blank");
            logEvent("ConnectExchangeModalOpenGuideVideo", {
              video: links.video,
            });
          }}
        >
          <div className={css(styles.guideVideoContainer)}>
            <img
              className={css(styles.guideVideoIcon)}
              src={playImg}
              alt="Play"
              width={23}
              height={26}
            />
            <div className={css(styles.guideVideoLabel)}>
              {t("message.watch_video_guide_uppercase")}
            </div>
          </div>
          <iframe
            src={links.video}
            title={t("message.watch_video_guide_uppercase")}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={css(styles.guideVideoIframe)}
          />
        </div>
      )}
      <div className={css(styles.guideTitle)}>
        <img
          src={chatBubbleImg}
          alt="Help"
          className={css(styles.guideTitleIcon)}
          width={15.75}
          height={14}
        />
        <span>{t("message.need_help_question")}</span>
      </div>
      <div className={css(styles.guideInfo)}>
        <Trans
          i18nKey="message.read_full_guide_or_chat_with_tuned"
          components={[
            <ColorAnchor
              key="read_full_guide_or_chat_with_tuned"
              color="offWhiteUnderline"
              href={links.guide}
              onClick={() =>
                logEvent("ConnectExchangeModalOpenGuideLink", {
                  guide: links.guide,
                })
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              -
            </ColorAnchor>,
          ]}
        />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  guide: {
    flex: "3 1",
    fontWeight: 400,
    fontSize: "14px",
    "@media (min-width: 1020px)": {
      paddingLeft: "65px",
    },
    "@media (max-width: 1019.98px)": {
      borderTop: `1px solid ${colors.offWhite}`,
      paddingTop: "30px",
    },
  },
  guideTitle: {
    color: colors.lightYellow,
    marginBottom: "20px",
  },
  guideTitleIcon: {
    marginRight: "5px",
    marginBottom: "-2px",
  },
  guideInfo: {
    color: colors.offWhite,
  },
  guideVideo: {
    position: "relative",
    borderRadius: "10px",
    background: colors.black,
    border: `5px solid ${colors.steel}`,
    marginBottom: "30px",
    textAlign: "center",
    color: colors.paleOrange,
    fontWeight: 600,
    fontSize: "14px",
    cursor: "pointer",
    height: "150px",
  },
  guideVideoContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    zIndex: 1,
  },
  guideVideoIcon: {
    margin: "auto auto 8px auto",
  },
  guideVideoLabel: {
    height: "18px",
    margin: "8px auto auto",
  },
  guideVideoIframe: {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    userSelect: "none",
    zIndex: 0,
    opacity: 0.1,
  },
});

export default ExchangeInfoLinks;
