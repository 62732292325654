import React, { ReactNode } from "react";
import { StyleSheet, css } from "aphrodite";
import colors from "styles/colors";

type Props = Readonly<{
  children: ReactNode;
  size?: "small" | "medium" | "large";
  color?: "bronze" | "pine" | "bronzeBorder" | "pineBorder";
  disabled?: boolean;
  onClick?: () => void;
}>;

function Button({
  children,
  size = "medium",
  color = "bronze",
  disabled = false,
  onClick,
}: Props) {
  return (
    <button
      className={css(
        styles.button,
        styles[size],
        styles[color],
        styles[disabled ? "disabled" : "enabled"],
      )}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </button>
  );
}

const styles = StyleSheet.create({
  button: {
    boxSizing: "border-box",
    borderRadius: "5px",
    border: 0,
    display: "block",
    fontSize: "14px",
    fontWeight: 600,
    textAlign: "center",
    transition: "0.2s background ease-out",
    width: "100%",
  },
  disabled: {
    cursor: "default",
    color: colors.steel,
    background: colors.offDarkShade,
    border: "unset",
    ":hover": {
      background: colors.offDarkShade,
      border: "unset",
    },
  },
  enabled: {
    cursor: "pointer",
    color: colors.lightGray,
  },
  small: {
    height: "30px",
    lineHeight: "29px",
    fontSize: "12px",
    fontWeight: 500,
  },
  medium: {
    height: "40px",
    lineHeight: "40px",
  },
  large: {
    height: "50px",
    lineHeight: "50px",
  },
  bronze: {
    background: colors.paleOrange,
    ":hover": {
      background: colors.lightBronze,
    },
  },
  pine: {
    background: colors.pine,
    ":hover": {
      background: colors.lightPine,
    },
  },
  bronzeBorder: {
    background: "none",
    border: "1px solid #b6782b",
    ":hover": {
      background: "none",
      border: "1px solid #e39d4c",
    },
  },
  pineBorder: {
    background: "none",
    border: "1px solid #264e5a",
    ":hover": {
      background: "none",
      border: "1px solid #316373",
    },
  },
});

export default Button;
