import { COLUMN_DEFINITIONS } from "./ExecutionColumns";
import { COLUMN_DEFINITIONS as BATCH_TEST_COLUMN_DEFINITIONS } from "./MultivariantColumns";
import { ExecutionColumn } from "../../graphql/schema";
import { ColumnDefinition } from "../../components/executions/list/ExecutionColumnDefinitions";
import { MultivariantColumnDefinition } from "./MultivariantColumns";
import {
  BotColumn,
  BOT_COLUMNS,
  BOT_COLUMN_DEFINITIONS,
  CommunityBotColumnDefinition,
} from "./CommunityBotsContext";
import { ExecutionColumn as GeneratedExecutionColumn } from "__generated__/graphql";

export function getColumns(
  defaultColumns: ColumnDefinition[],
): ColumnDefinition[] {
  const executionColumns = localStorage.getItem("executionColumns");
  let customColumns = defaultColumns;
  if (!executionColumns) {
    localStorage.setItem("executionColumns", JSON.stringify(customColumns));
  } else if (executionColumns) {
    customColumns = getSelectedColumns(executionColumns);
  }
  return customColumns;
}

export function getSelectedColumns(
  executionColumns: string,
): ColumnDefinition[] {
  let customColumns: ColumnDefinition[] = JSON.parse(executionColumns).filter(
    (column: ColumnDefinition) => COLUMN_DEFINITIONS[column.column],
  );

  // move the strategy name columns to the front to replace the deprecated name column
  if (customColumns[0].column !== "SCRIPT_NAME") {
    const stratNameIndex = customColumns.findIndex(
      (column) => column.column === "SCRIPT_NAME",
    );
    customColumns.splice(stratNameIndex, 1);
    const stratVersionIndex = customColumns.findIndex(
      (column) => column.column === "SCRIPT_VERSION",
    );
    customColumns.splice(stratVersionIndex, 1);
    const runNumberIndex = customColumns.findIndex(
      (column) => column.column === "EXECUTION_NUMBER",
    );
    customColumns.splice(runNumberIndex, 1);

    customColumns.splice(0, 0, COLUMN_DEFINITIONS.SCRIPT_NAME);
    customColumns.splice(1, 0, COLUMN_DEFINITIONS.SCRIPT_VERSION);
    customColumns.splice(2, 0, COLUMN_DEFINITIONS.EXECUTION_NUMBER);
    localStorage.setItem("executionColumns", JSON.stringify(customColumns));
  }

  customColumns.forEach((column: ColumnDefinition, i: number) => {
    // fields gets lost when the record is converted to a string
    column.propertyFn = COLUMN_DEFINITIONS[column.column].propertyFn;
    column.getLabels = COLUMN_DEFINITIONS[column.column].getLabels;
    column.filterType = COLUMN_DEFINITIONS[column.column].filterType;
    column.className = COLUMN_DEFINITIONS[column.column].className;
    column.neverVisible = COLUMN_DEFINITIONS[column.column].neverVisible;
    if (COLUMN_DEFINITIONS[column.column]?.filterOptions) {
      column.filterOptions = COLUMN_DEFINITIONS[column.column].filterOptions;
    }
    if (column.column === "TAG_ID") {
      column.isTag = true;
    }
  });

  for (const column in COLUMN_DEFINITIONS) {
    if (!customColumns.find((c: ColumnDefinition) => c.column === column)) {
      let hiddenColumn = COLUMN_DEFINITIONS[column as ExecutionColumn];
      customColumns.push(hiddenColumn);
    }
  }

  // remove the invisible columns
  customColumns = customColumns.filter(
    (column) => !column.neverVisible && column.column !== "SUBSCRIPTION_STATUS",
  );

  return customColumns;
}

export function getSelectedBatchTestColumns(multivariantColumns: string) {
  let customColumns: MultivariantColumnDefinition[] = JSON.parse(
    multivariantColumns,
  ).filter((column: MultivariantColumnDefinition) =>
    BATCH_TEST_COLUMN_DEFINITIONS.find((c) => c.column === column.column),
  );

  if (!customColumns.length) {
    customColumns = BATCH_TEST_COLUMN_DEFINITIONS;
    localStorage.removeItem("multivariantColumns");
  }

  // move the strategy name columns to the front to replace the deprecated name column
  if (customColumns[0].column !== "SCRIPT_NAME") {
    const stratNameIndex = customColumns.findIndex(
      (column) => column.column === "SCRIPT_NAME",
    );
    if (stratNameIndex >= 0) customColumns.splice(stratNameIndex, 1);
    const stratVersionIndex = customColumns.findIndex(
      (column) => column.column === "SCRIPT_VERSION",
    );
    if (stratVersionIndex >= 0) customColumns.splice(stratVersionIndex, 1);
    const runNumberIndex = customColumns.findIndex(
      (column) => column.column === "EXECUTION_NUMBER",
    );
    if (runNumberIndex >= 0) customColumns.splice(runNumberIndex, 1);

    customColumns.splice(0, 0, BATCH_TEST_COLUMN_DEFINITIONS[0]);
    customColumns.splice(1, 0, BATCH_TEST_COLUMN_DEFINITIONS[1]);
    customColumns.splice(2, 0, BATCH_TEST_COLUMN_DEFINITIONS[2]);
    customColumns.splice(3, 0, BATCH_TEST_COLUMN_DEFINITIONS[3]);

    localStorage.setItem("multivariantColumns", JSON.stringify(customColumns));
  }

  customColumns.forEach((column, _) => {
    const selectedColumn = BATCH_TEST_COLUMN_DEFINITIONS?.find(
      (c) => column.column === c.column,
    );
    // fields gets lost when the record is converted to a string
    column.propertyFn = selectedColumn?.propertyFn ?? (() => null);
    column.getLabels = selectedColumn?.getLabels ?? (() => ({ header: "" }));
  });

  for (const column of BATCH_TEST_COLUMN_DEFINITIONS) {
    if (!customColumns.find((c) => c.column === column.column)) {
      customColumns.push(column);
    }
  }

  return customColumns;
}

export function getBotColumns(
  defaultColumns: CommunityBotColumnDefinition[],
): CommunityBotColumnDefinition[] {
  const executionColumns = localStorage.getItem("publicBotColumns");
  let customColumns = defaultColumns;
  if (!executionColumns) {
    localStorage.setItem("publicBotColumns", JSON.stringify(customColumns));
  } else if (executionColumns) {
    customColumns = getSelectedPublicBotColumns(executionColumns);
  }
  return customColumns;
}

export function getSelectedPublicBotColumns(botColumns: string) {
  const customColumns: CommunityBotColumnDefinition[] = JSON.parse(
    botColumns,
  ).filter(
    (column: CommunityBotColumnDefinition) =>
      BOT_COLUMN_DEFINITIONS[column.column],
  );

  const botExclusiveColumns: BotColumn[] = [
    GeneratedExecutionColumn.MinAllocation,
    GeneratedExecutionColumn.TotalAllocated,
    GeneratedExecutionColumn.MonthlyFeeUsd,
    GeneratedExecutionColumn.AllocationFeePerc,
    GeneratedExecutionColumn.SuccessFeePerc,
  ];

  const nameIndex = customColumns.findIndex(
    (column) => column.column === GeneratedExecutionColumn.ScriptName,
  );
  const creatorNameIndex = customColumns.findIndex(
    (column) => column.column === GeneratedExecutionColumn.CreatorName,
  );

  if (nameIndex !== 0) {
    if (nameIndex !== -1) {
      customColumns.splice(nameIndex, 1);
    }

    customColumns.unshift(BOT_COLUMN_DEFINITIONS.SCRIPT_NAME);
  }

  if (creatorNameIndex !== 1) {
    if (creatorNameIndex !== -1) {
      customColumns.splice(creatorNameIndex, 1);
    }

    customColumns.splice(1, 0, BOT_COLUMN_DEFINITIONS.CREATOR_NAME);
  }

  customColumns.forEach((column: CommunityBotColumnDefinition) => {
    // fields gets lost when the record is converted to a string
    column.propertyFn = BOT_COLUMN_DEFINITIONS[column.column].propertyFn;
    column.getLabels = BOT_COLUMN_DEFINITIONS[column.column].getLabels;
    column.filterType = BOT_COLUMN_DEFINITIONS[column.column].filterType;
    column.className = BOT_COLUMN_DEFINITIONS[column.column].className;
    column.filterOptions = BOT_COLUMN_DEFINITIONS[column.column].filterOptions;
    column.sortable = BOT_COLUMN_DEFINITIONS[column.column].sortable;
    column.className = BOT_COLUMN_DEFINITIONS[column.column].className;

    if (botExclusiveColumns.includes(column.column)) {
      botExclusiveColumns.splice(
        botExclusiveColumns.findIndex((col) => col === column.column),
        1,
      );
    }
  });

  botExclusiveColumns.forEach((column) => {
    customColumns.push(BOT_COLUMN_DEFINITIONS[column]);
  });

  // get all the missing columns if there are any
  for (const column of BOT_COLUMNS) {
    if (!customColumns.find((c) => c.column === column.column)) {
      customColumns.push(column);
    }
  }

  return customColumns;
}
