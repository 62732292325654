import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import backImg from "images/back-arrow-off-light-gray.svg";
import { useBoolean } from "helpers/hooks";
import colors from "styles/colors";

type Props = Readonly<{
  onClick: () => void;
}>;

function ConnectCredentialBackButton({ onClick }: Props) {
  const { t } = useTranslation();

  const [isHovering, setIsHovering, setIsNotHovering] = useBoolean(false);

  return (
    <div
      className={css(styles.backButton)}
      onClick={onClick}
      onMouseOver={setIsHovering}
      onMouseLeave={setIsNotHovering}
    >
      <img src={backImg} alt="Back" width={7} height={12} />
      <span
        className={css(
          styles.backButtonLabel,
          styles[isHovering ? "hovering" : "notHovering"],
        )}
      >
        {t("common.back")}
      </span>
    </div>
  );
}

const styles = StyleSheet.create({
  backButton: {
    cursor: "pointer",
    marginTop: "auto",
    marginRight: "auto",
  },
  backButtonLabel: {
    marginLeft: "10px",
    fontWeight: 400,
    fontSize: "14px",
    color: colors.offLightGray,
  },
  hovering: {
    textDecoration: "underline",
  },
  notHovering: {},
});

export default ConnectCredentialBackButton;
