import queryString from "query-string";
import { TFunction } from "i18next";
import {
  Credential,
  CurrencyPairDetails,
  ExchangeDetails,
  Execution,
} from "graphql/schema";
import { PreloadedScript } from "__generated__/graphql";
import { OpenProps } from "contexts/ShareLinkContext";
import CreationFormValues from "components/composer/data/ComposerFormValues";
import { SETUPS_TAB } from "./navigation";
import { logEvent } from "./analytics";
import { trimZeroes } from "./numbers";
import { LinkMetadata } from "./urls";

// TODO: create unit tests
export function getFirstString(
  queryString: string | string[] | null | undefined,
) {
  if (queryString == null || !Array.isArray(queryString)) {
    return queryString;
  }

  if (queryString.length) {
    return queryString[0];
  }
}

export function getExchangeAndPairFromParameters(
  exchangeParam: string | string[] | null | undefined,
  currencyPairParam: string | string[] | null | undefined,
  exchangeDetails: ExchangeDetails[] | undefined,
  credentials: Credential[] | undefined,
): {
  exchange?: ExchangeDetails;
  currencyPair?: CurrencyPairDetails;
  credential?: Credential;
} {
  if (!exchangeParam || Array.isArray(exchangeParam)) {
    const credential = credentials?.find((c) =>
      exchangeDetails?.find((ex) => ex.id === c.exchange),
    );
    const exchange =
      exchangeDetails?.find((ex) => ex.id === credential?.exchange) ??
      exchangeDetails?.[0];
    const currencyPair = exchange?.currencyPairs?.[0];

    return {
      exchange,
      currencyPair,
      credential,
    };
  }

  const exchange =
    exchangeDetails?.find((ex) => ex.id === exchangeParam) ??
    exchangeDetails?.[0];
  const currencyPair =
    currencyPairParam && !Array.isArray(currencyPairParam)
      ? exchange?.currencyPairs?.find((pair) => pair.pair === currencyPairParam)
      : exchange?.currencyPairs?.[0];
  const credential = credentials?.find((c) => c.exchange === exchange?.id);

  return {
    exchange,
    currencyPair,
    credential,
  };
}

function scriptName(preloadedScript: PreloadedScript | undefined): string {
  switch (preloadedScript) {
    case "SETUP_LONG":
      return "Long Setup";
    case "SETUP_SHORT":
      return "Short Setup";
    case "SETUP_SPOT":
      return "Spot Setup";
    default:
      return "";
  }
}

const SETUP_PARAMETERS = [
  "1st Buy At",
  "2nd Buy At",
  "3rd Buy At",
  "4th Buy At",
  "5th Buy At",
  "1st Short At",
  "2nd Short At",
  "3rd Short At",
  "4th Short At",
  "5th Short At",
  "Stop Loss At",
  "Take Profit At",
];

//Similar to SETUP_PARAMETERS, but without the Stop Loss and Take Profits
const ENTRY_KEYS = [
  "1st Buy At",
  "2nd Buy At",
  "3rd Buy At",
  "4th Buy At",
  "5th Buy At",
  "1st Short At",
  "2nd Short At",
  "3rd Short At",
  "4th Short At",
  "5th Short At",
];

function setupLinkMetadata(
  preloadedScript: PreloadedScript | undefined,
  values: Record<string, any>,
  pair: string,
): LinkMetadata {
  const setup = scriptName(preloadedScript);
  const stopLoss = `Take Profit: ${values["Take Profit At"]}`;
  const takeProfit = `Stop Loss: ${values["Stop Loss At"]}`;
  const entries = ENTRY_KEYS.map((key) => values[key]).filter(
    (entry) => !!entry && Number(entry) > 0,
  );
  const description = `
  Entries: ${entries.join(", ")} \n
  ${takeProfit} \n
  ${stopLoss} \n
  `;
  return {
    title: `${pair} ${setup}, powered by Tuned`,
    description,
  };
}

export function copyPresetParametersUrl(
  values: CreationFormValues,
  openShareModal: (props: OpenProps) => void,
  t: TFunction,
) {
  const parameters = values.algorithmVersion?.parameters;

  const urlParams = queryString.parse("");

  urlParams["preloadedScript"] = values.preloadedScriptName;
  urlParams["exchange"] = values.exchange.id;
  urlParams["currencyPair"] = values.currencyPair.pair;
  urlParams["maxBuy"] = values.maxBuy.toString();

  if (parameters) {
    parameters.forEach((parameter) => {
      const name = parameter.readableName ?? parameter.fieldName;

      if (
        SETUP_PARAMETERS.includes(name) &&
        values.algorithmSettings[parameter.fieldName] != null
      ) {
        urlParams[name] = values.algorithmSettings[parameter.fieldName];
      }
    });
  } else if (values.preloadedScriptName === PreloadedScript.SetupShort) {
    urlParams["1st Short At"] = values.algorithmSettings["1st Short At"];
    urlParams["2nd Short At"] = values.algorithmSettings["2nd Short At"];
    urlParams["3rd Short At"] = values.algorithmSettings["3rd Short At"];
    urlParams["4th Short At"] = values.algorithmSettings["4th Short At"];
    urlParams["5th Short At"] = values.algorithmSettings["5th Short At"];
    urlParams["Stop Loss At"] = values.algorithmSettings["Stop Loss At"];
    urlParams["Take Profit At"] = values.algorithmSettings["Take Profit At"];
  } else {
    urlParams["1st Buy At"] = values.algorithmSettings["1st Buy At"];
    urlParams["2nd Buy At"] = values.algorithmSettings["2nd Buy At"];
    urlParams["3rd Buy At"] = values.algorithmSettings["3rd Buy At"];
    urlParams["4th Buy At"] = values.algorithmSettings["4th Buy At"];
    urlParams["5th Buy At"] = values.algorithmSettings["5th Buy At"];
    urlParams["Stop Loss At"] = values.algorithmSettings["Stop Loss At"];
    urlParams["Take Profit At"] = values.algorithmSettings["Take Profit At"];
  }

  // TODO: Move to helpers/navigation.ts
  const url = `${window.location.origin}/${SETUPS_TAB}?${queryString.stringify(
    urlParams,
  )}`;

  logEvent("SharedSetupLink", {
    name: values.preloadedScriptName,
    url,
  });

  openShareModal({
    link: url,
    title: t("setups.share_link_title"),
    subtitle: t("setups.share_link_subtitle"),
    metadata: setupLinkMetadata(
      values.preloadedScriptName,
      values.algorithmSettings,
      values.currencyPair.pair,
    ),
  });
}

export function copyLiveSetupParametersUrl(
  execution: Execution,
  openShareModal: (props: OpenProps) => void,
  t: TFunction,
) {
  const urlParams = queryString.parse("");

  urlParams["preloadedScript"] = execution.preloadedScript;
  urlParams["exchange"] = execution.exchange;
  urlParams["currencyPair"] = execution.currencyPair;
  urlParams["maxBuy"] = trimZeroes(execution.maxBuy?.toString());

  const algorithmSettings = JSON.parse(execution.algorithmSettings);

  execution.scriptDetails?.parameters?.forEach((parameter) => {
    const name = parameter.readableName ?? parameter.fieldName;

    if (
      SETUP_PARAMETERS.includes(name) &&
      algorithmSettings[parameter.fieldName] != null
    ) {
      urlParams[name] = algorithmSettings[parameter.fieldName];
    }
  });

  // TODO: Move to helpers/navigation.ts
  const url = `${window.location.origin}/${SETUPS_TAB}?${queryString.stringify(
    urlParams,
  )}`;

  logEvent("SharedSetupLink", {
    name: execution.preloadedScript,
    url,
  });

  openShareModal({
    link: url,
    title: t("setups.share_link_title"),
    subtitle: t("setups.share_link_subtitle"),
    metadata: setupLinkMetadata(
      execution.preloadedScript,
      algorithmSettings,
      execution.currencyPair,
    ),
  });
}
