import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { ID } from "graphql/schema";
import { UPDATE_EXECUTION_SHARING } from "graphql/mutations";
import Modal from "core/modal/Modal";
import LinkButton from "core/forms/LinkButton";
import Switch from "@material-ui/core/Switch";
import imgCloseModal from "images/closemodal.svg";
import { copyToClipboard } from "helpers/strings";
import { GUEST_TAB } from "helpers/navigation";
import "./share.scss";

type Props = Readonly<{
  hide: () => void;
  id: ID;
  shareToken?: string;
}>;

function ExecutionShareModal({ hide, id, shareToken }: Props) {
  const { t } = useTranslation();

  const [updateSharing, { loading }] = useMutation(UPDATE_EXECUTION_SHARING);
  const [copyTest, setCopyText] = useState("Copy");

  const onToggle = useCallback(() => {
    updateSharing({ variables: { id, share: !shareToken } });
  }, [updateSharing, id, shareToken]);

  const onCopy = useCallback(() => {
    if (!shareToken) return;
    copyToClipboard(window.location.origin + `/${GUEST_TAB}/` + shareToken);
    setCopyText("Copied");
  }, [shareToken]);

  return (
    <Modal
      className={"execution_share"}
      isOpen
      onRequestClose={hide}
      label={t("terminal.share_execution")}
    >
      <LinkButton className="modal__close-btn" onClick={hide}>
        <img src={imgCloseModal} alt="Close" />
      </LinkButton>
      <div className="share_content">
        <div>{t("terminal.shareable_link")}</div>
        <span>
          <Switch
            color="default"
            checked={shareToken ? true : false}
            onChange={onToggle}
          />
          {!loading && shareToken && (
            <LinkButton onClick={onCopy}>{copyTest}</LinkButton>
          )}
        </span>
      </div>
    </Modal>
  );
}

export default ExecutionShareModal;
