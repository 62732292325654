import classnames from "classnames";
import * as React from "react";
import { Link, Route } from "react-router-dom";
import { logEvent } from "../../../helpers/analytics";
import LinkButton from "../../../core/forms/LinkButton";
import "./item.scss";

type Props = Readonly<{
  active?: boolean;
  exact?: boolean;
  bordered?: boolean;
  className?: string;
  dropdown?: boolean;
  path?: string;
  externalPath?: string;
  action?: () => void;
  title: React.ReactNode;
  disabled?: boolean;
  stage?: string;
  newTests?: number | null;
  id?: string;
}>;

function NavigationItem({
  disabled,
  active,
  exact,
  bordered,
  className,
  dropdown,
  path,
  externalPath,
  action,
  title,
  stage = "",
  newTests,
  id,
}: Props) {
  const itemClasses = classnames(
    "nav__item",
    className,
    stage,
    { "nav__item--bordered": bordered },
    { "nav__item--active": active },
    { disabled: disabled },
  );

  const linkClasses = (active?: any) =>
    classnames("nav__link", {
      "nav__link--active": active,
      "nav__link--dropdown": dropdown,
    });

  const onLinkClick = React.useCallback(() => {
    action?.();
    logEvent("ChangeTab", { path });
  }, [action, path]);

  return (
    <li className={itemClasses}>
      {path && (
        <Route exact={exact} path={path}>
          {({ match }: any) => (
            <Link
              onClick={onLinkClick}
              to={path}
              className={linkClasses(active === true || match)}
              id={id}
            >
              {title}
            </Link>
          )}
        </Route>
      )}
      {externalPath && (
        <a
          href={externalPath}
          target="_blank"
          rel="noopener noreferrer"
          className={linkClasses(active === true)}
        >
          {title}
        </a>
      )}
      {action && !path && (
        <LinkButton
          className={linkClasses(active)}
          onClick={disabled ? undefined : action}
        >
          {title}
        </LinkButton>
      )}
      {newTests && <div className="new-tests">{newTests}</div>}
    </li>
  );
}

export default NavigationItem;
