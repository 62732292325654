import React from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import { Exchange } from "__generated__/graphql";
import ConnectCredentialHeader from "./ConnectCredentialHeader";
import ConnectCredentialBackButton from "./ConnectCredentialBackButton";
import ExchangeGuideLinks from "./ExchangeGuideLinks";
import CreateExchangeKeyInstruction, {
  ExchangeInstruction,
} from "core/exchange/CreateExchangeKeyInstruction";
import Button from "core/button/Button";
import { formatExchange } from "helpers/formLabelUtils";
import { logEvent } from "helpers/analytics";
import binanceStep2Img from "images/exchangeguides/binance-step-2.png";
import binanceStep4Img from "images/exchangeguides/binance-step-4.png";
import bitmexStep3Img from "images/exchangeguides/bitmex-step-3.png";
import bybitStep2Img from "images/exchangeguides/bybit-step-2.png";
import coinbaseStep2Img from "images/exchangeguides/coinbase-step-2.png";
import coinbaseStep3Img from "images/exchangeguides/coinbase-step-3.png";
import huobiStep2Img from "images/exchangeguides/huobi-step-2.png";
import krakenStep2Img from "images/exchangeguides/kraken-step-2.png";
import colors from "styles/colors";

export type ExchangeInformation = {
  url: string;
  website: string;
  instructions: ExchangeInstruction[];
};

function getExchangeInformation(
  exchange: Exchange,
  t: TFunction,
): ExchangeInformation {
  switch (exchange) {
    case Exchange.Bybit:
    case Exchange.BybitLinear:
      return {
        url: "https://www.bybit.com/en-US/register?affiliate_id=12420&language=en-US&group_id=0&group_type=1",
        website: "www.bybit.com",
        instructions: [
          {
            title: t("message.access_api_management"),
            info: [t("message.select_api_from_dropdown")],
            image: bybitStep2Img,
          },
          {
            title: t("message.create_new_api_key"),
            info: [t("message.click_create_new_key")],
          },
          {
            title: t("message.set_permissions_and_usage"),
            info: [
              t("message.set_api_key_usage_and_select_tuned"),
              t("message.enable_orders_positions_and_trade"),
            ],
          },
        ],
      };
    case Exchange.Binance:
    case Exchange.BinanceFutures:
    case Exchange.BinanceCoinFutures:
      return {
        url: "https://www.binance.com/en/register?ref=QH2FJB3L",
        website: "www.binance.com",
        instructions: [
          {
            title: t("message.access_api_management"),
            info: [t("message.select_api_management_from_profile_icon")],
            image: binanceStep2Img,
          },
          {
            title: t("message.create_new_api_key"),
            info: [t("message.create_api_and_enter_name")],
          },
          {
            title: t("message.disable_ip_restrictions"),
            info: [
              t("message.edit_restrictions_to_unrestricted"),
              t("message.tuned_does_not_require_permissions"),
            ],
            image: binanceStep4Img,
          },
        ],
      };
    case Exchange.Bitmex:
      return {
        url: "https://www.bitmex.com/",
        website: "www.bitmex.com",
        instructions: [
          {
            title: t("message.enable_cross_margin"),
            info: [t("message.make_sure_cross_margin_is_enabled")],
          },
          {
            title: t("message.go_to_api_key_management"),
            info: [t("message.select_api_key_management_in_sidebar")],
            image: bitmexStep3Img,
          },
          {
            title: t("message.create_new_api_key"),
            info: [
              t("message.enter_name_and_create_api"),
              t("message.leave_withdraw_unchecked"),
            ],
          },
        ],
      };
    case Exchange.Coinbase:
      return {
        url: "https://pro.coinbase.com/",
        website: "pro.coinbase.com",
        instructions: [
          {
            title: t("message.access_api_management"),
            info: [t("message.select_api_from_dropdown")],
            image: coinbaseStep2Img,
          },
          {
            title: t("message.create_new_api_key"),
            info: [t("message.click_new_api_key")],
            image: coinbaseStep3Img,
          },
          {
            title: t("message.set_permissions"),
            info: [
              t("message.enable_view_and_trade"),
              t("message.leave_transfer_disabled"),
            ],
          },
        ],
      };
    case Exchange.Kraken:
      return {
        url: "https://www.kraken.com/",
        website: "www.kraken.com",
        instructions: [
          {
            title: t("message.access_api_management"),
            info: [t("message.select_api_in_security_from_username")],
            image: krakenStep2Img,
          },
          {
            title: t("message.add_api_key"),
            info: [t("message.enter_name_and_enable_funds")],
          },
          {
            title: t("message.set_permissions"),
            info: [
              t("message.make_sure_funds_are_disabled"),
              t("message.account_needs_funds"),
            ],
          },
        ],
      };
    case Exchange.HuobiCoinSwaps:
      return {
        url: "https://www.huobi.com/",
        website: "www.huobi.com",
        instructions: [
          {
            title: t("message.access_api_management"),
            info: [t("message.select_api_management_from_profile_hover")],
            image: huobiStep2Img,
          },
          {
            title: t("message.create_api_key"),
            info: [t("message.enter_name_and_enable_trade_under_permissions")],
          },
        ],
      };
    case Exchange.CryptoDotCom:
      return {
        url: "https://crypto.com/",
        website: "crypto.com",
        instructions: [
          {
            title: t("message.access_api_management"),
            info: [t("message.select_api_in_security_from_profile")],
          },
          {
            title: t("message.create_api_key"),
            info: [t("message.click_create_new_api_key")],
          },
        ],
      };
    case Exchange.Nash:
      throw new Error("Nash is no longer used");
  }
}

type Props = Readonly<{
  exchange: Exchange;
  onBackClick: () => void;
  onContinueClick: () => void;
}>;

function CreateExchangeKeyStep({
  exchange,
  onBackClick,
  onContinueClick,
}: Props) {
  const { t } = useTranslation();

  const exchangeInfo = getExchangeInformation(exchange, t);

  return (
    <>
      <ConnectCredentialHeader
        title={t("message.create_an_api_key_for_exchange", {
          exchange: formatExchange(exchange),
        })}
        subtitle={t("message.follow_steps_or_watch_video")}
      />
      <div className={css(styles.body)}>
        <div className={css(styles.instructions)}>
          <CreateExchangeKeyInstruction
            step={1}
            instruction={{
              title: t("message.login_to_exchange", {
                exchange: formatExchange(exchange),
              }),
              info: [
                t("message.exchange_account_required_to_create_api_keys", {
                  exchange: formatExchange(exchange),
                }),
              ],
            }}
            button={{
              label: t("message.visit_exchange_to_create_account", {
                exchange_url: exchangeInfo.website,
              }),
              mobileLabel: t("message.visit_exchange", {
                exchange_url: exchangeInfo.website,
              }),
              onClick: () => {
                window.open(exchangeInfo.url, "_blank");
                logEvent("ConnectExchangeModalOpenExternalExchangeUrl", {
                  exchange,
                  url: exchangeInfo.url,
                });
              },
            }}
          />
          {exchangeInfo.instructions.map((instruction, i) => (
            <CreateExchangeKeyInstruction
              key={instruction.title}
              step={i + 2}
              instruction={instruction}
            />
          ))}
        </div>
        <ExchangeGuideLinks exchange={exchange} />
      </div>
      <div className={css(styles.footer)}>
        <div className={css(styles.footerBackButton)}>
          <ConnectCredentialBackButton onClick={onBackClick} />
        </div>
        <div className={css(styles.footerButton)}>
          <Button onClick={onContinueClick}>{t("common.continue")}</Button>
        </div>
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  body: {
    display: "flex",
    marginBottom: "100px",
    "@media (max-width: 1019.98px)": {
      flexDirection: "column",
      marginBottom: "30px",
    },
  },
  instructions: {
    flex: "5 1",
  },
  footer: {
    position: "relative",
    display: "flex",
    background: colors.black,
    marginTop: "auto",
    paddingBottom: "30px",
    zIndex: 2,
    "@media (max-width: 767.98px)": {
      flexDirection: "column-reverse",
    },
  },
  footerBackButton: {
    "@media (min-width: 768px)": {
      position: "absolute",
      left: "0",
      top: "11px",
    },
    "@media (max-width: 767.98px)": {
      marginTop: "15px",
    },
  },
  footerButton: {
    margin: "auto",
    width: "385px",
    "@media (max-width: 767.98px)": {
      width: "100%",
    },
  },
});

export default CreateExchangeKeyStep;
