import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import invariant from "invariant";
import { ID } from "../graphql/schema";
import { useRouteMatch } from "react-router-dom";
import { useNavigationContext } from "./NavigationContext";
import downImg from "../images/download.svg";
import {
  CANDIDATES_SUBTAB,
  COMMUNITY_BOTS_SUBTAB,
  EDITOR_TAB,
  EXPERIMENTS_SUBTAB,
  GUEST_TAB,
  LIVE_BOTS_SUBTAB,
  LIVE_SETUPS_SUBTAB,
  MY_BOTS_SUBTAB,
  PUBLIC_EDITOR_TAB,
  RELEASE_CANDIDATES_SUBTAB,
} from "helpers/navigation";

export type BotMeasurementsMode = "backtest" | "papertrade";

export type SelectedExecutionType = "execution" | "bot";

export type SelectedExecution = {
  id: ID | null;
  type: SelectedExecutionType;
};

interface Context {
  selectedExecutionDetails: SelectedExecution | null;
  setSelectedExecutionDetails: (id: SelectedExecution | null) => void;
  botMeasurementsMode: BotMeasurementsMode;
  setBotMeasurementsMode: (mode: BotMeasurementsMode) => void;
  setTradesLength: (length: number) => void;
  isArchivedLiveTests: boolean;
  setIsArchivedLiveTests: (isArchived: boolean) => void;
  setIsSubscription: (isSubscription: boolean) => void;
}

export const SelectedExecutionContext = createContext<Context | undefined>(
  undefined,
);

export function useSelectedExecutionContext() {
  const context = useContext(SelectedExecutionContext);
  invariant(
    context != null,
    "Component is not a child of SelectedExecutionContext provider",
  );
  return context;
}

export const SelectedExecutionContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { setSideNavIcons } = useNavigationContext();

  // Most of the time we want to trigger this based on route changes, but we also would like to
  // manually set the id in some cases (eg. script previews, or if we move away from URL routes in the future)
  const [selectedExecutionDetails, setSelectedExecutionDetails] =
    useState<SelectedExecution | null>(null);
  const [botMeasurementsMode, setBotMeasurementsModeInner] =
    useState<BotMeasurementsMode>(
      (localStorage.getItem("botMeasurementsMode") as BotMeasurementsMode) ??
        "papertrade",
    );
  const setBotMeasurementsMode = useCallback((mode: BotMeasurementsMode) => {
    setBotMeasurementsModeInner(mode);
    localStorage.setItem("botMeasurementsMode", mode);
  }, []);

  const [tradesLength, setTradesLength] = useState(0);
  const [isArchivedLiveTests, setIsArchivedLiveTests] = useState(false);
  const [isSubscription, setIsSubscription] = useState<boolean>(false);

  const match = useRouteMatch<{ executionId?: ID }>({
    path: `/(${LIVE_BOTS_SUBTAB}|${LIVE_SETUPS_SUBTAB}|${EXPERIMENTS_SUBTAB}|${CANDIDATES_SUBTAB}|${RELEASE_CANDIDATES_SUBTAB})/:executionId([0-9]+)?`,
  });

  const botMatch = useRouteMatch<{ executionId?: ID }>({
    path: `/${MY_BOTS_SUBTAB}/:executionId([0-9]+)?`,
  });

  const communityBotMatch = useRouteMatch<{ botId?: ID }>({
    path: `/${COMMUNITY_BOTS_SUBTAB}/:botId([0-9]+)?`,
  });

  const guestMatch = useRouteMatch<{ executionId: ID }>({
    path: `/${GUEST_TAB}/:executionId`,
  });

  const editorMatch = useRouteMatch<{ scriptId: ID }>({
    path: `/${EDITOR_TAB}/:scriptId([0-9]+)`,
  });

  const guestEditorMatch = useRouteMatch<{ scriptId: ID }>({
    path: `/${PUBLIC_EDITOR_TAB}/:scriptId([0-9]+)`,
  });

  useEffect(() => {
    // to prevent download icon from appearing in the editor navigation panel
    if (editorMatch || guestEditorMatch || communityBotMatch) {
      setTradesLength(-1);
    }
  }, [editorMatch, guestEditorMatch, communityBotMatch, setTradesLength]);

  useEffect(() => {
    if (
      selectedExecutionDetails?.id &&
      selectedExecutionDetails?.type === "execution" &&
      tradesLength !== -1
    ) {
      setSideNavIcons(
        tradesLength > 0 && !isArchivedLiveTests && !isSubscription
          ? [
              {
                alt: "Download",
                iconSrc: downImg,
                type: "link",
                path: `${process.env.REACT_APP_API_URL}/executions/${selectedExecutionDetails.id}/trades.json`,
              },
            ]
          : [],
      );
    }
  }, [
    setSideNavIcons,
    selectedExecutionDetails,
    tradesLength,
    isArchivedLiveTests,
    isSubscription,
  ]);

  useEffect(() => {
    if (
      match?.params?.executionId ||
      guestMatch?.params?.executionId ||
      botMatch?.params?.executionId ||
      communityBotMatch?.params?.botId
    ) {
      const id =
        match?.params?.executionId ??
        guestMatch?.params?.executionId ??
        botMatch?.params?.executionId ??
        communityBotMatch?.params?.botId ??
        null;
      // If a route with an (optional) executionId matches, set that value
      if (id !== selectedExecutionDetails?.id) {
        setSelectedExecutionDetails({
          id,
          type: communityBotMatch?.params?.botId ? "bot" : "execution",
        });
      }
    } else if (editorMatch === null && guestEditorMatch === null) {
      // If the route matches the editor, we can leave it as-is (the editor will manually set previews)
      // But if there is no editor match either, we'want to clear the selected execution
      setSelectedExecutionDetails(null);
    }
  }, [
    setSelectedExecutionDetails,
    selectedExecutionDetails,
    match,
    guestMatch,
    editorMatch,
    guestEditorMatch,
    botMatch,
    communityBotMatch,
  ]);

  const value: Context = useMemo<Context>(
    () => ({
      selectedExecutionDetails,
      setSelectedExecutionDetails,
      setTradesLength,
      isArchivedLiveTests,
      setIsArchivedLiveTests,
      setIsSubscription,
      botMeasurementsMode,
      setBotMeasurementsMode,
    }),
    [
      selectedExecutionDetails,
      isArchivedLiveTests,
      botMeasurementsMode,
      setBotMeasurementsMode,
    ],
  );

  return (
    <SelectedExecutionContext.Provider value={value}>
      {children}
    </SelectedExecutionContext.Provider>
  );
};
