import React, { MouseEvent, useCallback } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import gql from "graphql-tag";
import { MultivariantsRow_MultivariantFragment } from "__generated__/graphql";
import { PartialMultivariant } from "graphql/mutations";
import { MultivariantColumnDefinition } from "contexts/executions/MultivariantColumns";
import {
  CheckedExecutionDetails,
  CheckedIdRecord,
  useMultiSelectContext,
} from "contexts/MultiSelectContext";
import { useComposerStateContext } from "contexts/ComposerStateContext";
import LinkButton from "core/forms/LinkButton";
import imgBoxChecked from "images/box-checked.svg";
import imgBoxUnchecked from "images/box-unchecked.svg";
import { BATCHTEST_SUBTAB } from "helpers/navigation";
import { useMediaQuery } from "@material-ui/core";

MultivariantsRow.fragments = {
  multivariants: gql`
    fragment MultivariantsRow_multivariant on Multivariant {
      id
      name
      exchange
      currencyPair
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      candleSize
      candleType
      maxBuy
      minProfit
      stopLossPercentage
      stopLossTrailing
      takeProfitPercentage
      takeProfitTrailingPercentage
      algorithmSettings
      scriptDetails {
        id
        scriptId
        revisionId
        name
        language
        version
        isLatest
        createdAt
        parameters {
          readableName
          fieldName
        }
      }
      status
      progress {
        status
        count
      }
      totalCombinationsCount
      canResume
      createdAt
      favorite
      scriptVersionSequence
      tags {
        id
        name
        color
      }
      allocation
      performanceMode
      leverage
      leverageShort
      lookbackDays
      buyActionTime
      sellActionTime
    }
  `,
};

type Props = Readonly<{
  checkedMVTs: CheckedIdRecord;
  isCheckedMVTs: boolean;
  toggleCheckedMVTs: (details: CheckedExecutionDetails) => void;
  columns: MultivariantColumnDefinition[];
  multivariant: MultivariantsRow_MultivariantFragment;
  onCustomizerColumnClick: (e: MouseEvent<HTMLDivElement>) => void;
  setHovering: () => void;
  setNotHovering: () => void;
  isCustomizerHovering: boolean;
}>;

function MultivariantsRow({
  checkedMVTs,
  isCheckedMVTs,
  toggleCheckedMVTs,
  columns,
  multivariant,
  onCustomizerColumnClick,
  setHovering,
  setNotHovering,
  isCustomizerHovering,
}: Props) {
  const history = useHistory();

  const matches = useMediaQuery(`(max-width: 767.98px)`);

  const { displayForMVT, setCopied } = useComposerStateContext();
  const { disableCheckedList } = useMultiSelectContext();

  const { id, scriptDetails } = multivariant;

  const onSelection = useCallback(
    (name: string, id: string) => {
      if (!matches) displayForMVT(id);
      setCopied(false);
      disableCheckedList();
      history.push(
        `/${BATCHTEST_SUBTAB}/${id}?name=${encodeURIComponent(name)}`,
      );
    },
    [matches, history, displayForMVT, setCopied, disableCheckedList],
  );

  return (
    <tr
      key={id}
      className={classnames("as-link", {
        "select-enabled": checkedMVTs,
        "not-hovering": isCustomizerHovering,
      })}
      onClick={() => onSelection(scriptDetails?.name ?? "batchtesting", id)}
    >
      {checkedMVTs && (
        <td
          className={classnames("table-row-checkbox mvt-checkbox", {
            hidden: !isCheckedMVTs,
          })}
        >
          <LinkButton
            onClick={() => toggleCheckedMVTs({ id })}
            stopPropagation={true}
            className="select"
          >
            <img
              alt="Checked Box"
              src={checkedMVTs[id] ? imgBoxChecked : imgBoxUnchecked}
            />
          </LinkButton>
        </td>
      )}
      {columns.map((column, i) =>
        column.propertyFn(multivariant as unknown as PartialMultivariant, i),
      )}
      <td
        className={classnames("add-column", { hover: isCustomizerHovering })}
        onClick={onCustomizerColumnClick}
        onMouseEnter={setHovering}
        onMouseLeave={setNotHovering}
      />
      <td className="blank" />
    </tr>
  );
}

export default MultivariantsRow;
