/**
 * Shortcuts to check the build environment.
 * We have two possible environment variables to check from:
 *
 * - `REACT_APP_TUNED_ENV_TYPE` which is our explicitly defined .env variable (nonprod/staging/prod/local)
 * - `NODE_ENV` which is the default - it is set to 'development' for local development (eg. `npm start`),
 *      and 'production' for real builds (eg. `npm build`)
 *
 * `REACT_APP_TUNED_ENV_TYPE` takes precedence, but we fallback to `NODE_ENV` if it is not set,
 *      so as to not treat a prod environment as development, etc.
 */

import { useUserContext } from "contexts/UserContext";

type TunedEnvType = "nonprod" | "staging" | "prod" | "local" | undefined;
type NodeEnvType = "development" | "production" | "test";

const TUNED_ENV_TYPE = process.env.REACT_APP_TUNED_ENV_TYPE as TunedEnvType;
const NODE_ENV: NodeEnvType = process.env.NODE_ENV as NodeEnvType;

// For dev/prod checks, if no TUNED_ENV_TYPE is set, rely on the NODE_ENV flag
export const isDevelopment: boolean =
  TUNED_ENV_TYPE === undefined
    ? NODE_ENV === "development"
    : TUNED_ENV_TYPE === "nonprod" || TUNED_ENV_TYPE === "local";

export const isProduction: boolean =
  TUNED_ENV_TYPE === undefined
    ? NODE_ENV === "production"
    : TUNED_ENV_TYPE === "prod";

// Staging has no NODE_ENV equivalent (at least in `create-react-app`)
export const isStaging: boolean = TUNED_ENV_TYPE === "staging";

// We currently don't set our own test flag (or really run tests)
export const isTest: boolean = NODE_ENV === "test";

// Export the environment name as well
export const ENV_NAME = process.env.REACT_APP_TUNED_ENV_NAME ?? "local";

// Unify both environment variables to label this env type.
// If TUNED_ENV_TYPE isn't set, use either 'prod' or 'nonprod'
export const ENV_SIGIL = isDevelopment
  ? "D"
  : isStaging
  ? "S"
  : isProduction
  ? "P"
  : isTest
  ? "P"
  : "?";

export const ENV_TYPE = isDevelopment
  ? "Local / Nonprod"
  : isStaging
  ? "Staging"
  : isProduction
  ? "Production"
  : isTest
  ? "T"
  : "Unknown";

export const ENV_TITLE_NAME = isDevelopment
  ? "Tuned Dev"
  : isStaging
  ? "Tuned Staging"
  : isProduction
  ? "Tuned"
  : isTest
  ? "Tuned Test"
  : "Tuned Unknown";

export function setTitle(key: string) {
  document.title = key ? key + " | " + ENV_TITLE_NAME : ENV_TITLE_NAME;
}

export function isCryptoDotComVisible(isInternal?: boolean) {
  return isDevelopment || isStaging || isInternal;
}

export function isConnectCredentialModalVisible(_isInternal?: boolean) {
  return true;
}

export function isPageNavigatorVisible(_isInternal?: boolean) {
  return false;
}

export function isPortfolioPageVisible(_isInternal?: boolean) {
  return true;
}

export function isPglComposerVisible(isInternal?: boolean) {
  return isDevelopment || isStaging || isInternal;
}

export function useVirtualTable() {
  return true;
}

export function useExecutionsVirtualTable() {
  const { isTester } = useUserContext();
  return isTester;
}

export const RELEASE_VERSION = "v" + process.env.REACT_APP_VERSION || "0.0.0";
