import React, { useCallback, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import { ID, Execution, ScriptDetails } from "graphql/schema";
import { useUserContext } from "contexts/UserContext";
import { useSelectedExecutionContext } from "contexts/SelectedExecutionContext";
import { useExecutionContext } from "contexts/executions/ExecutionContext";
import InfoTooltip from "core/tooltip/InfoTooltip";
import AlgoValuesTable from "../../AlgoValuesTable";
import ExecutionFavorite from "./ExecutionFavorite";
import ExecutionAction, { ExecutionActionProps } from "./ExecutionAction";
import ExecutionMenuOptions from "./ExecutionMenuOptions";
import ExecutionShare from "../share/ExecutionShare";
import SyndicationShare from "../share/SyndicationShare";
import SyndicationAllowListModal from "../modal/SyndicationAllowListModal";
import ExecutionDownloadModal from "../modal/ExecutionDownloadModal";
import { useBoolean } from "helpers/hooks";
import { trimZeroes } from "helpers/numbers";
import { titleCase } from "helpers/strings";
import packImg from "images/strategy-pack-dark.svg";
import robotImg from "images/robot-small.svg";
import notesImg from "images/notes.svg";
import chevronsExperimentImg from "images/chevrons-experiment.svg";
import chevronsCandidateImg from "images/chevrons-candidate.svg";
import chevronsPrereleaseImg from "images/chevrons-prerelease.svg";
import chevronsLiveImg from "images/chevrons-live.svg";
import chevronsSyndicateImg from "images/chevrons-syndicate.svg";
import dotsImg from "images/three-dots.svg";
import dotsLightImg from "images/three-dots-light.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Tooltip from "@material-ui/core/Tooltip";
import { useMediaQuery } from "@material-ui/core";
import colors from "styles/colors";

type Props = Readonly<{
  scriptName: string;
  scriptDetails?: ScriptDetails;
  id: ID;
  algorithmSettings: string;
  favorite?: boolean;
  execution?: Execution;
  isPackListOpen?: boolean;
  onUpdateOpenPacks?: (id: ID, remove: boolean) => void;
}>;

function ExecutionScript({
  scriptName,
  scriptDetails,
  id,
  algorithmSettings,
  favorite,
  execution,
  isPackListOpen,
  onUpdateOpenPacks,
}: Props) {
  const { t } = useTranslation();

  const { isGuest } = useUserContext();
  const { isArchivedLiveTests } = useSelectedExecutionContext();
  const { actionDefs, showActionModal } = useExecutionContext();

  const matches = useMediaQuery("(max-width: 767.98px)");

  const currency = useMemo(
    () =>
      execution && execution.syndicationSettings
        ? execution.currencyPairDetails?.settleCurrency ?? undefined
        : undefined,
    [execution],
  );

  const [isOpen, setOpen, setClosed] = useBoolean(false);
  const [isMenuVisible, setMenuIsVisible, setMenuIsNotVisible] =
    useBoolean(false);
  const [isTogglerHovering, setTogglerIsHovering, setTogglerIsNotHovering] =
    useBoolean(false);

  const toggleActionMenu = useCallback(
    (e) => {
      e.stopPropagation();
      if (isOpen) setClosed();
      else setOpen();
    },
    [isOpen, setOpen, setClosed],
  );

  const executionFavorite = useMemo(
    () =>
      isGuest || execution?.packPercentage ? null : (
        <ExecutionFavorite id={id} favorite={favorite} />
      ),
    [isGuest, execution, id, favorite],
  );

  const executionStageChevrons = useMemo(() => {
    if (execution?.type === "LIVE") {
      return chevronsLiveImg;
    } else {
      switch (execution?.stage) {
        case "CANDIDATES":
          return chevronsCandidateImg;
        case "RELEASE_CANDIDATES":
          return chevronsPrereleaseImg;
        default:
          return chevronsExperimentImg;
      }
    }
  }, [execution]);

  const syndicationInfo = useMemo(
    () =>
      execution?.syndicationSettings
        ? {
            // only add description if it exists
            ...(execution.syndicationSettings.description && {
              Description: execution.syndicationSettings.description,
            }),
            [t("common.max_allocation")]:
              Number(execution.syndicationSettings.maxAllocation) === 0
                ? "--"
                : trimZeroes(execution.syndicationSettings.maxAllocation) +
                  " " +
                  currency,
            [t("common.min_allocation")]:
              trimZeroes(execution.syndicationSettings.minAllocation) +
              " " +
              currency,
            [t("terminal.is_private")]:
              execution.syndicationSettings.accessControl === "USE_ALLOW_LIST"
                ? t("common.yes")
                : t("common.no"),
            ...(execution.syndicationSettings.accessControl ===
              "USE_ALLOW_LIST" &&
              execution.syndicationSettings.allowList?.length && {
                [t("common.allow_list")]:
                  execution.syndicationSettings.allowList.reduce(
                    (prev, current) => ({
                      nickname: `${prev.nickname}, ${current.nickname}`,
                      canRemove: false,
                    }),
                  ).nickname,
              }),
          }
        : null,
    [execution, currency, t],
  );

  const extraAlgoSettings = useMemo(
    () => ({
      ...(execution?.stopLossPercentage &&
        execution?.stopLossPercentage !== "NaN" && {
          [t("common.stop_loss")]:
            trimZeroes(execution.stopLossPercentage) ?? "",
          [t("common.trailing_stop_loss")]: execution.stopLossTrailing
            ? t("common.enabled")
            : t("common.disabled"),
        }),
      ...(execution?.takeProfitPercentage &&
        execution?.takeProfitPercentage !== "NaN" && {
          [t("common.take_profit")]:
            trimZeroes(execution.takeProfitPercentage) ?? "",
        }),
      ...(execution?.takeProfitTrailingPercentage &&
        execution?.takeProfitTrailingPercentage !== "NaN" && {
          [t("common.take_profit_trailing_deviation")]:
            trimZeroes(execution.takeProfitTrailingPercentage) ?? "",
        }),
    }),
    [execution, t],
  );

  const readableAlgoNames = useMemo(() => {
    const readableNames: Record<string, string> = {};
    scriptDetails?.parameters?.forEach((parameter) => {
      if (parameter?.fieldName && parameter?.readableName) {
        readableNames[parameter.fieldName] = parameter.readableName;
      }
    });
    return readableNames;
  }, [scriptDetails]);

  const executionName = execution?.preloadedScript
    ? execution?.name
    : execution?.ownerNickname ??
      execution?.syndicationName ??
      execution?.syndication?.name ??
      scriptName;

  return (
    <div
      title={executionName}
      className={css(styles.nameContainer)}
      onMouseOver={setMenuIsVisible}
      onMouseLeave={setMenuIsNotVisible}
    >
      {!isArchivedLiveTests && !execution?.ownerNickname && executionFavorite}
      {!execution?.packPercentage &&
        !execution?.multivariantIterationId &&
        !execution?.ownerNickname &&
        !isGuest && (
          <img
            src={
              execution?.type === "SYNDICATION"
                ? chevronsSyndicateImg
                : executionStageChevrons
            }
            alt="Stage"
            className={css(styles.chevrons)}
            height={8}
          />
        )}
      {execution?.isPack && (
        <>
          <i
            className={css(
              styles.packToggle,
              isPackListOpen && styles.packToggled,
            )}
          ></i>
          <img
            src={packImg}
            alt="Strategy Pack"
            className={css(styles.nameIcon)}
            height={15}
          />
        </>
      )}
      {execution?.syndicationId && (
        <img
          src={robotImg}
          alt="Strategy Pack"
          className={css(styles.nameIcon)}
          height={15}
        />
      )}
      <span
        className={css(
          styles.name,
          algorithmSettings === "{}" && !syndicationInfo && styles.nameFlex,
          (!!execution?.ownerNickname || isGuest) && styles.nameIndent,
          !!execution?.packPercentage && styles.nameSubPackIndent,
        )}
      >
        {executionName}
      </span>
      {!!execution?.notes?.length && (
        <Tooltip
          title={
            <div style={{ whiteSpace: "pre-line", minWidth: "200px" }}>
              {execution.notes}
            </div>
          }
        >
          <img
            src={notesImg}
            alt="Notes"
            className={css(styles.notesIcon)}
            height={13}
          />
        </Tooltip>
      )}
      {algorithmSettings && algorithmSettings !== "{}" && (
        <InfoTooltip
          id={`mvt_algoSettings_${id}`}
          className={css(styles.infoIcon)}
        >
          <AlgoValuesTable
            algorithmSettings={algorithmSettings}
            readableAlgoNames={readableAlgoNames}
            extraAlgoSettings={extraAlgoSettings}
          />
        </InfoTooltip>
      )}
      {!!syndicationInfo && (
        <InfoTooltip
          id={`mvt_algoSettings_${id}`}
          className={css(styles.infoIcon)}
        >
          <AlgoValuesTable
            algorithmSettings={JSON.stringify(syndicationInfo)}
          />
        </InfoTooltip>
      )}
      {actionDefs && execution && !execution?.ownerNickname && (
        <ClickAwayListener onClickAway={setClosed}>
          <div
            className={css(
              styles.actionMenu,
              styles[isMenuVisible || isOpen ? "visibleMenu" : "hiddenMenu"],
            )}
            onClick={toggleActionMenu}
          >
            {actionDefs.map((definition, i) => {
              if (
                definition.isActiveFn(execution) &&
                definition.isIcon?.(execution)
              ) {
                const props: ExecutionActionProps = { execution, definition };
                if (definition.showConfirmPrompt) {
                  props.onShowModal = showActionModal?.function;
                }
                const tooltipId = `execution-${execution.id}-icon-button-${definition.action}`;
                return (
                  <span className={css(styles.actionMenuIconButton)} key={i}>
                    <span data-for={tooltipId} data-tip="">
                      <ExecutionAction
                        {...props}
                        closeMenu={setClosed}
                        onUpdateOpenPacks={onUpdateOpenPacks}
                      />
                    </span>
                    {!matches && (
                      <ReactTooltip
                        id={tooltipId}
                        effect="solid"
                        className="reactTooltip"
                      >
                        {titleCase(definition.action)}
                      </ReactTooltip>
                    )}
                  </span>
                );
              } else {
                return null;
              }
            })}
            <div
              className={css(styles.actionMenuToggler)}
              onMouseOver={setTogglerIsHovering}
              onMouseLeave={setTogglerIsNotHovering}
            >
              <img
                src={isOpen || isTogglerHovering ? dotsLightImg : dotsImg}
                alt="three-dots"
                width={3}
              />
            </div>
            <div
              className={css(
                styles.actionMenuDropdown,
                !isOpen && styles.invisible,
              )}
            >
              {!execution.packPercentage && (
                <ExecutionMenuOptions
                  id={execution.id}
                  name={execution.name}
                  stage={execution.stage}
                  status={execution.status}
                  type={execution.type}
                  exchange={execution.exchange}
                  currencyPair={execution.currencyPair}
                  closeMenu={setClosed}
                  isPack={execution.isPack}
                  isInsideBatch={!!execution.multivariantIterationId}
                  syndicationSettings={execution.syndicationSettings}
                  multiCoinCurrency={execution.multiCoinCurrency}
                  className={css(
                    styles.actionMenuOptionButton,
                    styles.enabledButton,
                  )}
                  spanClassName={css(styles.actionMenuOption)}
                  onUpdateOpenPacks={onUpdateOpenPacks}
                />
              )}
              {actionDefs.map((definition, i) => {
                if (definition.isIcon?.(execution)) {
                  return null;
                }

                const props: ExecutionActionProps = { execution, definition };
                if (definition.showConfirmPrompt) {
                  props.onShowModal = showActionModal?.function;
                }
                return (
                  <div key={i}>
                    <ExecutionAction
                      {...props}
                      closeMenu={setClosed}
                      onUpdateOpenPacks={onUpdateOpenPacks}
                    />
                  </div>
                );
              })}
              {!execution.multivariantIterationId && (
                <ExecutionDownloadModal
                  execution={execution}
                  disabled={
                    execution.type === "BACKTEST" &&
                    (execution.status === "RUNNING" ||
                      execution.status === "PENDING")
                  }
                  closeMenu={setClosed}
                  className={css(
                    styles.actionMenuOptionButton,
                    styles[
                      execution.type === "BACKTEST" &&
                      (execution.status === "RUNNING" ||
                        execution.status === "PENDING")
                        ? "disabledButton"
                        : "enabledButton"
                    ],
                  )}
                  spanClassName={css(styles.actionMenuOption)}
                />
              )}
              {!isArchivedLiveTests && (
                <ExecutionShare
                  id={execution.id}
                  shareToken={execution.shareToken}
                  closeMenu={setClosed}
                  className={css(
                    styles.actionMenuOptionButton,
                    styles.enabledButton,
                  )}
                  spanClassName={css(styles.actionMenuOption)}
                />
              )}
              {execution.type === "SYNDICATION" &&
                execution.syndicationSettings?.accessControl ===
                  "USE_ALLOW_LIST" && (
                  <>
                    <SyndicationAllowListModal
                      closeMenu={setClosed}
                      id={execution.id}
                      allowList={
                        execution?.syndicationSettings?.allowList ?? []
                      }
                      name={execution.name ?? ""}
                      className={css(
                        styles.actionMenuOptionButton,
                        styles.enabledButton,
                      )}
                      spanClassName={css(styles.actionMenuOption)}
                    />
                    <SyndicationShare
                      id={execution.id}
                      shareToken={execution.syndicationSettings?.shareToken}
                      closeMenu={setClosed}
                      name={execution.name ?? ""}
                      className={css(
                        styles.actionMenuOptionButton,
                        styles.enabledButton,
                      )}
                      spanClassName={css(styles.actionMenuOption)}
                    />
                  </>
                )}
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  nameContainer: {
    display: "flex",
    height: "30px",
    marginLeft: "-6px",
    width: "calc(100% + 12px)",
  },
  chevrons: {
    margin: "auto 10px auto 0",
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: "auto",
    marginBottom: "auto",
  },
  nameFlex: {
    flex: "1 1",
  },
  nameIndent: {
    marginLeft: "22px",
  },
  nameSubPackIndent: {
    marginLeft: "75px",
  },
  nameIcon: {
    margin: "auto 5px auto 0",
  },
  notesIcon: {
    margin: "auto 0 auto 5px",
  },
  infoIcon: {
    flex: "1 1",
    margin: "auto auto auto 0",
    minWidth: "14px",
    maxWidth: "14px",
    display: "inline-block",
    backgroundColor: colors.gray,
    border: `1px solid ${colors.gray}`,
    borderRadius: "50%",
    height: "14px",
    width: "14px",
    fontSize: "9px",
    marginLeft: "5px",
    padding: "0",
    fontWeight: 700,
    position: "relative",
    opacity: 0.5,
  },
  actionMenu: {
    position: "relative",
    display: "inline-flex",
  },
  hiddenMenu: {
    "@media (min-width: 1025px)": {
      visibility: "hidden",
    },
  },
  visibleMenu: {
    visibility: "visible",
  },
  actionMenuIconButton: {
    margin: "auto 0",
  },
  actionMenuToggler: {
    position: "relative",
    float: "right",
    padding: "9px 10px",
  },
  actionMenuDropdown: {
    position: "absolute",
    left: "calc(100% + 15px)",
    zIndex: 1,
    background: colors.black,
    display: "flex",
    flexDirection: "column",
    padding: "5px 0",
    border: `2px solid ${colors.offBlack}`,
    whiteSpace: "nowrap",
  },
  invisible: {
    visibility: "hidden",
  },
  actionMenuOptionButton: {
    width: "100%",
    textAlign: "left",
    paddingTop: "2px",
    paddingBottom: "0px",
    fontWeight: 500,
    fontSize: "12px",
  },
  enabledButton: {
    color: colors.offWhite,
    ":hover": {
      background: colors.iron,
      color: colors.white,
    },
  },
  disabledButton: {
    userSelect: "none",
    cursor: "default",
    color: colors.steel,
  },
  actionMenuOption: {
    width: "100%",
    margin: "0 15px",
  },
  packToggle: {
    borderTop: "5px solid transparent",
    borderBottom: "5px solid transparent",
    borderLeft: `5px solid ${colors.offWhite}`,
    display: "inline-block",
    width: "0",
    height: "0",
    margin: "auto 10px auto 0",
    position: "relative",
  },
  packToggled: {
    transform: "rotate(90deg)",
  },
});

export default React.memo(ExecutionScript);
