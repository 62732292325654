import React, { useCallback, useEffect, useState } from "react";
import { useModal } from "react-modal-hook/dist";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import {
  ADD_USER_TO_SYNDICATION_ALLOW_LOST,
  REMOVE_USER_FROM_SYNDICATION_ALLOW_LOST,
} from "../../../graphql/mutations";
import { AllowList, ID } from "../../../graphql/schema";
import { useComposerStateContext } from "../../../contexts/ComposerStateContext";
import { useAlertContext } from "../../../contexts/AlertContext";
import Modal from "../../../core/modal/Modal";
import Panel from "../../../core/panels/Panel";
import LinkButton from "../../../core/forms/LinkButton";
import { Button, TextField, CircularProgress } from "@material-ui/core";
import imgClose from "../../../images/close.svg";
import "./allowlistmodal.scss";

type Props = Readonly<{
  closeMenu: () => void;
  id: ID;
  allowList: AllowList[];
  name: string;
  className?: string;
  spanClassName?: string;
}>;

function SyndicationAllowListModal({
  closeMenu,
  id,
  allowList,
  name,
  className,
  spanClassName,
}: Props) {
  const { t } = useTranslation();

  const {
    setUpdatedSyndication,
    updatedSyndicationWithAllowList,
    setUpdatedSyndicationWithAllowList,
  } = useComposerStateContext();
  const { addError, setAlert } = useAlertContext();

  const [addUserToSyndicationAllowList, { loading: loadingAddTo }] =
    useMutation(ADD_USER_TO_SYNDICATION_ALLOW_LOST);
  const [removeUserFromSyndicationAllowList] = useMutation(
    REMOVE_USER_FROM_SYNDICATION_ALLOW_LOST,
  );

  const [userNickname, setNickname] = useState<string>("");
  const [loadingRemove, setLoadingRemove] = useState<Set<string>>(new Set());

  const onAddUserToAllowList = useCallback(async () => {
    try {
      const addUser = await addUserToSyndicationAllowList({
        variables: { id, userNickname },
      });
      if (addUser.data.addUserToSyndicationAllowList) {
        setUpdatedSyndication(id);
        setAlert({
          message: t("message.added_user_to_allow_list", {
            username: userNickname,
          }),
          severity: "success",
        });
      }
      setNickname("");
    } catch (err) {
      addError(t("error.failed_to_add_to_allow_list"));
    }
  }, [
    addUserToSyndicationAllowList,
    id,
    userNickname,
    setUpdatedSyndication,
    addError,
    setAlert,
    t,
  ]);

  const onAddUserToAllowListClick = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        onAddUserToAllowList();
      }
    },
    [onAddUserToAllowList],
  );

  const onRemoveUserFromAllowList = useCallback(
    async (nickname: string) => {
      try {
        const removeSet = loadingRemove;
        removeSet.add(nickname);
        setLoadingRemove(removeSet);
        const removeUser = await removeUserFromSyndicationAllowList({
          variables: { id, userNickname: nickname },
        });
        if (removeUser.data.removeUserFromSyndicationAllowList) {
          setUpdatedSyndication(id);
          setAlert({
            message: t("message.removed_user_from_allow_list", {
              username: userNickname,
            }),
            severity: "success",
          });
        }
        removeSet.delete(nickname);
        setLoadingRemove(removeSet);
      } catch (err) {
        addError(t("error.failed_to_remove_from_allow_list"));
        const removeSet = loadingRemove;
        removeSet.delete(nickname);
        setLoadingRemove(removeSet);
      }
    },
    [
      removeUserFromSyndicationAllowList,
      id,
      userNickname,
      setUpdatedSyndication,
      addError,
      loadingRemove,
      setAlert,
      t,
    ],
  );

  const [show, hide] = useModal(
    () => (
      <Modal isOpen onRequestClose={hide} className="allow-list--modal">
        <div className="allow-list">
          <img
            src={imgClose}
            alt="Close"
            className="close-icon"
            onClick={hide}
          />
          <div className="allow-list__header">
            <div className="allow-list__header--title">
              {t("terminal.manage_allow_list")}
            </div>
            <div className="allow-list__header--subtitle">
              {t("message.only_specified_users_can_subscribe_to_bot", {
                bot_name: name,
              })}
            </div>
            <div className="allow-list__header__input">
              <TextField
                variant="outlined"
                placeholder={t("message.enter_nickname")}
                className="allow-list__header__input--field"
                value={userNickname}
                onChange={(e) => setNickname(e.currentTarget.value)}
                onKeyPress={onAddUserToAllowListClick}
              />
              <Button
                variant="contained"
                className="allow-list__header__input--button"
                disabled={
                  userNickname.length === 0 ||
                  allowList.findIndex(
                    (allow) => allow.nickname === userNickname,
                  ) >= 0
                }
                onClick={onAddUserToAllowList}
              >
                {loadingAddTo ? (
                  <CircularProgress size={12} className="loading" />
                ) : (
                  t("terminal.add_user")
                )}
              </Button>
            </div>
          </div>
          <Panel position="center" className="allow-list__list">
            {allowList.map((allow, i) => (
              <div key={i} className="allow-list__list--item">
                <div className="nickname">@{allow.nickname}</div>
                {allow.canRemove ? (
                  <Button
                    variant="contained"
                    className="remove-button"
                    onClick={() => onRemoveUserFromAllowList(allow.nickname)}
                    disabled={loadingRemove.has(allow.nickname)}
                  >
                    {loadingRemove.has(allow.nickname) ? (
                      <CircularProgress size={12} className="loading" />
                    ) : (
                      t("common.remove")
                    )}
                  </Button>
                ) : (
                  <span className="subscribed">{t("common.subscribed")}</span>
                )}
              </div>
            ))}
          </Panel>
          <Button variant="contained" className="close-button" onClick={hide}>
            {t("common.close")}
          </Button>
        </div>
      </Modal>
    ),
    [
      userNickname,
      allowList,
      onAddUserToAllowListClick,
      onRemoveUserFromAllowList,
    ],
  );

  const onClick = useCallback(
    (e) => {
      show();
      closeMenu();
      e.stopPropagation();
    },
    [show, closeMenu],
  );

  useEffect(() => {
    if (updatedSyndicationWithAllowList === id) {
      setUpdatedSyndicationWithAllowList(undefined);
      show();
    }
  }, [
    id,
    updatedSyndicationWithAllowList,
    setUpdatedSyndicationWithAllowList,
    show,
  ]);

  return (
    <LinkButton onClick={onClick} className={className ?? "share"}>
      <span className={spanClassName ?? "action-option"}>
        {t("terminal.manage_allow_list")}
      </span>
    </LinkButton>
  );
}
export default SyndicationAllowListModal;
