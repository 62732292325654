import { useEffect } from "react";
import { isDevelopment } from "./environment";
import { v4 as uuid } from "uuid";

const ANON_USER_KEY = "anon-user-id";

export function useInitAnonId() {
  useEffect(() => {
    if (!localStorage.getItem(ANON_USER_KEY)) {
      localStorage.setItem(ANON_USER_KEY, uuid());
    }
  }, []);
}

export function logEvent(
  event: string,
  properties?: { [key: string]: string | number | boolean | undefined | null },
) {
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.log("Rudderstack Track Event", event, properties);
  } else {
    (window as any).rudderanalytics.track(event, properties);
  }
}

export function setIdentity(cognitoUserId: string) {
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.log("Rudderstack Cognito Identity", cognitoUserId);
  } else {
    (window as any).rudderanalytics.identify(cognitoUserId);
  }
}

export function setAnonIdentity() {
  const identity = localStorage.getItem(ANON_USER_KEY);
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.log("Rudderstack Anon Identity", identity);
  } else {
    (window as any).rudderanalytics.identify(identity);
  }
}
