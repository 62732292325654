import React from "react";
import { StyleSheet, css } from "aphrodite";
import colors from "styles/colors";

type Props = Readonly<{
  columnCount: number;
}>;

function LoadingRow({ columnCount }: Props) {
  const columns = [];
  for (let i = 0; i < columnCount; i++) {
    columns.push(
      <td key={i}>
        {/**
         * add a div since td element will blur too much height
         */}
        <div className={css(styles.loadingColumnBody)} />
      </td>,
    );
  }

  return <tr className={css(styles.loadingRow)}>{columns}</tr>;
}

const styles = StyleSheet.create({
  loadingRow: {
    height: "30px",
    filter: "blur(5px)",
  },
  loadingColumnBody: {
    height: "5px",
    background: colors.offWhite,
  },
});

export default LoadingRow;
