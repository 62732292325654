import { useModal } from "react-modal-hook/dist";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import ConfirmationModal, { OnConfirmFn } from "./ConfirmationModal";

const useConfirmationModal = () => {
  const { t } = useTranslation();

  const onConfirmRef = useRef(() => {});
  const textRef = useRef<React.ReactNode>("");
  const primaryActionRef = useRef("");
  const cancelTextRef = useRef("");
  const destructiveButtonsRef = useRef(false);

  const [show, hide] = useModal(
    () => (
      <ConfirmationModal
        isOpen
        text={textRef.current}
        primaryAction={primaryActionRef.current}
        onConfirm={onConfirmRef.current}
        cancelText={cancelTextRef.current}
        destructive={destructiveButtonsRef.current}
        onClose={hide}
      />
    ),
    [textRef, onConfirmRef, primaryActionRef],
  );

  return useCallback(
    (
      onConfirm: OnConfirmFn,
      text: React.ReactNode,
      primaryAction: string,
      cancelText?: string,
      destructive?: boolean,
    ) => {
      onConfirmRef.current = onConfirm;
      textRef.current = text;
      primaryActionRef.current = primaryAction;
      cancelTextRef.current = cancelText ?? t("common.cancel");
      destructiveButtonsRef.current = destructive ?? false;
      show();
    },
    [show, t],
  );
};

export default useConfirmationModal;
