import React, { useState } from "react";
import invariant from "invariant";
import { StyleSheet, css } from "aphrodite";
import { Exchange } from "__generated__/graphql";
import { useConnectCredentialModal } from "contexts/ConnectCredentialContext";
import ConnectCredentialNavigation from "./ConnectCredentialNavigation";
import SelectExchangeStep from "./SelectExchangeStep";
import CreateExchangeKeyStep from "./CreateExchangeKeyStep";
import ConnectExchangeKeyStep from "./ConnectExchangeKeyStep";
import ConnectCredentialSuccess from "./ConnectCredentialSuccess";
import Panel from "core/panels/Panel";
import { logEvent } from "helpers/analytics";
import colors from "styles/colors";

export type ConnectCredentialStep =
  | "SELECT_EXCHANGE"
  | "CREATE_KEY"
  | "CONNECT_KEY"
  | "CONNECT_SUCCESS";

function ConnectCredential() {
  const { close } = useConnectCredentialModal();

  const [step, setStep] = useState<ConnectCredentialStep>("SELECT_EXCHANGE");
  const [exchange, setExchange] = useState<Exchange>();

  const onSelectExchange = (exchange: Exchange) => {
    setStep("CREATE_KEY");
    setExchange(exchange);
    logEvent("ConnectExchangeModalSelectedExchange", { exchange });
  };

  let currentStep;
  switch (step) {
    case "SELECT_EXCHANGE":
      currentStep = <SelectExchangeStep onSelectExchange={onSelectExchange} />;
      break;
    case "CREATE_KEY":
      invariant(
        exchange != null,
        "Exchange must be selected in CREATE_KEY step",
      );
      currentStep = (
        <CreateExchangeKeyStep
          exchange={exchange}
          onBackClick={() => {
            setStep("SELECT_EXCHANGE");
            setExchange(undefined);
            logEvent(
              "ConnectExchangeModalGoBackToSelectExchangeStepFromCreateExchangeKeyStep",
              { exchange },
            );
          }}
          onContinueClick={() => {
            setStep("CONNECT_KEY");
            logEvent(
              "ConnectExchangeModalGoToConnectExchangeKeyStepFromCreateExchangeKeyStep",
              { exchange },
            );
          }}
        />
      );
      break;
    case "CONNECT_KEY":
      invariant(
        exchange != null,
        "Exchange must be selected in CONNECT_KEY step",
      );
      currentStep = (
        <ConnectExchangeKeyStep
          exchange={exchange}
          onBackClick={() => {
            setStep("CREATE_KEY");
            logEvent(
              "ConnectExchangeModalGoBackToSelectExchangeStepFromConnectExchangeKeyStep",
              { exchange },
            );
          }}
          onSuccess={() => {
            setStep("CONNECT_SUCCESS");
            logEvent("ConnectExchangeModalConnectedExchangeKeySuccess", {
              exchange,
            });
          }}
        />
      );
      break;
    case "CONNECT_SUCCESS":
      currentStep = <ConnectCredentialSuccess onClick={close} />;
      break;
  }

  return (
    <div className={css(styles.container)}>
      <ConnectCredentialNavigation step={step} onClose={close} />
      <Panel
        position="center"
        className={css(styles.panel)}
        innerClassName={css(styles.panelInner)}
      >
        <div className={css(styles.body)}>{currentStep}</div>
      </Panel>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    background: colors.black,
    height: "100%",
  },
  body: {
    position: "relative",
    background: colors.black,
    display: "flex",
    flexDirection: "column",
    padding: "65px 100px",
    height: "calc(100% - 85px)",
    boxSizing: "border-box",
    "@media (max-width: 767.98px)": {
      padding: "20px",
    },
  },
  panel: {
    flex: "7 1",
  },
  panelInner: {
    margin: "0",
    height: "100%",
  },
});

export default ConnectCredential;
