import React, {
  useRef,
  useEffect,
  forwardRef,
  Ref,
  MutableRefObject,
  useState,
} from "react";
import Scrollbars from "react-custom-scrollbars";
import "./forms/fields/scrollablemenu/scroll.scss";

type Props = Readonly<{
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onScroll?: React.UIEventHandler<any>;
  onFetchMore?: (scrollWrapper: any) => void;
}>;

const nop = () => {};

function PanelScroll(
  { children, className, style, onScroll, onFetchMore }: Props,
  ref: Ref<Scrollbars | null>,
) {
  const scrollbarRef = useRef<Scrollbars>(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    // to be able to fetch more under certain conditions where it cannot fetch more and there is no scrollbar
    // (ex. if the user starts / refreshes on the multivariant page and goes to the simulation page)
    if (
      isFetching &&
      scrollbarRef?.current &&
      scrollbarRef.current.getScrollHeight() ===
        scrollbarRef.current.getClientHeight()
    ) {
      setIsFetching(false);
    }
    // to fetch more rows if there are any and the scrollbar is not enabled
    if (
      scrollbarRef?.current &&
      onFetchMore &&
      scrollbarRef.current.getScrollHeight() ===
        scrollbarRef.current.getClientHeight()
    ) {
      onFetchMore(scrollbarRef.current);
      setIsFetching(true);
    }
  }, [scrollbarRef, onFetchMore, isFetching]);

  return (
    <Scrollbars
      renderThumbVertical={(props) => (
        <div {...props} className="thumb-vertical" />
      )}
      renderTrackVertical={(props) => (
        <div {...props} className="track-vertical" />
      )}
      renderThumbHorizontal={(props) => (
        <div {...props} className="thumb-horizontal" />
      )}
      renderTrackHorizontal={(props) => (
        <div {...props} className="track-horizontal" />
      )}
      ref={(r) => {
        (scrollbarRef as MutableRefObject<Scrollbars | null>).current = r;
        if (ref && typeof ref === "function") {
          ref(r);
        } else if (ref) {
          (ref as MutableRefObject<Scrollbars | null>).current = r;
        }
      }}
      // TODO: Fix eslint error
      // eslint-disable-next-line react/no-children-prop
      children={children}
      style={style}
      className={className}
      onScroll={onScroll ?? nop}
    />
  );
}

export default forwardRef(PanelScroll);
