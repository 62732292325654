import React, { createContext, useState, useCallback, useContext } from "react";
import invariant from "invariant";
import { Color } from "@material-ui/lab";
import { logEvent } from "../helpers/analytics";

export type AddErrorFn = (error: string) => void;
export type AddWarningFn = (warning: string) => void;

export interface AlertInfo {
  message: string;
  severity: Color | undefined;
  action?: React.ReactNode;
}

interface Context {
  errors: string[] | undefined;
  warnings: string[] | undefined;
  setErrors: (errors: string[] | undefined) => void;
  setWarnings: (errors: string[] | undefined) => void;
  addError: AddErrorFn;
  addWarning: AddWarningFn;
  alert: AlertInfo | undefined;
  setAlert: (alert: AlertInfo | undefined) => void;
}

export const AlertContext = createContext<Context | undefined>(undefined);

export function useAlertContext() {
  const context = useContext(AlertContext);
  invariant(
    context != null,
    "Component is not a child of AlertContext provider",
  );
  return context;
}

export function AlertContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [errors, setErrors] = useState<string[] | undefined>(undefined);
  const [warnings, setWarnings] = useState<string[] | undefined>(undefined);
  const [alert, setAlert] = useState<AlertInfo | undefined>(undefined);

  const addError = useCallback(
    (error: string) => {
      logEvent("Error", { error });
      if (!errors?.includes(error)) {
        const updatedErrors = errors?.concat(error) ?? [error];
        setErrors(updatedErrors);
      }
    },
    [errors, setErrors],
  );

  const addWarning = useCallback(
    (warning: string) => {
      logEvent("Warning", { warning });
      if (!warnings?.includes(warning)) {
        const updatedWarnings = warnings?.concat(warning) ?? [warning];
        setWarnings(updatedWarnings);
      }
    },
    [warnings, setWarnings],
  );

  return (
    <AlertContext.Provider
      value={{
        errors,
        warnings,
        setErrors,
        setWarnings,
        addError,
        addWarning,
        alert,
        setAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
}
