import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import {
  ColumnDef,
  ColumnOrderState,
  flexRender,
  getCoreRowModel,
  Row,
  TableOptions,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import { StyleSheet, css } from "aphrodite";
import { useVirtualizer } from "@tanstack/react-virtual";
import { SortDirection } from "__generated__/graphql";
import InfiniteTableHeader from "core/lists/InfiniteTableHeader";
import colors from "styles/colors";
import { useTranslation } from "react-i18next";
import LoadingRow from "core/table/LoadingRow";

type Props<D> = Readonly<{
  /**
   * MUST be memoized by parent to prevent unnecessary re-renders
   */
  columns: ColumnDef<D>[];
  /**
   * MUST be memoized by parent to prevent unnecessary re-renders
   */
  data: TableOptions<D>["data"];
  /**
   * If there is additional data to load
   */
  hasNextPage: boolean;
  /**
   * Set to true while querying for more data
   */
  isFetching: boolean;
  sort?: {
    /**
     * Id of column to sort by. This mutch match the `id` field of
     * the column definition.
     */
    sortKey: string;
    sortDirection: SortDirection;
  };
  style?: StyleSheet;
  selectedRowId?: string;
  columnVisibility?: VisibilityState;
  columnOrder?: ColumnOrderState;
  overscanLength: number;
  setSortKey?: (key: string) => void;
  /**
   * Callback to fetch more data
   */
  fetchNextPage: () => Promise<void> | void;
  onRowClick?: (data: D) => void;
  getRowCanExpand?: (row: Row<D>) => boolean;
  getSubRows?: (data: D) => ReactNode;
  getSubRowsCount?: (data: D) => number;
  onColumnResize?: (size: number, id: string) => void;
  onColumnFilterClick?: (id: string) => void;
}>;

/**
 * This component emulates the layout behavior of a plain html table, but adds
 * infinite scroll support for loading large data sets just-in-time as they
 * are scrolled into view.
 *
 * Furthermore, content is virtualized with react-virtual so that we only render
 * rows that are visible in the client's viewport.
 */
export default function InfiniteTable<D>(props: Props<D>) {
  const {
    data,
    columns,
    hasNextPage,
    isFetching,
    sort,
    selectedRowId,
    columnVisibility,
    columnOrder,
    overscanLength,
    setSortKey,
    fetchNextPage,
    onRowClick,
    getRowCanExpand,
    getSubRows,
    getSubRowsCount,
    onColumnResize,
    onColumnFilterClick,
  } = props;

  const { t } = useTranslation();

  const table = useReactTable({
    columns,
    data,
    state: {
      columnVisibility,
      columnOrder,
    },
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  const { rows } = table.getRowModel();

  const additionalRowsCount = rows.reduce((count, row) => {
    if (row.getIsExpanded() && getSubRowsCount) {
      return count + getSubRowsCount(row.original);
    }

    return count;
  }, 0);

  const rowVirtualizer = useVirtualizer<HTMLDivElement | null, D>({
    getScrollElement: () => containerRef.current,
    count: rows.length,
    overscan: overscanLength + additionalRowsCount,
    estimateSize: (_idx: number) => 31,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement == null) {
        return;
      }

      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

      if (
        scrollHeight - scrollTop - clientHeight < 300 &&
        !isFetching &&
        hasNextPage
      ) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetching],
  );

  useEffect(() => {
    // use a setTimeout so that it properly tries to fetch more if redirecting from one InfiniteTable to another
    setTimeout(() => {
      fetchMoreOnBottomReached(containerRef.current);
    }, 0);
  }, [fetchMoreOnBottomReached]);

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize +
        additionalRowsCount -
        (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <div
      className={css(styles.container)}
      ref={containerRef}
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
    >
      <div className={css(styles.wrapper)}>
        <table className={css(styles.table)}>
          {/* Head */}
          <thead className={css(styles.thead)}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <InfiniteTableHeader
                    key={header.id}
                    header={header}
                    sort={sort}
                    setSortKey={setSortKey}
                    onColumnResize={onColumnResize}
                    onColumnFilterClick={onColumnFilterClick}
                  />
                ))}
              </tr>
            ))}
          </thead>
          {/* Body */}
          <tbody>
            {!data.length && !isFetching && (
              <tr>
                <td
                  className={css(styles.td)}
                  style={{ height: `${paddingTop}px` }}
                >
                  {t("message.no_results_match")}
                </td>
              </tr>
            )}
            {paddingTop > 0 && (
              <tr>
                <td
                  className={css(styles.td)}
                  style={{ height: `${paddingTop}px` }}
                />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <React.Fragment key={row.id}>
                  <tr
                    className={css(
                      styles[
                        selectedRowId &&
                        selectedRowId === (row.original as any)?.id
                          ? "activeRow"
                          : "tr"
                      ],
                      virtualRow.index % 2 === 1 &&
                        (!selectedRowId ||
                          selectedRowId !== (row.original as any)?.id) &&
                        styles.oddRow,
                    )}
                    onClick={() =>
                      row.getCanExpand()
                        ? row.toggleExpanded()
                        : onRowClick?.(data[virtualRow.index])
                    }
                  >
                    {row.getVisibleCells().map((cell) => {
                      const isRightAligned =
                        cell.column.columnDef.meta?.rightAligned === true;
                      const overflowHidden =
                        cell.column.columnDef.meta?.overflow !== true;
                      return (
                        <td
                          key={cell.id}
                          className={css(
                            styles.td,
                            isRightAligned && styles.rightAligned,
                            overflowHidden && styles.overflowHidden,
                          )}
                          style={{ width: cell.column.getSize() }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  {row.getIsExpanded() && getSubRows?.(row.original)}
                </React.Fragment>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td
                  style={{ height: `${paddingBottom}px` }}
                  className={css(styles.td)}
                />
              </tr>
            )}
            {!data.length && isFetching && (
              <LoadingRow columnCount={table.getFlatHeaders().length} />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "block",
    position: "relative",
    flexGrow: 1,
    overflow: "auto",
  },
  wrapper: {
    display: "block",
    position: "absolute",
  },
  table: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    tableLayout: "fixed",
    fontSize: "0.85rem",
    color: colors.paleGray,
    width: "100%",
  },
  thead: {
    margin: 0,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  tr: {
    cursor: "pointer",
    position: "relative",
    ":hover": {
      backgroundColor: colors.charcoal,
    },
  },
  oddRow: {
    backgroundColor: colors.oddRowColor,
  },
  activeRow: {
    cursor: "pointer",
    position: "relative",
    backgroundColor: colors.darkGray,
  },
  td: {
    padding: "0 6px",
    border: `1px solid ${colors.offBlack}`,
    fontWeight: 400,
    whiteSpace: "nowrap",
  },
  overflowHidden: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  resizer: {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    width: "10px",
    background: "transparent",
    cursor: "col-resize",
    userSelect: "none",
    touchAction: "none",
    ":hover": {
      backgroundColor: colors.charcoal,
    },
  },
  rightAligned: {
    textAlign: "right",
  },
});
