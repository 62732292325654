import React from "react";

export type VisibileSignedColor = "positive" | "negative" | "all" | "none";

type Props = Readonly<{
  number?: string | null;
  signedColor: VisibileSignedColor;
  transformer?: (
    n: number | string | undefined | null,
  ) => number | string | undefined | null;
  endingString?: string;
  noGradiant?: boolean;
}>;

function ColoredNumberSpan({
  number,
  signedColor,
  transformer,
  endingString,
  noGradiant,
}: Props) {
  if (!number || number === "NaN") {
    return <span>--</span>;
  }

  const percentage = parseFloat(number ?? "0");

  const transformedVal = endingString
    ? `${transformer?.(number) ?? number} ${endingString}`
    : transformer?.(number) ?? number;

  if (
    percentage === 0 ||
    (signedColor === "positive" && percentage < 0) ||
    (signedColor === "negative" && percentage > 0) ||
    signedColor === "none" ||
    !transformedVal
  ) {
    return <span>{transformedVal}</span>;
  } else {
    const intensity = Math.min(10000, Math.abs(percentage)) / 10000;

    return (
      <span
        style={{
          filter: noGradiant ? "unset" : `brightness(${1 + intensity})`,
          color: percentage < 0 ? "#cc6464" : "#7daf7c",
        }}
      >
        {transformedVal}
      </span>
    );
  }
}

export default ColoredNumberSpan;
