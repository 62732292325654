import React, { ReactNode, useRef } from "react";
import ReactTooltip from "react-tooltip";
import classnames from "classnames";
import "./infotooltip.scss";

interface Props {
  children: ReactNode;
  id: string; // tooltip requires unique id
  isWhite?: boolean;
  className?: string;
  tooltipClassName?: string;
}

const InfoTooltip = ({
  children,
  id,
  isWhite,
  className,
  tooltipClassName,
}: Props) => {
  const tooltipRef = useRef(null);

  return (
    <>
      <button className={className ?? "infoIcon"} data-for={id} data-tip="">
        i
      </button>
      <ReactTooltip
        id={id}
        effect="solid"
        className={
          tooltipClassName ?? classnames("reactTooltip", { white: isWhite })
        }
        clickable
        delayHide={100}
        ref={tooltipRef}
        getContent={() => children}
        afterShow={() => {
          if (!tooltipRef.current) return;

          // TODO: Eliminate use of non-null assertion
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const tooltip: any = (tooltipRef.current! as any).tooltipRef;

          if (tooltip.offsetLeft < 0) {
            // don't make tooltip off-screen
            tooltip.style.left = "0px";
          }
        }}
      />
    </>
  );
};

export default InfoTooltip;
