import React from "react";
import ReactTooltip from "react-tooltip";
import { StyleSheet, css } from "aphrodite";
import { useBoolean } from "helpers/hooks";
import colors from "styles/colors";

type Props = Readonly<{
  title?: string;
  id?: string;
  src: string; // potential improvement by making this a union type of all of our images
  alt: string;
  color?: "red" | "default";
  paddingStyle?:
    | "topPadding"
    | "rightPadding"
    | "bottomPadding"
    | "leftPadding"
    | "noPadding";
  href?: string;
  disabled?: boolean;
  onClick?: (() => void) | ((e: React.MouseEvent) => void);
}>;

function IconButton({
  title,
  id,
  src,
  alt,
  color = "default",
  paddingStyle = "noPadding",
  href,
  disabled = false,
  onClick,
}: Props) {
  const [isHovering, setIsHovering, setIsNotHovering] = useBoolean(false);

  const stateStyle = disabled ? styles.disabled : styles.enabled;

  return (
    <>
      <button
        data-for={id}
        data-tip=""
        title={title && !id ? title : undefined}
        className={css(
          styles.button,
          styles[color],
          styles[paddingStyle],
          stateStyle,
        )}
        onClick={onClick}
        onMouseEnter={setIsHovering}
        onMouseLeave={setIsNotHovering}
      >
        {href ? (
          <a className={css(styles.anchor)} href={href}>
            <img
              className={css(
                styles.icon,
                styles[isHovering ? "hoveringIcon" : "notHoveringIcon"],
              )}
              src={src}
              alt={alt}
            />
          </a>
        ) : (
          <img
            className={css(
              styles.icon,
              styles[isHovering ? "hoveringIcon" : "notHoveringIcon"],
            )}
            src={src}
            alt={alt}
          />
        )}
      </button>
      {title && id && (
        <ReactTooltip id={id} effect="solid" className="reactTooltip">
          {title}
        </ReactTooltip>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    display: "flex",
    boxSizing: "border-box",
    color: colors.lightGray,
    transition: "0.2s background ease-out",
    width: "30px",
    height: "30px",
  },
  noPadding: {
    padding: "0",
  },
  topPadding: {
    padding: "2px 0 0",
  },
  rightPadding: {
    padding: "0 2px 0 0",
  },
  bottomPadding: {
    padding: "0 0 2px",
  },
  leftPadding: {
    padding: "0 0 0 2px",
  },
  enabled: {
    cursor: "pointer",
  },
  disabled: {
    cursor: "default",
    opacity: 0.5,
  },
  default: {
    background: colors.offBlack,
    ":hover": {
      background: colors.pine,
    },
  },
  red: {
    background: colors.error,
    ":hover": {
      background: colors.lightRed,
    },
  },
  anchor: {
    display: "flex",
    height: "12px",
    margin: "auto",
  },
  icon: {
    height: "12px",
    margin: "auto",
  },
  notHoveringIcon: {
    filter: "unset",
  },
  hoveringIcon: {
    filter: "grayscale(100%) brightness(232%)",
  },
});

export default IconButton;
