import React, {
  useState,
  createContext,
  useCallback,
  useMemo,
  useReducer,
  useContext,
  useEffect,
} from "react";
import { History } from "history";
import invariant from "invariant";
import queryString from "query-string";
import { ApolloError, DocumentNode, gql, useQuery } from "@apollo/client";
import {
  ExecutionColumn as ExecutionColumnEnum,
  ExecutionsInnerTable_ExecutionFragment,
  SortDirection as SortDirectionEnum,
  MultivariantExecutionContextProviderQuery,
  SyndicationSubscriptionsContextProviderQuery,
  MultivariantExecutionContextProviderQueryVariables,
  SyndicationSubscriptionsContextProviderQueryVariables,
  ExecutionContextQuery,
  ExecutionContextQueryVariables,
  ExecutionsInfiniteTable_ExecutionFragment,
} from "__generated__/graphql";
import {
  ExecutionColumn,
  ExecutionFilter,
  ExecutionSort,
  ID,
  ExecutionStatus,
  ExecutionType,
  ExecutionStage,
  SortDirection,
  Exchange,
  CurrencyPair,
  ExecutionFieldComparison,
  Execution,
} from "graphql/schema";
import { useFilterContext } from "./FilterContext";
import { usePrefilterContext } from "../PrefilterContext";
import {
  COLUMNS_PRESET_ID_KEY,
  useColumnsPresetsContext,
} from "./ColumnsPresetsContext";
import { getSelectedColumns } from "./ColumnsUtils";
import { getSearchParamsFromFilter } from "./FilterUtils";
import { useUserContext } from "contexts/UserContext";
import { SYNDICATION_SUBSCRIPTIONS_COLUMN_DEFS } from "contexts/executions/SyndicationSubscriptionsContextProvider";
import { ColumnDefinition } from "components/executions/list/ExecutionColumnDefinitions";
import ExecutionsInnerTable from "components/executions/list/ExecutionsInnerTable";
import ExecutionsInfiniteTable from "components/executions/list/ExecutionsInfiniteTable";
import { ActionDefinition } from "components/executions/list/ExecutionActionDefinitions";
import { logEvent } from "helpers/analytics";
import {
  BATCHTEST_SUBTAB,
  BOTS_TAB,
  CANDIDATES_SUBTAB,
  GUEST_TAB,
  LIVE_SETUPS_SUBTAB,
  LIVE_TAB,
  MY_BOTS_SUBSCRIPTIONS_SUBTAB,
  MY_BOTS_SUBTAB,
  RELEASE_CANDIDATES_SUBTAB,
} from "helpers/navigation";
import { getFirstString } from "helpers/urlParameters";
import { fromStringValue } from "helpers/strings";
import { useVirtualTable } from "helpers/environment";
import { ColumnDef } from "@tanstack/react-table";

export const EXECUTION_CONTEXT_QUERY__DEPRECATED = gql`
  query ExecutionContextDeprecated(
    $first: Int
    $after: String
    $sort: ExecutionSort
    $filters: ExecutionListFilters
  ) {
    me {
      executions(first: $first, after: $after, filters: $filters, sort: $sort) {
        edges {
          cursor
          node {
            ...ExecutionsInnerTable_execution
          }
        }
        pageInfo {
          hasNextPage
        }
      }
      executionsCount(filters: $filters)
    }
  }
  ${ExecutionsInnerTable.fragments.execution}
`;

export const EXECUTION_CONTEXT_QUERY = gql`
  query ExecutionContext(
    $first: Int
    $after: String
    $sort: ExecutionSort
    $filters: ExecutionListFilters
    $useCandleSize: Boolean!
    $useAbsoluteProfit: Boolean!
    $useNumberOfTrades: Boolean!
    $useMaxDrawdown: Boolean!
    $usePercentProfitableTrades: Boolean!
    $useProfitability: Boolean!
    $useAvgPositionPrice: Boolean!
    $useAvgMonthlyProfit: Boolean!
    $useAvgWinMonth: Boolean!
    $useAvgLoseMonth: Boolean!
    $usePercProfitableMonths: Boolean!
    $usePositionAmount: Boolean!
    $usePositionAbsoluteProfit: Boolean!
    $usePositionProfitLoss: Boolean!
    $useBalance: Boolean!
    $useRiskScore: Boolean!
    $useBuyHoldReturn: Boolean!
    $useSharpeRatio: Boolean!
    $useSortinoRatio: Boolean!
    $useTotalRealizedGain: Boolean!
    $useTotalRealizedLoss: Boolean!
    $useConsistencyScore: Boolean!
    $useScriptVersion: Boolean!
    $useScriptName: Boolean!
    $useCreatedAt: Boolean!
    $useTags: Boolean!
    $useAllocation: Boolean!
    $useStartedAt: Boolean!
    $useEndedAt: Boolean!
    $useAutoRebalance: Boolean!
    $isBot: Boolean!
    $useActiveSubscribersCount: Boolean!
  ) {
    me {
      executions(first: $first, after: $after, filters: $filters, sort: $sort) {
        edges {
          cursor
          node {
            ...ExecutionsInfiniteTable_execution
          }
        }
        pageInfo {
          hasNextPage
        }
      }
      executionsCount(filters: $filters)
    }
  }
  ${ExecutionsInfiniteTable.fragments.execution}
`;

type ExecutionsQuery = ExecutionContextQuery &
  MultivariantExecutionContextProviderQuery &
  SyndicationSubscriptionsContextProviderQuery;

type ExecutionsQueryVariables = ExecutionContextQueryVariables &
  MultivariantExecutionContextProviderQueryVariables &
  SyndicationSubscriptionsContextProviderQueryVariables;

const COMPLETE_STATUSES: ExecutionStatus[] = [
  "ENDED",
  "CANCELLED",
  "LIQUIDATED",
  "FAILED",
];

// to be able to give to the virutalized table
type ShowActionModalFn = (
  name: string,
  action: () => void,
  execution?: Execution,
) => void;
interface ShowActionModalInterface {
  function: ShowActionModalFn;
}

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_SORT: ExecutionSort = {
  sortKey: "CREATION_DATE",
  sortDirection: "DESC",
};

export type ExecutionListFilters = {
  types?: ExecutionType[];
  stage?: ExecutionStage;
  exchanges?: Exchange[];
  currencyPairs?: CurrencyPair[];
  fieldComparisons?: ExecutionFieldComparison[];
  filters?: ExecutionFilter[];
  userId?: number;
};

interface Context {
  creationPath?: string;
  executionsFromResult: (
    results: any,
  ) => ExecutionsInnerTable_ExecutionFragment[];
  getExecutionsCount: (results: ExecutionsQuery) => number | undefined;
  getLastExecutionCursor: (results: ExecutionsQuery) => string | undefined;
  onFetchMore: () => void;
  listQuery__DEPRECATED: DocumentNode;
  listQuery: DocumentNode;
  resultsPerPage: number;
  queryVariables: ExecutionsQueryVariables;
  sort: ExecutionSort;
  toggleSort: (sortKey: ExecutionColumn) => void;
  columnDefs: ColumnDefinition[];
  setColumnDefs: (columnDefs: ColumnDefinition[], isPreset?: boolean) => void;
  executionSelected: (
    executionId: ID,
    name?: string,
    type?: ExecutionType,
    status?: ExecutionStatus,
  ) => (e: React.MouseEvent) => void;
  onExecutionClick: (
    executionId: ID,
    name?: string,
    type?: ExecutionType,
    status?: ExecutionStatus,
  ) => void;
  stage?: ExecutionStage;
  openPacks: string[];
  setOpenPacks: (packs: string[]) => void;
  isLiveOrSyndication: boolean;
  executionTypes?: ExecutionType[];
  tableColumns: ColumnDef<ExecutionsInfiniteTable_ExecutionFragment>[];
  executions: ExecutionsInnerTable_ExecutionFragment[];
  executionsCount?: number;
  loadingExecutions: boolean;
  executionsError?: ApolloError;
  refetch: () => void;

  // used to get syndication subscription page info
  syndicationId?: ID;
  syndicationName: string | undefined;
  setSyndicationName: (name: string | undefined) => void;

  actionDefs: ActionDefinition[];
  setActionDefs: (actionDefs: ActionDefinition[]) => void;
  showActionModal?: ShowActionModalInterface;
  setShowActionModal: (showActionModal?: ShowActionModalInterface) => void;
}

type Props = Readonly<{
  creationPath?: string;
  additionalQueryVariables?: any;
  executionsFromResult: (
    results: any,
  ) => ExecutionsInnerTable_ExecutionFragment[];
  getExecutionsCount: (results: any) => number | undefined;
  getLastExecutionCursor: (results: any) => string | undefined;
  listQuery__DEPRECATED: DocumentNode;
  listQuery: DocumentNode;
  resultsPerPage: number;
  defaultSort: ExecutionSort;
  selectionUrl?: string;
  children: React.ReactNode;
  columnDefintions: ColumnDefinition[];
  history: History;
  syndicationId?: ID;
  executionTypes?: ExecutionType[];
  tableColumns: ColumnDef<ExecutionsInfiniteTable_ExecutionFragment>[];
}>;

const getQueryVariables = (
  sort: ExecutionSort,
  resultsPerPage: number,
  filters: ExecutionFilter[],
  stage: ExecutionStage | undefined,
  types: ExecutionType[] | undefined,
  columnDefs: ColumnDefinition[],
  creationPath: string | undefined,
  extra: any,
  afterCursor?: string,
): ExecutionsQueryVariables => {
  const visibleColumns = new Set(
    (creationPath === MY_BOTS_SUBSCRIPTIONS_SUBTAB
      ? SYNDICATION_SUBSCRIPTIONS_COLUMN_DEFS
      : columnDefs.filter(
          (c) =>
            !c.hidden &&
            (creationPath !== LIVE_SETUPS_SUBTAB ||
              (creationPath === LIVE_SETUPS_SUBTAB &&
                c.column !== "PERCENT_PROFITABLE_TRADES" &&
                c.column !== "SCRIPT_VERSION")),
        )
    ).map((c) => c.column),
  );
  return {
    first: resultsPerPage,
    after: afterCursor,
    sort,
    filters: {
      filters: [
        ...filters,
        // in case the user has an execution that used the nash exchange so it won't throw an error
        {
          filterKey: "EXCHANGE",
          filterType: "NOT_EQUALS",
          value: "NASH",
        },
      ],
      types,
      stage,
    },
    useCandleSize: visibleColumns.has("CANDLE_SIZE"),
    useAbsoluteProfit: visibleColumns.has("ABSOLUTE_PROFIT"),
    useNumberOfTrades: visibleColumns.has("NUMBER_OF_TRADES"),
    useMaxDrawdown: visibleColumns.has("MAX_DRAWDOWN"),
    usePercentProfitableTrades: visibleColumns.has("PERCENT_PROFITABLE_TRADES"),
    useProfitability: visibleColumns.has("PROFITABILITY"),
    useAvgPositionPrice: visibleColumns.has("AVG_POSITION_PRICE"),
    useAvgMonthlyProfit: visibleColumns.has("AVG_MONTHLY_PROFIT"),
    useAvgWinMonth: visibleColumns.has("AVG_WIN_MONTH"),
    useAvgLoseMonth: visibleColumns.has("AVG_LOSE_MONTH"),
    usePercProfitableMonths: visibleColumns.has("PERC_PROFITABLE_MONTHS"),
    usePositionAmount: visibleColumns.has("POSITION_AMOUNT"),
    usePositionAbsoluteProfit: visibleColumns.has("POSITION_ABSOLUTE_PROFIT"),
    usePositionProfitLoss: visibleColumns.has("POSITION_PROFIT_LOSS"),
    useBalance: visibleColumns.has("BALANCE"),
    useRiskScore: visibleColumns.has("RISK_SCORE"),
    useBuyHoldReturn: visibleColumns.has("BUY_HOLD_RETURN"),
    useSharpeRatio: visibleColumns.has("SHARPE_RATIO"),
    useSortinoRatio: visibleColumns.has("SORTINO_RATIO"),
    useTotalRealizedGain: visibleColumns.has("TOTAL_REALIZED_GAIN"),
    useTotalRealizedLoss: visibleColumns.has("TOTAL_REALIZED_LOSS"),
    useConsistencyScore: visibleColumns.has("CONSISTENCY_SCORE"),
    useScriptVersion: visibleColumns.has("SCRIPT_VERSION"),
    useScriptName: visibleColumns.has("SCRIPT_NAME"),
    useCreatedAt: visibleColumns.has("CREATION_DATE"),
    useTags: visibleColumns.has("TAG_ID"),
    useAllocation: visibleColumns.has("ALLOCATION"),
    useStartedAt: visibleColumns.has("STARTED_AT"),
    useEndedAt: visibleColumns.has("ENDED_AT"),
    useAutoRebalance: visibleColumns.has("AUTO_REBALANCE"),
    useActiveSubscribersCount: visibleColumns.has("ACTIVE_SUBSCRIBERS"),
    isBot: !!creationPath?.startsWith(BOTS_TAB),
    ...extra,
  };
};

export const ExecutionContext = createContext<Context | undefined>(undefined);

export function useExecutionContext() {
  const context = useContext(ExecutionContext);
  invariant(
    context != null,
    "Component is not a child of ExecutionContext provider",
  );
  return context;
}

export function ExecutionContextProvider({
  listQuery__DEPRECATED,
  listQuery,
  resultsPerPage,
  defaultSort,
  children,
  columnDefintions,
  history,
  selectionUrl,
  executionsFromResult,
  getExecutionsCount,
  getLastExecutionCursor,
  additionalQueryVariables,
  creationPath,
  syndicationId,
  executionTypes,
  tableColumns,
}: Props) {
  const searchParams = queryString.parse(history.location.search);

  const [columnDefs, setColumnDefsInner] =
    useState<ColumnDefinition[]>(columnDefintions);
  const setColumnDefs = useCallback(
    (columns: ColumnDefinition[], isPreset?: boolean) => {
      setColumnDefsInner(columns);
      if (!isPreset) {
        localStorage.removeItem(COLUMNS_PRESET_ID_KEY);
      }
      localStorage.setItem("executionColumns", JSON.stringify(columns));
    },
    [],
  );

  const [openPacks, setOpenPacks] = useState<string[]>([]);
  const [syndicationName, setSyndicationName] = useState<string | undefined>(
    undefined,
  );
  // to give the options to the virtualized table rows
  const [actionDefs, setActionDefs] = useState<ActionDefinition[]>([]);
  const [showActionModal, setShowActionModal] =
    useState<ShowActionModalInterface>();

  const { filters } = useFilterContext();
  const { prefilter } = usePrefilterContext();
  const { selectedColumnsPreset } = useColumnsPresetsContext();
  const { isLoggedIn, isGuest } = useUserContext();

  const sortCacheKey = `${creationPath}-ExecutionSort`;
  const [sort, toggleSort] = useReducer(
    (previousSort: ExecutionSort, sortKey: ExecutionColumn): ExecutionSort => {
      if (
        previousSort.sortDirection === "ASC" ||
        previousSort.sortKey !== sortKey
      ) {
        localStorage.setItem(sortCacheKey, `${sortKey}:DESC`);

        searchParams["sort"] = `${sortKey}:DESC`;
        history.replace({ search: queryString.stringify(searchParams) });

        logEvent("ToggleExecutionSort", {
          sortColumn: sortKey,
          sortDirection: "DESC",
        });
        return { sortKey: sortKey, sortDirection: "DESC" };
      } else if (previousSort.sortDirection === "DESC") {
        localStorage.setItem(sortCacheKey, `${sortKey}:ASC`);

        searchParams["sort"] = `${sortKey}:ASC`;
        history.replace({ search: queryString.stringify(searchParams) });

        logEvent("ToggleExecutionSort", {
          sortColumn: sortKey,
          sortDirection: "ASC",
        });
        return { sortKey: sortKey, sortDirection: "ASC" };
      } else {
        searchParams[
          "sort"
        ] = `${DEFAULT_SORT.sortKey}:${DEFAULT_SORT.sortDirection}`;
        history.replace({ search: queryString.stringify(searchParams) });

        logEvent("ToggleExecutionSort", {
          sortColumn: DEFAULT_SORT.sortKey,
          sortDirection: DEFAULT_SORT.sortDirection,
        });
        return DEFAULT_SORT;
      }
    },
    defaultSort,
    () => {
      const urlSort = getFirstString(searchParams["sort"]);
      const cacheSort = localStorage.getItem(sortCacheKey);

      if (urlSort) {
        const [sortKeyString, sortDirectionString] = urlSort.split(":");

        const sortKey = fromStringValue(ExecutionColumnEnum, sortKeyString);
        const sortDirection = fromStringValue(
          SortDirectionEnum,
          sortDirectionString,
        );

        if (!sortKey || !sortDirection) {
          return defaultSort;
        }

        localStorage.setItem(sortCacheKey, `${sortKey}:${sortDirection}`);

        return {
          sortKey: sortKey as ExecutionColumn,
          sortDirection: sortDirection as SortDirection,
        };
      } else if (cacheSort) {
        const [sortKeyString, sortDirectionString] = cacheSort.split(":");

        const sortKey = fromStringValue(ExecutionColumnEnum, sortKeyString);
        const sortDirection = fromStringValue(
          SortDirectionEnum,
          sortDirectionString,
        );

        if (!sortKey || !sortDirection) {
          return defaultSort;
        }

        searchParams["sort"] = `${sortKey}:${sortDirection}`;

        history.replace({ search: queryString.stringify(searchParams) });

        return {
          sortKey: sortKey as ExecutionColumn,
          sortDirection: sortDirection as SortDirection,
        };
      }

      return defaultSort;
    },
  );

  useEffect(() => {
    if (selectedColumnsPreset && creationPath !== GUEST_TAB) {
      setColumnDefs(getSelectedColumns(selectedColumnsPreset.columns), true);
    }
  }, [selectedColumnsPreset, creationPath, setColumnDefs]);

  useEffect(() => {
    if (filters.length) {
      const searchParams = queryString.stringify(
        getSearchParamsFromFilter(filters),
      );

      if (searchParams !== history.location.search) {
        history.push({ search: searchParams });
      }
    }
  }, [filters, history]);

  const stage: ExecutionStage | undefined = useMemo(() => {
    switch (creationPath) {
      case CANDIDATES_SUBTAB:
        return "CANDIDATES";
      case RELEASE_CANDIDATES_SUBTAB:
        return "RELEASE_CANDIDATES";
      default:
        return undefined;
    }
  }, [creationPath]);

  const internalFilters: ExecutionFilter[] = useMemo(() => {
    if (
      prefilter &&
      selectionUrl !== MY_BOTS_SUBTAB &&
      !selectionUrl?.startsWith(BATCHTEST_SUBTAB)
    ) {
      return [prefilter];
    }

    return [];
  }, [selectionUrl, prefilter]);

  const queryVariables = useMemo<ExecutionsQueryVariables>(
    () =>
      getQueryVariables(
        sort,
        resultsPerPage,
        [...filters, ...internalFilters],
        stage,
        executionTypes,
        columnDefs,
        creationPath,
        additionalQueryVariables,
      ),
    [
      sort,
      resultsPerPage,
      additionalQueryVariables,
      filters,
      stage,
      internalFilters,
      executionTypes,
      columnDefs,
      creationPath,
    ],
  );

  const virtualTableEnabled = useVirtualTable();

  const {
    data: data__DEPRECATED,
    loading: loadingExecutions__DEPRECATED,
    error: executionsError__DEPRECATED,
    fetchMore: fetchMore__DEPRECATED,
    refetch: refetch__DEPRECATED,
  } = useQuery<ExecutionsQuery, ExecutionsQueryVariables>(
    listQuery__DEPRECATED,
    {
      variables: queryVariables,
      notifyOnNetworkStatusChange: true,
      skip: virtualTableEnabled || (!isLoggedIn && !isGuest),
    },
  );

  const {
    data,
    loading: loadingExecutions,
    error: executionsError,
    fetchMore,
    refetch,
  } = useQuery<ExecutionsQuery, ExecutionsQueryVariables>(listQuery, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !virtualTableEnabled || (!isLoggedIn && !isGuest),
  });

  useEffect(() => {
    setSyndicationName(data?.execution?.name ?? undefined);
  }, [data, setSyndicationName]);

  const executions = useMemo(
    () => executionsFromResult(virtualTableEnabled ? data : data__DEPRECATED),
    [data, data__DEPRECATED, virtualTableEnabled, executionsFromResult],
  );
  const executionsCount = useMemo(
    () => getExecutionsCount(virtualTableEnabled ? data : data__DEPRECATED),
    [data, data__DEPRECATED, virtualTableEnabled, getExecutionsCount],
  );

  const onFetchMore = useCallback(() => {
    if (virtualTableEnabled) {
      const cursor = getLastExecutionCursor(data);

      if (cursor && !loadingExecutions) {
        fetchMore({
          variables: {
            ...queryVariables,
            after: cursor,
          },
        });
      }
      return;
    }

    const cursor = getLastExecutionCursor(data__DEPRECATED);

    if (cursor && !loadingExecutions__DEPRECATED) {
      fetchMore__DEPRECATED({
        variables: {
          ...queryVariables,
          after: cursor,
        },
      });
    }
  }, [
    data,
    data__DEPRECATED,
    virtualTableEnabled,
    loadingExecutions,
    loadingExecutions__DEPRECATED,
    queryVariables,
    getLastExecutionCursor,
    fetchMore,
    fetchMore__DEPRECATED,
  ]);

  useEffect(() => {
    if (virtualTableEnabled) {
      refetch();
    } else {
      refetch__DEPRECATED();
    }
  }, [creationPath, virtualTableEnabled, refetch, refetch__DEPRECATED]);

  // executionSelected without the mouseevent
  const onExecutionClick = useCallback(
    (
      executionId: string,
      name?: string,
      type?: ExecutionType,
      status?: ExecutionStatus,
    ) => {
      if (
        selectionUrl &&
        (type !== "BACKTEST" || !status || COMPLETE_STATUSES.includes(status))
      ) {
        logEvent("ExecutionSelected", { type: type, status: status });
        history.push(
          `/${selectionUrl}/${executionId}${
            name ? `?name=${encodeURIComponent(name)}` : ""
          }`,
        );
      }
    },
    [selectionUrl, history],
  );

  const executionSelected = useCallback(
    (
      executionId: string,
      name?: string,
      type?: ExecutionType,
      status?: ExecutionStatus,
    ) => {
      if (
        selectionUrl &&
        (type !== "BACKTEST" || !status || COMPLETE_STATUSES.includes(status))
      ) {
        return (e: React.MouseEvent) => {
          e.preventDefault();
          logEvent("ExecutionSelected", { type: type, status: status });
          history.push(
            `/${selectionUrl}/${executionId}${
              name ? `?name=${encodeURIComponent(name)}` : ""
            }`,
          );
        };
      }

      return (e: React.MouseEvent) => {
        e.preventDefault();
      };
    },
    [selectionUrl, history],
  );

  return (
    <ExecutionContext.Provider
      value={{
        creationPath,
        listQuery__DEPRECATED,
        listQuery,
        executionSelected,
        onExecutionClick,
        executionsFromResult,
        getExecutionsCount,
        getLastExecutionCursor,
        onFetchMore,
        resultsPerPage,
        queryVariables,
        toggleSort,
        columnDefs,
        setColumnDefs,
        sort,
        stage,
        openPacks,
        setOpenPacks,
        isLiveOrSyndication:
          !!creationPath?.includes(BOTS_TAB) ||
          !!creationPath?.includes(LIVE_TAB),
        syndicationId,
        syndicationName,
        setSyndicationName,
        executionTypes,
        tableColumns,
        actionDefs,
        setActionDefs,
        showActionModal,
        setShowActionModal,

        executions,
        executionsCount,
        loadingExecutions: virtualTableEnabled
          ? loadingExecutions
          : loadingExecutions__DEPRECATED,
        executionsError: virtualTableEnabled
          ? executionsError
          : executionsError__DEPRECATED,
        refetch: virtualTableEnabled ? refetch : refetch__DEPRECATED,
      }}
    >
      {children}
    </ExecutionContext.Provider>
  );
}
