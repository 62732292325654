// easter egg for when the bitcoin network was created, a set date so that we can't choose an earlier date
export const EARLIEST_DATE = "2009-01-03";

export function revisionDateTime(revision: any, script: any) {
  const length = script.revisions.length;
  const index = script.revisions?.findIndex(
    (rev: any) => rev.id === revision.id,
  );

  if (index === 0) {
    return `${length} - Current`;
  } else if (length - index === 1) {
    return "Original";
  } else {
    return `${length - index} - ${getDateTime(revision.updatedAt)}`;
  }
}

export function getDateTime(dateTime: string) {
  const date = new Date(dateTime);
  return date.toLocaleString();
}

export function getRoundedDate(dateTime: string) {
  const date = new Date(dateTime);
  if (date.getHours() > 12) {
    return new Date(dateTime + " 00:00:00").toLocaleDateString();
  }

  return date.toLocaleDateString();
}

export function getDate(dateTime: string) {
  const date = new Date(dateTime);
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  ).toLocaleDateString();
}

export function utcDateStr(date: Date): string {
  let month = date.getUTCMonth() + 1;
  let monthStr = month > 9 ? month.toString() : `0${month}`;

  let day = date.getUTCDate();
  let dayStr = day > 9 ? day.toString() : `0${day}`;

  return `${date.getUTCFullYear()}-${monthStr}-${dayStr}`;
}

// get the proper date for yyyy-mm-dd since some locales do not work with it
export function parseLocaleDateStr(dateStr: string): Date {
  const dateParts = dateStr.split("-");
  const date = new Date();
  date.setFullYear(
    Number(dateParts[0]),
    Number(dateParts[1]) - 1,
    Number(dateParts[2]),
  );
  return date;
}

export function getRuntime(dateStr?: string) {
  if (!dateStr) return 0;

  const startedAt = new Date(dateStr);
  const now = new Date();
  const time = now.getTime() - startedAt.getTime();
  return Math.floor(time / (1000 * 3600 * 24));
}
