import { TFunction } from "i18next";
import {
  Currency,
  CurrencyPair,
  CurrencyPairDetails,
  Exchange,
  ExchangeDetails,
  ExecutionStage,
  ExecutionStatus,
  ExecutionType,
  Syndication,
} from "../graphql/schema";
import {
  BOTS_TAB,
  CANDIDATES_SUBTAB,
  EXPERIMENTS_SUBTAB,
  LIVE_BOTS_SUBTAB,
  LIVE_SETUPS_SUBTAB,
  MY_BOTS_SUBTAB,
  RELEASE_CANDIDATES_SUBTAB,
} from "./navigation";

const ENDED_STATUSES: ExecutionStatus[] = [
  "ENDED",
  "FAILED",
  "CANCELLED",
  "LIQUIDATED",
];
export function isEnded(status: ExecutionStatus): boolean {
  return ENDED_STATUSES.includes(status);
}

const BACKTEST_TYPES: ExecutionType[] = ["BACKTEST", "PREVIEW"];
export function isBacktest(type: ExecutionType): boolean {
  return BACKTEST_TYPES.includes(type);
}

export function isUsd(currency?: Currency): boolean {
  return currency === "USD" || currency === "USDT" || currency === "USDC";
}

export function isFreeSyndication(syndication: Syndication): boolean {
  return (
    !syndication.monthlyFeeUsd &&
    !syndication.allocationFeePerc &&
    !syndication.successFeePerc
  );
}

/**
 * To get a more detailed exchange and currency pair
 *
 * @param exchangeDetails
 * @param exchangeName
 * @param currencyPair
 * @returns a tuple of ExchangeDetails and CurrencyPairDetails
 */
export function getExchangeAndCurrencyPairDetails(
  exchangeDetails: ExchangeDetails[],
  exchangeName: Exchange,
  currencyPair: CurrencyPair,
): [ExchangeDetails, CurrencyPairDetails] {
  const exchange =
    exchangeDetails.find((e) => e.id === exchangeName) || exchangeDetails[0];

  const currencyPairDetails =
    exchange.currencyPairs.find((a) => a.pair === currencyPair) ||
    exchange.currencyPairs[0];

  return [exchange, currencyPairDetails];
}

export function getExecutionStagesFromPath(
  creationPath: string | undefined,
): [ExecutionStage, ExecutionStage] {
  switch (creationPath) {
    case CANDIDATES_SUBTAB:
      return ["CANDIDATES", "RELEASE_CANDIDATES"];
    case RELEASE_CANDIDATES_SUBTAB:
      return ["RELEASE_CANDIDATES", undefined];
    case EXPERIMENTS_SUBTAB:
    default:
      return [undefined, "CANDIDATES"];
  }
}

export function getExecutionPageTitle(
  t: TFunction,
  url?: string,
  name?: string,
  communityBots?: boolean,
): string | undefined {
  if (!name) {
    return undefined;
  }

  switch (url) {
    case `/${LIVE_BOTS_SUBTAB}`:
    case `/${LIVE_SETUPS_SUBTAB}`:
      return t("terminal.live_name", { name });
    case `/${CANDIDATES_SUBTAB}`:
      return t("terminal.candidates_name", { name });
    case `/${RELEASE_CANDIDATES_SUBTAB}`:
      return t("terminal.release_candidates_name", { name });
    case `/${EXPERIMENTS_SUBTAB}`:
      return t("terminal.experiments_name", { name });
    case `/${BOTS_TAB}`:
      return t(
        communityBots
          ? "terminal.community_bots_name"
          : "terminal.my_bots_name",
        { name },
      );
    default:
      return undefined;
  }
}

export function getPageTitle(t: TFunction, url?: string): string | undefined {
  switch (url) {
    case `/${LIVE_BOTS_SUBTAB}`:
    case `/${LIVE_SETUPS_SUBTAB}`:
      return t("terminal.live");
    case `/${CANDIDATES_SUBTAB}`:
      return t("terminal.candidates");
    case `/${RELEASE_CANDIDATES_SUBTAB}`:
      return t("terminal.release_candidates");
    case `/${EXPERIMENTS_SUBTAB}`:
      return t("terminal.experiments");
    case `/${MY_BOTS_SUBTAB}`:
      return t("terminal.my_bots");
    default:
      return undefined;
  }
}
