import copy from "copy-to-clipboard";

export function titleCase(str: string) {
  if (!str) return str;
  const words = str.toString().toLowerCase().replace(/_/g, " ").split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(" ");
}

export function copyOfName(str: string) {
  try {
    if (str.startsWith("Copy of ")) {
      return str.replace("Copy of ", "Copy 2 of ");
    }
    const copyNo = str.match(/Copy ([0-9]+) of /);
    if (copyNo && copyNo.length > 0) {
      const newCopyNo = Number(copyNo[1]) + 1;
      return str.replace(/Copy ([0-9]+) of /, `Copy ${newCopyNo} of `);
    }
  } catch {
    // TODO: Fix eslint error
    // eslint-disable-next-line no-empty
  }
  return "Copy of " + str;
}

// TODO: Delete this function and directly call `copy`
export function copyToClipboard(str: string) {
  copy(str);
}

export function fromStringValue<T>(
  type: { [s: string]: T },
  value: string,
): T | undefined {
  return (Object.values(type) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined;
}
