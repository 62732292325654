import React from "react";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  SidebarPage,
  useAccountSettingsContext,
} from "../../contexts/AccountSettingsContext";
import { useUserContext } from "../../contexts/UserContext";
import ProfilePhoto from "../../core/photo/ProfilePhoto";
import TunedLogo from "../../core/tunedlogo/TunedLogo";
import { getTranslatedSidebarPage } from "../../helpers/formLabelUtils";
import { RELEASE_VERSION } from "helpers/environment";
import imgClose from "../../images/close.svg";
import "../../components/loginnavigation/loginnav.scss";

type Props = Readonly<{
  sidebarItems: SidebarPage[];
  collapsed: boolean;
  page: string;
  toggleCollapsed: () => void;
  onItemClick: (item: SidebarPage) => void;
}>;

function SettingsSidebar({
  sidebarItems,
  collapsed,
  page,
  toggleCollapsed,
  onItemClick,
}: Props) {
  const { t } = useTranslation();

  const { user } = useUserContext();
  const { photo } = useAccountSettingsContext();

  const history = useHistory();

  return (
    <>
      <button
        className={classnames("settings__toggler", { collapsed: collapsed })}
        onClick={toggleCollapsed}
      >
        {collapsed ? (
          <span className="navbar-toggler-icon"></span>
        ) : (
          <img src={imgClose} alt="Close" />
        )}
      </button>

      <div className={classnames("settings__side-panel", { show: !collapsed })}>
        {user && (
          <ProfilePhoto
            photo={photo}
            className="settings__side-panel--photo"
            user={user}
          />
        )}
        {/* {user?.nickname && user?.description && (
          <button
            className="settings__side-panel--profile-button"
            onClick={onViewProfileClick}
          >
            {t("common.view_profile")}
          </button>
        )} */}
        <div className="settings__side-panel__header">
          {t("common.account_dashboard")}
        </div>
        <div className="settings__side-panel__body">
          {sidebarItems.map((item, i) => (
            <div
              key={i}
              onClick={() => onItemClick(item)}
              className={classnames("settings__side-panel__body__item", {
                "current-path": item === page,
              })}
            >
              {getTranslatedSidebarPage(item, t)}
            </div>
          ))}
        </div>
        <div className="settings__brand">
          <h1 className="settings__brand--header">
            <div
              onClick={() => history.push("/")}
              className="settings__brand--link"
            >
              <TunedLogo />
            </div>
          </h1>
          <div className="settings__brand--version">{RELEASE_VERSION}</div>
        </div>
      </div>
    </>
  );
}

export default SettingsSidebar;
