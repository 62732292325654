import * as React from "react";

import { NavigationContext } from "../../../contexts/NavigationContext";

const MiddleHomeIcons: React.FunctionComponent = () => {
  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { secondaryNavIcons } = React.useContext(NavigationContext)!;

  return (
    <>
      {secondaryNavIcons?.map((icon, i) => (
        <li
          title={icon.title ?? ""}
          className="nav__item--icon secondary"
          key={i}
        >
          {icon.type === "link" ? (
            <a className="icon" href={icon.path}>
              <img src={icon.iconSrc} alt={icon.alt ?? ""} />
            </a>
          ) : (
            <div className="icon middle-icon" onClick={icon.action}>
              <img src={icon.iconSrc} alt={icon.alt ?? ""} />
            </div>
          )}
        </li>
      ))}
    </>
  );
};

export default MiddleHomeIcons;
