import React, { createContext, useCallback, useContext, useMemo } from "react";
import invariant from "invariant";
import { useQuery } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import {
  Currency,
  CurrencyPair,
  CurrencyPairDetails,
  Exchange,
  ExchangeDetails,
} from "../graphql/schema";
import { GET_EXCHANGE_DETAILS } from "../graphql/queries";
import { useUserContext } from "./UserContext";
import { isCryptoDotComVisible } from "../helpers/environment";

interface Context {
  exchangeDetails?: ExchangeDetails[];
  exchanges?: Exchange[];
  loading: boolean;
  error?: ApolloError;
  isMargin: (exchange: Exchange) => boolean;
  getPair: (currencyPair: CurrencyPair, exchange: Exchange) => PairDetails;
  currencyPairs: (exchanges: Exchange[]) => CurrencyPairDetails[];
}

export interface PairDetails {
  settleCurrency: Currency;
  positionCurrency: Currency;
  priceCurrency: Currency;
}

export const ExchangeContext = createContext<Context | undefined>(undefined);

export function useExchangeContext() {
  const context = useContext(ExchangeContext);
  invariant(
    context != null,
    "Component is not a child of ExchangeContext provider",
  );
  return context;
}

export const ExchangeContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isTester } = useUserContext();

  const { data, loading, error } =
    useQuery<{ exchanges: ExchangeDetails[] }>(GET_EXCHANGE_DETAILS);

  const exchangeDetails = useMemo(
    () =>
      isCryptoDotComVisible(isTester)
        ? data?.exchanges
        : data?.exchanges?.filter(
            (exchange) => exchange.id !== "CRYPTO_DOT_COM",
          ),
    [data, isTester],
  );

  const exchanges = useMemo(
    () => exchangeDetails?.map((i) => i.id),
    [exchangeDetails],
  );

  // get all unique settle currencies to make a list of multi coin currencies per exchange
  const exchangeMultiCoinCurrencies = useMemo(
    () =>
      exchangeDetails?.map((exchange) => {
        const currencySet = new Set<Currency>();
        const currencyPairs = exchange.currencyPairs
          .filter((pair) => {
            if (currencySet.has(pair.settleCurrency)) {
              return false;
            } else {
              currencySet.add(pair.settleCurrency);
              return true;
            }
          })
          .map((pair) => {
            return {
              id: `${pair.exchange}:MULTIPLE_${pair.settleCurrency}`,
              exchange: pair.exchange,
              pair: `_MULTIPLE_${pair.settleCurrency}`,
              base: "MULTIPLE",
              quote: pair.settleCurrency,
              orderRules: pair.orderRules,
              settleCurrency: pair.settleCurrency,
              positionCurrency: "MULTIPLE",
            } as CurrencyPairDetails;
          });

        return {
          id: exchange.id,
          currencyPairs,
          isMargin: exchange.isMargin,
        } as ExchangeDetails;
      }),
    [exchangeDetails],
  );

  const isMargin = useCallback(
    (exchange: Exchange) => {
      return exchangeDetails?.find((i) => i.id === exchange)?.isMargin ?? false;
    },
    [exchangeDetails],
  );

  const getPair = useCallback(
    (currencyPair: CurrencyPair, exchange: Exchange) => {
      const exchangeDetail = exchangeDetails?.find((i) => i.id === exchange);
      const currencyDetail = exchangeDetail?.currencyPairs?.find(
        (i) => i.pair === currencyPair,
      );

      if (!currencyDetail)
        return {
          settleCurrency: "",
          positionCurrency: "",
          priceCurrency: "",
        };

      return {
        settleCurrency: currencyDetail.settleCurrency,
        positionCurrency: currencyDetail.positionCurrency,
        priceCurrency: currencyDetail.quote,
      };
    },
    [exchangeDetails],
  );

  const currencyPairs = useCallback(
    (exchanges: Exchange[] = []) => {
      let currencyPairs: CurrencyPairDetails[] = [];

      const updatedExchangeDetails = exchangeDetails?.map((exchange) => {
        const pairs = exchange.currencyPairs.concat(
          exchangeMultiCoinCurrencies?.find((ex) => ex.id === exchange.id)
            ?.currencyPairs ?? [],
        );

        return {
          id: exchange.id,
          currencyPairs: pairs,
          isMargin: exchange.isMargin,
        } as ExchangeDetails;
      });

      updatedExchangeDetails?.forEach((exchange) => {
        if (!exchanges.length || exchanges.includes(exchange.id)) {
          currencyPairs = currencyPairs.concat(
            [...exchange.currencyPairs].sort((a, b) =>
              a.pair.localeCompare(b.pair),
            ),
          );
        }
      });

      return currencyPairs;
    },
    [exchangeDetails, exchangeMultiCoinCurrencies],
  );

  return (
    <ExchangeContext.Provider
      value={{
        exchangeDetails,
        loading,
        error,
        exchanges,
        getPair,
        isMargin,
        currencyPairs,
      }}
    >
      {children}
    </ExchangeContext.Provider>
  );
};
