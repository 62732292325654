import React, { createContext, useContext, useMemo } from "react";
import invariant from "invariant";
import { gql, useQuery } from "@apollo/client";
import { GetGettingStartedQuery, GettingStarted } from "__generated__/graphql";
import { useUserContext } from "./UserContext";

export const GETTING_STARTED_CONTEXT_QUERY = gql`
  query GettingStartedContext {
    gettingStarted {
      openedEditor
      createdExperiment
      createdMultivariant
      promotedExperiment
      promotedCandidate
      addedCredential
      launchedLive
    }
  }
`;

interface Context {
  gettingStarted?: GettingStarted | null;
  showGettingStarted: boolean;
  loading: boolean;
}

export const GettingStartedContext = createContext<Context | undefined>(
  undefined,
);

export function useGettingStartedContext() {
  const context = useContext(GettingStartedContext);
  invariant(
    context != null,
    "Component is not a child of GettingStartedContext provider",
  );
  return context;
}

type Props = Readonly<{
  children: React.ReactNode;
}>;

export function GettingStartedContextProvider({ children }: Props) {
  const { isLoggedIn } = useUserContext();

  const { data, loading } = useQuery<GetGettingStartedQuery>(
    GETTING_STARTED_CONTEXT_QUERY,
    {
      skip: !isLoggedIn,
    },
  );

  const gettingStarted = useMemo(
    () => data?.gettingStarted,
    [data?.gettingStarted],
  );

  const showGettingStarted = false;
  // const showGettingStarted = useMemo(() => {
  //   if (gettingStarted) {
  //     for (const checklist in gettingStarted) {
  //       if (!gettingStarted[checklist as keyof GettingStarted]) {
  //         return true;
  //       }
  //     }
  //   }

  //   return false;
  // }, [gettingStarted]);

  return (
    <GettingStartedContext.Provider
      value={{
        gettingStarted,
        showGettingStarted,
        loading,
      }}
    >
      {children}
    </GettingStartedContext.Provider>
  );
}
