import React, { useEffect, useMemo } from "react";
import { useRouteMatch, useHistory } from "react-router";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { MainPanelNavigationQuery } from "__generated__/graphql";
import {
  getBotsNavigationItems,
  useNavButtons,
  useNavigationContext,
} from "../../contexts/NavigationContext";
import { useUserContext } from "contexts/UserContext";
import TertiaryNavigation from "../../core/navigation/TertiaryNavigation";
import MiddleHomeItems from "../../core/navigation/elements/MiddleHomeItems";
import MiddleHomeIcons from "../../core/navigation/elements/MiddleHomeIcons";
import SelectItems from "../../core/navigation/elements/SelectItems";
import SideNavigation from "../../core/navigation/SideNavigation";
import {
  BOTS_TAB,
  DEFAULT_COMMUNITY_BOTS_SUBTAB,
  DEFAULT_MY_BOTS_SUBTAB,
  LIVE_TAB,
  showBotsNavigationItems,
  showLiveNavigationItems,
  showScriptsNavigationItems,
  showSetupsNavigationItems,
  showTestNavigationItems,
  showTradersNavigationItems,
} from "../../helpers/navigation";

export const MAIN_PANEL_NAVIGATION_QUERY = gql`
  query MainPanelNavigation {
    syndicationsCount {
      count
    }
  }
`;

type Props = Readonly<{
  hidePanels: () => void;
}>;

function MainPanelNavigation({ hidePanels }: Props) {
  const { t } = useTranslation();

  const history = useHistory();

  const { isLoggedIn } = useUserContext();
  const {
    liveNavItems,
    testNavItems,
    scriptNavItems,
    tradersNavItems,
    setupsNavItems,
    mainNavClasses,
    sideNavItems,
    setDefaultBotsTab,
  } = useNavigationContext();
  const buttonMaps = useNavButtons();

  const { data } = useQuery<MainPanelNavigationQuery>(
    MAIN_PANEL_NAVIGATION_QUERY,
    {
      skip: !isLoggedIn,
    },
  );

  useEffect(() => {
    if (!data?.syndicationsCount?.count) {
      setDefaultBotsTab(DEFAULT_COMMUNITY_BOTS_SUBTAB);
    } else {
      setDefaultBotsTab(DEFAULT_MY_BOTS_SUBTAB);
    }
  }, [data?.syndicationsCount?.count, setDefaultBotsTab]);

  const isLiveOrSyndication = useRouteMatch({
    path: [`/${LIVE_TAB}`, `/${BOTS_TAB}`],
  });

  const items = useMemo(() => {
    if (showLiveNavigationItems(history.location.pathname)) {
      return liveNavItems;
    } else if (showScriptsNavigationItems(history.location.pathname)) {
      return scriptNavItems;
    } else if (showTestNavigationItems(history.location.pathname)) {
      return testNavItems;
    } else if (showBotsNavigationItems(history.location.pathname)) {
      return getBotsNavigationItems(t, data?.syndicationsCount?.count ?? 0);
    } else if (showTradersNavigationItems(history.location.pathname)) {
      return tradersNavItems;
    } else if (showSetupsNavigationItems(history.location.pathname)) {
      return setupsNavItems;
    } else {
      return [];
    }
  }, [
    history.location.pathname,
    liveNavItems,
    scriptNavItems,
    testNavItems,
    tradersNavItems,
    setupsNavItems,
    data?.syndicationsCount?.count,
    t,
  ]);

  return (
    <TertiaryNavigation
      className={classnames(`main-nav ${mainNavClasses}`, {
        "visible-right-nav": !!sideNavItems,
        "on-live-or-syndication": isLiveOrSyndication,
      })}
      items={items ?? []}
      buttons={buttonMaps}
      hidePanels={hidePanels}
      home={true}
    >
      <SelectItems />
      <MiddleHomeIcons />
      <MiddleHomeItems hidePanels={hidePanels} />
      <SideNavigation />
    </TertiaryNavigation>
  );
}

export default MainPanelNavigation;
