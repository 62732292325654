import { History } from "history";
import { ACCOUNT_SETTINGS_URL_STORAGE_KEY } from "components/settings/AccountSettings";
import { PREVIOUS_URL_STORAGE_KEY } from "components/settings/SettingsHeader";

// does not have a "/"" in front because some routes check for multiple places. ex) "/(this|that)"
// Live Tab
export const LIVE_TAB = "live";
export const PAPER_TRADES = "paper-trades";
export const LIVE_BOTS_SUBTAB = "live/bots";
export const LIVE_SETUPS_SUBTAB = "live/setups";

// Tests Tab
export const TESTS_TAB = "tests";
export const BATCHTEST_SUBTAB = "tests/bt";
export const EXPERIMENTS_SUBTAB = "tests/e";
export const CANDIDATES_SUBTAB = "tests/c";
export const RELEASE_CANDIDATES_SUBTAB = "tests/rc";
// have a "/" in front since it needs to be set as a type
export const DEFAULT_MY_BOTS_SUBTAB = "/bots/mybots";
export const DEFAULT_COMMUNITY_BOTS_SUBTAB = "/bots/community";

// Bots Tab
export const BOTS_TAB = "bots";
export const MY_BOTS_SUBTAB = "bots/mybots";
export const MY_BOTS_SUBSCRIPTIONS_SUBTAB = "bots/mybots/subscriptions";
export const COMMUNITY_BOTS_SUBTAB = "bots/community";

// Account Tab
export const ACCOUNT_TAB = "account";
export const ACCOUNT_EXCHANGES_TAB = "account/exchanges";
export const ACCOUNT_BILLING_TAB = "account/billing";
export const ACCOUNT_SECURITY_TAB = "account/security";
export const ACCOUNT_SUBSCRIPTIONS_TAB = "account/subscriptions";
export const ACCOUNT_EMAIL_TAB = "account/email";
export const ACCOUNT_REFERRALS_TAB = "account/referrals";
export const ACCOUNT_PLANS_TAB = "account/plans";

export type AccountTab =
  | typeof ACCOUNT_TAB
  | typeof ACCOUNT_EXCHANGES_TAB
  | typeof ACCOUNT_BILLING_TAB
  | typeof ACCOUNT_SECURITY_TAB
  | typeof ACCOUNT_SUBSCRIPTIONS_TAB
  | typeof ACCOUNT_EMAIL_TAB
  | typeof ACCOUNT_REFERRALS_TAB
  | typeof ACCOUNT_PLANS_TAB;

// Editor Tab
export const EDITOR_TAB = "editor";
export const PUBLIC_EDITOR_TAB = "editor/public";

// Scripts Tab
export const SCRIPTS_TAB = "scripts";
export const MY_SCRIPTS_SUBTAB = "scripts/myscripts";
export const MY_PUBLIC_SCRIPTS_SUBTAB = "scripts/mypublic";
export const COMMUNITY_SCRIPTS_SUBTAB = "scripts/community";

// Traders Tab
export const TRADERS_TAB = "traders";
export const COMMUNITY_TRADERS_SUBTAB = "traders/community";
export const TOP_TRADERS_SUBTAB = "traders/top";

// Setups Tab
export const SETUPS_TAB = "setups";

// Invest Page
export const TRADER_TAB = "t";
export const INVEST_TAB = "invest";
export const INVITE_TAB = "invite";

// Login Page
export const REGISTER_TAB = "register";
export const LOGIN_TAB = "login";
export const MOBILE_BLOCKER_TAB = "mobileblocker";

// Portfolio Page
export const PORTFOLIO_PAGE = "portfolio";

export const GUEST_TAB = "guest";
export const SUBSCRIBE_TAB = "subscribe";
export const NOT_FOUND_TAB = "404";

export function showLiveNavigationItems(pathname?: string) {
  return pathname?.startsWith(`/${PAPER_TRADES}`);
}

export function showTestNavigationItems(pathname?: string) {
  return pathname?.startsWith(`/${TESTS_TAB}`);
}

export function showScriptsNavigationItems(pathname?: string) {
  return pathname?.startsWith(`/${SCRIPTS_TAB}`);
}

export function showBotsNavigationItems(pathname?: string) {
  return pathname?.startsWith(`/${BOTS_TAB}`);
}

export function showTradersNavigationItems(pathname?: string) {
  return pathname?.startsWith(`/${TRADERS_TAB}`);
}

export function showSetupsNavigationItems(pathname?: string) {
  return pathname?.startsWith(`/${SETUPS_TAB}`);
}

export function goToAccountDashboard(
  url: AccountTab,
  history: History,
  disableStorePrevious?: boolean,
) {
  localStorage.setItem(ACCOUNT_SETTINGS_URL_STORAGE_KEY, url);
  if (!disableStorePrevious) {
    localStorage.setItem(
      PREVIOUS_URL_STORAGE_KEY,
      `${history.location.pathname}${history.location.search}`,
    );
  }

  history.push(`/${url}`);
}

export function isOnTerminalPage(pathname?: string) {
  return (
    pathname?.startsWith(`/${LIVE_TAB}`) ||
    pathname?.startsWith(`/${TESTS_TAB}`) ||
    pathname?.startsWith(`/${SCRIPTS_TAB}`) ||
    pathname?.startsWith(`/${BOTS_TAB}`) ||
    pathname?.startsWith(`/${TRADERS_TAB}`) ||
    pathname?.startsWith(`/${SETUPS_TAB}`) ||
    pathname?.startsWith(`/${GUEST_TAB}`) ||
    pathname?.startsWith(`/${SUBSCRIBE_TAB}`)
  );
}

export function isOnInvestPage(pathname?: string) {
  return (
    pathname?.startsWith(`/${TRADER_TAB}/`) ||
    pathname?.startsWith(`/${INVEST_TAB}`) ||
    pathname?.startsWith(`/${INVITE_TAB}`)
  );
}

export function isOnPortfolioPage(pathname?: string) {
  return pathname?.startsWith(`/${PORTFOLIO_PAGE}`);
}
