import React from "react";
import { TFunction } from "i18next";
import { StyleSheet, css } from "aphrodite";
import { Trans } from "react-i18next";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import {
  ExecutionColumn as ExecutionColumnEnum,
  ExecutionsInfiniteTable_ExecutionFragment,
  SyndicationAccessControl,
} from "__generated__/graphql";
import { Execution, ScriptDetails, Tag } from "graphql/schema";
import ExecutionNameContainer from "components/executions/list/ExecutionNameContainer";
import ExecutionScriptRevision from "components/executions/list/ExecutionScriptRevision";
import ExecutionValueCurrency from "components/executions/list/ExecutionValueCurrency";
import ExecutionStatusInfo from "components/executions/list/ExecutionStatus";
import ExecutionTags from "components/executions/list/ExecutionTags";
import ExecutionSubscriptionStatusInfo from "components/executions/list/ExecutionSubscriptionStatusInfo";
import ExecutionCheckbox from "components/executions/list/ExecutionCheckbox";
import ExecutionCheckboxHeader from "components/executions/list/ExecutionCheckboxHeader";
import { ColumnDefinition } from "components/executions/list/ExecutionColumnDefinitions";
import MeasurementColorSpan from "core/element/MeasurementColorSpan";
import ColorSpan from "core/element/ColorSpan";
import { titleCase } from "helpers/strings";
import { getDateTime, getRoundedDate } from "helpers/dates";
import { formatExchange } from "helpers/formLabelUtils";
import {
  percentageValue,
  trimToTwoDecimals,
  trimZeroes,
} from "helpers/numbers";

export enum ExecutionExtraColumn {
  ScriptVersion = "SCRIPT_VERSION",
  ActiveSubscribers = "ACTIVE_SUBSCRIBERS",
  Checkbox = "CHECKBOX",
}

export type ExecutionTableColumn =
  | Exclude<
      ExecutionColumnEnum,
      | "FAVORITE"
      | "STARTED_AT_DATE"
      | "IS_PACK"
      | "ARCHIVED"
      | "IS_SUBSCRIPTION"
      | "SYNDICATION_ID"
      | "MIN_ALLOCATION"
      | "TOTAL_ALLOCATED"
      | "MONTHLY_FEE_USD"
      | "ALLOCATION_FEE_PERC"
      | "SUCCESS_FEE_PERC"
      | "NAME"
      | "IS_MULTI_COIN_PACK"
      | "MULTI_COIN_PACK"
      | "CREATOR_NAME"
      | "IS_PRELOADED_SCRIPT"
      | "PRELOADED_SCRIPT"
      | "IS_BASKET"
      | "BUY_HOLD_RATIO"
      | "COMPOUND_DAILY_RETURNS"
      | "ENDED_AT_DATE"
      | "PERC_BUY_HOLD_RATIO"
    >
  | ExecutionExtraColumn;

const columnHelper =
  createColumnHelper<ExecutionsInfiniteTable_ExecutionFragment>();

export function makeExecutionsColumnDefMap(
  t: TFunction,
  columnDefintions?: ColumnDefinition[],
): Record<
  ExecutionTableColumn,
  ColumnDef<ExecutionsInfiniteTable_ExecutionFragment>
> {
  const columnDefRecord: Record<string, ColumnDefinition> = {};
  columnDefintions?.forEach((col) => {
    columnDefRecord[col.column] = col;
  });

  return {
    CHECKBOX: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionExtraColumn.Checkbox,
        header: () => <ExecutionCheckboxHeader />,
        cell: (ctx) => <ExecutionCheckbox execution={ctx.getValue()} />,
        meta: {
          sortable: false,
          overflow: true,
        },
        size: 20,
        enableResizing: false,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    CREATION_DATE: {
      ...(columnHelper.accessor((data) => data.createdAt, {
        id: ExecutionColumnEnum.CreationDate,
        header: () => t("common.creation_date"),
        cell: (ctx) => (
          <div title={getDateTime(ctx.getValue() ?? "")}>
            {getDateTime(ctx.getValue() ?? "")}
          </div>
        ),
        meta: {
          sortable: true,
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.CreationDate]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.CreationDate].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    SCRIPT_NAME: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.ScriptName,
        header: () => t("common.name"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <ExecutionNameContainer
              scriptDetails={e.scriptDetails as ScriptDetails}
              scriptName={e.scriptDetails?.name ?? e.name ?? ""}
              algorithmSettings={e.algorithmSettings ?? "{}"}
              id={e.id}
              favorite={!!e.favorite}
              execution={e as Execution}
              isExpanded={ctx.row.getIsExpanded()}
            />
          );
        },
        meta: {
          sortable: true,
          overflow: true,
          canFilter: true,
        },
        minSize: 150,
        size: columnDefRecord[ExecutionColumnEnum.ScriptName]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.ScriptName].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    SCRIPT_VERSION: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionExtraColumn.ScriptVersion,
        header: () => t("common.strategy_version"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <ExecutionScriptRevision
              version={e.scriptDetails?.version ?? 1}
              isLatest={!!e.scriptDetails?.isLatest}
              createdAt={e.scriptDetails?.createdAt ?? ""}
            />
          );
        },
        meta: {
          sortable: false,
        },
        size: columnDefRecord[ExecutionExtraColumn.ScriptVersion]?.width
          ? Number(
              columnDefRecord[
                ExecutionExtraColumn.ScriptVersion
              ].width?.replace("px", ""),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    EXECUTION_NUMBER: {
      ...(columnHelper.accessor((data) => data.runNumber, {
        id: ExecutionColumnEnum.ExecutionNumber,
        header: () => t("terminal.run_number"),
        cell: (ctx) => (
          <div title={ctx.getValue() ?? ""} className="execution-num">
            {ctx.getValue()}
          </div>
        ),
        meta: {
          sortable: true,
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.ExecutionNumber]?.width
          ? Number(
              columnDefRecord[
                ExecutionColumnEnum.ExecutionNumber
              ].width?.replace("px", ""),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    TYPE: {
      ...(columnHelper.accessor((data) => data.type, {
        id: ExecutionColumnEnum.Type,
        header: () => t("common.type"),
        cell: (ctx) => (
          <div title={titleCase(ctx.getValue())}>
            {titleCase(ctx.getValue())}
          </div>
        ),
        meta: {
          sortable: true,
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.Type]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.Type].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    EXCHANGE: {
      ...(columnHelper.accessor((data) => data.exchange, {
        id: ExecutionColumnEnum.Exchange,
        header: () => t("common.exchange"),
        cell: (ctx) => (
          <span title={formatExchange(ctx.getValue())}>
            {formatExchange(ctx.getValue())}
          </span>
        ),
        meta: {
          sortable: true,
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.Exchange]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.Exchange].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    CURRENCY_PAIR: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.CurrencyPair,
        header: () => t("common.trading_pair"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <span>
              {e.multiCoinCurrency
                ? `Multiple / ${e.multiCoinCurrency}`
                : e.currencyPair?.replace("_", " / ")}
            </span>
          );
        },
        meta: {
          sortable: true,
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.CurrencyPair]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.CurrencyPair].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    CANDLE_SIZE: {
      ...(columnHelper.accessor(
        (data) => data.candleSize?.replace("_", " / "),
        {
          id: ExecutionColumnEnum.CandleSize,
          header: () => t("common.candle"),
          cell: (ctx) => <span title={ctx.getValue()}>{ctx.getValue()}</span>,
          meta: {
            sortable: true,
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.CandleSize]?.width
            ? Number(
                columnDefRecord[ExecutionColumnEnum.CandleSize].width?.replace(
                  "px",
                  "",
                ),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    PROFITABILITY: {
      ...(columnHelper.accessor(
        (data) => percentageValue(data.measurements?.profitability),
        {
          id: ExecutionColumnEnum.Profitability,
          header: () => t("common.profit_loss_percent"),
          cell: (ctx) => <MeasurementColorSpan value={ctx.getValue()} />,
          meta: {
            sortable: true,
            rightAligned: true,
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.Profitability]?.width
            ? Number(
                columnDefRecord[
                  ExecutionColumnEnum.Profitability
                ].width?.replace("px", ""),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    PERCENT_PROFITABLE_TRADES: {
      ...(columnHelper.accessor(
        (data) =>
          percentageValue(data.measurements?.percentProfitableTrades) ?? "--",
        {
          id: ExecutionColumnEnum.PercentProfitableTrades,
          header: () => t("common.profitable_trades"),
          cell: (ctx) => <span title={ctx.getValue()}>{ctx.getValue()}</span>,
          meta: {
            sortable: true,
            rightAligned: true,
            tooltip: t("message.profitable_trades_information"),
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.PercentProfitableTrades]
            ?.width
            ? Number(
                columnDefRecord[
                  ExecutionColumnEnum.PercentProfitableTrades
                ].width?.replace("px", ""),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    ABSOLUTE_PROFIT: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.AbsoluteProfit,
        header: () => t("common.profit_loss"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <ExecutionValueCurrency
              value={e.measurements?.absoluteProfit}
              currency={e.currencyPairDetails?.settleCurrency}
              colorSign={true}
              noGradiant={true}
            />
          );
        },
        meta: {
          sortable: true,
          rightAligned: true,
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.AbsoluteProfit]?.width
          ? Number(
              columnDefRecord[
                ExecutionColumnEnum.AbsoluteProfit
              ].width?.replace("px", ""),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    AVG_POSITION_PRICE: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.AvgPositionPrice,
        header: () => t("common.average_position_price"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <ExecutionValueCurrency
              value={e.measurements?.avgPositionPrice}
              currency={e.currencyPairDetails?.quote}
              colorSign={false}
              hidden={!!e.multiCoinCurrency}
            />
          );
        },
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.average_position_price"),
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.AvgPositionPrice]?.width
          ? Number(
              columnDefRecord[
                ExecutionColumnEnum.AvgPositionPrice
              ].width?.replace("px", ""),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    MAX_DRAWDOWN: {
      ...(columnHelper.accessor(
        (data) => percentageValue(data.measurements?.maxDrawdown) ?? "--",
        {
          id: ExecutionColumnEnum.MaxDrawdown,
          header: () => t("common.mdd"),
          cell: (ctx) => <span title={ctx.getValue()}>{ctx.getValue()}</span>,
          meta: {
            sortable: true,
            rightAligned: true,
            tooltip: t("message.mdd_information"),
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.MaxDrawdown]?.width
            ? Number(
                columnDefRecord[ExecutionColumnEnum.MaxDrawdown].width?.replace(
                  "px",
                  "",
                ),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    NUMBER_OF_TRADES: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.NumberOfTrades,
        header: () => t("common.number_trades"),
        cell: (ctx) => {
          const e = ctx.getValue();
          const val = e.multiCoinCurrency
            ? "--"
            : `${e.measurements?.numberOfTrades ?? 0}`;
          return <span title={val}>{val}</span>;
        },
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.total_amount_of_trades"),
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.NumberOfTrades]?.width
          ? Number(
              columnDefRecord[
                ExecutionColumnEnum.NumberOfTrades
              ].width?.replace("px", ""),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    PROFIT_FACTOR: {
      ...(columnHelper.accessor(
        (data) => trimToTwoDecimals(data.measurements?.profitFactor) ?? "--",
        {
          id: ExecutionColumnEnum.ProfitFactor,
          header: () => t("common.profit_factor"),
          cell: (ctx) => <span title={ctx.getValue()}>{ctx.getValue()}</span>,
          meta: {
            sortable: true,
            rightAligned: true,
            tooltip: t("message.gross_profits_divided_by_gross_losses"),
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.ProfitFactor]?.width
            ? Number(
                columnDefRecord[
                  ExecutionColumnEnum.ProfitFactor
                ].width?.replace("px", ""),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    STATUS: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.Status,
        header: () => t("common.status"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return <ExecutionStatusInfo execution={e as Execution} />;
        },
        meta: {
          sortable: true,
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.Status]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.Status].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    LEVERAGE: {
      ...(columnHelper.accessor((data) => trimZeroes(data.leverage), {
        id: ExecutionColumnEnum.Leverage,
        header: () => t("common.long_leverage"),
        cell: (ctx) => <span title={ctx.getValue()}>{ctx.getValue()}</span>,
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.long_leverage_information"),
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.Leverage]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.Leverage].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    LEVERAGE_SHORT: {
      ...(columnHelper.accessor((data) => trimZeroes(data.leverageShort), {
        id: ExecutionColumnEnum.LeverageShort,
        header: () => t("common.short_leverage"),
        cell: (ctx) => <span title={ctx.getValue()}>{ctx.getValue()}</span>,
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.short_leverage_information"),
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.LeverageShort]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.LeverageShort].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    AVG_MONTHLY_PROFIT: {
      ...(columnHelper.accessor(
        (data) => percentageValue(data.measurements?.avgMonthlyProfit) ?? "--",
        {
          id: ExecutionColumnEnum.AvgMonthlyProfit,
          header: () => t("common.average_monthly_profit"),
          cell: (ctx) => <span title={ctx.getValue()}>{ctx.getValue()}</span>,
          meta: {
            sortable: true,
            rightAligned: true,
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.AvgMonthlyProfit]?.width
            ? Number(
                columnDefRecord[
                  ExecutionColumnEnum.AvgMonthlyProfit
                ].width?.replace("px", ""),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    AVG_WIN_MONTH: {
      ...(columnHelper.accessor(
        (data) => percentageValue(data.measurements?.avgWinMonth) ?? "--",
        {
          id: ExecutionColumnEnum.AvgWinMonth,
          header: () => t("common.average_win_month"),
          cell: (ctx) => <span title={ctx.getValue()}>{ctx.getValue()}</span>,
          meta: {
            sortable: true,
            rightAligned: true,
            tooltip: t("message.average_gain_for_profitable_months"),
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.AvgWinMonth]?.width
            ? Number(
                columnDefRecord[ExecutionColumnEnum.AvgWinMonth].width?.replace(
                  "px",
                  "",
                ),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    AVG_LOSE_MONTH: {
      ...(columnHelper.accessor(
        (data) => percentageValue(data.measurements?.avgLoseMonth) ?? "--",
        {
          id: ExecutionColumnEnum.AvgLoseMonth,
          header: () => t("common.average_lose_month"),
          cell: (ctx) => <span title={ctx.getValue()}>{ctx.getValue()}</span>,
          meta: {
            sortable: true,
            rightAligned: true,
            tooltip: t("message.average_loss_for_unprofitable_months"),
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.AvgLoseMonth]?.width
            ? Number(
                columnDefRecord[
                  ExecutionColumnEnum.AvgLoseMonth
                ].width?.replace("px", ""),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    PERC_PROFITABLE_MONTHS: {
      ...(columnHelper.accessor(
        (data) =>
          percentageValue(data.measurements?.percProfitableMonths) ?? "--",
        {
          id: ExecutionColumnEnum.PercProfitableMonths,
          header: () => t("common.profitable_months"),
          cell: (ctx) => (
            <MeasurementColorSpan value={ctx.getValue()} negativeColorOnly />
          ),
          meta: {
            sortable: true,
            rightAligned: true,
            tooltip: t("message.percent_profitable_months"),
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.PercProfitableMonths]?.width
            ? Number(
                columnDefRecord[
                  ExecutionColumnEnum.PercProfitableMonths
                ].width?.replace("px", ""),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    TAG_ID: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.TagId,
        header: () => t("common.tags"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <ExecutionTags executionId={e.id} executionTags={e.tags as Tag[]} />
          );
        },
        meta: {
          sortable: false,
          overflow: true,
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.TagId]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.TagId].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    ALLOCATION: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.Allocation,
        header: () => t("common.initial_allocation"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <ExecutionValueCurrency
              value={e.allocation}
              currency={e.currencyPairDetails?.settleCurrency}
              colorSign={false}
            />
          );
        },
        meta: {
          sortable: true,
          rightAligned: true,
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.Allocation]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.Allocation].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    POSITION_AMOUNT: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.PositionAmount,
        header: () => t("common.position_amount"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <ExecutionValueCurrency
              value={e.measurements?.positionAmount}
              currency={e.currencyPairDetails?.positionCurrency}
              colorSign={false}
              hidden={!!e.multiCoinCurrency}
            />
          );
        },
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.open_position_total_value"),
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.PositionAmount]?.width
          ? Number(
              columnDefRecord[
                ExecutionColumnEnum.PositionAmount
              ].width?.replace("px", ""),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    POSITION_ABSOLUTE_PROFIT: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.PositionAbsoluteProfit,
        header: () => t("common.position_profit"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <ExecutionValueCurrency
              value={e.measurements?.positionAbsoluteProfit}
              currency={e.currencyPairDetails?.settleCurrency}
              colorSign={true}
            />
          );
        },
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.open_position_profit_loss"),
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.PositionAbsoluteProfit]?.width
          ? Number(
              columnDefRecord[
                ExecutionColumnEnum.PositionAbsoluteProfit
              ].width?.replace("px", ""),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    POSITION_PROFIT_LOSS: {
      ...(columnHelper.accessor(
        (data) =>
          percentageValue(data.measurements?.positionProfitLoss) ?? "--",
        {
          id: ExecutionColumnEnum.PositionProfitLoss,
          header: () => t("common.position_profit_percent"),
          cell: (ctx) => <MeasurementColorSpan value={ctx.getValue()} />,
          meta: {
            sortable: true,
            rightAligned: true,
            tooltip: t("message.open_position_percent_profit"),
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.PositionProfitLoss]?.width
            ? Number(
                columnDefRecord[
                  ExecutionColumnEnum.PositionProfitLoss
                ].width?.replace("px", ""),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    STARTED_AT: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.StartedAt,
        header: () => t("common.run_start_date"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <div
              title={
                e.rangeStart
                  ? getDateTime(e.rangeStart)
                  : e.startedAt
                  ? getDateTime(e.startedAt)
                  : "--"
              }
            >
              {e.rangeStart
                ? getRoundedDate(e.rangeStart)
                : e.startedAt
                ? getDateTime(e.startedAt)
                : "--"}
            </div>
          );
        },
        meta: {
          sortable: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.StartedAt]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.StartedAt].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    ENDED_AT: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.EndedAt,
        header: () => t("common.run_end_date"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <div
              title={
                e.rangeEnd
                  ? getDateTime(e.rangeEnd)
                  : e.endedAt
                  ? getDateTime(e.endedAt)
                  : "--"
              }
            >
              {e.rangeEnd
                ? getRoundedDate(e.rangeEnd)
                : e.endedAt
                ? getDateTime(e.endedAt)
                : "--"}
            </div>
          );
        },
        meta: {
          sortable: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.EndedAt]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.EndedAt].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    BALANCE: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.Balance,
        header: () => t("terminal.balance"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <ExecutionValueCurrency
              value={e.measurements?.balance}
              currency={e.currencyPairDetails?.settleCurrency}
              colorSign={false}
            />
          );
        },
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.run_total_funds_information"),
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.Balance]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.Balance].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    AUTO_REBALANCE: {
      ...(columnHelper.accessor((data) => data.autoRebalance, {
        id: ExecutionColumnEnum.AutoRebalance,
        header: () => t("common.rebalancing"),
        cell: (ctx) => <span>{ctx.getValue() ? "On" : "--"}</span>,
        meta: {
          sortable: true,
          tooltip: t("message.auto_rebalance_information"),
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.AutoRebalance]?.width
          ? Number(
              columnDefRecord[ExecutionColumnEnum.AutoRebalance].width?.replace(
                "px",
                "",
              ),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    RISK_SCORE: {
      ...(columnHelper.accessor(
        (data) => percentageValue(data.measurements?.riskScore) ?? "--",
        {
          id: ExecutionColumnEnum.RiskScore,
          header: () => t("common.risk_score"),
          cell: (ctx) => <span title={ctx.getValue()}>{ctx.getValue()}</span>,
          meta: {
            sortable: true,
            rightAligned: true,
            tooltip: (
              <div>
                <div className={css(styles.tooltipHeader)}>
                  {t("message.average_monthly_profit_for_mdd")}
                </div>
                <Trans
                  i18nKey="message.risk_score_information"
                  components={[
                    <div
                      key="multiplication"
                      className={css(styles.tooltipMultiplication)}
                    >
                      x
                    </div>,
                    <div key="squared" className={css(styles.tooltipSquared)}>
                      2
                    </div>,
                  ]}
                />
              </div>
            ),
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.RiskScore]?.width
            ? Number(
                columnDefRecord[ExecutionColumnEnum.RiskScore].width?.replace(
                  "px",
                  "",
                ),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    SUBSCRIPTION_STATUS: {
      ...(columnHelper.accessor((data) => data.id, {
        id: ExecutionColumnEnum.SubscriptionStatus,
        header: () => t("common.subscription_status"),
        cell: (ctx) => (
          <ExecutionSubscriptionStatusInfo executionId={ctx.getValue()} />
        ),
        meta: {
          sortable: true,
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.SubscriptionStatus]?.width
          ? Number(
              columnDefRecord[
                ExecutionColumnEnum.SubscriptionStatus
              ].width?.replace("px", ""),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    VISIBLE_SYNDICATION: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.VisibleSyndication,
        header: () => t("terminal.is_visible_bot"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <span>
              {e.syndicationSettings
                ? e.syndicationSettings?.accessControl ===
                  SyndicationAccessControl.AllowAllUsers
                  ? "Yes"
                  : "No"
                : "--"}
            </span>
          );
        },
        meta: {
          sortable: true,
          tooltip: t("message.visible_bot_information"),
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.VisibleSyndication]?.width
          ? Number(
              columnDefRecord[
                ExecutionColumnEnum.VisibleSyndication
              ].width?.replace("px", ""),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    BUY_HOLD_RETURN: {
      ...(columnHelper.accessor(
        (data) => percentageValue(data.measurements?.buyHoldReturn) ?? "--",
        {
          id: ExecutionColumnEnum.BuyHoldReturn,
          header: () => t("common.buy_and_hold_return"),
          cell: (ctx) => (
            <MeasurementColorSpan value={ctx.getValue()} negativeColorOnly />
          ),
          meta: {
            sortable: true,
            rightAligned: true,
            tooltip: t("message.holding_profit_loss"),
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.BuyHoldReturn]?.width
            ? Number(
                columnDefRecord[
                  ExecutionColumnEnum.BuyHoldReturn
                ].width?.replace("px", ""),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    ACTIVE_SUBSCRIBERS: {
      ...(columnHelper.accessor(
        (data) => data.syndicationSettings?.activeSubscribersCount ?? "--",
        {
          id: ExecutionExtraColumn.ActiveSubscribers,
          header: () => t("common.active_subscribers"),
          cell: (ctx) => <span>{ctx.getValue()}</span>,
          meta: {
            sortable: false,
          },
          size: columnDefRecord[ExecutionExtraColumn.ActiveSubscribers]?.width
            ? Number(
                columnDefRecord[
                  ExecutionExtraColumn.ActiveSubscribers
                ].width?.replace("px", ""),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    SHARPE_RATIO: {
      ...(columnHelper.accessor(
        (data) => trimToTwoDecimals(data.measurements?.sharpeRatio) ?? "--",
        {
          id: ExecutionColumnEnum.SharpeRatio,
          header: () => t("terminal.sharpe_ratio"),
          cell: (ctx) => <span title={ctx.getValue()}>{ctx.getValue()}</span>,
          meta: {
            sortable: true,
            rightAligned: true,
            tooltip: t("message.sharpe_ratio_information"),
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.SharpeRatio]?.width
            ? Number(
                columnDefRecord[ExecutionColumnEnum.SharpeRatio].width?.replace(
                  "px",
                  "",
                ),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    SORTINO_RATIO: {
      ...(columnHelper.accessor(
        (data) => trimToTwoDecimals(data.measurements?.sortinoRatio) ?? "--",
        {
          id: ExecutionColumnEnum.SortinoRatio,
          header: () => t("terminal.sortino_ratio"),
          cell: (ctx) => <span title={ctx.getValue()}>{ctx.getValue()}</span>,
          meta: {
            sortable: true,
            rightAligned: true,
            tooltip: t("message.sortino_ratio_information"),
            canFilter: true,
          },
          size: columnDefRecord[ExecutionColumnEnum.SortinoRatio]?.width
            ? Number(
                columnDefRecord[
                  ExecutionColumnEnum.SortinoRatio
                ].width?.replace("px", ""),
              )
            : undefined,
        },
      ) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    TOTAL_REALIZED_GAIN: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.TotalRealizedGain,
        header: () => t("terminal.total_realized_gain"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <ExecutionValueCurrency
              value={e.measurements?.totalRealizedGain}
              currency={e.currencyPairDetails?.settleCurrency}
              colorSign={true}
              noGradiant={true}
            />
          );
        },
        meta: {
          sortable: true,
          rightAligned: true,
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.TotalRealizedGain]?.width
          ? Number(
              columnDefRecord[
                ExecutionColumnEnum.TotalRealizedGain
              ].width?.replace("px", ""),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    TOTAL_REALIZED_LOSS: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumnEnum.TotalRealizedLoss,
        header: () => t("terminal.total_realized_loss"),
        cell: (ctx) => {
          const e = ctx.getValue();
          return (
            <ExecutionValueCurrency
              value={e.measurements?.totalRealizedLoss}
              currency={e.currencyPairDetails?.settleCurrency}
              colorSign={true}
              noGradiant={true}
            />
          );
        },
        meta: {
          sortable: true,
          rightAligned: true,
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.TotalRealizedLoss]?.width
          ? Number(
              columnDefRecord[
                ExecutionColumnEnum.TotalRealizedLoss
              ].width?.replace("px", ""),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
    CONSISTENCY_SCORE: {
      ...(columnHelper.accessor((data) => data.measurements?.consistencyScore, {
        id: ExecutionColumnEnum.ConsistencyScore,
        header: () => t("terminal.consistency_score"),
        cell: (ctx) => {
          const consistencyScore = ctx.getValue();

          return (
            <ColorSpan
              color={
                !consistencyScore
                  ? "offWhite"
                  : consistencyScore >= 60
                  ? "pastelGreen"
                  : consistencyScore >= 35
                  ? "pear"
                  : consistencyScore >= 25
                  ? "meteor"
                  : "offWhite"
              }
            >
              {consistencyScore ?? "--"}
            </ColorSpan>
          );
        },
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: (
            <div>
              <div>{t("message.consistency_score_information")}</div>
              <div>
                <p>
                  <Trans
                    i18nKey="message.consistency_score_low_percentage_information"
                    components={[
                      <ColorSpan
                        key="consistency_score_low_percentage_information"
                        color="meteor"
                      >
                        -
                      </ColorSpan>,
                    ]}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="message.consistency_score_medium_percentage_information"
                    components={[
                      <ColorSpan
                        key="consistency_score_medium_percentage_information"
                        color="pear"
                      >
                        -
                      </ColorSpan>,
                    ]}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="message.consistency_score_high_percentage_information"
                    components={[
                      <ColorSpan
                        key="consistency_score_high_percentage_information"
                        color="pastelGreen"
                      >
                        -
                      </ColorSpan>,
                    ]}
                  />
                </p>
              </div>
            </div>
          ),
          canFilter: true,
        },
        size: columnDefRecord[ExecutionColumnEnum.ConsistencyScore]?.width
          ? Number(
              columnDefRecord[
                ExecutionColumnEnum.ConsistencyScore
              ].width?.replace("px", ""),
            )
          : undefined,
      }) as ColumnDef<ExecutionsInfiniteTable_ExecutionFragment, unknown>),
    },
  };
}

const styles = StyleSheet.create({
  tooltipHeader: {
    display: "inline-block",
  },
  tooltipMultiplication: {
    display: "inline-block",
    transform: "scale(0.7)",
  },
  tooltipSquared: {
    display: "inline-block",
    transform: "scale(0.7) translateY(-5px)",
  },
});
