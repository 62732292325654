import { SelectedExecution } from "contexts/SelectedExecutionContext";
import {
  Bar,
  LibrarySymbolInfo,
} from "../charting_library/charting_library.min";

interface ApiQuery {
  [key: string]: any;
}

interface PlotDataResponse {
  startTime: number | undefined;
  values: number[];
}

type ColorDataResponse = Record<string, number[]>;

type SignalData = Record<string, number>;

export interface SignalDataResponse {
  buyTimes: SignalData;
  sellTimes: SignalData;
  stopLossTimes: SignalData;
  takeProfitTimes: SignalData;
  liquidationTimes: SignalData;
}

export interface PlotRangeResponse {
  rangeStart: string;
  rangeEnd?: string;
}

const DATE_STR_LENGTH = 17;
const EXPONENT_REGEX = /1e-(\d+)/;
// const MAX_RETRIES = 5;

/**
 * Centralizing all data fetching operations now that multiple sources may be using them.
 * Also simplifies the TraderatorDataFeed
 */
class TraderatorChartAPI {
  private apiURL: string;
  private decoder: TextDecoder;

  constructor(apiURL: string) {
    this.apiURL = apiURL;
    this.decoder = new TextDecoder("utf-8");
  }

  private async parseCandleData(
    resp: Response,
    candleSeconds: number,
  ): Promise<Bar[]> {
    if (!resp.ok || !resp.body) {
      return [];
    }

    let firstCandleTime: number | null = null;
    let i: number = 0;
    let bars: Bar[] = [];

    // NOTE: I originally tried implementing this using streams, but performance was actually worse.
    // May be worth reinvestigating in the future, perhaps if we move to a binary format, eg. Thrift/Avro
    // Or once more Firefox supports stream transformation natively (polyfill may be the slow part)
    const body = await resp.text();
    body.split("\n").forEach((line) => {
      if (firstCandleTime == null) {
        firstCandleTime = new Date(line).getTime();
        return;
      }

      const values = line.split(",");
      const [open, high, low, close, volume] = values.map(parseFloat);

      const nextBar: Bar = isNaN(open)
        ? ({
            time: firstCandleTime + i * candleSeconds * 1000,
            open: undefined,
            close: undefined,
            high: undefined,
            low: undefined,
            volume: undefined,
          } as unknown as Bar)
        : {
            time: firstCandleTime + i * candleSeconds * 1000,
            open,
            close,
            high,
            low,
            volume,
          };

      bars.push(nextBar);

      i++;
    });

    return bars;
  }

  private async parsePlotData(resp: Response): Promise<PlotDataResponse> {
    let values: number[] = [];
    let firstCandleTime: number | undefined;

    // If plot data was not found, return an empty result.
    if (resp.status === 404)
      return {
        startTime: undefined,
        values: [],
      };

    // If a server-size error occurred, throw an exception.
    if (!resp.ok) throw Error(`Plot Fetch failed: "${resp.statusText}"`);

    try {
      const body = await resp.arrayBuffer();

      // The first 17 bytes of the response are the start time in plaintext
      const startTimeStr = this.decoder.decode(
        new Uint8Array(body.slice(0, DATE_STR_LENGTH)),
      );
      firstCandleTime = new Date(startTimeStr).getTime() / 1000;

      // The remaining bytes of the response are binary-encoded doubles.
      // Each double is 8 bytes.
      const candleView = new DataView(body, DATE_STR_LENGTH);
      if (candleView.byteLength >= 8) {
        for (let i = 0; i < candleView.byteLength; i += 8) {
          if (i + 8 <= candleView.byteLength)
            try {
              values.push(candleView.getFloat64(i, false));
            } catch (e) {
              // TODO: Fix eslint error
              // eslint-disable-next-line no-console
              console.warn("Error parsing a value", e);
            }
        }
      }
    } catch (e) {
      // TODO: Find a way to notify the user that the plots failed to load/parse, perhaps a toast library?
      // TODO: Fix eslint error
      // eslint-disable-next-line no-console
      console.warn("Error parsing plot data", e);
    }

    return {
      startTime: firstCandleTime,
      values,
    };
  }

  private getScaleForTickSize(value: number): number {
    const strVal = value.toString();
    // Check for scientific notation, as that occurs at 7+ decimal places (eg. BNB_BTC)
    const expMatch = strVal.match(EXPONENT_REGEX);

    // If scientific notation, take the exponent - otherwise count the number of decimal places
    try {
      const pow = expMatch
        ? parseInt(expMatch[1])
        : strVal.split(".")[1].length;

      return Math.pow(10, pow);
    } catch (e) {
      // If this failed, that means `strVal.split` failed due to having no decimal places.
      // In this case we can just return a scale of 1
      return 1;
    }
  }

  private async apiCall(
    path: string,
    query?: ApiQuery,
    abortSignal?: AbortSignal,
    retry: number = 0,
  ): Promise<Response> {
    let url = new URL(this.apiURL + path);
    if (query) {
      Object.keys(query).forEach((key) =>
        url.searchParams.append(key, query[key]),
      );
    }

    const res = await fetch(url.href, {
      cache: "no-cache",
      credentials: "include",
      mode: "cors",
      signal: abortSignal,
    });

    // Temporarily disabling request-level retry logic as it can lead to
    // unnecessary load on the server (eg. if the error is due to a server-side OOM)
    // if (!res.ok && retry < MAX_RETRIES) {
    //     if (retry > MAX_RETRIES) {
    //         console.debug(`Max retries exceeded for ${path}?${query}!`);
    //     } else {
    //         return await new Promise<Response>(res => {
    //             setTimeout(async () => res(
    //                 await this.apiCall(path, query, retry + 1)
    //             ),
    //             1000 * (2 ^ (retry + 1)))
    //         })
    //     }
    // }

    return res;
  }

  /**
   * Retrieve info for a given symbol. Used by the TradingView charts.
   * @param symbol The symbol to retrieve info for
   */
  async getSymbolInfo(symbol: string): Promise<LibrarySymbolInfo> {
    const res = await this.apiCall(`/coinpairs/${symbol}`);
    const info = await res.json();

    return {
      name: symbol,
      full_name: symbol,
      exchange: info.exchange,
      listed_exchange: "",
      ticker: symbol,
      description: info.name,
      type: "crypto",
      session: "24x7",
      has_seconds: false,
      has_daily: true,
      has_intraday: true,
      has_weekly_and_monthly: false,
      timezone: "Etc/UTC",
      format: "price",
      minmov: 1,
      pricescale: info.tickSize
        ? this.getScaleForTickSize(info.tickSize)
        : 100000000,
      minmove2: 0,
      fractional: false,
    };
  }

  /**
   * Fetch and parse the requested range of candle data from the Charting API.
   * @param symbol The symbol matching the exchange/currency pair to fetch for
   * @param resolutionMins The size of each candle (in minutes)
   * @param rangeStart The start of the requested range
   * @param rangeEnd The end of the requested range
   * @returns Candle data in a TradingView-compatible format.
   */
  async getCandles(
    symbol: string,
    resolutionMins: number,
    rangeStart: Date,
    rangeEnd: Date,
    abortSignal?: AbortSignal,
  ): Promise<Bar[]> {
    const res = await this.apiCall(
      `/coinpairs/${symbol}/candles`,
      {
        candleSize: resolutionMins,
        startTime: rangeStart.toISOString(),
        endTime: rangeEnd.toISOString(),
      },
      abortSignal,
    );

    return this.parseCandleData(res, resolutionMins * 60);
  }

  /**
   * Fetch and parse the requested range of plot data from the Charting API.
   * @param executionId The id of the execution to fetch plot data for
   * @param plotId The id of the plot to fetch data for
   * @param rangeStart The start of the requested range
   * @param rangeEnd The end of the requested range
   * @returns Plot data as a series of numerical values + the time of the first value.
   */
  async getPlotData(
    executionId: string,
    plotId: string,
    rangeStart: Date,
    rangeEnd: Date,
    abortSignal?: AbortSignal,
  ): Promise<PlotDataResponse> {
    const pId = plotId.match(/legacy_plot_(\d+)/)?.[1] ?? plotId;
    const res = await this.apiCall(
      `/executions/${executionId}/plots/${pId}/values`,
      {
        startTime: rangeStart.toISOString(),
        endTime: rangeEnd.toISOString(),
      },
      abortSignal,
    );

    return this.parsePlotData(res);
  }

  async getColorData(
    executionId: string,
    plotId: string,
    abortSignal?: AbortSignal,
  ): Promise<ColorDataResponse> {
    const res = await this.apiCall(
      `/executions/${executionId}/plots/${plotId}/colors`,
      undefined,
      abortSignal,
    );

    if (res.ok) return (await res.json()) as ColorDataResponse;

    // If we get here, `this.apiCall()` has run out of retry attempts.
    return {};
  }

  async getPlotRange(
    executionId: string,
    plotId: string,
    abortSignal?: AbortSignal,
  ): Promise<PlotRangeResponse | null> {
    const res = await this.apiCall(
      `/executions/${executionId}/plots/${plotId}/range`,
      undefined,
      abortSignal,
    );

    if (res.ok) return (await res.json()) as PlotRangeResponse;

    // If we get here, `this.apiCall()` has run out of retry attempts.
    return null;
  }

  /**
   * Fetch and return the requested range of signal data from the Charting API.
   * @param executionDetails The id and type of the execution/bot to fetch signal data for
   * @param rangeStart The start of the requested range
   * @param rangeEnd The end of the requested range
   * @returns Signal data as a map of {[order time]:[order price]} for each order type
   */
  async getSignalData(
    executionDetails: SelectedExecution,
    rangeStart: Date,
    rangeEnd: Date,
    abortSignal?: AbortSignal,
  ): Promise<SignalDataResponse> {
    const res = await this.apiCall(
      this.getSignalDataPath(executionDetails),
      {
        startTime: rangeStart.toISOString(),
        endTime: rangeEnd.toISOString(),
      },
      abortSignal,
    );

    if (res.ok) return (await res.json()) as SignalDataResponse;

    // If we get here, `this.apiCall()` has run out of retry attempts.
    return {
      buyTimes: {},
      sellTimes: {},
      stopLossTimes: {},
      takeProfitTimes: {},
      liquidationTimes: {},
    };
  }

  private getSignalDataPath(executionDetails: SelectedExecution) {
    switch (executionDetails.type) {
      case "bot":
        return `/bots/${executionDetails.id}/signals`;
      case "execution":
        return `/executions/${executionDetails.id}/signals`;
    }
  }

  /**
   * Fetch and return the requested range of signal data from the Charting API.
   * @param executionId The id of the execution to fetch signal data for
   * @param rangeStart The start of the requested range
   * @param rangeEnd The end of the requested range
   * @returns Signal data as a map of {[order time]:[order price]} for each order type
   */
  async getSharedSignalData(
    executionId: string,
    rangeStart: Date,
    rangeEnd: Date,
    abortSignal?: AbortSignal,
  ): Promise<SignalDataResponse> {
    const res = await this.apiCall(
      `/shared/${executionId}/signals`,
      {
        startTime: rangeStart.toISOString(),
        endTime: rangeEnd.toISOString(),
      },
      abortSignal,
    );

    if (res.ok) return (await res.json()) as SignalDataResponse;

    // If we get here, `this.apiCall()` has run out of retry attempts.
    return {
      buyTimes: {},
      sellTimes: {},
      stopLossTimes: {},
      takeProfitTimes: {},
      liquidationTimes: {},
    };
  }
}

export default TraderatorChartAPI;
