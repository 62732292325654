import React from "react";
import { StyleSheet, css } from "aphrodite";
import colors from "styles/colors";

type Props = Readonly<{
  value?: string;
  negativeColorOnly?: boolean;
}>;

function MeasurementColorSpan({ value, negativeColorOnly = false }: Props) {
  let val = value || "--";
  const percentage = parseFloat(typeof val === "string" ? val : "0");
  if (percentage === 0 || val === "--") {
    return <span title={val}>{val}</span>;
  } else {
    const intensity = Math.min(10000, Math.abs(percentage)) / 10000;
    return (
      <span
        title={val}
        className={css(
          styles[negativeColorOnly || percentage < 0 ? "neg" : "pos"],
        )}
        style={{ filter: `brightness(${1 + intensity})` }}
      >
        {val}
      </span>
    );
  }
}

const styles = StyleSheet.create({
  neg: {
    color: colors.red,
  },
  pos: {
    color: colors.paleGreen,
  },
});

export default MeasurementColorSpan;
