import React, { useCallback, useEffect, useMemo, MouseEvent } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import { ExecutionsInnerTable_ExecutionFragment } from "__generated__/graphql";
import {
  CurrencyPair,
  Execution,
  ExecutionStatus,
  ExecutionType,
} from "graphql/schema";
import { ActionDefinition } from "./ExecutionActionDefinitions";
import { ColumnDefinition } from "./ExecutionColumnDefinitions";
import ExecutionRow from "./ExecutionRow";
import { COLUMN_DEFINITIONS } from "contexts/executions/ExecutionColumns";
import { useExecutionContext } from "contexts/executions/ExecutionContext";
import { useFilterContext } from "contexts/executions/FilterContext";
import {
  CheckedIdRecord,
  MAXIMUM_PACK_CURRENCY_PAIR_SIZE,
  MultiCoinPackExchangeAndCurrency,
  useMultiSelectContext,
} from "contexts/MultiSelectContext";
import { useComposerStateContext } from "contexts/ComposerStateContext";
import { useColumnsPresetsContext } from "contexts/executions/ColumnsPresetsContext";
import { SYNDICATION_SUBSCRIPTIONS_COLUMN_DEFS } from "contexts/executions/SyndicationSubscriptionsContextProvider";
import LoadingRow from "core/table/LoadingRow";
import useColumnsResizing, {
  ResizeColumn,
} from "core/table/useColumnsResizing";
import imgSearch from "images/search.svg";
import imgBoxChecked from "images/box-checked.svg";
import imgBoxCheckedBlue from "images/box-checked-blue.svg";
import imgBoxCheckedGreen from "images/box-checked-green.svg";
import imgBoxUnchecked from "images/box-unchecked.svg";
import imgAddColumn from "images/addColumn.svg";
import { useBoolean } from "helpers/hooks";
import { logEvent } from "helpers/analytics";
import {
  BOTS_TAB,
  LIVE_SETUPS_SUBTAB,
  MY_BOTS_SUBSCRIPTIONS_SUBTAB,
  MY_BOTS_SUBTAB,
} from "helpers/navigation";
import Tooltip from "@material-ui/core/Tooltip";

const nop = () => {};

ExecutionsInnerTable.fragments = {
  execution: gql`
    fragment ExecutionsInnerTable_execution on Execution {
      id
      isPack
      syndicationId
      status
      exchange
      currencyPair
      currencyPairDetails {
        settleCurrency
      }
      multiCoinCurrency
      leverage
      leverageShort
    }
  `,
};

type Props = Readonly<{
  currentId?: string;
  executions: ExecutionsInnerTable_ExecutionFragment[];
  actionDefs?: ActionDefinition[];
  checkedList?: CheckedIdRecord;
  isFetching: boolean;
  onExecutionSelected: (
    exId: string,
    name?: string,
    type?: ExecutionType,
    status?: ExecutionStatus,
  ) => (e: MouseEvent) => void;
  onShowActionModal: (
    name: string,
    action: () => void,
    execution?: Execution,
  ) => void;
}>;

// The main display of the table
function ExecutionsInnerTable({
  actionDefs,
  checkedList,
  currentId,
  executions,
  onExecutionSelected,
  isFetching,
  onShowActionModal,
}: Props) {
  const { t } = useTranslation();

  const {
    sort,
    toggleSort,
    columnDefs,
    creationPath,
    openPacks,
    setOpenPacks,
  } = useExecutionContext();
  const { setSelectedAddFilterColumn } = useFilterContext();
  const {
    updateCheckedList,
    isCheckedList,
    setExecutionsCount,
    exeCheckedType,
    updateCheckedForPack,
    updateCheckedForSyndication,
    checkedForSyndication,
    setMultiCoinPackExchangeAndCurrency,
    setMultiCoinPackCurrencyPairs,
  } = useMultiSelectContext();
  const { isMultiCoinPack } = useComposerStateContext();
  const { openCustomizer, setColumnsPreset } = useColumnsPresetsContext();

  const [isCustomizerHovering, setHovering, setNotHovering] = useBoolean(false);

  useEffect(
    () => setExecutionsCount(executions?.length ?? 0),
    [setExecutionsCount, executions],
  );

  const columns = useMemo(
    () =>
      creationPath === MY_BOTS_SUBSCRIPTIONS_SUBTAB
        ? SYNDICATION_SUBSCRIPTIONS_COLUMN_DEFS
        : columnDefs.filter(
            (c) =>
              !c.hidden &&
              (creationPath !== LIVE_SETUPS_SUBTAB ||
                (creationPath === LIVE_SETUPS_SUBTAB &&
                  c.column !== "PERCENT_PROFITABLE_TRADES" &&
                  c.column !== "SCRIPT_VERSION")),
          ),
    [creationPath, columnDefs],
  );

  const onColumnHeaderClick = useCallback(
    (e: MouseEvent<HTMLTableHeaderCellElement>) => {
      const headerKey = e.currentTarget.getAttribute("data-index");
      const columnDef = columns[Number(headerKey)];
      toggleSort(columnDef.column);
      logEvent("ToggleExecutionSort", {
        sortColumn: sort.sortKey,
        sortDirection: sort.sortDirection === "ASC" ? "DESC" : "ASC",
      });
    },
    [toggleSort, columns, sort],
  );

  const onColumnHeaderFilterClick = useCallback(
    (e: MouseEvent<HTMLTableHeaderCellElement>) => {
      const headerKey = e.currentTarget.getAttribute("data-index");
      const columnDef = columns[Number(headerKey)];
      setSelectedAddFilterColumn(columnDef);
      e.stopPropagation();
    },
    [columns, setSelectedAddFilterColumn],
  );

  const toggleAllClick = useCallback(
    (isChecked: boolean) => {
      // toggle all checkbox for syndication is only used to uncheck the current checked row since
      // the checked list is only used to check one strategy pack row at a time
      if (exeCheckedType === "SYNDICATION") {
        updateCheckedForSyndication(undefined);
        return;
      }

      const newCheckedList: CheckedIdRecord = { ...checkedList };

      const multiCoinCurrencyPairs: Record<CurrencyPair, number> = {};
      let multiCoinPackExchangeAndCurrency:
        | MultiCoinPackExchangeAndCurrency
        | undefined = undefined;

      // to uncheck all executions within a strategy pack
      if (!isChecked) {
        updateCheckedForPack({});
        updateCheckedList({});
        setMultiCoinPackExchangeAndCurrency(undefined);
        setMultiCoinPackCurrencyPairs({});
        return;
      }

      for (const execution of executions) {
        // so checking all executions for strategy pack creation do not check rows that are subscriptions
        if (isChecked && exeCheckedType === "CREATE_PACK") {
          // don't let the user create a pack with more than 50 tests
          if (
            Object.keys(newCheckedList).filter((id) => newCheckedList[id])
              .length >= 50
          ) {
            break;
          } else if (
            !execution.isPack &&
            !execution.syndicationId &&
            (!isMultiCoinPack ||
              (isMultiCoinPack &&
                (!multiCoinPackExchangeAndCurrency ||
                  ((multiCoinCurrencyPairs[execution.currencyPair] ||
                    (!multiCoinCurrencyPairs[execution.currencyPair] &&
                      Object.keys(multiCoinCurrencyPairs).length <
                        MAXIMUM_PACK_CURRENCY_PAIR_SIZE)) &&
                    multiCoinPackExchangeAndCurrency &&
                    multiCoinPackExchangeAndCurrency.exchange ===
                      execution.exchange &&
                    multiCoinPackExchangeAndCurrency.settleCurrency ===
                      execution.currencyPairDetails?.settleCurrency))))
          ) {
            if (isMultiCoinPack) {
              multiCoinCurrencyPairs[execution.currencyPair] =
                (multiCoinCurrencyPairs[execution.currencyPair] ?? 0) + 1;

              if (!multiCoinPackExchangeAndCurrency) {
                multiCoinPackExchangeAndCurrency = {
                  exchange: execution.exchange,
                  settleCurrency:
                    execution.currencyPairDetails?.settleCurrency ?? "",
                  initialCurrencyPair: execution.currencyPair,
                };
              }
            }
            newCheckedList[execution.id] = {
              id: execution.id,
              status: execution.status,
              exchange: execution.exchange,
              currencyPair: execution.currencyPair,
              multiCoinPackCurrency: execution.multiCoinCurrency ?? undefined,
              leverage: execution.leverage,
              leverageShort: execution.leverageShort,
            };
          }
        } else {
          newCheckedList[execution.id] = {
            id: execution.id,
            status: execution.status,
            exchange: execution.exchange,
            currencyPair: execution.currencyPair,
            multiCoinPackCurrency: execution.multiCoinCurrency ?? undefined,
            leverage: execution.leverage,
            leverageShort: execution.leverageShort,
          };
        }
      }

      if (exeCheckedType === "CREATE_PACK") {
        updateCheckedForPack(newCheckedList);

        if (isMultiCoinPack) {
          setMultiCoinPackExchangeAndCurrency(multiCoinPackExchangeAndCurrency);
          setMultiCoinPackCurrencyPairs(multiCoinCurrencyPairs);
        }
      } else {
        updateCheckedList(newCheckedList);
      }
    },
    [
      exeCheckedType,
      executions,
      checkedList,
      updateCheckedList,
      updateCheckedForPack,
      updateCheckedForSyndication,
      isMultiCoinPack,
      setMultiCoinPackExchangeAndCurrency,
      setMultiCoinPackCurrencyPairs,
    ],
  );

  const onCheckColumnSize = useCallback(
    (width: number, column?: ResizeColumn) => {
      if (column?.column === "SCRIPT_NAME") {
        if (width < 250) {
          return 250;
        } else if (width > 900) {
          return 900;
        }
      } else {
        if (width < 50) {
          return 50;
        } else if (width > 600) {
          return 600;
        }
      }
      return width;
    },
    [],
  );

  const { onResizeDragStart, setDefaultWidth, isDragging } = useColumnsResizing(
    columns,
    onCheckColumnSize,
    (column) =>
      COLUMN_DEFINITIONS[(column as ColumnDefinition).column]?.width ?? "100px",
  );

  // update strategy packs that are open here so that creating a new test doesn't close the current pack and open the pack above right it
  const onUpdateOpenPacks = useCallback(
    (id: string, remove: boolean) => {
      if (remove) {
        setOpenPacks(openPacks.filter((packId) => packId !== id));
      } else {
        setOpenPacks(openPacks.concat(id));
      }
    },
    [setOpenPacks, openPacks],
  );

  const onCustomizerColumnClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      openCustomizer();
    },
    [openCustomizer],
  );

  return (
    <div className="table__wrapper simulations-table">
      {isDragging && <div className="hidden-layer" />}
      <table
        className={classnames("table table--compact", {
          dragging: isDragging,
        })}
      >
        <thead>
          <tr className={classnames({ "select-enabled": checkedList })}>
            {checkedList && (
              <th
                className={classnames("table-row-checkbox", {
                  hidden: !isCheckedList,
                  "create-pack":
                    exeCheckedType === "CREATE_PACK" &&
                    !Object.keys(checkedList).length,
                })}
              >
                <img
                  alt={"Checked Box"}
                  src={
                    Object.keys(checkedList).length
                      ? exeCheckedType === "SELECT"
                        ? imgBoxChecked
                        : imgBoxCheckedBlue
                      : exeCheckedType === "SYNDICATION" &&
                        !!checkedForSyndication
                      ? imgBoxCheckedGreen
                      : imgBoxUnchecked
                  }
                  onClick={() =>
                    toggleAllClick(
                      Object.keys(checkedList).length ? false : true,
                    )
                  }
                />
              </th>
            )}
            {columns.map((columnDef, i) => {
              if (
                creationPath !== MY_BOTS_SUBTAB &&
                (columnDef.column === "VISIBLE_SYNDICATION" ||
                  columnDef.column === "ACTIVE_SUBSCRIBERS")
              ) {
                return null;
              }

              const translatedColumn = columnDef.getLabels(t);
              let sortDirection = sort.sortDirection;
              if (sort.sortKey === "MAX_DRAWDOWN") {
                sortDirection = sortDirection === "ASC" ? "DESC" : "ASC";
              }
              const header =
                columnDef.column === sort.sortKey ? (
                  <>
                    <span className="sorted-column">
                      {translatedColumn.header}
                    </span>
                    <i className={sortDirection.toLowerCase()}></i>
                  </>
                ) : (
                  translatedColumn.header
                );

              return (
                <th
                  key={i}
                  className={columnDef.className}
                  style={{ width: columnDef.width }}
                >
                  {i !== 0 && (
                    <div
                      className="column-divider left-side"
                      onMouseDown={(e) => {
                        setColumnsPreset(null);
                        onResizeDragStart(e, columns[i - 1]);
                      }}
                      onDoubleClick={(e) => {
                        setDefaultWidth(columns[i - 1], "executionColumns");
                        setColumnsPreset(null);
                      }}
                    />
                  )}
                  <div className="column-header">
                    {translatedColumn.tooltip ? (
                      <Tooltip
                        title={
                          <div className="execution-table-tooltip__content">
                            <div className="execution-table-tooltip__title">
                              {translatedColumn.header}
                            </div>
                            <div className="execution-table-tooltip__body">
                              {translatedColumn.tooltip}
                            </div>
                          </div>
                        }
                        PopperProps={{
                          className: "execution-table-tooltip",
                        }}
                      >
                        <div
                          className="as-link"
                          data-index={i}
                          onClick={
                            !columnDef.unsortable ? onColumnHeaderClick : nop
                          }
                        >
                          {header}
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        className="as-link"
                        data-index={i}
                        onClick={
                          !columnDef.unsortable ? onColumnHeaderClick : nop
                        }
                      >
                        {header}
                      </div>
                    )}
                    {columnDef.filterType && (
                      <div
                        data-index={i}
                        onClick={onColumnHeaderFilterClick}
                        className={"column-filter"}
                      >
                        <img alt={"Search"} src={imgSearch} />{" "}
                      </div>
                    )}
                    {columnDef.column === "SCRIPT_NAME" && (
                      <div className="right-header">{t("common.actions")}</div>
                    )}
                  </div>
                  <div
                    className="column-divider"
                    onMouseDown={(e) => {
                      onResizeDragStart(e, columnDef);
                      setColumnsPreset(null);
                    }}
                    onDoubleClick={(_) => {
                      setDefaultWidth(columnDef, "executionColumns");
                      setColumnsPreset(null);
                    }}
                  />
                </th>
              );
            })}
            {creationPath !== MY_BOTS_SUBSCRIPTIONS_SUBTAB && (
              <th
                className={classnames("add-column", {
                  hover: isCustomizerHovering,
                })}
                onClick={openCustomizer}
                onMouseEnter={setHovering}
                onMouseLeave={setNotHovering}
              >
                <div
                  className="column-divider left-side"
                  onMouseDown={(e) => {
                    onResizeDragStart(e, columns[columns.length - 1]);
                    setColumnsPreset(null);
                  }}
                  onDoubleClick={(_) => {
                    setDefaultWidth(
                      columns[columns.length - 1],
                      "executionColumns",
                    );
                    setColumnsPreset(null);
                  }}
                />
                <img alt="Add Column" src={imgAddColumn} />
              </th>
            )}
            <th className="blank" />
          </tr>
        </thead>
        <tbody>
          {isFetching || executions.length ? (
            <>
              {executions.map((execution, i) => (
                <ExecutionRow
                  key={i}
                  active={
                    currentId !== undefined &&
                    currentId === execution.id &&
                    (exeCheckedType !== "CREATE_PACK" || !isCheckedList)
                  }
                  currentId={currentId}
                  executionId={execution.id}
                  checkedList={checkedList}
                  onExecutionSelected={onExecutionSelected}
                  columns={columns}
                  actionDefs={actionDefs}
                  onShowActionModal={onShowActionModal}
                  isPackListOpen={openPacks.includes(execution?.id)}
                  onUpdateOpenPacks={onUpdateOpenPacks}
                  onCustomizerColumnClick={onCustomizerColumnClick}
                  setHovering={setHovering}
                  setNotHovering={setNotHovering}
                  isCustomizerHovering={isCustomizerHovering}
                  isSyndication={creationPath?.startsWith(BOTS_TAB)}
                />
              ))}
              {isFetching && <LoadingRow columnCount={columns.length + 1} />}
              <tr className="empty-row" />
            </>
          ) : (
            <tr>
              <td>
                <div className="empty-state">
                  {t("message.no_results_match")}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ExecutionsInnerTable;
