import { useUserContext } from "contexts/UserContext";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

interface WithPermissionProps {
  permission: string;
  children: ReactNode;
}

export const WithPermission: React.FC<WithPermissionProps> = ({
  permission,
  children,
}) => {
  const { t } = useTranslation();
  const { permissions } = useUserContext();

  const isDisabled = !permissions.includes(permission);

  return (
    <div
      onClick={(e) => {
        if (isDisabled) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      data-for={`has-no-permission-${permission}`}
      data-tip=""
    >
      <div
        style={{
          pointerEvents: isDisabled ? "none" : "inherit",
        }}
      >
        {children}
      </div>
      {isDisabled && (
        <ReactTooltip
          id={`has-no-permission-${permission}`}
          effect="solid"
          place="top"
          className="reactTooltip"
        >
          {t("common.not_available_with_current_permissions")}
        </ReactTooltip>
      )}
    </div>
  );
};
