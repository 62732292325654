import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import { useBoolean } from "helpers/hooks";
import imgError from "images/exclamation-mark-red.svg";
import colors from "styles/colors";

type Props = Readonly<{
  name: string;
  label: string;
  readableName?: string;
  required?: boolean;
  endAdornment?: ReactNode;
  value: string;
  errorMessage?: string;
  size?: "medium" | "large";
  disabled?: boolean;
  showErrorIcon?: boolean;
  isTouched?: boolean;
  onChange: (value: string) => void;
}>;

function TextField({
  name,
  label,
  readableName,
  required = false,
  endAdornment,
  value,
  errorMessage,
  size = "large",
  disabled = false,
  showErrorIcon = false,
  isTouched = false,
  onChange,
}: Props) {
  const { t } = useTranslation();

  const [isActive, setIsActive, setIsNotActive] = useBoolean(false);
  const [touched, setTouched] = useBoolean(isTouched);

  const focused = isActive || !!value;
  const hasError = touched && required && !!errorMessage;

  return (
    <>
      <div
        className={css(
          styles.textContainer,
          styles[size === "large" ? "largeContainer" : "mediumContainer"],
        )}
        onFocusCapture={setIsActive}
        onBlurCapture={setIsNotActive}
      >
        <div
          className={css(
            styles.labelContainer,
            styles[
              focused
                ? size === "large"
                  ? "activeLargeLabel"
                  : "activeMediumLabel"
                : "inactiveLabel"
            ],
            styles[
              disabled
                ? "disabledLabel"
                : hasError
                ? "errorLabel"
                : "normalLabel"
            ],
            styles[size === "large" ? "largeLabel" : "mediumLabel"],
          )}
        >
          <span className={css(styles.label)}>{label}</span>
        </div>
        <div className={css(styles.inputContainer)}>
          <input
            disabled={disabled}
            className={css(
              styles.input,
              disabled && styles.disabledInput,
              hasError && styles.errorInput,
              styles[size === "large" ? "largeInput" : "mediumInput"],
            )}
            value={value}
            onChange={(e) => {
              if (disabled) {
                return;
              }

              setTouched();
              onChange(e.target.value);
            }}
            name={name}
          />
          <fieldset
            className={css(
              styles.fieldset,
              styles[
                disabled
                  ? "disabledContainer"
                  : hasError
                  ? "errorContainer"
                  : focused
                  ? "activeContainer"
                  : "inactiveContainer"
              ],
            )}
          >
            <legend
              className={css(
                styles.legend,
                styles[focused ? "focusedLegend" : "unfocusedLegend"],
                styles.mediumLabel,
              )}
            >
              <span>{label}</span>
            </legend>
          </fieldset>
          {endAdornment && (
            <div className={css(styles.endAdornment)}>{endAdornment}</div>
          )}
        </div>
      </div>
      {hasError && (
        <div className={css(styles.errorMessage)}>
          {showErrorIcon && (
            <img
              className={css(styles.errorMessageIcon)}
              src={imgError}
              alt="Error"
              width={12}
              height={12}
            />
          )}
          {errorMessage ??
            t("error.required_field", { field: readableName ?? name })}
        </div>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  textContainer: {
    position: "relative",
    display: "flex",
    borderRadius: "5px",
    margin: "10px 0",
  },
  activeContainer: {
    border: `1px solid ${colors.offWhite}`,
  },
  inactiveContainer: {
    border: `1px solid ${colors.steel}`,
    ":hover": {
      border: `1px solid ${colors.offWhite}`,
    },
  },
  errorContainer: {
    border: `1px solid ${colors.red}`,
    ":hover": {
      border: `1px solid ${colors.lightRed}`,
    },
  },
  disabledContainer: {
    border: `1px solid ${colors.steel}`,
    ":hover": {
      border: `1px solid ${colors.steel}`,
    },
  },
  mediumContainer: {
    height: "30px",
  },
  largeContainer: {
    height: "40px",
  },
  fieldset: {
    position: "absolute",
    top: "-5px",
    right: 0,
    bottom: 0,
    left: 0,
    pointerEvents: "none",
    borderRadius: "5px",
    margin: 0,
    padding: "0 0 0 5px",
  },
  legend: {
    display: "block",
    width: "auto",
    maxWidth: "1000px",
    height: "11px",
    padding: 0,
    visibility: "hidden",
    transition: "max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms",
  },
  focusedLegend: {
    maxWidth: "1000px",
  },
  unfocusedLegend: {
    maxWidth: "0.01px",
  },
  labelContainer: {
    position: "absolute",
    fontWeight: 400,
    userSelect: "none",
    pointerEvents: "none",
    transition:
      "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
  normalLabel: {
    color: colors.offWhite,
  },
  errorLabel: {
    color: colors.red,
  },
  disabledLabel: {
    color: colors.steel,
  },
  label: {
    margin: "auto 0",
    padding: "0 8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  activeLargeLabel: {
    transform: "translate(-10px, -9px) scale(0.75)",
  },
  activeMediumLabel: {
    transform: "translate(-3px, -8px) scale(0.75)",
  },
  inactiveLabel: {
    display: "flex",
    height: "100%",
  },
  mediumLabel: {
    fontSize: "12px",
  },
  largeLabel: {
    fontSize: "14px",
  },
  inputContainer: {
    display: "flex",
    width: "100%",
  },
  input: {
    fontWeight: 400,
    fontSize: "14px",
    color: colors.lightGray,
    background: "unset",
    border: "0",
    width: "100%",
    padding: "0 5px 0 10px",
  },
  disabledInput: {
    color: colors.steel,
  },
  errorInput: {
    color: colors.redError,
  },
  mediumInput: {
    fontSize: "12px",
  },
  largeInput: {
    fontSize: "14px",
  },
  endAdornment: {
    display: "flex",
    paddingRight: "10px",
    margin: "auto 0 auto auto",
  },
  errorMessage: {
    display: "flex",
    color: colors.red,
    fontWeight: 400,
    fontSize: "12px",
    marginTop: "-5px",
  },
  errorMessageIcon: {
    margin: "auto 5px auto 0",
  },
});

export default TextField;
