import React, { useMemo, useCallback } from "react";
import { StyleSheet, css } from "aphrodite";
import { ExecutionsInfiniteTable_ExecutionFragment } from "__generated__/graphql";
import { useMultiSelectContext } from "contexts/MultiSelectContext";
import { useComposerStateContext } from "contexts/ComposerStateContext";
import LinkButton from "core/forms/LinkButton";
import imgBoxChecked from "images/box-checked.svg";
import imgBoxCheckedBlue from "images/box-checked-blue.svg";
import imgBoxCheckedGreen from "images/box-checked-green.svg";
import imgBoxUnchecked from "images/box-unchecked.svg";
import colors from "styles/colors";

type Props = Readonly<{
  execution?: ExecutionsInfiniteTable_ExecutionFragment;
  canToggle?: boolean;
  onClick?: () => void;
}>;

function ExecutionCheckbox({ execution, canToggle = false, onClick }: Props) {
  const {
    exeCheckedType,
    checkedForPack,
    checkedList,
    checkedForSyndication,
    multiCoinPackExchangeAndCurrency,
    toggleChecked,
    setMultiCoinPackExchangeAndCurrency,
    setMultiCoinPackCurrencyPairs,
    canAddToMultiCoinPack,
    updateMultiCoinPackCurrencyPairs,
  } = useMultiSelectContext();
  const { isMultiCoinPack } = useComposerStateContext();

  const checkedRecords = useMemo(
    () => (exeCheckedType === "CREATE_PACK" ? checkedForPack : checkedList),
    [exeCheckedType, checkedForPack, checkedList],
  );

  const canToggleCheck = useMemo(() => {
    if (!execution) {
      return canToggle;
    }

    if (checkedRecords[execution.id]) {
      return true;
    }

    if (
      exeCheckedType === "CREATE_PACK" &&
      Object.keys(checkedRecords).filter((id) => checkedRecords[id]).length >=
        51 &&
      !checkedRecords[execution.id]
    ) {
      return false;
    }

    return (
      exeCheckedType === "SELECT" ||
      (!execution?.isPack &&
        !execution?.syndicationId &&
        exeCheckedType === "CREATE_PACK" &&
        (!isMultiCoinPack ||
          (isMultiCoinPack &&
            execution.currencyPairDetails?.settleCurrency &&
            canAddToMultiCoinPack(
              execution.exchange,
              execution.currencyPairDetails.settleCurrency,
              execution.currencyPair,
            )))) ||
      (exeCheckedType === "SYNDICATION" &&
        execution?.isPack &&
        execution?.type === "PAPERTRADE" &&
        (!checkedForSyndication ||
          (checkedForSyndication &&
            checkedForSyndication.id === execution?.id)))
    );
  }, [
    canToggle,
    exeCheckedType,
    checkedRecords,
    execution,
    checkedForSyndication,
    isMultiCoinPack,
    canAddToMultiCoinPack,
  ]);

  const onCheckboxClick = useCallback(() => {
    if (!canToggleCheck) return;

    if (!execution) {
      onClick?.();
      return;
    }

    toggleChecked({
      id: execution?.id,
      exchange: execution?.exchange,
      currencyPair: execution?.currencyPair,
      leverage: execution?.leverage,
      leverageShort: execution?.leverageShort,
      status: execution?.status,
    });

    if (
      isMultiCoinPack &&
      exeCheckedType === "CREATE_PACK" &&
      !multiCoinPackExchangeAndCurrency &&
      execution.currencyPairDetails?.settleCurrency
    ) {
      setMultiCoinPackExchangeAndCurrency({
        exchange: execution.exchange,
        settleCurrency: execution.currencyPairDetails.settleCurrency,
        initialCurrencyPair: execution.currencyPair,
      });
      setMultiCoinPackCurrencyPairs({
        [execution.currencyPair]: 1,
      });
    } else if (
      isMultiCoinPack &&
      exeCheckedType === "CREATE_PACK" &&
      multiCoinPackExchangeAndCurrency
    ) {
      const checkedRecordsCopy = checkedRecords ?? {};
      delete checkedRecordsCopy[execution?.id];

      if (!Object.keys(checkedRecordsCopy).length) {
        setMultiCoinPackExchangeAndCurrency(undefined);
        setMultiCoinPackCurrencyPairs({});
      } else if (execution.currencyPairDetails?.settleCurrency) {
        updateMultiCoinPackCurrencyPairs(
          !checkedRecords[execution?.id] ?? true,
          execution.exchange,
          execution.currencyPairDetails.settleCurrency,
          execution.currencyPair,
        );
      }
    }
  }, [
    canToggleCheck,
    checkedRecords,
    execution,
    exeCheckedType,
    isMultiCoinPack,
    multiCoinPackExchangeAndCurrency,
    toggleChecked,
    setMultiCoinPackExchangeAndCurrency,
    setMultiCoinPackCurrencyPairs,
    updateMultiCoinPackCurrencyPairs,
    onClick,
  ]);

  return (
    <LinkButton
      onClick={onCheckboxClick}
      stopPropagation={true}
      className={css(
        styles.checkboxContainer,
        (!canToggleCheck ||
          (exeCheckedType === "SELECT" && execution?.packPercentage)) &&
          styles.disabled,
      )}
    >
      <img
        alt="Checked Box"
        className={css(
          styles.checkbox,
          exeCheckedType === "CREATE_PACK" &&
            execution &&
            !checkedRecords[execution?.id] &&
            styles.packCheckbox,
          exeCheckedType === "SYNDICATION" &&
            execution?.isPack &&
            checkedForSyndication?.id !== execution?.id &&
            styles.monetizeCheckbox,
        )}
        src={
          (execution && checkedRecords[execution?.id]) ||
          (checkedForSyndication?.id === execution?.id &&
            exeCheckedType === "SYNDICATION")
            ? exeCheckedType === "SELECT"
              ? imgBoxChecked
              : exeCheckedType === "CREATE_PACK"
              ? imgBoxCheckedBlue
              : imgBoxCheckedGreen
            : imgBoxUnchecked
        }
        width={14}
        height={14}
      />
    </LinkButton>
  );
}

const styles = StyleSheet.create({
  checkboxContainer: {
    marginTop: "3px",
  },
  disabled: {
    opacity: 0,
  },
  checkbox: {
    border: `1px solid ${colors.offBlack}`,
    ":hover": {
      border: `1px solid ${colors.iron}`,
    },
  },
  packCheckbox: {
    border: `1px solid ${colors.pine}`,
    ":hover": {
      border: `1px solid ${colors.palePine}`,
    },
  },
  monetizeCheckbox: {
    border: `1px solid ${colors.flatGreen}`,
    ":hover": {
      border: `1px solid ${colors.lightGreen}`,
    },
  },
});

export default ExecutionCheckbox;
