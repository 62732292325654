import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import { ConnectCredentialStep } from "./ConnectCredential";
import closeImg from "images/close.svg";
import connectionsImg from "images/exchange-connection.svg";
import colors from "styles/colors";

type StepProps = Readonly<{
  title: string;
  active?: boolean;
  viewed?: boolean;
  hasConnector?: boolean;
}>;

function NavigationStep({
  title,
  active = false,
  viewed = false,
  hasConnector = false,
}: StepProps) {
  return (
    <div className={css(styles.step)}>
      <div className={css(styles.stepBody)}>
        <div
          className={css(
            styles.stepDot,
            styles[active ? "activeDot" : viewed ? "viewedDot" : "inactiveDot"],
          )}
        />
        <div
          className={css(
            styles.stepTitle,
            styles[active ? "activeTitle" : "inactiveTitle"],
          )}
        >
          {title}
        </div>
      </div>
      {hasConnector && <div className={css(styles.stepConnector)} />}
    </div>
  );
}

type Props = Readonly<{
  step: ConnectCredentialStep;
  onClose: () => void;
}>;

function ConnectCredentialNavigation({ step, onClose }: Props) {
  const { t } = useTranslation();

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.iconContainer)}>
        <img
          className={css(styles.icon)}
          src={connectionsImg}
          alt="Connections"
        />
      </div>
      <div className={css(styles.steps)}>
        <NavigationStep
          title={t("message.select_your_exchange")}
          active={step === "SELECT_EXCHANGE"}
          viewed={step !== "SELECT_EXCHANGE"}
        />
        <NavigationStep
          title={t("message.create_an_api_key")}
          active={step === "CREATE_KEY"}
          viewed={step === "CONNECT_KEY" || step === "CONNECT_SUCCESS"}
          hasConnector
        />
        <NavigationStep
          title={t("message.connect_api_key")}
          active={step === "CONNECT_KEY" || step === "CONNECT_SUCCESS"}
          hasConnector
        />
      </div>
      <div className={css(styles.close)}>
        <img
          className={css(styles.closeIcon)}
          onClick={onClose}
          src={closeImg}
          alt="Close"
          width={12}
          height={12}
        />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    background: colors.warmGray,
    display: "flex",
    height: "85px",
  },
  iconContainer: {
    display: "flex",
    marginRight: "auto",
    flex: "1",
  },
  icon: {
    margin: "auto auto auto 25px",
  },
  steps: {
    display: "flex",
    flex: "3",
    margin: "auto",
  },
  step: {
    position: "relative",
    flex: "1",
    padding: "0 5px",
  },
  stepBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  stepDot: {
    borderRadius: "8px",
    marginTop: "-4px",
    zIndex: 1,
  },
  viewedDot: {
    width: "8px",
    height: "8px",
    background: colors.steel,
  },
  activeDot: {
    width: "8px",
    height: "8px",
    background: colors.paleYellow,
  },
  inactiveDot: {
    width: "7px",
    height: "7px",
    background: colors.nero,
    border: `1px solid ${colors.steel}`,
  },
  stepTitle: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "13px",
    marginTop: "5px",
  },
  activeTitle: {
    color: colors.lightGray,
  },
  inactiveTitle: {
    color: colors.steel,
  },
  stepConnector: {
    flex: "1 1 auto",
    position: "absolute",
    top: "0",
    left: "-50%",
    right: "50%",
    borderTop: `1px solid ${colors.steel}`,
  },
  close: {
    display: "flex",
    flex: "1",
    marginLeft: "auto",
  },
  closeIcon: {
    cursor: "pointer",
    margin: "auto 25px auto auto",
    ":hover": {
      filter: "grayscale(100%) brightness(232%)",
    },
  },
});

export default ConnectCredentialNavigation;
