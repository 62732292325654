import React from "react";
import imgSpinner from "../../images/spinner-dark.svg";
import "./spinner.scss";

const NewSpinner: React.FunctionComponent = () => (
  <section className="spinner">
    <img src={imgSpinner} alt="Loading" />
  </section>
);

export default NewSpinner;
