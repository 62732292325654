import React, { createContext, useCallback, useContext } from "react";
import invariant from "invariant";
import {
  ApolloError,
  FetchResult,
  useMutation,
  useQuery,
} from "@apollo/client";
import { CreatePromoterProfileMutation } from "__generated__/graphql";
import { Promoter } from "graphql/schema";
import { GET_MY_PROMOTER_DETAILS } from "graphql/queries";
import { CREATE_PROMOTER_PROFILE } from "graphql/mutations";
import { useUserContext } from "./UserContext";
import { parseError } from "helpers/errorUtils";
import { logEvent } from "helpers/analytics";

interface IReferralContext {
  promoterDetails?: Promoter;
  promoterError?: string;
  loadingCreatePromoter: boolean;
  createPromoterProfile: (
    customReferralId?: string,
    onCompleted?: (data: CreatePromoterProfileMutation) => void,
    onError?: (error: ApolloError) => void,
  ) => Promise<undefined | FetchResult<CreatePromoterProfileMutation>>;
  tryGetOrCreatePromoterReferralCode: () => Promise<string | undefined>;
}

export const ReferralContext = createContext<IReferralContext | undefined>(
  undefined,
);

export function useReferralContext() {
  const context = useContext(ReferralContext);
  invariant(
    context != null,
    "Component is not a child of ReferralContext provider",
  );
  return context;
}

export const ReferralContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user, isLoggedIn } = useUserContext();

  const { data: promoterData, error: promoterError } = useQuery(
    GET_MY_PROMOTER_DETAILS,
    {
      skip: !isLoggedIn,
    },
  );
  const promoterDetails = promoterData?.me?.promoterDetails;

  const [mutation, { loading: loadingCreatePromoter }] =
    useMutation<CreatePromoterProfileMutation>(CREATE_PROMOTER_PROFILE, {
      refetchQueries: ["GetMyPromoterDetails"],
    });

  const createPromoterProfile = useCallback(
    async (
      customReferralId?: string,
      onCompleted?: (data: CreatePromoterProfileMutation) => void,
      onError?: (error: ApolloError) => void,
    ) => {
      return mutation({
        variables: {
          refId: customReferralId?.length
            ? customReferralId.toLowerCase()
            : undefined,
        },
        onCompleted: (data) => {
          onCompleted?.(data);
          logEvent("CreatedFirstPromoterProfile", {
            promoterId: data?.createPromoterProfile?.id,
            userId: user?.id,
          });
        },
      }).catch((e) => {
        onError?.(e);
        return undefined;
      });
    },
    [mutation, user],
  );

  const tryGetOrCreatePromoterReferralCode = useCallback(async () => {
    if (!isLoggedIn) {
      return;
    }

    if (promoterDetails?.defaultRefId) {
      return promoterDetails?.defaultRefId;
    } else {
      const promoterData = await createPromoterProfile();

      return promoterData?.data?.createPromoterProfile?.defaultRefId;
    }
  }, [isLoggedIn, promoterDetails, createPromoterProfile]);

  return (
    <ReferralContext.Provider
      value={{
        promoterDetails,
        promoterError: parseError(promoterError)[1],
        loadingCreatePromoter,
        createPromoterProfile,
        tryGetOrCreatePromoterReferralCode,
      }}
    >
      {children}
    </ReferralContext.Provider>
  );
};
