import React, {
  createContext,
  useReducer,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { useRouteMatch } from "react-router-dom";
import invariant from "invariant";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import {
  GetBotBacktestRealizedProfitsLogData,
  GetBotRealizedProfitsLogData,
  GetExecutionRealizedProfitsLogData,
  GetPortfolioDailyLogData,
  GetPortfolioRealizedProfitsLogData,
  GetSharedExecutionRealizedProfitsLogData,
  GET_BOT_BACKTEST_REALIZED_PROFITS_LOG,
  GET_BOT_REALIZED_PROFITS_LOG,
  GET_EXECUTION_REALIZED_PROFITS_LOG,
  GET_PORTFOLIO_REALIZED_PROFITS_LOG,
  GET_SHARED_EXECUTION_REALIZED_PROFITS_LOG,
  PORTFOLIO_DAILY_LOG,
} from "../graphql/queries";
import {
  PortfolioDailyLog,
  PortfolioRealizedProfitsLog,
} from "../graphql/schema";
import {
  SelectedExecution,
  useSelectedExecutionContext,
} from "./SelectedExecutionContext";
import { useUserContext } from "./UserContext";
import { useBoolean } from "../helpers/hooks";
import { logEvent } from "../helpers/analytics";
import { getExecutionPageTitle } from "helpers/executions";
import { setTitle } from "helpers/environment";

export type PortfolioCurrency = "USD" | "BTC";
export type ChartType = "PORTFOLIO" | "PERFORMANCE" | "MARKET";
export type Chart = "BAR_CHART" | "LINE_CHART";

interface Context {
  openAssetExplorer: boolean;
  toggleAssetExplorer: (e: any) => void;
  chartType: ChartType;
  setChartType: (type: ChartType) => void;
  chart: Chart;
  setChart: (chart: Chart) => void;
  isCandleChartHidden: boolean;
  hideCandleChart: () => void;
  showCandleChart: () => void;
  range: number | undefined;
  onRangeClick: (range: number | undefined) => void;
  portfolioDailyLog: PortfolioDailyLog[];
  loadingPortfolio: boolean;
  currency: PortfolioCurrency;
  toggleCurrency: (e: any) => void;
  selectedLog: PortfolioDailyLog | undefined;
  setLog: (log: PortfolioDailyLog | undefined) => void;
  portfolioStartingLog: PortfolioDailyLog;
  onMonthToDateClick: () => void;
  onMonthToDate: boolean;

  selectedProfitsLog: PortfolioRealizedProfitsLog | undefined;
  setProfitsLog: (log: PortfolioRealizedProfitsLog | undefined) => void;
  chartExecutionDetails?: SelectedExecution;
  clearChartExecution: () => void;
  analysisReturns?: number;
  setAnalysisReturns: (returns?: number) => void;
  executionRealizedProfitsLog?: GetExecutionRealizedProfitsLogData;
  sharedExecutionRealizedProfitsLog?: GetSharedExecutionRealizedProfitsLogData;
  botRealizedProfitsLog?: GetBotRealizedProfitsLogData;
  botBacktestRealizedProfitsLog?: GetBotBacktestRealizedProfitsLogData;
  loadingExecutionLogs: boolean;
  portfolioRealizedProfitsLog: PortfolioRealizedProfitsLog[];
  loadingPortfolioLogs: boolean;
  chartName?: string;
}

export const TerminalPortfolioContext = createContext<Context | undefined>(
  undefined,
);

export function useTerminalPortfolioContext() {
  const context = useContext(TerminalPortfolioContext);
  invariant(
    context != null,
    "Component is not a child of TerminalPortfolioContext provider",
  );
  return context;
}

export function TerminalPortfolioContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation();

  const routeMatch = useRouteMatch();

  const { selectedExecutionDetails, botMeasurementsMode } =
    useSelectedExecutionContext();
  const { isGuest, isLoggedIn } = useUserContext();

  const [openAssetExplorer, toggleAssetExplorer] = useReducer(
    (prev: boolean) => !prev,
    false,
  );
  const [chartType, setChartTypeInner] = useState<ChartType>(
    (!isLoggedIn
      ? "MARKET"
      : (localStorage.getItem("chartType") as ChartType)) ?? "MARKET",
  );
  const [chart, setChartInner] = useState<Chart>("BAR_CHART");
  const [isCandleChartHidden, hideCandleChart, showCandleChart] =
    useBoolean(false);

  const [currency, toggleCurrency] = useReducer(
    (prev: PortfolioCurrency) => {
      localStorage.setItem(
        "showAssetExplorerBTC",
        prev === "USD" ? "true" : "false",
      );
      return (prev === "BTC" ? "USD" : "BTC") as PortfolioCurrency;
    },
    localStorage.getItem("showAssetExplorerBTC") === "true" ? "BTC" : "USD",
  );

  const [range, setRange] = useState<number | undefined>(undefined);
  const [onMonthToDate, isMTD, isNotMTD] = useBoolean(false);

  const [selectedLog, setLog] = useState<PortfolioDailyLog | undefined>();

  const { data, loading: loadingPortfolio } =
    useQuery<GetPortfolioDailyLogData>(PORTFOLIO_DAILY_LOG, {
      variables: { range },
      fetchPolicy: "no-cache",
      skip: !isLoggedIn,
    });

  const [selectedProfitsLog, setProfitsLog] = useState<
    PortfolioRealizedProfitsLog | undefined
  >();
  const [chartExecutionDetails, setChartExecutionDetails] =
    useState<SelectedExecution>();
  const [analysisReturns, setAnalysisReturns] = useState<number | undefined>();

  useEffect(() => {
    if (selectedExecutionDetails?.id) {
      setChartExecutionDetails(selectedExecutionDetails);
    }
  }, [selectedExecutionDetails]);

  const clearChartExecution = useCallback(() => {
    setChartExecutionDetails(undefined);
    showCandleChart();
  }, [showCandleChart]);

  const {
    data: portfolioRealizedProfitsLogData,
    loading: loadingPortfolioLogs,
  } = useQuery<GetPortfolioRealizedProfitsLogData>(
    GET_PORTFOLIO_REALIZED_PROFITS_LOG,
    {
      skip: chartType !== "PERFORMANCE" || !isLoggedIn,
    },
  );

  const { data: botLogsData, loading: loadingBotLogs } =
    useQuery<GetBotRealizedProfitsLogData>(GET_BOT_REALIZED_PROFITS_LOG, {
      variables: {
        id: chartExecutionDetails?.id,
        rangeDays: range != null ? range + 1 : undefined,
      },
      skip:
        chartExecutionDetails?.type !== "bot" ||
        !chartExecutionDetails?.id ||
        botMeasurementsMode !== "papertrade",
    });

  const { data: botBacktestLogsData, loading: loadingBacktestBotLogs } =
    useQuery<GetBotBacktestRealizedProfitsLogData>(
      GET_BOT_BACKTEST_REALIZED_PROFITS_LOG,
      {
        variables: {
          id: chartExecutionDetails?.id,
        },
        skip:
          chartExecutionDetails?.type !== "bot" ||
          !chartExecutionDetails?.id ||
          botMeasurementsMode !== "backtest",
      },
    );

  const { data: executionLogsData, loading: loadingExecutionLogs } =
    useQuery<GetExecutionRealizedProfitsLogData>(
      GET_EXECUTION_REALIZED_PROFITS_LOG,
      {
        variables: {
          id: chartExecutionDetails?.id,
          rangeDays: range != null ? range + 1 : undefined,
        },
        skip:
          chartExecutionDetails?.type !== "execution" ||
          !chartExecutionDetails?.id ||
          isGuest,
      },
    );

  const { data: sharedExecutionLogsData, loading: loadingSharedExecutionLogs } =
    useQuery<GetSharedExecutionRealizedProfitsLogData>(
      GET_SHARED_EXECUTION_REALIZED_PROFITS_LOG,
      {
        variables: {
          shareToken: chartExecutionDetails?.id,
          rangeDays: range != null ? range + 1 : undefined,
        },
        skip:
          chartExecutionDetails?.type !== "execution" ||
          !chartExecutionDetails?.id ||
          !isGuest,
      },
    );

  const portfolioDailyLog = useMemo(
    () => data?.portfolioDailyLog ?? [],
    [data],
  );
  const portfolioStartingLog = useMemo(
    () => portfolioDailyLog[0],
    [portfolioDailyLog],
  );

  useEffect(() => {
    if (!selectedLog) {
      setLog(portfolioDailyLog[portfolioDailyLog.length - 1]);
    }
  }, [selectedLog, portfolioDailyLog]);

  useEffect(() => {
    if (selectedProfitsLog) {
      return;
    }

    if (
      chartExecutionDetails?.id &&
      chartExecutionDetails?.type === "execution" &&
      executionLogsData?.execution?.realizedProfitsLog?.length
    ) {
      setProfitsLog(
        executionLogsData.execution.realizedProfitsLog[
          executionLogsData.execution.realizedProfitsLog.length - 1
        ],
      );
    } else if (
      isGuest &&
      chartExecutionDetails?.id &&
      chartExecutionDetails?.type === "execution" &&
      sharedExecutionLogsData?.sharedExecution?.realizedProfitsLog?.length
    ) {
      setProfitsLog(
        sharedExecutionLogsData.sharedExecution.realizedProfitsLog[
          sharedExecutionLogsData.sharedExecution.realizedProfitsLog.length - 1
        ],
      );
    } else if (
      !chartExecutionDetails?.id &&
      portfolioRealizedProfitsLogData?.me?.portfolioRealizedProfitsLog?.length
    ) {
      setProfitsLog(
        portfolioRealizedProfitsLogData.me.portfolioRealizedProfitsLog[
          portfolioRealizedProfitsLogData.me.portfolioRealizedProfitsLog
            .length - 1
        ],
      );
    }
  }, [
    selectedProfitsLog,
    chartExecutionDetails,
    executionLogsData,
    portfolioRealizedProfitsLogData,
    isGuest,
    sharedExecutionLogsData,
  ]);

  useEffect(() => {
    // don't return right away if the value is 0
    if (analysisReturns != null) {
      return;
    }

    if (
      chart === "LINE_CHART" &&
      (executionLogsData?.execution?.type !== "LIVE" ||
        (isGuest && sharedExecutionLogsData?.sharedExecution?.type !== "LIVE"))
    ) {
      if (
        executionLogsData?.execution?.analysis?.dailyProfits?.values?.length
      ) {
        setAnalysisReturns(
          Number(
            executionLogsData.execution.analysis.dailyProfits.values[
              executionLogsData.execution.analysis.dailyProfits.values.length -
                1
            ],
          ) * 100,
        );
      } else if (
        isGuest &&
        sharedExecutionLogsData?.sharedExecution?.analysis?.dailyProfits?.values
          ?.length
      ) {
        setAnalysisReturns(
          Number(
            sharedExecutionLogsData.sharedExecution.analysis.dailyProfits
              .values[
              sharedExecutionLogsData.sharedExecution.analysis.dailyProfits
                .values.length - 1
            ],
          ) * 100,
        );
      } else if (
        chartExecutionDetails?.id &&
        chartExecutionDetails?.type === "bot" &&
        botLogsData?.publicSyndication?.analysis?.dailyProfits?.values?.length
      ) {
        setAnalysisReturns(
          Number(
            botLogsData.publicSyndication.analysis.dailyProfits.values[
              botLogsData.publicSyndication.analysis.dailyProfits.values
                .length - 1
            ],
          ) * 100,
        );
      } else if (
        chartExecutionDetails?.id &&
        chartExecutionDetails?.type === "bot" &&
        botBacktestLogsData?.publicSyndication?.backtestAnalysis?.dailyProfits
          ?.values?.length
      ) {
        setAnalysisReturns(
          Number(
            botBacktestLogsData.publicSyndication.backtestAnalysis.dailyProfits
              .values[
              botBacktestLogsData.publicSyndication.backtestAnalysis
                .dailyProfits.values.length - 1
            ],
          ) * 100,
        );
      }
    } else if (chart === "BAR_CHART") {
      if (
        executionLogsData?.execution?.analysis?.monthlyProfits?.values?.length
      ) {
        setAnalysisReturns(
          Number(
            executionLogsData.execution.analysis.monthlyProfits.values[
              executionLogsData.execution.analysis.monthlyProfits.values
                .length - 1
            ],
          ) * 100,
        );
      } else if (
        isGuest &&
        sharedExecutionLogsData?.sharedExecution?.analysis?.monthlyProfits
          ?.values?.length
      ) {
        setAnalysisReturns(
          Number(
            sharedExecutionLogsData.sharedExecution.analysis.monthlyProfits
              .values[
              sharedExecutionLogsData.sharedExecution.analysis.monthlyProfits
                .values.length - 1
            ],
          ) * 100,
        );
      } else if (
        chartExecutionDetails?.id &&
        chartExecutionDetails?.type === "bot" &&
        botLogsData?.publicSyndication?.analysis?.monthlyProfits?.values?.length
      ) {
        setAnalysisReturns(
          Number(
            botLogsData.publicSyndication.analysis.monthlyProfits.values[
              botLogsData.publicSyndication.analysis.monthlyProfits.values
                .length - 1
            ],
          ) * 100,
        );
      } else if (
        chartExecutionDetails?.id &&
        chartExecutionDetails?.type === "bot" &&
        botBacktestLogsData?.publicSyndication?.backtestAnalysis?.monthlyProfits
          ?.values?.length
      ) {
        setAnalysisReturns(
          Number(
            botBacktestLogsData.publicSyndication.backtestAnalysis
              .monthlyProfits.values[
              botBacktestLogsData.publicSyndication.backtestAnalysis
                .monthlyProfits.values.length - 1
            ],
          ) * 100,
        );
      }
    }
  }, [
    analysisReturns,
    chart,
    range,
    executionLogsData,
    isGuest,
    sharedExecutionLogsData,
    botLogsData,
    botBacktestLogsData,
    chartExecutionDetails,
  ]);

  const onRangeClick = useCallback(
    (range: number | undefined) => {
      setRange(range);
      isNotMTD();
    },
    [isNotMTD],
  );

  const onMonthToDateClick = useCallback(() => {
    const now = new Date();
    setRange(now.getUTCDate());
    isMTD();
  }, [isMTD]);

  const setChart = useCallback(
    (chart: Chart) => {
      setChartInner(chart);
      onRangeClick(undefined);
      setProfitsLog(undefined);
      setAnalysisReturns(undefined);
    },
    [onRangeClick],
  );

  const setChartType = useCallback((chartType: ChartType) => {
    setChartTypeInner(chartType);
    logEvent("SelectChartType", { chartType });
    localStorage.setItem("chartType", chartType);
  }, []);

  const chartName = useMemo(() => {
    if (executionLogsData?.execution?.type === "PREVIEW") {
      return t("terminal.execution_name_and_version", {
        name: executionLogsData?.execution?.scriptDetails?.name,
        version: executionLogsData?.execution?.scriptDetails?.version,
      });
    }

    const name =
      sharedExecutionLogsData?.sharedExecution?.name ??
      sharedExecutionLogsData?.sharedExecution?.scriptName ??
      executionLogsData?.execution?.syndication?.name ??
      executionLogsData?.execution?.scriptDetails?.name ??
      executionLogsData?.execution?.name ??
      botLogsData?.publicSyndication?.name ??
      (botBacktestLogsData?.publicSyndication?.name
        ? t("terminal.bot_backtest", {
            bot_name: botBacktestLogsData.publicSyndication.name,
          })
        : "");
    const runNumber = executionLogsData?.execution?.runNumber ?? "";

    if (name) {
      return name + " " + runNumber;
    }
  }, [
    executionLogsData,
    sharedExecutionLogsData,
    botLogsData,
    botBacktestLogsData,
    t,
  ]);

  useEffect(() => {
    const pageTitle = getExecutionPageTitle(
      t,
      routeMatch.url,
      chartName,
      !!botLogsData || !!botBacktestLogsData,
    );
    if (pageTitle) {
      setTitle(pageTitle);
    }
  });

  const value = useMemo(
    () => ({
      openAssetExplorer,
      toggleAssetExplorer,
      chartType,
      setChartType,
      chart,
      setChart,
      isCandleChartHidden,
      hideCandleChart,
      showCandleChart,
      range,
      onRangeClick,
      portfolioDailyLog,
      loadingPortfolio,
      currency,
      toggleCurrency,
      selectedLog,
      setLog,
      portfolioStartingLog,
      onMonthToDateClick,
      onMonthToDate,
      selectedProfitsLog,
      setProfitsLog,
      chartExecutionDetails,
      clearChartExecution,
      analysisReturns,
      setAnalysisReturns,
      executionRealizedProfitsLog: executionLogsData,
      sharedExecutionRealizedProfitsLog: sharedExecutionLogsData,
      botRealizedProfitsLog: botLogsData,
      botBacktestRealizedProfitsLog: botBacktestLogsData,
      loadingExecutionLogs:
        loadingExecutionLogs ||
        loadingSharedExecutionLogs ||
        loadingBotLogs ||
        loadingBacktestBotLogs,
      portfolioRealizedProfitsLog:
        portfolioRealizedProfitsLogData?.me?.portfolioRealizedProfitsLog ?? [],
      loadingPortfolioLogs,
      chartName,
    }),
    [
      openAssetExplorer,
      chartType,
      setChartType,
      chart,
      setChart,
      isCandleChartHidden,
      hideCandleChart,
      showCandleChart,
      range,
      onRangeClick,
      portfolioDailyLog,
      loadingPortfolio,
      currency,
      selectedLog,
      portfolioStartingLog,
      onMonthToDateClick,
      onMonthToDate,
      selectedProfitsLog,
      chartExecutionDetails,
      clearChartExecution,
      analysisReturns,
      executionLogsData,
      loadingExecutionLogs,
      portfolioRealizedProfitsLogData,
      loadingPortfolioLogs,
      sharedExecutionLogsData,
      botLogsData,
      botBacktestLogsData,
      loadingSharedExecutionLogs,
      loadingBotLogs,
      loadingBacktestBotLogs,
      chartName,
    ],
  );

  return (
    <TerminalPortfolioContext.Provider value={value}>
      {children}
    </TerminalPortfolioContext.Provider>
  );
}
