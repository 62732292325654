import React, { ReactNode } from "react";
import { StyleSheet, css } from "aphrodite";
import colors from "styles/colors";

type Props = Readonly<{
  borderStyles: ("borderTop" | "borderRight" | "borderBottom" | "borderLeft")[];
  children: ReactNode;
}>;

function ListItem({ children, borderStyles }: Props) {
  const borderStylesList = borderStyles.map((style) => styles[style]);

  return <li className={css(styles.list, borderStylesList)}>{children}</li>;
}

const styles = StyleSheet.create({
  list: {
    listStyle: "none",
    background: colors.offBlack,
  },
  borderTop: {
    borderTop: "2px solid #131313",
  },
  borderRight: {
    borderRight: "2px solid #131313",
  },
  borderBottom: {
    borderBottom: "2px solid #131313",
  },
  borderLeft: {
    borderLeft: "2px solid #131313",
  },
});

export default ListItem;
