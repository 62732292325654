import React from "react";
import { SubscriptionClient } from "subscriptions-transport-ws";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  FetchResult,
  InMemoryCache,
  Observable,
  split,
  TypePolicy,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import {
  getCurrentAuthenticatedUser,
  trySessionRefresh,
} from "../helpers/cognitoUtils";
import { logEvent } from "../helpers/analytics";

function onSocketClose(ev: CloseEvent) {
  // TODO: Fix eslint error
  // eslint-disable-next-line no-console
  console.log("closed!?", ev.code);
  if (ev.code === 1006) {
    // Abnormal close - the client will attempt to reconnect.
    // Because of this we want to try to refresh any Cognito tokens so that
    // we don't keep getting auth errors.
    trySessionRefresh();
  }
}

// We define the `SubscriptionClient` here so that we can add error handlers
// on connection errors to ensure we refresh tokens when necessary.
const socketClient = new SubscriptionClient(
  `${process.env.REACT_APP_WS_URL}/subscriptions`,
  { reconnect: true },
);

// Hook up the close handler to the initial websocket
(socketClient.client as WebSocket).onclose = onSocketClose;

// Also ensure the onclose handler is re-set after successfully connecting,
// just in case it can be a new websocket in any cases not handled by `onError`.
socketClient.onConnected((_) => {
  (socketClient.client as WebSocket).onclose = onSocketClose;
});

// On every error, we have to re-set the onclose handler as a new socket is created.
socketClient.onError((_) => {
  (socketClient.client as WebSocket).onclose = onSocketClose;
});

const link = ApolloLink.from([
  // First step of error handling - if unauthorized response received,
  // attempt to refresh any stale cognito tokens and retrying before moving on to the standard error handler
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (err.extensions && err.extensions.error_code === "UNAUTHORIZED") {
          // If an unauthorized response returned, attempt to refresh tokens and retry.
          return new Observable<FetchResult>((observer) => {
            trySessionRefresh()
              .catch(() => {}) // Prevents errors from leaking during dev
              .finally(() => {
                // Whether or not successfully refreshed, forward responses to the next stage.
                // TODO: Eliminate use of non-null assertion
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const sub = forward!(operation).subscribe(observer);
                return () => {
                  sub.unsubscribe();
                };
              });
          });
        }
      }
    }
  }),

  // Standard error handling - if still unauthorized after the token refresh above,
  // clear any open session and redirect to the login screen (unless already on it or another public page).
  // Log any non-auth errors.
  onError((res) => {
    const { graphQLErrors, networkError, operation, forward } = res;

    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        logEvent("GraphQLError", {
          operation: operation?.operationName,
          errorMessage: err.message,
          errorCode: err.extensions?.error_code,
        });
      }
    }

    // Forward network errors on for retries
    if (networkError) {
      // If this is a 401 or 404, allow a retry as it may be transient
      if (
        "statusCode" in networkError &&
        [401, 404].includes(networkError.statusCode)
      ) {
        return forward(operation);
      } else {
        // Log other network errors
        logEvent("NetworkError", {
          operation: operation?.operationName,
          errorBody:
            "bodyText" in networkError ? networkError.bodyText : undefined,
          errorMessage: networkError.name + networkError.message,
          statusCode:
            "statusCode" in networkError ? networkError.statusCode : undefined,
        });
      }
    }
  }),

  // NOTE: `RetryLink` only works for network errors - and only 404/401s will
  // be redirected to retry.
  // 500s, etc will be handled immediately in the previous step.
  new RetryLink({
    attempts: {
      max: 2,
    },
  }),

  setContext((_operation, previousContext) => {
    const { headers } = previousContext;
    return {
      ...previousContext,
      headers: {
        ...headers,
        "x-tnd-client-platform": "web",
        "x-tnd-client-version": process.env.REACT_APP_VERSION,
      },
    };
  }),

  // For all "standard" graphql calls, attempt to fetch the current cognito user first
  // so that any expired tokens will be refreshed.
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind !== "OperationDefinition" ||
        definition.operation !== "subscription"
      );
    },
    new ApolloLink((operation, forward) => {
      return new Observable<FetchResult>((observer) => {
        // Calling `getCurrentAuthenticatedUser` before each graphql call
        // ensures Cognito tokens are refreshed so we won't get unexpected auth errors.
        getCurrentAuthenticatedUser(false)
          .catch(() => {}) // Prevents errors from leaking during dev
          .finally(() => {
            // TODO: Eliminate use of non-null assertion
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const subscription = forward!(operation).subscribe(observer);
            return () => {
              subscription.unsubscribe();
            };
          });
      });
    }),
  ),

  // Provide a websocket connection for graphql subscriptions.
  // It has been configured above to attempt to refresh tokens on disconnect.
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    new WebSocketLink(socketClient),
    new BatchHttpLink({
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
      credentials: "include",
    }),
  ),
]);

// Plot / group ids are not currently unique globally, only to a plot group / execution.
// Without this, Apollo will cache plot details from previously loaded charts and things will look real weird!
const CACHE_SKIP_TYPES: string[] = [
  "PlotInfo",
  "PlotArrowInfo",
  "PlotCharInfo",
  "PlotShapeInfo",
  "HLineInfo",
  "FillInfo",
  "ScriptPlotDefinition",
  "ScriptPlotGroup",
  "ScriptPlotPalette",
];

// Helper function to merge new items into a list with connection based pagination
const paginationMerge = (
  existing: any,
  incoming: any,
  mergeArugments: { args: Record<string, any> | null },
) => {
  const args = mergeArugments.args;
  // only use the new results since this assumes that
  // the merge is not for a pagination but rather for
  // a refetch (usually for changing the sort direction)
  if (args && !args?.after) {
    const socketCursor = incoming?.edges[0]?.cursor;

    // extra check for if the new results are not from a web socket
    if (socketCursor !== "FRONT" && socketCursor !== "BACK") {
      return incoming;
    }
  } else if (
    incoming?.edges?.length &&
    existing?.edges?.length &&
    incoming.edges[incoming.edges.length - 1].cursor === "BACK" &&
    incoming.edges[0].cursor === "BACK" &&
    JSON.stringify(incoming.edges[incoming.edges.length - 1].node) !==
      JSON.stringify(existing.edges[existing.edges.length - 1].node)
  ) {
    return {
      edges: existing.edges.concat(incoming.edges),
      pageInfo: existing.pageInfo,
    };
  } else if (
    incoming?.edges?.length &&
    existing?.edges?.length &&
    incoming.edges[incoming.edges.length - 1].cursor === "FRONT" &&
    incoming.edges[0].cursor === "FRONT" &&
    JSON.stringify(incoming.edges[0].node) !==
      JSON.stringify(existing.edges[0].node)
  ) {
    return {
      edges: incoming.edges.concat(existing.edges),
      pageInfo: existing.pageInfo,
    };
  } else if (
    !incoming?.edges ||
    (existing?.edges?.length &&
      incoming?.edges?.length &&
      existing?.edges[existing.edges.length - 1]?.cursor ===
        incoming?.edges[incoming.edges.length - 1]?.cursor)
  ) {
    return existing;
  } else if (
    !existing?.edges ||
    (existing?.pageInfo &&
      !existing.pageInfo.hasNextPage &&
      incoming?.edges?.length)
  ) {
    return incoming;
  }

  return {
    edges: existing.edges.concat(incoming.edges),
    pageInfo: incoming.pageInfo,
  };
};

const typePolicies: Record<string, TypePolicy> = {
  // Explicitly overwrite nested measurements object to avoid warnings / unexpected behaviour
  Execution: {
    fields: {
      measurements: {
        merge: true,
      },
      logsConnection: {
        keyArgs: false,
        merge: paginationMerge,
      },
      orders: {
        keyArgs: false,
        merge: paginationMerge,
      },
      tradesConnection: {
        keyArgs: false,
        merge: paginationMerge,
      },
      subscriptionExecutions: {
        keyArgs: ["first", "sort", "filters"],
        merge: paginationMerge,
      },
      packExecutions: {
        keyArgs: ["first", "sort", "filters"],
        merge: paginationMerge,
      },
    },
  },

  Multivariant: {
    fields: {
      tests: {
        keyArgs: ["first", "sort", "filters"],
        merge: paginationMerge,
      },
    },
  },

  SharedExecution: {
    keyFields: ["shareToken"],
    fields: {
      measurements: {
        merge: true,
      },
      tradesConnection: {
        keyArgs: false,
        merge: paginationMerge,
      },
    },
  },

  ScriptRevision: {
    fields: {
      parameters: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },

  User: {
    merge: true,
    fields: {
      invoices: {
        keyArgs: ["first", "filter"],
        merge: paginationMerge,
      },
      subscriptions: {
        keyArgs: ["first", "filter"],
        merge: paginationMerge,
      },
      scripts: {
        keyArgs: ["first", "sort", "filters"],
        merge: paginationMerge,
      },
      batchTests: {
        keyArgs: ["first", "sort", "filters"],
        merge: paginationMerge,
      },
      executions: {
        keyArgs: ["first", "sort", "filters"],
        merge: paginationMerge,
      },
    },
  },

  PublicSyndication: {
    // TODO: resolve this later. This is just a mitigation for normalization breaking the publicSyndications list query
    keyFields: false,
    fields: {
      updates: {
        keyArgs: false,
        merge: paginationMerge,
      },
      trades: {
        keyArgs: false,
        merge: paginationMerge,
      },
    },
  },

  // Configure specific cache redirects to reuse data between eg. list and single views
  Query: {
    fields: {
      publicScriptList: {
        keyArgs: ["first", "sort", "filters"],
        merge: paginationMerge,
      },
      creators: {
        keyArgs: ["first", "sort", "filters"],
        merge: paginationMerge,
      },
      publicBots: {
        keyArgs: ["first", "sort", "filters"],
        merge: paginationMerge,
      },
    },
  },
};

// Inject all the skipped types so they are not cached
for (let type of CACHE_SKIP_TYPES) {
  typePolicies[type] = {
    keyFields: false,
  };
}

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({ typePolicies }),
  resolvers: {
    Query: {
      blank: () => null, // A little trick to redirect query hooks we don't want away from the network
    },
  },
});
window.apolloClient = client;
const TunedApolloProvider = ({ children }: { children: React.ReactNode }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default TunedApolloProvider;
