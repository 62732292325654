import React from "react";
import { StyleSheet, css } from "aphrodite";
import colors from "styles/colors";

type Props = Readonly<{
  companyName?: string | null;
  nickname?: string | null;
}>;

function BotCreatorNameField({ companyName, nickname }: Props) {
  return (
    <div className={css(styles.field)}>
      <span>{companyName ?? nickname ?? "--"}</span>
    </div>
  );
}

const styles = StyleSheet.create({
  field: {
    color: colors.steel,
  },
});

export default BotCreatorNameField;
