import Modal from "core/modal/Modal";
import { StyleSheet, css } from "aphrodite";
import invariant from "invariant";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import colors from "styles/colors";
import Button from "core/button/Button";
import MaterialButton from "@material-ui/core/Button";
import copyImg from "images/copy.svg";
import { useTranslation } from "react-i18next";
import copy from "copy-to-clipboard";
import { useAlertContext } from "./AlertContext";
import { useBoolean } from "helpers/hooks";
import { LinkMetadata } from "helpers/urls";
import { CircularProgress } from "@material-ui/core";
import { useReferralContext } from "./ReferralContext";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

export const CREATE_SHORT_LINK = gql`
  mutation CreateShortLink($in: CreateShortLinkInput!) {
    createShortLink(link: $in) {
      url
    }
  }
`;

export type OpenProps = {
  // Url to copy. Note that fpr parameter is added implicitly.
  link: string;
  title: string;
  subtitle: string;
  metadata: LinkMetadata;
};

type Context = {
  open: (props: OpenProps) => void;
};

const ShareLinkContext = createContext<Context | undefined>(undefined);

export function useShareLinkModal() {
  const context = useContext(ShareLinkContext);
  invariant(
    context != null,
    "Component is not a child of ShareLinkModalProvider",
  );
  return context;
}

type Props = {
  children: React.ReactNode;
};

export function ShareLinkModalProvider(props: Props) {
  const { children } = props;

  const [openProps, setOpenProps] = useState<OpenProps>();

  const hide = useCallback(() => {
    setOpenProps(undefined);
  }, []);

  return (
    <ShareLinkContext.Provider value={{ open: setOpenProps }}>
      {openProps != null && (
        <ShareLinkModal
          title={openProps.title}
          subtitle={openProps.subtitle}
          link={openProps.link}
          metadata={openProps.metadata}
          onClose={hide}
        />
      )}
      {children}
    </ShareLinkContext.Provider>
  );
}

type ModalProps = {
  onClose: () => void;
} & OpenProps;

function ShareLinkModal(props: ModalProps) {
  const { link, title, subtitle, metadata, onClose } = props;
  const [createShortLink] = useMutation(CREATE_SHORT_LINK);
  const { t } = useTranslation();
  const { setAlert } = useAlertContext();
  const inputRef = useRef(null);
  const [hasError, showError, hideError] = useBoolean(false);

  const { tryGetOrCreatePromoterReferralCode } = useReferralContext();

  const [linkToCopy, setLinkToCopy] = useState<string>();

  useEffect(() => {
    async function generateLink() {
      const referralCode = await tryGetOrCreatePromoterReferralCode();
      try {
        const shortUrl = await createShortLink({
          variables: {
            in: {
              link,
              title: metadata.title,
              description: metadata.description,
              imageUrl: metadata.imageUrl,
            },
          },
        });
        let tempLink = shortUrl.data.createShortLink.url ?? link;
        const operator = shortUrl.data.createShortLink.url ? "?" : "&";
        tempLink += referralCode ? `${operator}fpr=${referralCode}` : "";
        setLinkToCopy(tempLink);
      } catch (error) {
        setLinkToCopy(link + (referralCode ? `&fpr=${referralCode}` : ""));
      }
    }
    generateLink();
  }, [createShortLink, link, metadata, tryGetOrCreatePromoterReferralCode]);

  const onClickCopy = () => {
    const success = copy(linkToCopy ?? "");
    if (success) {
      hideError();
      setAlert({
        severity: "success",
        message: t("terminal.copied_share_link"),
      });
    } else {
      showError();
    }
  };

  const loading = linkToCopy == null;

  return (
    <Modal
      isOpen={link != null}
      manualClose
      onRequestClose={onClose}
      className={css(styles.container)}
    >
      <div className={css(styles.title)}>{title}</div>
      <div className={css(styles.subtitle)}>{subtitle}</div>
      <div className={css(styles.formContainer)}>
        {loading ? (
          <div className={css(styles.loading)}>
            <CircularProgress />
          </div>
        ) : (
          <div className={css(styles.form)}>
            <input
              readOnly
              contentEditable={false}
              value={linkToCopy ?? undefined}
              className={css(styles.input)}
              ref={inputRef}
              onFocus={(event) => event.target.select()}
            />
            <MaterialButton
              variant="contained"
              className={css(styles.copyButton)}
              onClick={onClickCopy}
            >
              <div>
                <img
                  src={copyImg}
                  alt="Copy button"
                  color={colors.lightGray}
                  height={12}
                  width={12}
                  style={{ marginRight: 8 }}
                />
                {t("terminal.copy_link")}
              </div>
            </MaterialButton>
          </div>
        )}
        {hasError && (
          <div className={css(styles.error)}>
            {t("error.copy_clipboard_failure")}
          </div>
        )}
      </div>

      <Button onClick={onClose}>{t("common.done")}</Button>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 600,
    display: "flex",
    backgroundColor: colors.offBlack,
    flexDirection: "column",
    padding: 70,
    paddingTop: 60,
    borderRadius: 5,
  },
  error: {
    color: colors.red,
  },
  formContainer: {
    paddingBottom: 70,
  },
  form: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: 14,
    width: "100%",
  },
  exchange: {
    margin: "0 30px",
  },
  input: {
    flexGrow: 1,
    height: 22,
    backgroundColor: colors.offBlack,
    color: colors.offWhite,
    fontSize: 14,
    border: `1px solid ${colors.offWhite}`,
    borderRadius: 5,
    padding: 9,
    marginRight: 11,
  },
  copyButton: {
    fontStyle: "normal",
    height: 40,
    textTransform: "none",
    backgroundColor: colors.pine,
    color: colors.lightGray,
  },
  title: {
    color: colors.lightGray,
    fontWeight: 400,
    fontSize: "26px",
    lineHeight: "42px",
    marginBottom: 10,
  },
  subtitle: {
    color: colors.offWhite,
    fontSize: "16px",
    lineHeight: "19px",
    paddingBottom: 26,
  },
  loading: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
});
