import React, { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CONFIG, Locale } from "i18n/i18n";
import "./theme.scss";

const NOTO_THEME = "noto-theme";
const ROBOTO_THEME = "roboto-theme";

type Props = Readonly<{
  children: ReactNode;
}>;

function Theme({ children }: Props) {
  const { i18n } = useTranslation();

  const theme =
    CONFIG[i18n.language as Locale].font === "roboto"
      ? ROBOTO_THEME
      : NOTO_THEME;

  useEffect(() => {
    if (theme === NOTO_THEME) {
      document.documentElement.classList.remove(ROBOTO_THEME);
      document.documentElement.classList.add(NOTO_THEME);
    } else {
      document.documentElement.classList.add(ROBOTO_THEME);
      document.documentElement.classList.remove(NOTO_THEME);
    }
  }, [theme]);

  return <>{children}</>;
}

export default Theme;
