export const rebillyErrorsMapper = (code: string, t: any) => {
  switch (code) {
    case "REBILLY_CANCEL_SUBSCRIPTION":
      return t("error.rebilly_cancel_subscription");
    case "REBILLY_FAILED_UPGRADE":
      return t("error.rebilly_failed_upgrade");
    case "REBILLY_GETTING_INVOICES":
      return t("error.rebilly_get_invoices");
    case "REBILLY_ALREADY_PURCHASED":
      return t("error.rebilly_product_already_purchased");
    case "REBILLY_MONTH_YEAR":
      return t("error.rebilly_change_month_year");
    case "REBILLY_ACTIVE_CHANGE_PLAN":
      return t("error.rebilly_active_change_plan");
    case "INVOICE_WAITING":
      return t("error.rebilly_invoice_waiting");

    default:
      return null;
  }
};
