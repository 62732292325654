import React, {
  useState,
  createContext,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import invariant from "invariant";
import { useQuery, useMutation } from "@apollo/client";
import {
  GetColumnsPresetsData,
  GET_COLUMNS_PRESETS,
} from "../../graphql/queries";
import {
  CREATE_COLUMNS_PRESET,
  DELETE_COLUMNS_PRESET,
  RENAME_COLUMNS_PRESET,
  UPDATE_COLUMNS_PRESET,
} from "../../graphql/mutations";
import { ColumnsPreset, ID } from "../../graphql/schema";
import { useAlertContext } from "../AlertContext";
import { useUserContext } from "../UserContext";
import { useBoolean } from "../../helpers/hooks";
import { logEvent } from "../../helpers/analytics";

export const COLUMNS_PRESET_ID_KEY = "selectedColumnsPresetId";
export const BOT_COLUMNS_PRESET_ID_KEY = "selectedBotColumnsPresetId";

interface Context {
  columnsPresets: ColumnsPreset[];
  selectedColumnsPreset: ColumnsPreset | null;
  setColumnsPreset: (preset: ColumnsPreset | null) => void;
  selectedBotColumnsPreset: ColumnsPreset | null;
  setBotColumnsPreset: (preset: ColumnsPreset | null) => void;
  onCreateColumnsPreset: (
    name: string,
    columns: string,
    cacheKey: string,
    callback: (col: ColumnsPreset | null) => void,
  ) => void;
  onDeleteColumnsPreset: (id: ID) => void;
  onRenameColumnsPreset: (
    id: ID,
    name: string,
    cacheKey: string,
    callback: (col: ColumnsPreset | null) => void,
  ) => void;
  onUpdateColumnsPreset: (
    id: ID,
    columns: string,
    cacheKey: string,
    callback: (col: ColumnsPreset | null) => void,
  ) => void;
  isCustomizerOpen: boolean;
  openCustomizer: () => void;
  closeCustomizer: () => void;
  isBatchTestCustomizerOpen: boolean;
  openBatchTestCustomizer: () => void;
  closeBatchTestCustomizer: () => void;
}

export const ColumnsPresetsContext = createContext<Context | undefined>(
  undefined,
);

export function useColumnsPresetsContext() {
  const context = useContext(ColumnsPresetsContext);
  invariant(
    context != null,
    "Component is not a child of ColumnsPresetsContext provider",
  );
  return context;
}

export function ColumnsPresetsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation();

  const { addError } = useAlertContext();
  const { isLoggedIn } = useUserContext();

  const { data, refetch } = useQuery<GetColumnsPresetsData>(
    GET_COLUMNS_PRESETS,
    {
      skip: !isLoggedIn,
    },
  );
  const columnsPresets = useMemo(() => data?.columnsPresets ?? [], [data]);

  const [createColumnsPreset] = useMutation(CREATE_COLUMNS_PRESET, {
    onCompleted: () => {
      refetch();
      logEvent("CreatedColumnsPreset");
    },
    onError: () => {
      addError(t("eror.failed_to_create_column_preset"));
    },
  });
  const [deleteColumnsPreset] = useMutation(DELETE_COLUMNS_PRESET, {
    onCompleted: () => {
      refetch();
      localStorage.removeItem(COLUMNS_PRESET_ID_KEY);
      localStorage.removeItem(BOT_COLUMNS_PRESET_ID_KEY);
      setColumnsPresetInner(null);
      logEvent("DeletedColumnsPreset");
    },
    onError: () => {
      addError(t("error.failed_to_delete_column_preset"));
    },
  });
  const [renameColumnsPreset] = useMutation(RENAME_COLUMNS_PRESET, {
    onCompleted: () => {
      refetch();
      logEvent("RenamedColumnsPreset");
    },
    onError: () => {
      addError(t("error.failed_to_rename_column_preset"));
    },
  });
  const [updateColumnsPreset] = useMutation(UPDATE_COLUMNS_PRESET, {
    onCompleted: () => {
      refetch();
      logEvent("UpdatedColumnsPreset");
    },
    onError: () => {
      addError(t("error.failed_to_update_column_preset"));
    },
  });

  const [isCustomizerOpen, openCustomizer, closeCustomizer] = useBoolean(false);
  const [selectedColumnsPreset, setColumnsPresetInner] =
    useState<ColumnsPreset | null>(null);
  const setColumnsPreset = useCallback((preset: ColumnsPreset | null) => {
    setColumnsPresetInner(preset);
    if (preset) {
      localStorage.setItem("executionColumns", preset.columns);
      localStorage.setItem(COLUMNS_PRESET_ID_KEY, preset.id);
    } else {
      localStorage.removeItem(COLUMNS_PRESET_ID_KEY);
    }
  }, []);

  const [selectedBotColumnsPreset, setBotColumnsPresetInner] =
    useState<ColumnsPreset | null>(null);
  const setBotColumnsPreset = useCallback((preset: ColumnsPreset | null) => {
    setBotColumnsPresetInner(preset);
    if (preset) {
      localStorage.setItem("publicBotColumns", preset.columns);
      localStorage.setItem(BOT_COLUMNS_PRESET_ID_KEY, preset.id);
    } else {
      localStorage.removeItem(BOT_COLUMNS_PRESET_ID_KEY);
    }
  }, []);

  const [
    isBatchTestCustomizerOpen,
    openBatchTestCustomizer,
    closeBatchTestCustomizer,
  ] = useBoolean(false);

  useEffect(() => {
    if (
      columnsPresets?.length &&
      !!localStorage.getItem(COLUMNS_PRESET_ID_KEY) &&
      !selectedColumnsPreset
    ) {
      setColumnsPresetInner(
        columnsPresets.find(
          (preset: ColumnsPreset) =>
            preset.id === localStorage.getItem(COLUMNS_PRESET_ID_KEY),
        ) ?? null,
      );
    }
  }, [columnsPresets, selectedColumnsPreset]);

  useEffect(() => {
    if (
      columnsPresets?.length &&
      !!localStorage.getItem(BOT_COLUMNS_PRESET_ID_KEY) &&
      !selectedBotColumnsPreset
    ) {
      setBotColumnsPresetInner(
        columnsPresets.find(
          (preset: ColumnsPreset) =>
            preset.id === localStorage.getItem(BOT_COLUMNS_PRESET_ID_KEY),
        ) ?? null,
      );
    }
  }, [columnsPresets, selectedBotColumnsPreset]);

  const onCreateColumnsPreset = useCallback(
    async (
      name: string,
      columns: string,
      cacheKey: string,
      callback: (col: ColumnsPreset | null) => void,
    ) => {
      const created = await createColumnsPreset({
        variables: { name, columns },
      });

      if (created?.data?.createColumnsPreset?.id) {
        localStorage.setItem(cacheKey, created.data.createColumnsPreset.id);
        callback(
          columnsPresets.find(
            (preset: ColumnsPreset) =>
              preset.id === created.data.createColumnsPreset.id,
          ) ?? null,
        );
      }
    },
    [createColumnsPreset, columnsPresets],
  );

  const onDeleteColumnsPreset = useCallback(
    async (id: ID) => {
      await deleteColumnsPreset({ variables: { id } });
    },
    [deleteColumnsPreset],
  );

  const onRenameColumnsPreset = useCallback(
    async (
      id: ID,
      name: string,
      cacheKey: string,
      callback: (col: ColumnsPreset | null) => void,
    ) => {
      const updated = await renameColumnsPreset({ variables: { id, name } });

      // do this here instead of onCompleted since it requires the cacheKey and callback
      if (updated?.data?.renameColumnsPreset?.id) {
        localStorage.setItem(cacheKey, updated.data.renameColumnsPreset.id);
        callback(
          columnsPresets.find(
            (preset: ColumnsPreset) =>
              preset.id === updated.data.renameColumnsPreset.id,
          ) ?? null,
        );
      }
    },
    [renameColumnsPreset, columnsPresets],
  );

  const onUpdateColumnsPreset = useCallback(
    async (
      id: ID,
      columns: string,
      cacheKey: string,
      callback: (col: ColumnsPreset | null) => void,
    ) => {
      const updated = await updateColumnsPreset({
        variables: { id, columns },
      });

      // do this here instead of onCompleted since it requires the cacheKey and callback
      if (updated?.data?.updateColumnsPreset?.id) {
        localStorage.setItem(cacheKey, updated.data.updateColumnsPreset.id);
        const updatedPreset = columnsPresets.find(
          (preset: ColumnsPreset) =>
            preset.id === updated.data.updateColumnsPreset.id,
        );

        if (updatedPreset) {
          callback({
            ...updatedPreset,
            columns,
          });
        }
      }
    },
    [updateColumnsPreset, columnsPresets],
  );

  return (
    <ColumnsPresetsContext.Provider
      value={{
        columnsPresets,
        selectedColumnsPreset,
        setColumnsPreset,
        selectedBotColumnsPreset,
        setBotColumnsPreset,
        onCreateColumnsPreset,
        onDeleteColumnsPreset,
        onRenameColumnsPreset,
        onUpdateColumnsPreset,
        isCustomizerOpen,
        openCustomizer,
        closeCustomizer,
        isBatchTestCustomizerOpen,
        openBatchTestCustomizer,
        closeBatchTestCustomizer,
      }}
    >
      {children}
    </ColumnsPresetsContext.Provider>
  );
}
