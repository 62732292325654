import React, { createContext, useCallback, useContext, useState } from "react";
import invariant from "invariant";
import { useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import Modal from "core/modal/Modal";
import closeImg from "images/close.svg";
import ColorAnchor from "core/element/ColorAnchor";
import Button from "core/button/Button";
import colors from "styles/colors";

type Context = {
  setInformation: (information?: ModalInformation) => void;
  close: () => void;
};

const InformationModalContext = createContext<Context | undefined>(undefined);

export function useInformationModal() {
  const context = useContext(InformationModalContext);
  invariant(
    context != null,
    "Component is not a child of InformationModalProvider",
  );
  return context;
}

type ModalFooterLink = {
  url: string;
  label: string;
};

type ModalInformation = {
  title: string;
  messages: string[];
  footerLink?: ModalFooterLink;
};

type Props = {
  children: React.ReactNode;
};

export function InformationModalProvider({ children }: Props) {
  const [information, setInformation] = useState<ModalInformation>();

  const close = useCallback(() => {
    setInformation(undefined);
  }, []);

  return (
    <InformationModalContext.Provider value={{ setInformation, close }}>
      <InformationModal information={information} onClose={close} />
      {children}
    </InformationModalContext.Provider>
  );
}

type ModalProps = Readonly<{
  information?: ModalInformation;
  onClose: () => void;
}>;

function InformationModal({ information, onClose }: ModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={!!information}
      manualClose={false}
      onRequestClose={onClose}
      className={css(styles.container)}
    >
      <img
        className={css(styles.close)}
        onClick={onClose}
        src={closeImg}
        alt="Close"
        width={12}
        height={12}
      />
      <div className={css(styles.title)}>{information?.title}</div>
      <div className={css(styles.body)}>
        {information?.messages?.map((message) => (
          <p key={message}>{message}</p>
        ))}
      </div>
      {information?.footerLink && (
        <div className={css(styles.footer)}>
          <ColorAnchor
            href={information.footerLink.url}
            target="_blank"
            rel="noopener noreferrer"
            color="lightGrayUnderline"
          >
            {information.footerLink.label}
          </ColorAnchor>
        </div>
      )}
      <Button onClick={onClose}>{t("common.continue")}</Button>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: "70px",
    borderRadius: 15,
    width: 620,
    overflow: "hidden",
    boxSizing: "border-box",
    "@media (max-width: 767.98px)": {
      padding: "50px 20px",
    },
  },
  close: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    ":hover": {
      filter: "grayscale(100%) brightness(232%)",
    },
  },
  title: {
    fontWeight: 600,
    fontSize: "32px",
    color: colors.white,
  },
  body: {
    fontWeight: 400,
    fontSize: "15px",
    color: colors.lightGray,
    marginTop: "40px",
    marginBottom: "30px",
  },
  footer: {
    marginBottom: "25px",
  },
});
