import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";
import invariant from "invariant";
import { useLazyQuery } from "@apollo/client";
import {
  GET_BOT_CONTROLS,
  GET_EXECUTION_CONTROLS,
  GET_SHARED_EXECUTION_CONTROLS,
} from "../../graphql/queries";
import {
  ScriptPlotDefinition,
  ScriptPlotPalette,
} from "../../graphql/schema/plots";
import {
  CurrencyPair,
  Exchange,
  Int,
  CandleType,
  Datestr,
  ExecutionType,
  CurrencyPairDetails,
} from "../../graphql/schema";
import {
  SelectedExecution,
  useSelectedExecutionContext,
} from "../SelectedExecutionContext";
import { useUserContext } from "../UserContext";
import { useExchangeContext } from "../ExchangeContext";
import { useTerminalPortfolioContext } from "../TerminalPortfolioContext";
import TraderatorChartAPI from "../../components/chart/data/TraderatorChartAPI";
import { resolutionToMins } from "../../helpers/resolution";
import { useChanged } from "../../helpers/hooks";
import { isBacktest } from "../../helpers/executions";

// These properties must all be present and updated at once if we are displaying a chart for an execution.
export interface ExecutionDetails {
  name: string;
  algoName: string;
  executionId: string;
  isMargin: boolean;
  plotDefinition: ScriptPlotDefinition;
  plotPalettes: ScriptPlotPalette[];
  type: ExecutionType;
  isGuest: boolean;
  isPublicBot: boolean;
  startTime?: Date;
  endTime?: Date;
}

export interface ChartControls {
  currencyPair: CurrencyPair;
  currencyPairDetails?: CurrencyPairDetails;
  exchange: Exchange;
  candleSize: Int | string;
  candleType: CandleType;
  rangeStart?: Datestr;
  rangeEnd?: Datestr;
  executionDetails?: ExecutionDetails;
}

interface Context {
  chartControls: ChartControls;
  setChartControls: (controls: ChartControls) => void;
  updateChartControlsExchangeAndPair: (
    exchange: Exchange,
    currencyPairDetails: CurrencyPairDetails,
  ) => void;
  refreshControls: () => any;
  clearChartExecution: () => void;
  setChartExecutionDetails: (ex?: SelectedExecution) => void;
}

const LOCAL_STORAGE_KEY = "chart_controls";
const getDefaultChartControls = (): ChartControls => {
  try {
    const savedValue = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedValue) {
      let savedCtrls = JSON.parse(savedValue) as ChartControls;

      // On initial pageload, we don't want to keep execution-specific details.
      // This leads to unnecessary chart refreshes, etc.
      delete savedCtrls.executionDetails;
      delete savedCtrls.rangeStart;
      delete savedCtrls.rangeEnd;

      return savedCtrls;
    }
  } catch {
    /* fall back to default */
  }

  return {
    currencyPair: "BTC_USDT",
    currencyPairDetails: {
      base: "BTC",
      exchange: "BINANCE",
      id: "BINANCE:BTC_USDT",
      orderRules: {
        minOrderAmount: "0.00000100",
        maxOrderAmount: "9000.00000000",
        minOrderValue: "10.00000000",
      },
      pair: "BTC_USDT",
      positionCurrency: "BTC",
      quote: "USDT",
      settleCurrency: "USDT",
    },
    exchange: "BINANCE",
    candleSize: "H",
    candleType: "STANDARD",
  };
};

export const ChartControlsContext = createContext<Context | undefined>(
  undefined,
);

export function useChartControlsContext() {
  const context = useContext(ChartControlsContext);
  invariant(
    context != null,
    "Component is not a child of ChartControlsContext provider",
  );
  return context;
}

const NOOP = () => {};

const MAX_INITIAL_BARS = 100;

/**
 * Given a date range, alter the start date so that it does not surpass `maxBars`.
 * We do this as some executions can have hundreds of thousands of bars worth of data
 * and trying to load all that at once would hang the browser for awhile.
 *
 * @param origStart The originally provided start date of the range
 * @param origEnd The originally provided end date of the range
 * @param resolution The size of each each bar in minutes
 * @param maxBars The maximum number of bars the range can contain
 * @returns The updated start date for the range to respect the size limit, or the original if it is fine
 */
function limitedStartDate(
  origStart: Date,
  origEnd: Date,
  resolution: number,
  maxBars: number,
): Date {
  const resMillis = resolution * 60 * 1000; // Number of milliseconds in each bar
  const maxMillis = resMillis * maxBars; // The maximum number of milliseconds allowed in the range
  const diffMillis = origEnd.getTime() - origStart.getTime(); // Number of milliseconds between the start and end range

  if (diffMillis > maxMillis) {
    // Round to nearest bar within the max range
    const newMillis =
      Math.round((origEnd.getTime() - maxMillis) / resMillis) * resMillis;
    return new Date(newMillis);
  } else {
    return origStart;
  }
}

function useContextObject(): Context {
  const { selectedExecutionDetails } = useSelectedExecutionContext();
  const { isMargin, loading: isExchangeLoading } = useExchangeContext();
  const {
    setChartType,
    isCandleChartHidden,
    hideCandleChart,
    showCandleChart,
  } = useTerminalPortfolioContext();
  const { isGuest } = useUserContext();

  const chartAPI = useMemo(
    () => new TraderatorChartAPI(process.env.REACT_APP_API_URL || ""),
    [],
  );

  const [chartExecutionDetails, setChartExecutionDetails] =
    useState<SelectedExecution>();

  useEffect(() => {
    if (selectedExecutionDetails?.id) {
      setChartExecutionDetails(selectedExecutionDetails);
    }
  }, [selectedExecutionDetails]);

  const clearChartExecution = useCallback(
    () => setChartExecutionDetails(undefined),
    [],
  );

  const [chartControls, setChartControls] = useState<ChartControls>(
    getDefaultChartControls,
  );

  const updateChartControlsExchangeAndPair = useCallback(
    (exchange: Exchange, currencyPairDetails: CurrencyPairDetails) => {
      setChartControls({
        ...chartControls,
        exchange,
        currencyPairDetails,
        currencyPair: currencyPairDetails.pair,
      });
    },
    [chartControls],
  );

  useEffect(() => {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(chartControls),
    );
  }, [chartControls]);

  // We can cache these up here so they can be used for triggering
  // `getControls()` both in the effect hook below, and in the `refreshControls` callback.
  const query = isGuest
    ? GET_SHARED_EXECUTION_CONTROLS
    : chartExecutionDetails?.type === "bot"
    ? GET_BOT_CONTROLS
    : GET_EXECUTION_CONTROLS;

  // Caching this in a `useMemo` to avoid unnecessary re-renders
  const queryVars = useMemo(
    () =>
      chartExecutionDetails?.id
        ? isGuest
          ? { shareToken: chartExecutionDetails.id }
          : {
              id: chartExecutionDetails.id,
            }
        : {},
    [chartExecutionDetails, isGuest],
  );

  // NOTE: If we let this query trigger itself on re-render (eg. the `useQuery` hook),
  //       we end up making unnecessary duplicate requests because the queries trigger faster
  //       than the first result can be returned / cached.
  //       We avoid this by manually triggering the query when necessary.
  const [getControls, { data, loading: controlsLoading }] = useLazyQuery<
    any,
    any
  >(query);

  // If a new execution's controls (or exchange data) is loading, don't update the executionDetails.
  // This prevents an unnecessary flicker (eg. temporarily setting the execution to `undefined`)
  const [execution, setExecution] = useState<any | undefined>(undefined);
  useEffect(() => {
    // don't set execution for multi coin packs for now
    if (
      !controlsLoading &&
      !isExchangeLoading &&
      !data?.execution?.multiCoinCurrency &&
      !data?.publicSyndication?.multiCoinCurrency &&
      !data?.sharedExecution?.multiCoinCurrency
    ) {
      setExecution(
        isGuest
          ? data?.sharedExecution
          : chartExecutionDetails?.type === "bot"
          ? data?.publicSyndication
          : data?.execution,
      );
    }
  }, [
    controlsLoading,
    isExchangeLoading,
    isGuest,
    chartExecutionDetails,
    data,
  ]);

  useEffect(() => {
    if (
      data?.execution?.multiCoinCurrency ||
      data?.sharedExecution?.multiCoinCurrency ||
      data?.publicSyndication?.multiCoinCurrency
    ) {
      if (selectedExecutionDetails?.id && chartExecutionDetails?.id) {
        hideCandleChart();
      } else {
        showCandleChart();
      }
    } else if (isCandleChartHidden) {
      showCandleChart();
    }
  }, [
    data?.execution?.multiCoinCurrency,
    data?.sharedExecution?.multiCoinCurrency,
    data?.publicSyndication?.multiCoinCurrency,
    selectedExecutionDetails,
    chartExecutionDetails,
    setChartType,
    hideCandleChart,
    isCandleChartHidden,
    showCandleChart,
  ]);

  useEffect(() => {
    if (chartExecutionDetails?.id) {
      // If an executionId is now provided, trigger the query
      getControls({ variables: queryVars });
    } else {
      // If it has just been unset, clear the cached execution
      setExecution(undefined);
    }
  }, [chartExecutionDetails, getControls, setExecution, queryVars]);

  const {
    currencyPair,
    currencyPairDetails,
    exchange,
    candleSize,
    candleType,
  } = chartControls;
  const controlsHaveExecution = !!chartControls.executionDetails;

  const [plotRangeLoading, setPlotRangeLoading] = useState(false);
  const [plotEnd, setPlotEnd] = useState<Date | null | undefined>();

  const plotEndChanged = useChanged(plotEnd);
  const executionChanged = useChanged(execution);

  // Storing a second layer of state for the execution data so that
  // we can wait until an attempt is made to fetch plot ranges before
  // updated the chart controls.
  // Without this, the chart experiences unnecessary reloads.
  const [finalExecution, setFinalExecution] = useState<any | undefined>();

  // Fetch the stored plot range for this execution, so we can
  // prevent unnecessary fetches for out-of-range data.
  useEffect(() => {
    // Wait until relevant control data is loaded before doing this
    if (!executionChanged) return;

    if (!execution) {
      // If a finalExecution record is stored, we can now clear it
      if (finalExecution) setFinalExecution(undefined);
      setPlotEnd(undefined);
      return;
    }

    // If this is a shared execution use the token as an id
    const storedEx = execution.shareToken
      ? {
          ...execution,
          id: execution.shareToken,
        }
      : execution;

    // We only need to fetch this for backtest + previews
    if (
      !(isBacktest(execution.type as ExecutionType) || execution.shareToken)
    ) {
      // No plot range to fetch, we can set finalExecution
      setFinalExecution(storedEx);
      setPlotEnd(null);
      return;
    }

    let firstPlotId: string | undefined;

    // setPlotRangeLoading(true);

    // Attempt to find a plot in the definition, which we can look up
    // to find the stored plot range.
    groupiter: for (const group of execution.plotDefinition?.groups ?? []) {
      for (const plot of group.plots ?? []) {
        firstPlotId = `${group.id}_${plot.id}`;
        break groupiter;
      }

      for (const plot of group.chars ?? []) {
        firstPlotId = `${group.id}_${plot.id}`;
        break groupiter;
      }

      for (const plot of group.shapes ?? []) {
        firstPlotId = `${group.id}_${plot.id}`;
        break groupiter;
      }

      for (const plot of group.arrows ?? []) {
        firstPlotId = `${group.id}_${plot.id}`;
        break groupiter;
      }
    }

    // If no plots set, don't try to load this
    if (!firstPlotId) {
      // No plot range to fetch, we can set finalExecution
      setFinalExecution(storedEx);
      setPlotEnd(null);
      return;
    }

    chartAPI.getPlotRange(execution.id, firstPlotId).then(
      (range) => {
        // TODO: Fix eslint error
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!range?.rangeEnd) {
          // TODO: Eliminate use of non-null assertion
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          setPlotEnd(new Date(range!.rangeEnd!));
        } else {
          setPlotEnd(null);
        }
        // After fetching the range, we can set finalExecution
        setFinalExecution(storedEx);
      },
      (err) => {
        setPlotEnd(null);
        // Set finalExecution so we can continue without a range
        setFinalExecution(storedEx);
      },
    );
  }, [
    setPlotEnd,
    chartAPI,
    execution,
    executionChanged,
    setPlotRangeLoading,
    finalExecution,
    setFinalExecution,
  ]);

  // Use this flag so that the following effect only occurs when finalExecution changes
  const finalExecutionChanged = useChanged(finalExecution);

  useEffect(() => {
    // If the loaded execution hasn't changed we don't want to run anything
    if (!finalExecutionChanged) return;

    // If there is no execution set, we want to clear the execution-specific details if they are set,
    // or do nothing if they are not.
    if (!finalExecution) {
      if (controlsHaveExecution) {
        setChartControls({
          currencyPair,
          currencyPairDetails,
          exchange,
          candleSize,
          candleType,
          // No executionDetails/rangeStart/rangeEnd
        });
      }
      return;
    }

    // If there is an execution, we want to update the chart controls with the execution details
    // and corresponding exchange/coinpair/candlesize
    const noScript = isGuest || !finalExecution.scriptDetails;

    const getChartName = () => {
      if (finalExecution?.type === "PREVIEW") {
        return `${finalExecution?.scriptDetails?.name} - version ${finalExecution?.scriptDetails?.version}`;
      } else if (
        !!finalExecution?.scriptDetails?.name &&
        !!finalExecution?.runNumber
      ) {
        return (
          finalExecution.scriptDetails?.name + " " + finalExecution.runNumber
        );
      } else {
        return (
          finalExecution.syndication?.name ??
          finalExecution.scriptName ??
          finalExecution.name ??
          ""
        );
      }
    };

    const name = getChartName();

    const newExecutionDetails: ExecutionDetails = {
      name,
      type: finalExecution.type,
      algoName: noScript ? "" : finalExecution.scriptDetails.name,
      executionId: finalExecution.id,
      plotDefinition: noScript ? { groups: [] } : finalExecution.plotDefinition,
      plotPalettes: noScript ? [] : finalExecution.plotPalettes,
      isMargin: isMargin(finalExecution.exchange),
      isGuest,
      isPublicBot: selectedExecutionDetails?.type === "bot",
    };

    const exEnded = !!finalExecution.endedAt;
    const exBacktest = isBacktest(finalExecution.type as ExecutionType);

    const [origStart, origEnd] = exBacktest
      ? [new Date(finalExecution.rangeStart), new Date(finalExecution.rangeEnd)]
      : [
          new Date(finalExecution.startedAt),
          exEnded ? new Date(finalExecution.endedAt) : new Date(),
        ];

    let [rangeStart, rangeEnd] = [
      limitedStartDate(
        origStart,
        origEnd,
        resolutionToMins(finalExecution.candleSize),
        MAX_INITIAL_BARS,
      ).toISOString(),
      origEnd.toISOString(),
    ];

    // Set the start time of this execution
    newExecutionDetails.startTime = origStart;

    // If this execution has ended, provide the correct end time
    if (exEnded) {
      if (exBacktest) {
        // If a backtest/preview, use the earlier of the specified rangeEnd or the endedAt time.
        // This is because if this task was run on the day of `rangeEnd`, it may not be complete.
        // let ended = new Date(execution.endedAt);
        // newExecutionDetails.endTime = (origEnd < ended) ? origEnd : ended;

        // If the plot end is earlier than the end date (but by less than a day),
        // that means at the time of execution there was not a full day of candles available.
        if (
          plotEnd &&
          (!newExecutionDetails.endTime ||
            newExecutionDetails.endTime.getTime() - plotEnd.getTime() <
              24 * 60 * 60_000)
        ) {
          newExecutionDetails.endTime = plotEnd;
        } else {
          // If no plotEnd is fetched, use the earlier of the specified rangeEnd or the endedAt time.
          // This is because if this task was run on the day of `rangeEnd`, it may not be complete.
          // `plotEnd` is more accurate but this is a sane fallback
          let ended = new Date(finalExecution.endedAt);
          newExecutionDetails.endTime = origEnd < ended ? origEnd : ended;
        }
      } else {
        // If this is an ended live run, use that end time
        newExecutionDetails.endTime = origEnd;
      }

      rangeStart = limitedStartDate(
        origStart,
        newExecutionDetails.endTime,
        resolutionToMins(finalExecution.candleSize),
        MAX_INITIAL_BARS,
      ).toISOString();
      rangeEnd = newExecutionDetails.endTime.toISOString();
    }
    setChartControls({
      executionDetails: newExecutionDetails,
      currencyPair: finalExecution.currencyPair,
      currencyPairDetails: finalExecution.currencyPairDetails,
      exchange: finalExecution.exchange,
      candleSize: finalExecution.candleSize,
      candleType: finalExecution.candleType,
      rangeStart,
      rangeEnd,
    });
  }, [
    isGuest,
    finalExecution,
    finalExecutionChanged,
    controlsHaveExecution,
    isMargin,
    currencyPair,
    currencyPairDetails,
    exchange,
    candleSize,
    candleType,
    plotEnd,
    plotEndChanged,
    plotRangeLoading,
    controlsLoading,
    selectedExecutionDetails,
  ]);

  return useMemo<Context>(() => {
    if (finalExecution) {
      return {
        chartControls,
        setChartControls,
        updateChartControlsExchangeAndPair,
        refreshControls: () => {
          getControls({
            variables: queryVars,
          });
        },
        clearChartExecution,
        setChartExecutionDetails,
      };
    } else {
      return {
        chartControls,
        setChartControls,
        updateChartControlsExchangeAndPair,
        refreshControls: NOOP,
        clearChartExecution,
        setChartExecutionDetails,
      };
    }
  }, [
    finalExecution,
    chartControls,
    clearChartExecution,
    getControls,
    updateChartControlsExchangeAndPair,
    queryVars,
  ]); //refetch]);
}

export const ChartControlsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <ChartControlsContext.Provider value={useContextObject()}>
    {children}
  </ChartControlsContext.Provider>
);
