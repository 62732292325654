import React from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { StyleSheet, css } from "aphrodite";
import { Execution } from "graphql/schema";
import { getTranslatedSyndicationSubscriptionStatus } from "helpers/formLabelUtils";
import colors from "styles/colors";

type Props = Readonly<{
  execution: Execution;
}>;

function ExecutionSubscriptionStatus({ execution }: Props) {
  const { t } = useTranslation();

  if (!execution.subscriptionStatus) return null;

  const id = "statusTooltip:" + execution.id;
  const subscriptionStatus =
    execution.subscriptionStatus === "NOT_SUBSCRIBED"
      ? "Expired"
      : execution.subscriptionStatus;

  return (
    <div className={css(styles.container)}>
      <div
        data-for={id}
        data-tip=""
        className={css(
          styles.statusCircle,
          styles[
            subscriptionStatus === "ACTIVE"
              ? "active"
              : subscriptionStatus === "CANCELLED"
              ? "cancelled"
              : subscriptionStatus === "Expired"
              ? "expired"
              : "pending"
          ],
        )}
      />
      <ReactTooltip id={id} effect="solid" className={css(styles.tooltip)}>
        {getTranslatedSyndicationSubscriptionStatus(
          execution.subscriptionStatus,
          t,
        )}
      </ReactTooltip>
      {getTranslatedSyndicationSubscriptionStatus(
        execution.subscriptionStatus,
        t,
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    textAlign: "left",
  },
  statusCircle: {
    width: "8px",
    height: "8px",
    borderRadius: "100%",
    margin: "auto 10px auto 5px",
    top: "6px",
    position: "relative",
    display: "inline-block",
    float: "left",
  },
  pending: {
    background: colors.statusPending,
  },
  active: {
    background: colors.statusRunning,
  },
  cancelled: {
    background: colors.statusCancelled,
  },
  expired: {
    background: colors.statusFailed,
  },
  tooltip: {
    opacity: 1,
    backgroundColor: colors.offBlack,
  },
});

export default React.memo(ExecutionSubscriptionStatus);
