import React, { useEffect, useCallback, useState, MouseEvent } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { gql, useSubscription } from "@apollo/client";
import { MultivariantsTable_MultivariantFragment } from "__generated__/graphql";
import { MULTIVARIANT_SUBSCRIPTION } from "graphql/queries";
import { MultivariantFilterColumn } from "graphql/schema";
import { useMultiSelectContext } from "contexts/MultiSelectContext";
import { useMultivariantColumnContext } from "contexts/executions/MultivariantColumnContext";
import {
  COLUMN_DEFINITIONS,
  MultivariantColumnDefinition,
  FILTER_DEFINITIONS,
} from "contexts/executions/MultivariantColumns";
import { useFilterContext } from "contexts/executions/FilterContext";
import { useColumnsPresetsContext } from "contexts/executions/ColumnsPresetsContext";
import { useUserContext } from "contexts/UserContext";
import { ColumnDefinition } from "components/executions/list/ExecutionColumnDefinitions";
import { useBoolean } from "helpers/hooks";
import { logEvent } from "helpers/analytics";
import LoadingRow from "core/table/LoadingRow";
import MultivariantsRow from "./MultivariantsRow";
import imgBoxChecked from "images/box-checked.svg";
import imgBoxUnchecked from "images/box-unchecked.svg";
import imgAddColumn from "images/addColumn.svg";
import imgSearch from "images/search.svg";
import Tooltip from "@material-ui/core/Tooltip";

MultivariantsTable.fragments = {
  multivariants: gql`
    fragment MultivariantsTable_multivariant on Multivariant {
      ...MultivariantsRow_multivariant
    }
    ${MultivariantsRow.fragments.multivariants}
  `,
};

type Props = Readonly<{
  multivariants: MultivariantsTable_MultivariantFragment[];
  isFetching: boolean;
}>;

const nop = () => {};

function MultivariantsTable({ multivariants, isFetching }: Props) {
  const { t } = useTranslation();

  const { isLoggedIn } = useUserContext();
  const {
    updateCheckedMVTs,
    toggleCheckedMVTs,
    setMultivariantsCount,
    isCheckedMVTs,
    checkedMVTs,
  } = useMultiSelectContext();
  const { columnDefs, sort, toggleSort } = useMultivariantColumnContext();
  const { setSelectedAddFilterColumn } = useFilterContext();
  const { openBatchTestCustomizer } = useColumnsPresetsContext();

  const columns = columnDefs.filter((c) => !c.hidden);

  const [isCustomizerHovering, setHovering, setNotHovering] = useBoolean(false);
  const [resize, setResize] = useState(0);
  const [resizeOrigPos, setResizeOrigPos] = useState(0);
  const [resizeOrigWidth, setResizeOrigWidth] = useState(0);
  const [resizeColumn, setColumn] = useState<
    MultivariantColumnDefinition | undefined
  >(undefined);
  const [isDragging, setDragging, setNotDragging] = useBoolean(false);

  useEffect(
    () => setMultivariantsCount(multivariants?.length ?? 0),
    [setMultivariantsCount, multivariants],
  );

  const toggleAllClick = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        const newCheckedList = { ...checkedMVTs };
        for (const multivariant of multivariants) {
          newCheckedList[multivariant.id] = {
            id: multivariant.id,
          };
        }
        updateCheckedMVTs(newCheckedList);
      } else if (!isChecked) {
        updateCheckedMVTs({});
      }
    },
    [multivariants, checkedMVTs, updateCheckedMVTs],
  );

  // Should we just subscribe to all IDs?
  const nonEndedIDs = multivariants
    .filter((ex) => ex.status !== "ENDED")
    .map((ex) => ex.id);

  useSubscription(MULTIVARIANT_SUBSCRIPTION, {
    variables: { multivariantIds: nonEndedIDs },
    skip: !isLoggedIn,
  });

  const onCheckColumnSize = useCallback(
    (column: MultivariantColumnDefinition, width: number) => {
      if (column.column === "SCRIPT_NAME") {
        if (width < 150) {
          return 150;
        } else if (width > 900) {
          return 900;
        }
      } else {
        if (width < 50) {
          return 50;
        } else if (width > 600) {
          return 600;
        }
      }
      return width;
    },
    [],
  );

  const onResizeDrag = useCallback(
    (e) => {
      setResize(e.clientX);
    },
    [setResize],
  );

  const onResizeDragEnd = useCallback(
    (
      e,
      startPos: number,
      initialWidth: number,
      column: MultivariantColumnDefinition,
    ) => {
      column.width = `${onCheckColumnSize(
        column,
        initialWidth - (startPos - e.clientX),
      )}px`;
      localStorage.setItem("multivariantColumns", JSON.stringify(columnDefs));
      setNotDragging();
      window.removeEventListener("mousemove", onResizeDrag);
    },
    [columnDefs, setNotDragging, onResizeDrag, onCheckColumnSize],
  );

  const onResizeDragStart = useCallback(
    (e: MouseEvent<HTMLDivElement>, column: MultivariantColumnDefinition) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging();
      const startPos = e.clientX;
      const initialWidth = parseInt(column.width as string);
      setColumn(column);
      setResizeOrigPos(startPos);
      setResizeOrigWidth(initialWidth);
      window.addEventListener("mousemove", onResizeDrag);
      window.addEventListener(
        "mouseup",
        (event) => {
          onResizeDragEnd(event, startPos, initialWidth, column);
        },
        { once: true },
      );
    },
    [setDragging, onResizeDragEnd, onResizeDrag, setResizeOrigWidth],
  );

  useEffect(() => {
    if (resizeColumn && isDragging && resize > 0) {
      resizeColumn.width = `${onCheckColumnSize(
        resizeColumn,
        resizeOrigWidth - (resizeOrigPos - resize),
      )}px`;
    }
  }, [
    isDragging,
    resize,
    resizeColumn,
    resizeOrigPos,
    resizeOrigWidth,
    onCheckColumnSize,
  ]);

  const setDefaultWidth = useCallback(
    (column: MultivariantColumnDefinition) => {
      column.width =
        COLUMN_DEFINITIONS.find((col) => col.column === column.column)?.width ??
        "50px";
      localStorage.setItem("multivariantColumns", JSON.stringify(columnDefs));
      setResize(resize !== -1 ? -1 : 0);
    },
    [columnDefs, setResize, resize],
  );

  const onCustomizerColumnClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      openBatchTestCustomizer();
    },
    [openBatchTestCustomizer],
  );

  const onColumnHeaderClick = useCallback(
    (filterColumn: MultivariantFilterColumn) => {
      toggleSort(filterColumn);
      logEvent("ToggleMultivariantSort", {
        sortColumn: sort.sortKey,
        sortDirection: sort.sortDirection === "ASC" ? "DESC" : "ASC",
      });
    },
    [toggleSort, sort],
  );

  const onColumnHeaderFilterClick = useCallback(
    (filterColumnDef: ColumnDefinition) => {
      setSelectedAddFilterColumn(filterColumnDef);
    },
    [setSelectedAddFilterColumn],
  );

  return (
    <div className="table__wrapper simulations-table multivariants-table">
      {isDragging && <div className="hidden-layer" />}
      <table
        className={classnames("table table--compact", { dragging: isDragging })}
      >
        <thead>
          <tr className={classnames({ "select-enabled": checkedMVTs })}>
            {checkedMVTs && (
              <th
                className={classnames(
                  "table-row-checkbox mvt-checkbox header-checkbox",
                  { hidden: !isCheckedMVTs },
                )}
              >
                <img
                  alt="Checked Box"
                  src={
                    Object.keys(checkedMVTs).length
                      ? imgBoxChecked
                      : imgBoxUnchecked
                  }
                  onClick={() =>
                    toggleAllClick(
                      Object.keys(checkedMVTs).length ? false : true,
                    )
                  }
                />
              </th>
            )}
            {columns.map((column, i) => {
              const columnDef = columns[i];
              const translatedColumn = columnDef.getLabels(t);
              const filterDefs = Object.keys(
                FILTER_DEFINITIONS,
              ) as MultivariantFilterColumn[];
              const filterColumn = filterDefs.find(
                (filterDef) =>
                  FILTER_DEFINITIONS[filterDef].column === columnDef.column,
              );
              const filterColumnDef = filterColumn
                ? FILTER_DEFINITIONS[filterColumn]
                : undefined;

              return (
                <th
                  key={i}
                  className={column.class}
                  style={{ width: column.width }}
                >
                  {i !== 0 && (
                    <div
                      className="column-divider left-side"
                      onMouseDown={(e) => onResizeDragStart(e, columns[i - 1])}
                      onDoubleClick={(e) => setDefaultWidth(columns[i - 1])}
                    />
                  )}
                  <div className="column-header">
                    {translatedColumn.tooltip ? (
                      <Tooltip
                        title={
                          <div className="execution-table-tooltip__content">
                            <div className="execution-table-tooltip__title">
                              {translatedColumn.header}
                            </div>
                            <div className="execution-table-tooltip__body">
                              {translatedColumn.tooltip}
                            </div>
                          </div>
                        }
                        PopperProps={{
                          className: "execution-table-tooltip",
                        }}
                      >
                        <div
                          className="as-link"
                          data-index={i}
                          onClick={() =>
                            filterColumn && filterColumn !== "TAG_ID"
                              ? onColumnHeaderClick(filterColumn)
                              : nop
                          }
                        >
                          {filterColumn === sort.sortKey ? (
                            <>
                              <span className="sorted-column">
                                {translatedColumn.header}
                              </span>
                              <i
                                className={sort.sortDirection.toLowerCase()}
                              ></i>
                            </>
                          ) : (
                            translatedColumn.header
                          )}
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        className="as-link"
                        data-index={i}
                        onClick={() =>
                          filterColumn && filterColumn !== "TAG_ID"
                            ? onColumnHeaderClick(filterColumn)
                            : nop
                        }
                      >
                        {filterColumn === sort.sortKey ? (
                          <>
                            <span className="sorted-column">
                              {translatedColumn.header}
                            </span>
                            <i className={sort.sortDirection.toLowerCase()}></i>
                          </>
                        ) : (
                          translatedColumn.header
                        )}
                      </div>
                    )}
                    {filterColumnDef?.filterType && (
                      <div
                        data-index={i}
                        onClick={() =>
                          onColumnHeaderFilterClick(filterColumnDef)
                        }
                        className={"column-filter"}
                      >
                        <img alt={"Search"} src={imgSearch} />{" "}
                      </div>
                    )}
                    {columnDef.column === "SCRIPT_NAME" && (
                      <div className="right-header">{t("common.actions")}</div>
                    )}
                  </div>
                  <div
                    className="column-divider"
                    onMouseDown={(e) => onResizeDragStart(e, column)}
                    onDoubleClick={(e) => setDefaultWidth(column)}
                  />
                </th>
              );
            })}
            <th
              className={classnames("add-column", {
                hover: isCustomizerHovering,
              })}
              onClick={openBatchTestCustomizer}
              onMouseEnter={setHovering}
              onMouseLeave={setNotHovering}
            >
              <div
                className="column-divider left-side"
                onMouseDown={(e) =>
                  onResizeDragStart(e, columns[columns.length - 1])
                }
                onDoubleClick={(e) =>
                  setDefaultWidth(columns[columns.length - 1])
                }
              />
              <img alt="Add Column" src={imgAddColumn} />
            </th>
            <th className="blank" />
          </tr>
        </thead>
        <tbody>
          {isFetching || multivariants?.length ? (
            <>
              {multivariants?.map((multivariant, i) => (
                <MultivariantsRow
                  key={i}
                  checkedMVTs={checkedMVTs}
                  isCheckedMVTs={isCheckedMVTs}
                  toggleCheckedMVTs={toggleCheckedMVTs}
                  columns={columns}
                  multivariant={multivariant}
                  onCustomizerColumnClick={onCustomizerColumnClick}
                  setHovering={setHovering}
                  setNotHovering={setNotHovering}
                  isCustomizerHovering={isCustomizerHovering}
                />
              ))}
              {isFetching && <LoadingRow columnCount={15} />}
              <tr className="empty-row" />
            </>
          ) : (
            <div className="empty-state">{t("message.no_results_match")}</div>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default MultivariantsTable;
