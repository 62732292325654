import { ReactNode, createElement } from "react";
import { TFunction } from "i18next";
import {
  Execution,
  ExecutionColumn,
  ExecutionStatus,
  Measurements,
} from "graphql/schema";
import { ActionDefinition } from "./ExecutionActionDefinitions";
import { titleCase } from "helpers/strings";
import { trimZeroes } from "helpers/numbers";
import backtestImg from "images/backtest.svg";
import paperImg from "images/papertrade.svg";

export type MeasurementVal = number | string | null | undefined;

type ShowActionModal = (
  name: string,
  action: () => void,
  execution?: Execution,
) => void;

// Used to extract a displayable property from an execution
type PropertyFn = (
  ex: Execution,
  actions?: ActionDefinition[],
  onShowActionModal?: ShowActionModal,
  isPackListOpen?: boolean,
  onUpdateOpenPacks?: (id: string, remove: boolean) => void,
) => ReactNode;

export type ColumnFilterType =
  | "string"
  | "number"
  | "options"
  | "boolean"
  | "datetime"
  | "runtime";

// Used to apply an additional transformation to a property in the `exProp` functions
type PropTransformer = (n: ReactNode) => ReactNode;

// Used to apply an additional transformation to a property in the `msProp` functions
export type MeasurementTransformer = (n: MeasurementVal) => ReactNode;

// A default transformer that does nothing
export const ID: PropTransformer = (n) => n;

// Used to properly display columns as well as sorting and filtering
export interface ColumnDefinition {
  className: string;
  getLabels: (t: TFunction) => { header: string; tooltip?: ReactNode };
  propertyFn: PropertyFn;
  column: ExecutionColumn;
  filterType?: ColumnFilterType;
  filterOptions?: string[];
  isTag?: boolean; // used to get the actual tags in the filter options since tags are mutable
  hidden?: boolean; // For now, this is used to hide columns that we need filtering for (eg. Favorite)
  canMove?: boolean;
  canToggle?: boolean;
  neverVisible?: boolean;
  width?: string;
  unsortable?: boolean;
}

// Wrap negative values in a span so it can be colored
export function colorSigned(
  val: MeasurementVal,
  bipolar: boolean = false,
): ReactNode {
  if (val === undefined || val === null) return undefined;
  return parseFloat(val as string) < 0
    ? createElement("span", { className: "neg" }, val)
    : bipolar
    ? createElement("span", { className: "pos" }, val)
    : val;
}

// Status
export function statusCircle({
  status,
}: {
  status: ExecutionStatus;
}): ReactNode {
  return createElement("div", {
    className: `status status_${status.toLowerCase()}`,
    title: titleCase(status),
  });
}

// type
export function executionType({ type }: Execution) {
  return createElement("img", {
    className: "execution-type-img",
    src: type === "BACKTEST" ? backtestImg : paperImg,
    title: type,
  });
}

// Shortcut to easily access an execution property in column definitions
export function exProp(
  name: keyof Execution,
  transform: PropTransformer = ID,
): PropertyFn {
  // TODO: Fix eslint error
  // eslint-disable-next-line react/display-name
  return (ex) => {
    const val = transform(ex[name]);
    if (typeof val === "string") {
      const strVal = val.replace("_", " / ");
      return createElement("span", { title: strVal }, strVal);
    } else {
      return createElement("span", { title: val }, val ?? "--");
    }
  };
}

// Shortcut to easily access an execution property in column definitions without zeroes
export function exZeroesProp(
  name: keyof Execution,
  transform: PropTransformer = ID,
): PropertyFn {
  // TODO: Fix eslint error
  // eslint-disable-next-line react/display-name
  return (ex) => {
    const val = transform(ex[name]);
    return createElement(
      "span",
      { title: val },
      typeof val === "string" ? trimZeroes(val) : val,
    );
  };
}

// Shortcut to easily access a measurement property in column definitions
export function msProp(
  name: keyof Measurements,
  transform: MeasurementTransformer = ID,
  colorSign = false,
  hideForMultiCoinPack = false,
): PropertyFn {
  // TODO: Fix eslint error
  // eslint-disable-next-line react/display-name
  return (ex) => {
    if (
      (hideForMultiCoinPack && ex.multiCoinCurrency) ||
      ex.measurements[name] === "NaN"
    ) {
      return createElement("span", { title: "--" }, "--");
    }
    const val = transform(ex.measurements[name])?.toString() || "--";
    return colorSign
      ? createElement("span", { title: val }, colorSigned(val))
      : createElement("span", { title: val }, val);
  };
}

// Shortcut to easily access a measurement property in column definitions with brightness depending on how high / low the numbers are
export function clProp(
  name: keyof Measurements,
  transform: MeasurementTransformer = ID,
): PropertyFn {
  // TODO: Fix eslint error
  // eslint-disable-next-line react/display-name
  return (ex) => {
    let val = transform(ex.measurements[name]) || "--";
    const percentage = parseFloat(typeof val === "string" ? val : "0");
    if (percentage === 0 || val === "--") {
      return createElement("span", { title: val }, val);
    } else {
      const intensity = Math.min(10000, Math.abs(percentage)) / 10000;
      return createElement(
        "span",
        {
          className: percentage < 0 ? "neg" : "pos",
          style: { filter: `brightness(${1 + intensity})` },
          title: val,
        },
        val,
      );
    }
  };
}
