import React, { MouseEvent } from "react";
import {
  Execution,
  ExecutionStatus,
  ExecutionType,
  ID,
} from "../../../graphql/schema";
import { CheckedIdRecord } from "../../../contexts/MultiSelectContext";
import { ActionDefinition } from "./ExecutionActionDefinitions";
import { ColumnDefinition } from "./ExecutionColumnDefinitions";
import ExecutionRow from "./ExecutionRow";

interface Props {
  executionIds: ID[];
  currentId?: ID;
  checkedList?: CheckedIdRecord;
  onExecutionSelected: (
    exId: string,
    name?: string,
    type?: ExecutionType,
    status?: ExecutionStatus,
  ) => (e: MouseEvent) => void;
  columns: ColumnDefinition[];
  actionDefs?: ActionDefinition[];
  onShowActionModal: (
    name: string,
    action: () => void,
    execution?: Execution,
  ) => void;
  canHightlight: boolean;
  onCustomizerColumnClick: (e: MouseEvent<HTMLDivElement>) => void;
  setHovering: () => void;
  setNotHovering: () => void;
  isCustomizerHovering: boolean;
}

const ExecutionPackList: React.FunctionComponent<Props> = ({
  currentId,
  executionIds,
  checkedList,
  onExecutionSelected,
  columns,
  actionDefs,
  onShowActionModal,
  canHightlight,
  onCustomizerColumnClick,
  setHovering,
  setNotHovering,
  isCustomizerHovering,
}) => (
  <>
    {executionIds.map((id, i) => (
      <ExecutionRow
        key={i}
        active={currentId === id && canHightlight}
        executionId={id}
        checkedList={checkedList}
        onExecutionSelected={onExecutionSelected}
        columns={columns}
        actionDefs={actionDefs}
        onShowActionModal={onShowActionModal}
        isPackListOpen={false}
        onCustomizerColumnClick={onCustomizerColumnClick}
        setHovering={setHovering}
        setNotHovering={setNotHovering}
        isCustomizerHovering={isCustomizerHovering}
      />
    ))}
  </>
);

export default React.memo(ExecutionPackList);
