import React, { createContext, useContext } from "react";
import invariant from "invariant";
import { useBoolean } from "helpers/hooks";

type Context = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

const PageNavigatorContext = createContext<Context | undefined>(undefined);

export function usePageNavigatorContext() {
  const context = useContext(PageNavigatorContext);
  invariant(
    context != null,
    "Component is not a child of PageNavigatorContextProvider",
  );
  return context;
}

type Props = {
  children: React.ReactNode;
};

export function PageNavigatorContextProvider(props: Props) {
  const { children } = props;

  const [isOpen, open, close] = useBoolean(false);

  return (
    <PageNavigatorContext.Provider value={{ isOpen, open, close }}>
      {children}
    </PageNavigatorContext.Provider>
  );
}
