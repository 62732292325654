import React from "react";
import { StyleSheet, css } from "aphrodite";
import colors from "styles/colors";

type Props = Readonly<{
  title: string;
  subtitle: string;
}>;

function ConnectCredentialHeader({ title, subtitle }: Props) {
  return (
    <div className={css(styles.header)}>
      <div className={css(styles.headerTitle)}>{title}</div>
      <div className={css(styles.headerSubtitle)}>{subtitle}</div>
    </div>
  );
}

const styles = StyleSheet.create({
  header: {
    margin: "0 auto 55px auto",
    textAlign: "center",
    "@media (max-width: 767.98px)": {
      width: "100%",
      marginBottom: "15px",
    },
  },
  headerTitle: {
    fontWeight: 600,
    fontSize: "34px",
    color: colors.lightGray,
    "@media (max-width: 767.98px)": {
      fontSize: "24px",
    },
  },
  headerSubtitle: {
    fontWeight: 400,
    fontSize: "14px",
    color: colors.offWhite,
    marginTop: "5px",
  },
});

export default ConnectCredentialHeader;
