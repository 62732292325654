import React from "react";
import { StyleSheet, css } from "aphrodite";
import ExchangeGuideImageTooltip from "./ExchangeGuideImageTooltip";
import Button from "core/button/Button";
import colors from "styles/colors";

export type ExchangeInstruction = {
  title: string;
  info: string[];
  image?: string;
};

export type InstructionButton = {
  label: string;
  mobileLabel: string;
  onClick: () => void;
};

type Props = Readonly<{
  step: number;
  instruction: ExchangeInstruction;
  button?: InstructionButton;
}>;

function CreateExchangeKeyInstruction({ step, instruction, button }: Props) {
  return (
    <div className={css(styles.instruction)}>
      <div className={css(styles.instructionStep)}>{step}</div>
      <div>
        <div className={css(styles.instructionTitle)}>
          {instruction.title}
          {instruction.image && (
            <span className={css(styles.instructionImage)}>
              <ExchangeGuideImageTooltip img={instruction.image} />
            </span>
          )}
        </div>
        <div>
          {instruction.info.map((desc) => (
            <div key={desc} className={css(styles.instructionInfo)}>
              {desc}
            </div>
          ))}
        </div>
        {button && (
          <div className={css(styles.buttonContainer)}>
            <Button color="pineBorder" onClick={button.onClick}>
              <span className={css(styles.desktopLabel)}>{button.label}</span>
              <span className={css(styles.mobileLabel)}>
                {button.mobileLabel}
              </span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  instruction: {
    display: "flex",
    marginBottom: "30px",
  },
  instructionStep: {
    flexShrink: 0,
    width: "23px",
    height: "23px",
    lineHeight: "23px",
    color: colors.paleYellow,
    border: `1px solid ${colors.paleYellow}`,
    borderRadius: "23px",
    margin: "0 10px auto 0",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "13px",
  },
  instructionTitle: {
    display: "flex",
    fontWeight: 600,
    fontSize: "18px",
    color: colors.white,
    lineHeight: "26px",
    marginBottom: "10px",
  },
  instructionImage: {
    height: 17.25,
    lineHeight: "17.25px",
    margin: "auto 0 auto 10px",
  },
  instructionInfo: {
    margin: "5px 0",
    fontWeight: 400,
    fontSize: "14px",
    color: colors.offWhite,
  },
  buttonContainer: {
    width: "295px",
    marginTop: "10px",
    "@media (max-width: 767.98px)": {
      maxWidth: "100%",
      width: "unset",
    },
  },
  desktopLabel: {
    "@media (max-width: 767.98px)": {
      display: "none",
    },
  },
  mobileLabel: {
    "@media (min-width: 768px)": {
      display: "none",
    },
  },
});

export default CreateExchangeKeyInstruction;
