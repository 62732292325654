import React, { useCallback } from "react";
import { Route } from "react-router-dom";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { ColumnsPreset } from "graphql/schema";
import { StyleSheet, css } from "aphrodite";
import { DELETE_SIMULATIONS, DELETE_MULTIVARIANTS } from "graphql/mutations";
import { useMultiSelectContext } from "contexts/MultiSelectContext";
import { useComposerStateContext } from "contexts/ComposerStateContext";
import { useColumnsPresetsContext } from "contexts/executions/ColumnsPresetsContext";
import { useSelectedExecutionContext } from "contexts/SelectedExecutionContext";
import { useGettingStartedContext } from "contexts/GettingStartedContext";
import {
  EXECUTION_CONTEXT_QUERY,
  EXECUTION_CONTEXT_QUERY__DEPRECATED,
} from "contexts/executions/ExecutionContext";
import { MULTIVARIANT_COLUMN_CONTEXT_PROVIDER_QUERY } from "contexts/executions/MultivariantColumnContext";
import SentryRoute from "components/router/SentryRoute";
import useStopExecutionsModal from "components/executions/modal/useStopExecutionsModal";
import IconButton from "core/button/IconButton";
import ListItem from "./ListItem";
import { useBoolean } from "helpers/hooks";
import { logEvent } from "helpers/analytics";
import {
  EXPERIMENTS_SUBTAB,
  CANDIDATES_SUBTAB,
  RELEASE_CANDIDATES_SUBTAB,
  MY_BOTS_SUBTAB,
  COMMUNITY_BOTS_SUBTAB,
  BATCHTEST_SUBTAB,
  SUBSCRIBE_TAB,
  LIVE_BOTS_SUBTAB,
  LIVE_SETUPS_SUBTAB,
} from "helpers/navigation";
import useConfirmationModal from "../../modal/useConfirmationModal";
import LinkButton from "../../forms/LinkButton";
import MaterialSelect from "../../forms/fields/MaterialSelect";
import imgTrashbin from "images/trashbin.svg";
import imgTrashbinLight from "images/trashbin-light.svg";
import imgSelect from "images/select-cursor.svg";
import imgBars from "images/vertical-bars.svg";
import imgPause from "images/pause-light.svg";
import imgLive from "images/live-bold.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import "../tertiary.scss";

function SelectItems() {
  const { t } = useTranslation();

  const [deleteSimulations] = useMutation(DELETE_SIMULATIONS, {
    refetchQueries: [
      EXECUTION_CONTEXT_QUERY,
      EXECUTION_CONTEXT_QUERY__DEPRECATED,
    ],
  });
  const [deleteMultivariants] = useMutation(DELETE_MULTIVARIANTS, {
    refetchQueries: [MULTIVARIANT_COLUMN_CONTEXT_PROVIDER_QUERY],
  });

  const {
    checkedList,
    checkedMVTs,
    isCheckedList,
    enableCheckedSelectList,
    disableCheckedList,
    isCheckedMVTs,
    enableCheckedMVTs,
    disableCheckedMVTs,
    updateCheckedMVTs,
    updateCheckedList,
    exeCheckedType,
    updateCheckedForPack,
  } = useMultiSelectContext();

  const { hide, setCopied } = useComposerStateContext();

  const {
    columnsPresets,
    openCustomizer,
    setColumnsPreset,
    selectedColumnsPreset,
    selectedBotColumnsPreset,
    setBotColumnsPreset,
    openBatchTestCustomizer,
  } = useColumnsPresetsContext();
  const { botMeasurementsMode, setBotMeasurementsMode } =
    useSelectedExecutionContext();
  const { showGettingStarted } = useGettingStartedContext();

  const [isColumnsPresetsOpen, openColumnsPresets, closeColumnsPresets] =
    useBoolean(false);

  const confirm = useConfirmationModal();
  const stopExecutions = useStopExecutionsModal();

  const checkedMVTIds = Object.keys(checkedMVTs).map((key) => Number(key));

  const deleteCheckedMVTs = useCallback(async () => {
    await deleteMultivariants({
      variables: { multivariantIds: checkedMVTIds },
    });
    updateCheckedMVTs({});
    disableCheckedMVTs();
    logEvent("BatchDeleteSelectedMultivariants");
  }, [
    checkedMVTIds,
    deleteMultivariants,
    disableCheckedMVTs,
    updateCheckedMVTs,
  ]);

  const checkedIds = Object.keys(checkedList).map((key) => Number(key));
  const checkedIdsForStopping = Object.keys(checkedList)
    .filter((id) =>
      ["RUNNING", "STOPPING_NEXTCLOSE"].includes(checkedList[id].status ?? ""),
    )
    .map((id) => Number(id));

  const deleteCheckedSimulations = useCallback(async () => {
    await deleteSimulations({ variables: { executionIds: checkedIds } });
    updateCheckedList({});
    disableCheckedList();
    logEvent("BatchDeleteSelectedExecutions");
  }, [checkedIds, deleteSimulations, disableCheckedList, updateCheckedList]);

  const stopCheckedExecutions = useCallback(() => {
    updateCheckedList({});
    disableCheckedList();
    logEvent("BatchStopSelectedExecutions");
  }, [disableCheckedList, updateCheckedList]);

  const onSimulationActionClick = useCallback(() => {
    if (exeCheckedType === "SELECT" && Object.keys(checkedList).length) {
      confirm(
        deleteCheckedSimulations,
        t("message.delete_runs_confirmation", { count: checkedIds.length }),
        t("common.delete"),
        undefined,
        true,
      );
    }
  }, [
    exeCheckedType,
    checkedList,
    confirm,
    checkedIds,
    deleteCheckedSimulations,
    t,
  ]);

  const onCancelClick = useCallback(() => {
    disableCheckedList();
    setCopied(false);
    if (exeCheckedType === "CREATE_PACK") {
      hide();
      updateCheckedForPack({});
    }
    logEvent("CancelMultiselectForExecutionsList");
  }, [
    disableCheckedList,
    hide,
    exeCheckedType,
    updateCheckedForPack,
    setCopied,
  ]);

  const onSelectColmnsPreset = (preset: ColumnsPreset) => {
    setColumnsPreset(preset);
    closeColumnsPresets();
    logEvent("SelectColumnsPreset", { preset: preset.id });
  };

  const onSelectBotColmnsPreset = (preset: ColumnsPreset) => {
    setBotColumnsPreset(preset);
    closeColumnsPresets();
    logEvent("SelectColumnsPreset", { preset: preset.id });
  };

  const onOpenColumnsCustomizer = () => {
    openCustomizer();
    closeColumnsPresets();
    logEvent("OpenColumnsCustomizer");
  };

  const columnsCustomizerButton = (
    <ListItem borderStyles={["borderLeft"]}>
      <IconButton
        title={t("terminal.customize_columns")}
        id="nav-item-columns-customizer"
        src={imgBars}
        alt="Select"
        onClick={
          columnsPresets.length ? openColumnsPresets : onOpenColumnsCustomizer
        }
      />
      {isColumnsPresetsOpen && (
        <ClickAwayListener onClickAway={closeColumnsPresets}>
          <div className="nav__dropdown">
            {columnsPresets.map((preset, i) => (
              <div
                key={i}
                className={classnames("nav__dropdown--item", {
                  selected: selectedColumnsPreset?.id === preset.id,
                })}
                onClick={() => onSelectColmnsPreset(preset)}
              >
                {preset.name}
              </div>
            ))}
            <div
              className="nav__dropdown--item"
              onClick={onOpenColumnsCustomizer}
            >
              {t("terminal.plus_new")}
            </div>
          </div>
        </ClickAwayListener>
      )}
    </ListItem>
  );

  return (
    <>
      <SentryRoute
        exact
        path={`/(${EXPERIMENTS_SUBTAB}|${CANDIDATES_SUBTAB}|${RELEASE_CANDIDATES_SUBTAB})/:simulationId([0-9]+)?`}
      >
        {!showGettingStarted && columnsCustomizerButton}
        {!isCheckedList ? (
          <ListItem borderStyles={["borderLeft"]}>
            <IconButton
              title={t("terminal.select_runs")}
              id="nav-item-delete-selected"
              src={imgSelect}
              alt="Select"
              onClick={enableCheckedSelectList}
            />
          </ListItem>
        ) : (
          <>
            {exeCheckedType === "SELECT" ? (
              <>
                <ListItem borderStyles={["borderLeft"]}>
                  <IconButton
                    title={
                      checkedIdsForStopping.length
                        ? t("terminal.pause_selected")
                        : ""
                    }
                    id="nav-item-stop-selected"
                    src={imgPause}
                    alt="Pause"
                    disabled={!checkedIdsForStopping.length}
                    onClick={() => {
                      checkedIdsForStopping.length &&
                        stopExecutions(
                          stopCheckedExecutions,
                          checkedIdsForStopping,
                          checkedIds.length,
                        );
                    }}
                  />
                </ListItem>
                <ListItem borderStyles={["borderLeft"]}>
                  <IconButton
                    title={
                      Object.keys(checkedList).length
                        ? t("terminal.delete_selected")
                        : t("message.nothing_selected")
                    }
                    id={"nav-item-delete-selected"}
                    src={
                      !Object.keys(checkedList).length
                        ? imgTrashbin
                        : imgTrashbinLight
                    }
                    alt="Delete"
                    color="red"
                    disabled={!Object.keys(checkedList).length}
                    onClick={onSimulationActionClick}
                  />
                </ListItem>
                <li className="nav__item middle-left">
                  <LinkButton
                    title={t("common.cancel")}
                    className="nav__item--select"
                    onClick={onCancelClick}
                  >
                    {t("common.cancel")}
                  </LinkButton>
                </li>
              </>
            ) : (
              <li
                className={classnames("nav__item--icon", {
                  "create-pack": exeCheckedType === "CREATE_PACK",
                  "create-syndication": exeCheckedType === "SYNDICATION",
                })}
              >
                <LinkButton
                  title={
                    checkedList
                      ? t("terminal.create_pack_with_selected")
                      : t("message.nothing_selected")
                  }
                  onClick={onSimulationActionClick}
                >
                  {exeCheckedType === "CREATE_PACK"
                    ? t("terminal.strategy_pack")
                    : t("terminal.monetize")}
                </LinkButton>
              </li>
            )}
          </>
        )}
      </SentryRoute>
      <SentryRoute
        exact
        path={`/(${LIVE_BOTS_SUBTAB}|${LIVE_SETUPS_SUBTAB})/:strategyId([0-9]+)?`}
      >
        {!showGettingStarted && columnsCustomizerButton}
        {isCheckedList && exeCheckedType !== "SELECT" && (
          <li
            className={classnames("nav__item--icon", {
              "create-pack": exeCheckedType === "CREATE_PACK",
              "create-syndication": exeCheckedType === "SYNDICATION",
            })}
          >
            <LinkButton>
              {exeCheckedType === "CREATE_PACK"
                ? t("terminal.strategy_pack")
                : t("terminal.monetize")}
            </LinkButton>
          </li>
        )}
      </SentryRoute>
      <SentryRoute exact path={`/${MY_BOTS_SUBTAB}/:botId([0-9]+)?`}>
        {!showGettingStarted && columnsCustomizerButton}
      </SentryRoute>
      <SentryRoute exact path={`/${COMMUNITY_BOTS_SUBTAB}/:botId([0-9]+)?`}>
        <li className="nav__item middle-left">
          <MaterialSelect
            value={botMeasurementsMode}
            options={[
              {
                value: "backtest",
                display: (
                  <div>
                    <span>{t("terminal.backtest_performance")}</span>
                  </div>
                ),
              },
              {
                value: "papertrade",
                display: (
                  <div>
                    <img
                      className={css(styles.liveImage)}
                      src={imgLive}
                      alt="Select"
                      width={"15px"}
                      height={"15px"}
                    />
                    <span className={css(styles.livePerformance)}>
                      {t("terminal.live_performance")}
                    </span>
                  </div>
                ),
              },
            ]}
            onChange={(e) => setBotMeasurementsMode(e.target.value)}
            className="chart-toggle"
          />
        </li>
        {!showGettingStarted && (
          <ListItem borderStyles={["borderLeft"]}>
            <IconButton
              title={t("terminal.customize_columns")}
              id="nav-item-columns-customizer"
              src={imgBars}
              alt="Select"
              onClick={
                columnsPresets.length
                  ? openColumnsPresets
                  : onOpenColumnsCustomizer
              }
            />
            {isColumnsPresetsOpen && (
              <ClickAwayListener onClickAway={closeColumnsPresets}>
                <div className="nav__dropdown">
                  {columnsPresets.map((preset, i) => (
                    <div
                      key={i}
                      className={classnames("nav__dropdown--item", {
                        selected: selectedBotColumnsPreset?.id === preset.id,
                      })}
                      onClick={() => onSelectBotColmnsPreset(preset)}
                    >
                      {preset.name}
                    </div>
                  ))}
                  <div
                    className="nav__dropdown--item"
                    onClick={onOpenColumnsCustomizer}
                  >
                    {t("terminal.plus_new")}
                  </div>
                </div>
              </ClickAwayListener>
            )}
          </ListItem>
        )}
      </SentryRoute>
      <Route exact path={`/${BATCHTEST_SUBTAB}`}>
        {!isCheckedMVTs ? (
          <>
            <ListItem borderStyles={["borderLeft"]}>
              <IconButton
                title={t("terminal.customize_columns")}
                id="nav-item-columns-customizer"
                src={imgBars}
                alt="Select"
                onClick={openBatchTestCustomizer}
              />
            </ListItem>
            <ListItem borderStyles={["borderLeft"]}>
              <IconButton
                title={
                  checkedMVTs
                    ? t("terminal.delete_selected")
                    : t("message.nothing_to_delete")
                }
                id="nav-item-mvt-delete"
                src={imgSelect}
                alt="Select"
                onClick={enableCheckedMVTs}
              />
            </ListItem>
          </>
        ) : (
          <>
            <ListItem borderStyles={["borderLeft"]}>
              <IconButton
                title={t("terminal.customize_columns")}
                id="nav-item-columns-customizer"
                src={imgBars}
                alt="Select"
                onClick={openBatchTestCustomizer}
              />
            </ListItem>
            <ListItem borderStyles={["borderLeft"]}>
              <IconButton
                title={
                  Object.keys(checkedMVTs).length
                    ? t("terminal.delete_selected")
                    : t("message.nothing_to_delete")
                }
                id="nav-item-mvt-delete"
                src={
                  !Object.keys(checkedMVTs).length
                    ? imgTrashbin
                    : imgTrashbinLight
                }
                alt="Delete"
                color="red"
                disabled={!Object.keys(checkedMVTs).length}
                onClick={() =>
                  confirm(
                    deleteCheckedMVTs,
                    t("message.delete_batches_confirmation", {
                      count: checkedMVTIds.length,
                    }),
                    t("common.delete"),
                    undefined,
                    true,
                  )
                }
              />
            </ListItem>
            <li className="nav__item middle-left">
              <LinkButton
                title={
                  checkedMVTs
                    ? t("terminal.delete_selected")
                    : t("message.nothing_to_delete")
                }
                className="nav__item--select"
                onClick={disableCheckedMVTs}
              >
                {t("common.cancel")}
              </LinkButton>
            </li>
          </>
        )}
      </Route>
      <Route path={`/${SUBSCRIBE_TAB}`}>
        <li className="nav__item middle-left syndication-subscribe">
          <LinkButton
            title={t("common.subscribe")}
            className="nav__item--select"
          >
            {t("common.subscribe")}
          </LinkButton>
        </li>
      </Route>
    </>
  );
}

const styles = StyleSheet.create({
  liveImage: {
    display: "inline-block",
    flexWrap: "wrap",
    verticalAlign: "middle",
  },
  livePerformance: {
    display: "inline-block",
    flexWrap: "wrap",
    fontSize: "14px",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    verticalAlign: "middle",
  },
});

export default SelectItems;
