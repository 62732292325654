import React from "react";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import {
  ExecutionStatus,
  Int,
  MultivariantProgress,
} from "../../../graphql/schema";
import { titleCase } from "../../../helpers/strings";
import "./progressbar.scss";

type Props = Readonly<{
  progress: MultivariantProgress[];
  multivariantId: string;
}>;

const STATUS_SORT: Record<ExecutionStatus, Int> = {
  ENDED: 0,
  LIQUIDATED: 1,
  RUNNING: 2,
  PENDING: 3,
  STOPPING: 4,
  CANCELLED: 5,
  FAILED: 6,
  DELETING: 7,
  STOPPING_NEXTCLOSE: 8,
  STOPPING_CLOSEMARKET: 9,
};

function MultivariantProgressBar({ progress, multivariantId }: Props) {
  const { t } = useTranslation();

  // A bit strange but this is needed by ReactTooltip to know which tooltip to show
  const tooltipId = `progress_tooltip_${multivariantId}`;
  const sortedProgress = [...progress].sort(
    (a, b) => STATUS_SORT[a.status] - STATUS_SORT[b.status],
  );

  return (
    <div className="multivariantProgress" data-for={tooltipId} data-tip="">
      <ReactTooltip id={tooltipId} effect="solid" className="reactTooltip">
        {sortedProgress.length ? (
          sortedProgress.map(({ status, count }: MultivariantProgress) =>
            count > 0 ? (
              <span
                key={status}
                className={`status_${status.toLowerCase()} progress-bar`}
              >
                {count} {titleCase(status)}
              </span>
            ) : null,
          )
        ) : (
          <span className="status_pending progress-bar">
            {t("common.pending")}
          </span>
        )}
      </ReactTooltip>
      <div className="progressBar">
        {sortedProgress.length ? (
          sortedProgress.map(({ status, count }: MultivariantProgress) => (
            <div
              key={status}
              className={`status_${status.toLowerCase()} progress-bar`}
              style={{ flex: count }}
            />
          ))
        ) : (
          <div className="status_pending progress-bar" style={{ flex: 1 }} />
        )}
      </div>
    </div>
  );
}

export default MultivariantProgressBar;
