import React from "react";
import Spinner from "core/spinner/Spinner";
import "./splash.scss";

function SplashPage() {
  return (
    <div className="splash-page">
      <div className="splash-page__wrapper">
        <Spinner />
      </div>
    </div>
  );
}

export default SplashPage;
