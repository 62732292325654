import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import { Exchange } from "__generated__/graphql";
import { useUserContext } from "contexts/UserContext";
import ConnectCredentialHeader from "./ConnectCredentialHeader";
import ConnectCredentialBackButton from "./ConnectCredentialBackButton";
import binanceImg from "images/binance-logo.svg";
import binanceLogoImg from "images/binance-icon-color.png";
import bybitImg from "images/bybit-logo-color.png";
import bitmexImg from "images/bitmex-logo.svg";
import coinbaseImg from "images/coinbase-pro-logo.svg";
import krakenImg from "images/kraken-logo-color.svg";
import huobiImg from "images/huobi-logo-color.svg";
import cryptoDotComImg from "images/crypto-com-logo-color.svg";
import { formatExchange } from "helpers/formLabelUtils";
import { isCryptoDotComVisible } from "helpers/environment";
import colors from "styles/colors";

type TradingPlatform = Exclude<
  Exchange,
  | Exchange.BybitLinear
  | Exchange.BinanceCoinFutures
  | Exchange.BinanceFutures
  | Exchange.Nash
>;

interface TradingPlatformDetails {
  img: string;
  logo?: string;
  height?: number;
  tradingVenues: Exchange[];
}

const TRADING_PLATFORMS: Record<TradingPlatform, TradingPlatformDetails> = {
  [Exchange.Bybit]: {
    img: bybitImg,
    height: 17,
    tradingVenues: [Exchange.Bybit, Exchange.BybitLinear],
  },
  [Exchange.Binance]: {
    img: binanceImg,
    logo: binanceLogoImg,
    height: 22,
    tradingVenues: [
      Exchange.Binance,
      Exchange.BinanceFutures,
      Exchange.BinanceCoinFutures,
    ],
  },
  [Exchange.Bitmex]: {
    tradingVenues: [Exchange.Bitmex],
    img: bitmexImg,
  },
  [Exchange.Coinbase]: {
    tradingVenues: [Exchange.Coinbase],
    img: coinbaseImg,
  },
  [Exchange.Kraken]: {
    tradingVenues: [Exchange.Kraken],
    img: krakenImg,
  },
  [Exchange.HuobiCoinSwaps]: {
    tradingVenues: [Exchange.HuobiCoinSwaps],
    img: huobiImg,
  },
  [Exchange.CryptoDotCom]: {
    tradingVenues: [Exchange.CryptoDotCom],
    img: cryptoDotComImg,
  },
};

type Props = Readonly<{
  onSelectExchange: (ex: Exchange) => void;
}>;

function SelectExchangeStep({ onSelectExchange }: Props) {
  const { t } = useTranslation();

  const { isTester } = useUserContext();

  const [selectedPlatform, setSelectedPlatform] = useState<TradingPlatform>();

  const onPlatformClick = (tradingPlatform: TradingPlatform) => {
    const details = TRADING_PLATFORMS[tradingPlatform];
    if (details.tradingVenues.length === 1) {
      onSelectExchange(details.tradingVenues[0]);
      return;
    }

    setSelectedPlatform(tradingPlatform);
  };

  const renderTradingVenues = () =>
    selectedPlatform &&
    TRADING_PLATFORMS[selectedPlatform].tradingVenues.map((venue) => {
      const details = TRADING_PLATFORMS[selectedPlatform];
      return (
        <div
          key={venue}
          className={css(styles.exchangeOption)}
          onClick={() => onSelectExchange(venue)}
        >
          <img
            className={css(styles.exchangeOptionLogo)}
            src={details.logo ?? details.img}
            alt="Exchange"
            height={details.height}
          />
          <span className={css(styles.exchangeOptionLabel)}>
            {formatExchange(venue)}
          </span>
          <span className={css(styles.exchangeOptionArrow)}>→</span>
        </div>
      );
    });

  const renderTradingPlatforms = () =>
    Object.keys(TRADING_PLATFORMS)
      .filter((tradingPlatform) => {
        if (isCryptoDotComVisible(isTester)) {
          return true;
        } else {
          return tradingPlatform !== Exchange.CryptoDotCom;
        }
      })
      .map((tradingPlatform) => (
        <div
          className={css(styles.exchange)}
          key={tradingPlatform}
          onClick={() => onPlatformClick(tradingPlatform as TradingPlatform)}
        >
          <img
            className={css(styles.exchangeImage)}
            src={TRADING_PLATFORMS[tradingPlatform as TradingPlatform].img}
            alt="Exchange"
          />
        </div>
      ));

  return (
    <>
      <ConnectCredentialHeader
        title={t("message.connect_supported_exchange_to_get_started")}
        subtitle={t("message.supports_trading_venues_globally")}
      />
      <div className={css(styles.exchanges)}>
        {selectedPlatform ? renderTradingVenues() : renderTradingPlatforms()}
      </div>
      {!!selectedPlatform && (
        <ConnectCredentialBackButton
          onClick={() => setSelectedPlatform(undefined)}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  exchanges: {
    display: "flex",
    flexWrap: "wrap",
    "@media (min-width: 1020px)": {
      width: "780px",
    },
  },
  exchange: {
    display: "flex",
    width: "250px",
    height: "180px",
    background: colors.offBlack,
    borderRadius: "10px",
    cursor: "pointer",
    margin: "0 15px 15px 0",
    ":hover": {
      background: colors.nero,
    },
    "@media (min-width: 1020px)": {
      ":nth-child(3n)": {
        marginRight: "0",
      },
    },
    "@media (max-width: 1019.98px)": {
      marginLeft: "auto",
      ":nth-child(2n)": {
        marginLeft: "0",
        marginRight: "auto",
      },
    },
    "@media (max-width: 767.98px)": {
      width: "100%",
      margin: "0 0 15px 0",
    },
  },
  exchangeImage: {
    margin: "auto",
  },
  exchangeOption: {
    display: "flex",
    cursor: "pointer",
    background: colors.offBlack,
    borderRadius: "10px",
    width: "100%",
    height: "70px",
    marginBottom: "15px",
    ":hover": {
      background: colors.nero,
    },
  },
  exchangeOptionLogo: {
    margin: "auto 10px auto 30px",
  },
  exchangeOptionLabel: {
    fontWeight: 700,
    fontSize: "15px",
    margin: "auto auto auto 0",
  },
  exchangeOptionArrow: {
    lineHeight: "4px",
    margin: "auto 30px auto auto",
  },
});

export default SelectExchangeStep;
