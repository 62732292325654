import gql from "graphql-tag";

export const PAYMENT_SOURCE_DATA = gql`
  fragment PaymentSourceData on PaymentSource {
    id
    type
    status
    name
    meta
  }
`;

export const PRODUCT_DATA = gql`
  fragment ProductData on Product {
    id
    name
    price
    currency
    entitlements {
      type
      referenceId
      ... on SyndicationEntitlement {
        syndication {
          id
          owner {
            firstName
            lastName
            companyName
            nickname
          }
          name
          currencyPair
          currencyPairDetails {
            id
            exchange
            pair
            base
            quote
            settleCurrency
            positionCurrency
          }
          syndicationSettings {
            id
            monthlyFeeUsd
            allocationFeePerc
            successFeePerc
            accessControl
          }
          startedAt
        }
      }
    }
  }
`;

export const BILLING_SUBSCRIPTION_DATA = gql`
  fragment BillingSubscriptionData on BillingSubscription {
    id
    statusDetails {
      creationFailure {
        reason
      }
      latestRenewalFailure {
        reason
      }

      mayUnsubscribe
      mayRevokeUnsubscription
      mayUseEntitlements
      hasPaymentPending

      hasCreationFailed
      hasLatestRenewalFailed
      willBeCancelledAtEndOfCycle
      userHasUnsubscribed
    }
    product {
      ...ProductData
    }
    nextBillingTime
  }
  ${PRODUCT_DATA}
`;

export const INVOICE_ITEM_DATA = gql`
  fragment InvoiceItem on InvoiceItem {
    id
    itemType
    amount
    description
    associations {
      ... on Product {
        ...ProductData
      }
      ... on BillingSubscription {
        id
        startTime
        endTime
      }
    }
    successFeeLineItemMetadata {
      previousTotalValuePeak
      newTotalValuePeak
      totalValueDeltaUsd
    }
  }
  ${PRODUCT_DATA}
`;

export const INVOICE_PAYMENT_DATA = gql`
  fragment InvoicePayment on InvoicePayment {
    id
    paymentProvider
    status
    statusReason
    amount
    externalId
    metadata {
      ... on NashLinkPaymentMetadata {
        paymentUrl
      }
      ... on CheckoutPaymentMetadata {
        redirectLink3DS2
      }
    }
    refunds {
      id
      amount
      reason
    }
  }
`;

export const INVOICE_DATA = gql`
  fragment Invoice on Invoice {
    id
    createdAt
    total
    totalPaid
    status
    currency
    payments {
      ...InvoicePayment
    }
    items {
      ...InvoiceItem
    }
    pending
    balance
    periodStart
    periodEnd
    mayCreatePayment
  }
  ${INVOICE_ITEM_DATA}
  ${INVOICE_PAYMENT_DATA}
`;

// *** - New payment flow requests
