import React from "react";
import imgSpinner from "../../images/spinner.svg";
import "./spinner.scss";

interface SpinnerProps {}

const Spinner: React.FunctionComponent<SpinnerProps> = () => (
  <section className="spinner">
    <img src={imgSpinner} alt="Loading" />
  </section>
);

export default Spinner;
