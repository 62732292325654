import { Route } from "react-router-dom";
import { withSentryRouting } from "@sentry/react";

/**
 * Gives Sentry access to the route path so it can
 * correctly match url patterns when collecting
 * performance data.
 *
 * https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#parameterized-transaction-names
 *
 */
export default withSentryRouting(Route);
