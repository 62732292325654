import React, { useState, useCallback, useContext } from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { AlertContext } from "../../contexts/AlertContext";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";

import "./photo.scss";

// interface for react-easy-crop
interface CropArea {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface Crop {
  x: number;
  y: number;
}

const createPhoto = (photo: File) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = URL.createObjectURL(photo);
  });
};

type Props = Readonly<{
  correctPhoto: File;
  closeCrop: () => void;
  setCorrectPhoto: (file: File | undefined) => void;
  setCroppedPhoto: (file: File | undefined) => void;
  maxZoom: number;
}>;

function PhotoCrop({
  correctPhoto,
  closeCrop,
  setCorrectPhoto,
  setCroppedPhoto,
  maxZoom,
}: Props) {
  const { t } = useTranslation();

  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { addError } = useContext(AlertContext)!;

  const [crop, setCrop] = useState<Crop>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropArea | null>(
    null,
  );

  const createCroppedImage = useCallback(
    async (photo: File, croppedArea: CropArea) => {
      const image: HTMLImageElement = (await createPhoto(
        correctPhoto,
      )) as HTMLImageElement;

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // need to use maxSize and safeArea to get the dimensions so that the image isn't black
      const maxSize = Math.max(image.width, image.height);
      const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

      canvas.width = safeArea;
      canvas.height = safeArea;

      // TODO: Eliminate use of non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ctx!.translate(safeArea / 2, safeArea / 2);
      // TODO: Eliminate use of non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ctx!.translate(-safeArea / 2, -safeArea / 2);

      // TODO: Eliminate use of non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ctx!.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5,
      );
      // TODO: Eliminate use of non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const data = ctx!.getImageData(0, 0, safeArea, safeArea);

      canvas.width = croppedArea.width;
      canvas.height = croppedArea.height;

      // TODO: Eliminate use of non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ctx!.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - croppedArea.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - croppedArea.y),
      );

      return new Promise((resolve) => {
        canvas.toBlob((file) => {
          resolve(file);
        }, "image/jpeg");
      });
    },
    [correctPhoto],
  );

  const onCloseCrop = useCallback(() => {
    closeCrop();
    setCorrectPhoto(undefined);
  }, [closeCrop, setCorrectPhoto]);

  const onSetPhotoClick = useCallback(async () => {
    try {
      const croppedPhoto = (await createCroppedImage(
        correctPhoto,
        // TODO: Eliminate use of non-null assertion
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        croppedAreaPixels!,
      )) as File;
      setCroppedPhoto(croppedPhoto);
      onCloseCrop();
    } catch (e) {
      addError(t("error.failed_to_show_cropped_image"));
    }
  }, [
    correctPhoto,
    croppedAreaPixels,
    setCroppedPhoto,
    onCloseCrop,
    addError,
    createCroppedImage,
    t,
  ]);

  const onCropComplete = useCallback(
    (croppedArea: CropArea, croppedAreaPixels: CropArea) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  return (
    <div className="image-crop--container">
      <div className="image-crop">
        <Cropper
          image={URL.createObjectURL(correctPhoto)}
          crop={crop}
          zoom={zoom}
          maxZoom={maxZoom}
          aspect={1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className="image-crop__controls">
        <Slider
          value={zoom}
          min={1}
          max={maxZoom}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e, zoom) =>
            setZoom(typeof zoom === "number" ? zoom : zoom[0])
          }
          className="image-crop__controls--slider"
        />
        <div className="image-crop__controls__buttons">
          <Button
            onClick={onCloseCrop}
            variant="contained"
            className="image-crop__controls__buttons--button cancel-button"
          >
            {t("common.cancel")}
          </Button>
          <Button
            onClick={onSetPhotoClick}
            variant="contained"
            className="image-crop__controls__buttons--button"
          >
            {t("terminal.set_photo")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PhotoCrop;
