import React, { ReactNode } from "react";
import classnames from "classnames";
import { TFunction } from "i18next";
import { PartialMultivariant } from "../../graphql/mutations";
import {
  MultivariantColumn,
  MultivariantFilterColumn,
} from "../../graphql/schema";
import InfoTooltip from "../../core/tooltip/InfoTooltip";
import AlgoValuesTable from "../../components/AlgoValuesTable";
import { ColumnDefinition } from "../../components/executions/list/ExecutionColumnDefinitions";
import ExecutionScriptRevision from "../../components/executions/list/ExecutionScriptRevision";
import ExecutionNumber from "../../components/executions/list/ExecutionNumber";
import ExecutionValueCurrency from "../../components/executions/list/ExecutionValueCurrency";
import { statusCircle } from "../../components/executions/list/ExecutionColumnDefinitions";
import MultivariantFavorite from "../../components/multivariants/MultivariantFavorite";
import MultivariantActions from "../../components/multivariants/MultivariantActions";
import MultivariantTags from "../../components/multivariants/MultivariantTags";
import MultivariantProgressBar from "../../components/multivariants/progressbar/MultivariantProgressBar";
import { titleCase } from "../../helpers/strings";
import { getDateTime } from "../../helpers/dates";
import { formatExchange } from "../../helpers/formLabelUtils";
import { EXECUTION_STATUSES, EXCHANGES } from "./ExecutionColumns";
import CircularProgress from "@material-ui/core/CircularProgress";

export const FILTER_DEFINITIONS: Record<
  MultivariantFilterColumn,
  ColumnDefinition
> = {
  FAVORITE: {
    className: "Favorite",
    getLabels: (t: TFunction) => ({ header: t("common.favorite") }),
    propertyFn: () => null,
    column: "FAVORITE",
    filterType: "boolean",
  },
  CREATION_DATE: {
    className: "Creation Date",
    getLabels: (t: TFunction) => ({ header: t("common.creation_date") }),
    propertyFn: () => null,
    column: "CREATION_DATE",
  },
  SCRIPT_NAME: {
    className: "Name",
    getLabels: (t: TFunction) => ({ header: t("common.name") }),
    propertyFn: () => null,
    column: "SCRIPT_NAME",
    filterType: "string",
  },
  EXCHANGE: {
    className: "Exchange",
    getLabels: (t: TFunction) => ({ header: t("common.exchange") }),
    propertyFn: () => null,
    column: "EXCHANGE",
    filterType: "options",
    filterOptions: EXCHANGES,
  },
  CURRENCY_PAIR: {
    className: "Trading Pair",
    getLabels: (t: TFunction) => ({ header: t("common.trading_pair") }),
    propertyFn: () => null,
    column: "CURRENCY_PAIR",
    filterType: "string",
  },
  STATUS: {
    className: "Status",
    getLabels: (t: TFunction) => ({ header: t("common.status") }),
    propertyFn: () => null,
    column: "STATUS",
    filterType: "options",
    filterOptions: EXECUTION_STATUSES,
  },
  TAG_ID: {
    className: "Tags",
    getLabels: (t: TFunction) => ({ header: t("common.tags") }),
    propertyFn: () => null,
    column: "TAG_ID",
    filterType: "options",
    isTag: true,
    unsortable: true,
  },
  ALLOCATION: {
    className: "Initial Allocation",
    getLabels: (t: TFunction) => ({ header: t("common.initial_allocation") }),
    propertyFn: () => null,
    column: "ALLOCATION",
    filterType: "number",
  },
  EXECUTION_NUMBER: {
    className: "Run #",
    getLabels: (t: TFunction) => ({ header: t("terminal.run_number") }),
    propertyFn: () => null,
    column: "EXECUTION_NUMBER",
    filterType: "number",
  },
};

export interface MultivariantColumnDefinition {
  column: MultivariantColumn;
  getLabels: (t: TFunction) => { header: string; tooltip?: string };
  class: string;
  hidden?: boolean;
  canToggle?: boolean;
  canMove?: boolean;
  width?: string;
  propertyFn: (multivariant: PartialMultivariant, index: number) => ReactNode;
}

export const COLUMN_DEFINITIONS: MultivariantColumnDefinition[] = [
  {
    column: "SCRIPT_NAME",
    getLabels: (t: TFunction) => ({ header: t("common.name") }),
    class: "Strategy",
    canMove: false,
    canToggle: false,
    width: "300px",
    propertyFn: function (multivariant, i) {
      const readableAlgoNames: Record<string, string> = {};
      multivariant.scriptDetails?.parameters?.forEach((parameter) => {
        if (parameter?.fieldName && parameter?.readableName) {
          readableAlgoNames[parameter.fieldName] = parameter.readableName;
        }
      });

      return (
        <td
          key={i}
          title={multivariant.scriptDetails.name}
          className={this.class}
          style={{ width: this.width }}
        >
          <div title={multivariant.scriptDetails.name} className="strat-name">
            <MultivariantFavorite multivariant={multivariant} />
            <span
              className={classnames("name", {
                "name-flex": multivariant.algorithmSettings === "{}",
              })}
            >
              {multivariant.scriptDetails.name}
            </span>
            {multivariant.algorithmSettings !== "{}" && (
              <InfoTooltip id={`mvt_algoSettings_${multivariant.id}`}>
                <AlgoValuesTable
                  className="algo_info"
                  algorithmSettings={multivariant.algorithmSettings}
                  readableAlgoNames={readableAlgoNames}
                  multivariantId={multivariant.id}
                />
              </InfoTooltip>
            )}
            <MultivariantActions multivariant={multivariant} />
          </div>
        </td>
      );
    },
  },
  {
    column: "SCRIPT_VERSION",
    getLabels: (t: TFunction) => ({ header: t("common.strategy_version") }),
    class: "Strategy Version",
    canMove: true,
    canToggle: true,
    width: "85px",
    propertyFn: function (multivariant, i) {
      return (
        <td
          key={i}
          title={multivariant.scriptDetails.name}
          className={this.class}
          style={{ width: this.width }}
        >
          <ExecutionScriptRevision
            version={multivariant.scriptDetails.version}
            isLatest={multivariant.scriptDetails.isLatest}
            createdAt={multivariant.scriptDetails.createdAt}
          />
        </td>
      );
    },
  },
  {
    column: "EXECUTION_NUMBER",
    getLabels: (t: TFunction) => ({ header: t("terminal.run_number") }),
    class: "execution-number",
    canMove: true,
    canToggle: true,
    width: "60px",
    propertyFn: function (multivariant, i) {
      return (
        <td key={i} className={this.class} style={{ width: this.width }}>
          <ExecutionNumber
            scriptVersionSequence={multivariant.scriptVersionSequence}
          />
        </td>
      );
    },
  },
  {
    column: "STATUS",
    getLabels: (t: TFunction) => ({ header: t("common.status") }),
    class: "Status",
    canMove: true,
    canToggle: false,
    width: "85px",
    propertyFn: function (multivariant, i) {
      const status = titleCase(
        multivariant.status === "CANCELLED" ? "Paused" : multivariant.status,
      );
      const endedBacktests =
        multivariant.progress.find((progress) => progress.status === "ENDED")
          ?.count ?? 0;
      const backtestProgress =
        endedBacktests / multivariant.totalCombinationsCount;

      return (
        <td
          key={i}
          title={status}
          className={this.class}
          style={{ width: this.width }}
        >
          <div className="outer-status">
            {multivariant.status === "RUNNING" ||
            multivariant.status === "CANCELLED" ? (
              <div className="status status_running-backtest">
                <CircularProgress
                  className="status_running-backtest--circle"
                  // multiply by 82 since the progress circle is filled at 82 for some reason and the value is a percentage
                  variant="determinate"
                  value={(backtestProgress ?? 0) * 82}
                />
              </div>
            ) : (
              statusCircle(multivariant)
            )}
            {status}
          </div>
        </td>
      );
    },
  },
  {
    column: "CREATION_DATE",
    getLabels: (t: TFunction) => ({ header: t("common.creation_date") }),
    class: "Creation Date",
    canMove: true,
    canToggle: true,
    width: "130px",
    propertyFn: function (multivariant, i) {
      const date = getDateTime(multivariant.createdAt);
      return (
        <td
          key={i}
          title={date}
          className={this.class}
          style={{ width: this.width }}
        >
          {date}
        </td>
      );
    },
  },
  {
    column: "EXCHANGE",
    getLabels: (t: TFunction) => ({
      header: t("common.exchange"),
    }),
    class: "Exchange",
    canMove: true,
    canToggle: true,
    width: "75px",
    propertyFn: function (multivariant, i) {
      const exchange = formatExchange(multivariant.exchange);
      return (
        <td
          key={i}
          title={exchange}
          className={this.class}
          style={{ width: this.width }}
        >
          {exchange}
        </td>
      );
    },
  },
  {
    column: "CURRENCY_PAIR",
    getLabels: (t: TFunction) => ({
      header: t("common.trading_pair"),
      tooltip: t("message.trading_pair_infomation"),
    }),
    class: "Trading Pair",
    canMove: true,
    canToggle: true,
    width: "80px",
    propertyFn: function (multivariant, i) {
      const currencyPair = multivariant.currencyPair.replace("_", " / ");
      return (
        <td
          key={i}
          title={currencyPair}
          className={this.class}
          style={{ width: this.width }}
        >
          {currencyPair}
        </td>
      );
    },
  },
  {
    column: "ALLOCATION",
    getLabels: (t: TFunction) => ({ header: t("common.initial_allocation") }),
    class: "Allocation",
    canMove: true,
    canToggle: true,
    width: "110px",
    propertyFn: function (multivariant, i) {
      return (
        <td
          key={i}
          title={`${multivariant.allocation}`}
          className={this.class}
          style={{ width: this.width }}
        >
          <ExecutionValueCurrency
            value={multivariant.allocation}
            currency={multivariant.currencyPairDetails?.settleCurrency}
            colorSign={false}
          />
        </td>
      );
    },
  },
  {
    column: "CANDLE_SIZE",
    getLabels: (t: TFunction) => ({
      header: t("common.candle"),
      tooltip: t("message.candle_size_information"),
    }),
    class: "Candle",
    canMove: true,
    canToggle: true,
    width: "65px",
    propertyFn: function (multivariant, i) {
      return (
        <td
          key={i}
          title={multivariant.candleSize.toString()}
          className={this.class}
          style={{ width: this.width }}
        >
          {multivariant.candleSize}
        </td>
      );
    },
  },
  {
    column: "LEVERAGE",
    getLabels: (t: TFunction) => ({
      header: t("common.long_leverage"),
      tooltip: t("message.long_leverage_information"),
    }),
    class: "Long Leverage",
    canMove: true,
    canToggle: true,
    width: "100px",
    propertyFn: function (multivariant, i) {
      return (
        <td
          key={i}
          title={multivariant.leverage?.toString()}
          className={this.class}
          style={{ width: this.width }}
        >
          {multivariant.leverage?.toString() ?? "--"}
        </td>
      );
    },
  },
  {
    column: "LEVERAGE_SHORT",
    getLabels: (t: TFunction) => ({
      header: t("common.short_leverage"),
      tooltip: t("message.short_leverage_information"),
    }),
    class: "Short Leverage",
    canMove: true,
    canToggle: true,
    width: "100px",
    propertyFn: function (multivariant, i) {
      return (
        <td
          key={i}
          title={multivariant.leverageShort?.toString()}
          className={this.class}
          style={{ width: this.width }}
        >
          {multivariant.leverageShort?.toString() ?? "--"}
        </td>
      );
    },
  },
  {
    column: "LOOKBACK_DAYS",
    getLabels: (t: TFunction) => ({
      header: t("common.lookback_days"),
      tooltip: t("message.lookback_days_information"),
    }),
    class: "Lookback Days",
    canMove: true,
    canToggle: true,
    width: "80px",
    propertyFn: function (multivariant, i) {
      return (
        <td
          key={i}
          title={multivariant.lookbackDays.toString()}
          className={this.class}
          style={{ width: this.width }}
        >
          {multivariant.lookbackDays ?? "--"}
        </td>
      );
    },
  },
  {
    column: "BUY_ACTION_TIME",
    getLabels: (t: TFunction) => ({
      header: t("common.buy_action_time"),
    }),
    class: "Buy Action Time",
    canMove: true,
    canToggle: true,
    width: "100px",
    propertyFn: function (multivariant, i) {
      return (
        <td
          key={i}
          title={titleCase(multivariant.buyActionTime.toString())}
          className={this.class}
          style={{ width: this.width }}
        >
          {titleCase(multivariant.buyActionTime.toString())}
        </td>
      );
    },
  },
  {
    column: "SELL_ACTION_TIME",
    getLabels: (t: TFunction) => ({
      header: t("common.sell_action_time"),
    }),
    class: "Sell Action Time",
    canMove: true,
    canToggle: true,
    width: "100px",
    propertyFn: function (multivariant, i) {
      return (
        <td
          key={i}
          title={titleCase(multivariant.sellActionTime.toString())}
          className={this.class}
          style={{ width: this.width }}
        >
          {titleCase(multivariant.sellActionTime.toString())}
        </td>
      );
    },
  },
  {
    column: "MAX_BUY",
    getLabels: (t: TFunction) => ({
      header: t("common.max_buy"),
      tooltip: t("message.percent_available_funds_for_each_trade"),
    }),
    class: "max-buy",
    canMove: true,
    canToggle: true,
    width: "50px",
    propertyFn: function (multivariant, i) {
      return (
        <td
          key={i}
          title={multivariant.maxBuy.toString()}
          className={this.class}
          style={{ width: this.width }}
        >
          {multivariant.maxBuy}
        </td>
      );
    },
  },
  {
    column: "MIN_PROFIT",
    getLabels: (t: TFunction) => ({
      header: t("common.min_profit"),
      tooltip: t("message.min_profit_information"),
    }),
    class: "min-profit",
    canMove: true,
    canToggle: true,
    width: "55px",
    propertyFn: function (multivariant, i) {
      const minProfit =
        multivariant.minProfit !== "NaN"
          ? multivariant.minProfit.toString()
          : "--";
      return (
        <td
          key={i}
          title={minProfit}
          className={this.class}
          style={{ width: this.width }}
        >
          {minProfit}
        </td>
      );
    },
  },
  {
    column: "STOP_LOSS_PERCENTAGE",
    getLabels: (t: TFunction) => ({
      header: t("common.s_l_percent"),
      tooltip: t("message.stop_loss_information"),
    }),
    class: "stop-loss",
    canMove: true,
    canToggle: true,
    width: "50px",
    propertyFn: function (multivariant, i) {
      const stopLossPercentage =
        multivariant.stopLossPercentage !== "NaN"
          ? multivariant.stopLossPercentage.toString()
          : "--";
      return (
        <td
          key={i}
          title={stopLossPercentage}
          className={this.class}
          style={{ width: this.width }}
        >
          {stopLossPercentage}
        </td>
      );
    },
  },
  {
    column: "TRAILING_STOP_LOSS",
    getLabels: (t: TFunction) => ({
      header: t("common.tsl_question"),
      tooltip: t("message.trailing_stop_loss_information"),
    }),
    class: "trailing-stop-loss",
    canMove: true,
    canToggle: true,
    width: "50px",
    propertyFn: function (multivariant, i) {
      return (
        <td
          key={i}
          title={multivariant.stopLossTrailing ? "Yes" : "No"}
          className={this.class}
          style={{ width: this.width }}
        >
          {multivariant.stopLossTrailing ? "Yes" : "No"}
        </td>
      );
    },
  },
  {
    column: "TAKE_PROFIT_PERCENTAGE",
    getLabels: (t: TFunction) => ({
      header: t("common.tp_percent"),
      tooltip: t("message.take_profit_information"),
    }),
    class: "take-profit",
    canMove: true,
    canToggle: true,
    width: "50px",
    propertyFn: function (multivariant, i) {
      const takeProfitPercentage =
        multivariant.takeProfitPercentage !== "NaN"
          ? multivariant.takeProfitPercentage.toString()
          : "--";
      return (
        <td
          key={i}
          title={takeProfitPercentage}
          className={this.class}
          style={{ width: this.width }}
        >
          {takeProfitPercentage}
        </td>
      );
    },
  },
  {
    column: "TRAILING_TAKE_PROFIT_PERCENTAGE",
    getLabels: (t: TFunction) => ({
      header: t("common.ttp_percent"),
      tooltip: t("message.trailing_take_profit_information"),
    }),
    class: "trailing-take-profit",
    canMove: true,
    canToggle: true,
    width: "50px",
    propertyFn: function (multivariant, i) {
      const takeProfitTrailingPercentage =
        multivariant.takeProfitTrailingPercentage !== "NaN"
          ? multivariant.takeProfitTrailingPercentage.toString()
          : "--";
      return (
        <td
          key={i}
          title={takeProfitTrailingPercentage}
          className={this.class}
          style={{ width: this.width }}
        >
          {takeProfitTrailingPercentage}
        </td>
      );
    },
  },
  {
    column: "PERMUTATIONS",
    getLabels: (t: TFunction) => ({
      header: t("common.permutations"),
      tooltip: t("message.total_variations_tested"),
    }),
    class: "permutations",
    canMove: true,
    canToggle: true,
    width: "75px",
    propertyFn: function (multivariant, i) {
      return (
        <td
          key={i}
          title={multivariant.totalCombinationsCount.toString()}
          className={this.class}
          style={{ width: this.width }}
        >
          {multivariant.totalCombinationsCount}
        </td>
      );
    },
  },
  {
    column: "TAG_ID",
    getLabels: (t: TFunction) => ({ header: t("common.tags") }),
    class: "Tags",
    canMove: true,
    canToggle: true,
    width: "200px",
    propertyFn: function (multivariant, i) {
      return (
        <td key={i} className={this.class} style={{ width: this.width }}>
          <MultivariantTags
            multivariantId={multivariant.id}
            multivariantTags={multivariant.tags}
          />
        </td>
      );
    },
  },
  {
    column: "PROGRESS",
    getLabels: (t: TFunction) => ({ header: t("common.progress") }),
    class: "progress",
    canMove: true,
    canToggle: true,
    width: "200px",
    propertyFn: function (multivariant, i) {
      return (
        <td key={i} className={this.class} style={{ width: this.width }}>
          <MultivariantProgressBar
            progress={multivariant.progress}
            multivariantId={multivariant.id}
          />
        </td>
      );
    },
  },
];
