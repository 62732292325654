import React, { CSSProperties } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import "./materialSelect.scss";

export type SelectOption = {
  value: any;
  display: React.ReactNode;
};

type Props = Readonly<{
  value: any;
  options: readonly (SelectOption | null)[];
  onChange: (
    value: React.ChangeEvent<{
      name?: string | undefined;
      value: any;
    }>,
  ) => void;
  className: string;
  variant?: "filled" | "outlined" | "standard";
  menuStyle?: CSSProperties;
  paperStyle?: CSSProperties;
  disabled?: boolean;
  label?: string;
}>;

function MaterialSelect({
  value,
  options,
  onChange,
  className,
  variant = "outlined",
  menuStyle,
  paperStyle,
  disabled = false,
  label,
}: Props) {
  return (
    <FormControl variant={variant} className={className}>
      <Select
        className="material-select"
        value={value}
        onChange={onChange}
        MenuProps={{
          className: "material-select-dropdown",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          style: menuStyle,
          PaperProps: {
            style: paperStyle,
          },
        }}
        disabled={disabled}
        label={label}
      >
        {options
          .filter((option) => option)
          .map((option, i) => (
            // TODO: Eliminate use of non-null assertion
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <MenuItem key={i} value={option!!.value} className="select-option">
              {/* TODO: Eliminate use of non-null assertion */}
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              {option!!.display}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default MaterialSelect;
