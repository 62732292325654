import React, { useCallback, useEffect } from "react";
import { useModal } from "react-modal-hook/dist";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { ID } from "../../../graphql/schema";
import { UPDATE_SYNDICATION_SHARING } from "../../../graphql/mutations";
import LinkButton from "../../../core/forms/LinkButton";
import Modal from "../../../core/modal/Modal";
import imgClose from "../../../images/close.svg";
import imgShare from "../../../images/share.svg";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import { copyToClipboard } from "../../../helpers/strings";
import { useBoolean } from "../../../helpers/hooks";
import { INVITE_TAB } from "helpers/navigation";

type Props = Readonly<{
  id: ID;
  shareToken?: string;
  closeMenu?: () => void;
  name: string;
  className?: string;
  spanClassName?: string;
}>;

function SyndicationShare({
  id,
  shareToken,
  closeMenu,
  name,
  className,
  spanClassName,
}: Props) {
  const { t } = useTranslation();

  const [updateSyndicationSharing, { loading }] = useMutation(
    UPDATE_SYNDICATION_SHARING,
  );

  const [copiedLink, setCopied, setNotCopied] = useBoolean(false);

  const onToggle = useCallback(async () => {
    updateSyndicationSharing({ variables: { id, share: !shareToken } });
  }, [updateSyndicationSharing, id, shareToken]);

  const onCopySyndicationClick = useCallback(() => {
    copyToClipboard(`${window.location.origin}/${INVITE_TAB}/${shareToken}`);
    setCopied();
  }, [shareToken, setCopied]);

  useEffect(() => {
    if (copiedLink) {
      setTimeout(() => {
        setNotCopied();
      }, 3000);
    }
  }, [copiedLink, setNotCopied]);

  const [show, hide] = useModal(
    () => (
      <Modal isOpen onRequestClose={hide} className="allow-list--modal mini">
        <div className="allow-list">
          <img
            src={imgClose}
            alt="Close"
            className="close-icon"
            onClick={hide}
          />
          <div className="allow-list__header">
            <div className="allow-list__header--title">
              {t("terminal.share_private_bot_link")}
            </div>
            <div className="allow-list__header--subtitle">
              {t("message.users_with_link_can_subscribe_to_bot", {
                bot_name: name,
              })}
            </div>
            {shareToken && (
              <div className="allow-list__header__input">
                <TextField
                  disabled
                  variant="outlined"
                  className="allow-list__header__input--field"
                  value={
                    shareToken
                      ? `${window.location.origin}/${INVITE_TAB}/${shareToken}`
                      : ""
                  }
                />
                <Button
                  variant="contained"
                  className="allow-list__header__input--button"
                  disabled={!shareToken}
                  onClick={onCopySyndicationClick}
                >
                  {copiedLink ? (
                    t("common.copied")
                  ) : (
                    <>
                      <img src={imgShare} alt="Share" />
                      {t("terminal.copy_link")}
                    </>
                  )}
                </Button>
              </div>
            )}
            {shareToken ? (
              <Button
                variant="text"
                className="allow-list__header__revoke-button"
                onClick={onToggle}
              >
                {loading ? (
                  <CircularProgress size={12} className="loading" />
                ) : (
                  t("terminal.revoke_link")
                )}
              </Button>
            ) : (
              <Button
                variant="text"
                className="allow-list__header__share-button"
                onClick={onToggle}
              >
                {loading ? (
                  <CircularProgress size={12} className="loading" />
                ) : (
                  <>
                    <img src={imgShare} alt="Share" />
                    {t("terminal.generate_share_link")}
                  </>
                )}
              </Button>
            )}
          </div>
          <Button variant="contained" className="close-button" onClick={hide}>
            {t("common.done")}
          </Button>
        </div>
      </Modal>
    ),
    [shareToken, onCopySyndicationClick, onToggle, loading, copiedLink],
  );

  const onClick = useCallback(
    (e) => {
      show();
      if (closeMenu) closeMenu();
      e.stopPropagation();
    },
    [show, closeMenu],
  );

  return (
    <LinkButton onClick={onClick} className={className ?? "share"}>
      <span className={spanClassName ?? "action-option"}>
        {t("terminal.share_private_link")}
      </span>
    </LinkButton>
  );
}

export default React.memo(SyndicationShare);
