import React, { useMemo } from "react";
import gql from "graphql-tag";
import { VisibilityState, ColumnOrderState } from "@tanstack/react-table";
import { useQuery } from "@apollo/client";
import { useExecutionContext } from "contexts/executions/ExecutionContext";
import InfiniteTableSubRows from "core/lists/InfiniteTableSubRows";
import {
  ExecutionsInfiniteTable_ExecutionFragment,
  ExecutionsSubRowsQuery,
  ExecutionsSubRowsQueryVariables,
} from "__generated__/graphql";
import { ExecutionType } from "graphql/schema";

const EXECUTIONS_SUBROWS_QUERY = gql`
  query ExecutionsSubRows(
    $id: ID!
    $first: Int
    $after: String
    $sort: ExecutionSort
    $filters: ExecutionListFilters
    $useCandleSize: Boolean!
    $useAbsoluteProfit: Boolean!
    $useNumberOfTrades: Boolean!
    $useMaxDrawdown: Boolean!
    $usePercentProfitableTrades: Boolean!
    $useProfitability: Boolean!
    $useAvgPositionPrice: Boolean!
    $useAvgMonthlyProfit: Boolean!
    $useAvgWinMonth: Boolean!
    $useAvgLoseMonth: Boolean!
    $usePercProfitableMonths: Boolean!
    $usePositionAmount: Boolean!
    $usePositionAbsoluteProfit: Boolean!
    $usePositionProfitLoss: Boolean!
    $useBalance: Boolean!
    $useRiskScore: Boolean!
    $useBuyHoldReturn: Boolean!
    $useSharpeRatio: Boolean!
    $useSortinoRatio: Boolean!
    $useTotalRealizedGain: Boolean!
    $useTotalRealizedLoss: Boolean!
    $useConsistencyScore: Boolean!
    $useScriptVersion: Boolean!
    $useScriptName: Boolean!
    $useCreatedAt: Boolean!
    $useTags: Boolean!
    $useAllocation: Boolean!
    $useStartedAt: Boolean!
    $useEndedAt: Boolean!
    $useAutoRebalance: Boolean!
    $isBot: Boolean!
    $useActiveSubscribersCount: Boolean!
  ) {
    execution(id: $id) {
      packExecutions(
        first: $first
        after: $after
        filters: $filters
        sort: $sort
      ) {
        edges {
          cursor
          node {
            id
            name
            isPack
            packPercentage
            status
            candleType
            type
            exchange
            currencyPair
            currencyPairDetails {
              id
              exchange
              pair
              base
              quote
              settleCurrency
              positionCurrency
            }
            multiCoinCurrency
            multiCoinPackExecutions {
              currency
              percentage
            }
            candleSize @include(if: $useCandleSize)
            measurements {
              absoluteProfit @include(if: $useAbsoluteProfit)
              numberOfTrades @include(if: $useNumberOfTrades)
              maxDrawdown @include(if: $useMaxDrawdown)
              percentProfitableTrades @include(if: $usePercentProfitableTrades)
              profitability @include(if: $useProfitability)
              avgPositionPrice @include(if: $useAvgPositionPrice)
              avgMonthlyProfit @include(if: $useAvgMonthlyProfit)
              avgWinMonth @include(if: $useAvgWinMonth)
              avgLoseMonth @include(if: $useAvgLoseMonth)
              percProfitableMonths @include(if: $usePercProfitableMonths)
              positionAmount @include(if: $usePositionAmount)
              positionAbsoluteProfit @include(if: $usePositionAbsoluteProfit)
              positionProfitLoss @include(if: $usePositionProfitLoss)
              balance @include(if: $useBalance)
              riskScore @include(if: $useRiskScore)
              avgBarsInTrade
              profitFactor
              avgTradePrice
              lastTick
              positionState
              buyHoldReturn @include(if: $useBuyHoldReturn)
              sharpeRatio @include(if: $useSharpeRatio)
              sortinoRatio @include(if: $useSortinoRatio)
              totalRealizedGain @include(if: $useTotalRealizedGain)
              totalRealizedLoss @include(if: $useTotalRealizedLoss)
              consistencyScore @include(if: $useConsistencyScore)
            }
            favorite
            error
            runtimeSeconds
            shareToken
            scriptDetails {
              id
              scriptId
              revisionId
              parameters {
                fieldName
                readableName
              }
              name
              version @include(if: $useScriptVersion)
              isLatest @include(if: $useScriptVersion)
              createdAt @include(if: $useScriptVersion)
            }
            algorithmSettings @include(if: $useScriptName)
            createdAt @include(if: $useCreatedAt)
            leverage
            leverageShort
            runNumber
            multivariantIterationId
            scriptVersionSequence
            tags @include(if: $useTags) {
              id
              name
              color
            }
            allocation @include(if: $useAllocation)
            rangeStart @include(if: $useStartedAt)
            startedAt @include(if: $useStartedAt)
            rangeEnd @include(if: $useEndedAt)
            endedAt @include(if: $useEndedAt)
            autoRebalance @include(if: $useAutoRebalance)
            stage
            syndicationId
            syndicationSettings @include(if: $isBot) {
              maxAllocation
              minAllocation
              description
              isVisible
              allowList {
                nickname
                canRemove
              }
              accessControl
              shareToken
              activeSubscribersCount @include(if: $useActiveSubscribersCount)
            }
            syndication {
              name
            }
            stopLossPercentage
            stopLossTrailing
            takeProfitPercentage
            takeProfitTrailingPercentage
            notes
            owner {
              nickname
            }
            preloadedScript
            maxBuy
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;

type Props = Readonly<{
  id: string;
  selectedRowId?: string;
  columnVisibility?: VisibilityState;
  columnOrder?: ColumnOrderState;
}>;

export default function ExecutionsSubRows({
  id,
  selectedRowId,
  columnVisibility,
  columnOrder,
}: Props) {
  const { queryVariables, tableColumns, onExecutionClick } =
    useExecutionContext();

  const { data } = useQuery<
    ExecutionsSubRowsQuery,
    ExecutionsSubRowsQueryVariables
  >(EXECUTIONS_SUBROWS_QUERY, {
    variables: {
      ...queryVariables,
      id,
      first: 50,
      // do not need filters for sub-executions of a pack
      filters: {},
    },
  });

  const executions = useMemo(
    () =>
      data?.execution?.packExecutions?.edges?.map((edge) => edge?.node) ?? [],
    [data?.execution?.packExecutions?.edges],
  );

  return (
    <InfiniteTableSubRows<ExecutionsInfiniteTable_ExecutionFragment>
      data={executions as ExecutionsInfiniteTable_ExecutionFragment[]}
      columns={tableColumns}
      selectedRowId={selectedRowId}
      columnVisibility={columnVisibility}
      columnOrder={columnOrder}
      onRowClick={(execution) => {
        onExecutionClick(
          execution.id,
          execution.name ?? undefined,
          execution.type as ExecutionType,
          execution.status,
        );
      }}
    />
  );
}
