import React, { forwardRef, Ref } from "react";
import classnames from "classnames";
import Scrollbars from "react-custom-scrollbars";
import PanelScroll from "../PanelScroll";
import imgMinimize from "../../images/minimize.svg";
import TertiaryNavigation, {
  ITertiaryNavigationProps,
} from "../navigation/TertiaryNavigation";
import LinkButton from "../forms/LinkButton";

export interface ActionButtonDef {
  label: String;
  href: string;
}

type Props = Readonly<{
  children: React.ReactNode;
  position: "left" | "center" | "right";
  className?: string;
  innerClassName?: string;
  id?: string;
  subnavItems?: ITertiaryNavigationProps["items"];
  long?: boolean;
  noscroll?: boolean;
  onScroll?: React.UIEventHandler<any>;
  onFetchMore?: (scrollWrapper: any) => void;
  onMinimize?: () => void;
}>;

function Panel(
  {
    children,
    position,
    className,
    innerClassName,
    id,
    subnavItems,
    long = false,
    noscroll = false,
    onScroll,
    onFetchMore,
    onMinimize,
  }: Props,
  ref: Ref<Scrollbars | null>,
) {
  return (
    <div
      className={classnames(
        "panel",
        { [`panel--${position}`]: !long, [`panel--${position}-long`]: long },
        className,
      )}
      id={id}
    >
      {onMinimize && (
        <LinkButton className="minimize" onClick={onMinimize}>
          <img src={imgMinimize} alt="Minimize" />
        </LinkButton>
      )}
      {noscroll && (
        <div className="panel__inner">
          {subnavItems && <TertiaryNavigation items={subnavItems} />}
          {children}
        </div>
      )}
      {!noscroll && (
        <PanelScroll
          style={{ width: "100%", height: "100%" }}
          onScroll={onScroll}
          onFetchMore={onFetchMore}
          ref={ref}
        >
          <div
            className={classnames(
              "panel__inner panel__scrollable",
              innerClassName,
            )}
          >
            {subnavItems && <TertiaryNavigation items={subnavItems} />}
            {children}
          </div>
        </PanelScroll>
      )}
    </div>
  );
}

export default forwardRef(Panel);
