import React, { useContext, useState, useCallback } from "react";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import {
  DELETE_MULTIVARIANT,
  PartialMultivariant,
  RESUME_MULTIVARIANT,
  STOP_MULTIVARIANT,
} from "graphql/mutations";
import ComposerStateContext from "contexts/ComposerStateContext";
import { MultiSelectContext } from "contexts/MultiSelectContext";
import { MULTIVARIANT_COLUMN_CONTEXT_PROVIDER_QUERY } from "contexts/executions/MultivariantColumnContext";
import { logEvent } from "helpers/analytics";
import useConfirmationModal from "core/modal/useConfirmationModal";
import LinkButton from "core/forms/LinkButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import imgGear from "images/gear.svg";
import imgPause from "images/pause.svg";
import imgResume from "images/resume.svg";

type Props = Readonly<{
  multivariant: PartialMultivariant;
}>;

function MultivariantActions({ multivariant }: Props) {
  const { t } = useTranslation();

  const [stopMultivariant] = useMutation(STOP_MULTIVARIANT);
  const [deleteMultivariant] = useMutation(DELETE_MULTIVARIANT, {
    refetchQueries: [MULTIVARIANT_COLUMN_CONTEXT_PROVIDER_QUERY],
  });
  const [resumeMultivariant] = useMutation(RESUME_MULTIVARIANT);
  const confirm = useConfirmationModal();

  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { displayForMVT, setCopied } = useContext(ComposerStateContext)!;
  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { disableCheckedList } = useContext(MultiSelectContext)!;
  const [isOpen, setOpen] = useState(false);

  const toggleActionMenu = useCallback(
    (e) => {
      e.stopPropagation();
      setOpen(!isOpen);
    },
    [isOpen, setOpen],
  );

  let scriptVersionSequence = `${multivariant.scriptVersionSequence}`;
  let zeroes = "";

  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (multivariant.scriptVersionSequence! > 0) {
    const maxZeroes = 3 - `${multivariant.scriptVersionSequence}`.length;
    for (let i = 0; i < Math.max(maxZeroes, 0); i++) {
      zeroes += "0";
    }
    scriptVersionSequence = zeroes + `${multivariant.scriptVersionSequence}`;
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="action-menu">
        {multivariant.status === "RUNNING" && (
          <>
            <span
              className="action-menu--icon-button"
              data-for={`multivariant-${multivariant.id}-pause-button`}
              data-tip=""
            >
              <LinkButton
                onClick={(e) => {
                  logEvent("StopBatchTest");
                  confirm(
                    async () =>
                      await stopMultivariant({
                        variables: { multivariantId: multivariant.id },
                      }),
                    <Trans
                      i18nKey="message.execution_stop_confirmation"
                      values={{
                        execution_name: `${multivariant.scriptDetails.name} - ${scriptVersionSequence}`,
                      }}
                      components={[
                        <span key="execution_stop_confirmation"></span>,
                      ]}
                    />,
                    t("terminal.stop"),
                    undefined,
                    true,
                  );
                }}
                stopPropagation={true}
              >
                <img src={imgPause} alt="Pause" />
              </LinkButton>
            </span>
            <ReactTooltip
              id={`multivariant-${multivariant.id}-pause-button`}
              effect="solid"
              className="reactTooltip"
            >
              {t("terminal.pause")}
            </ReactTooltip>
          </>
        )}
        {multivariant.canResume && (
          <>
            <span
              className="action-menu--icon-button"
              data-for={`multivariant-${multivariant.id}-resume-button`}
              data-tip=""
            >
              <LinkButton
                style={{ top: "0px" }}
                onClick={(e) => {
                  confirm(
                    async () => {
                      logEvent("ResumeBatchTest");
                      await resumeMultivariant({
                        variables: { multivariantId: multivariant.id },
                      });
                    },
                    <Trans
                      i18nKey="message.execution_resume_confirmation"
                      values={{
                        execution_name: `${multivariant.scriptDetails.name} - ${scriptVersionSequence}`,
                      }}
                      components={[
                        <span key="execution_resume_confirmation"></span>,
                      ]}
                    />,
                    t("terminal.resume"),
                  );
                }}
                stopPropagation={true}
              >
                <img src={imgResume} alt="Resume" />
              </LinkButton>
            </span>
            <ReactTooltip
              id={`multivariant-${multivariant.id}-resume-button`}
              effect="solid"
              className="reactTooltip"
            >
              {t("terminal.resume")}
            </ReactTooltip>
          </>
        )}
        <span
          className="action-menu--icon-button"
          data-for={`multivariant-${multivariant.id}-settings-button`}
          data-tip=""
        >
          <LinkButton
            onClick={(e) => {
              displayForMVT(multivariant.id);
              setCopied(false);
              disableCheckedList();
            }}
            stopPropagation={true}
          >
            <img src={imgGear} alt="Settings" />
          </LinkButton>
        </span>
        <ReactTooltip
          id={`multivariant-${multivariant.id}-settings-button`}
          effect="solid"
          className="reactTooltip"
        >
          {t("common.settings")}
        </ReactTooltip>
        <span className="action-menu-toggler--outer" onClick={toggleActionMenu}>
          <span className="action-menu-toggler" />
        </span>
        <div className={classnames("action-dropdown", { hidden: !isOpen })}>
          <LinkButton
            title={t("common.delete")}
            onClick={(e) => {
              toggleActionMenu(e);
              confirm(
                async () =>
                  await deleteMultivariant({
                    variables: { multivariantId: multivariant.id },
                  }),
                <Trans
                  i18nKey="message.execution_delete_confirmation"
                  values={{
                    execution_name: `${multivariant.scriptDetails.name} - ${scriptVersionSequence}`,
                  }}
                  components={[
                    <span key="execution_delete_confirmation"></span>,
                  ]}
                />,
                t("common.delete"),
                undefined,
                true,
              );
            }}
            stopPropagation={true}
          >
            <span className="action-option">{t("terminal.delete_batch")}</span>
          </LinkButton>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default MultivariantActions;
