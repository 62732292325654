import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import invariant from "invariant";
import { gql, useMutation, useQuery } from "@apollo/client";
import { TourContextQuery, UserTourType } from "__generated__/graphql";
import { ADD_COMPLETED_TOUR } from "graphql/mutations";
import { useUserContext } from "./UserContext";
import { PowerBarType } from "components/powerbar/PowerBar";
import { logEvent } from "helpers/analytics";

export const TOUR_CONTEXT_QUERY = gql`
  query TourContext {
    me {
      id
      completedTours
    }
  }
`;

export function getCompletedTourKey(type: UserTourType) {
  return `HasCompletedTour-${type}`;
}

interface Context {
  selectedPowerbarTab?: PowerBarType;
  setSelectedPowerbarTab: (type?: PowerBarType) => void;
  hasCompletedTour: (type: UserTourType) => boolean;
  addCompletedTour: (type: UserTourType) => void;
}

const ALL_TOURS = Object.values(UserTourType);

export const TourContext = createContext<Context | undefined>(undefined);

export function useTourContext() {
  const context = useContext(TourContext);
  invariant(
    context != null,
    "Component is not a child of TourContext provider",
  );
  return context;
}

type Props = Readonly<{
  children: React.ReactNode;
}>;

export function TourContextProvider({ children }: Props) {
  const { isLoggedIn } = useUserContext();

  const { data: completedToursData } = useQuery<TourContextQuery>(
    TOUR_CONTEXT_QUERY,
    {
      skip: !isLoggedIn,
    },
  );

  // Assume all tours are completed when query is loading or if query fails
  const completedToursServer =
    completedToursData?.me?.completedTours ?? ALL_TOURS;

  const [mutation] = useMutation(ADD_COMPLETED_TOUR);

  // Again, assume all tours are completed for initial state
  const [completedTours, setCompletedTours] =
    useState<ReadonlyArray<UserTourType | null | undefined>>(ALL_TOURS);

  // used to highlight the selected powerbar tab during the tour
  const [selectedPowerbarTab, setSelectedPowerbarTab] =
    useState<PowerBarType>();

  useEffect(() => {
    const completedToursLoggedOut: UserTourType[] = Object.values(
      UserTourType,
    ).filter((tour) => localStorage.getItem(getCompletedTourKey(tour)) === "1");

    // For logged-in users, source of truth is from graphql
    // Logged-out users from local storage
    setCompletedTours(
      isLoggedIn ? completedToursServer ?? [] : completedToursLoggedOut,
    );
  }, [completedToursServer, isLoggedIn]);

  const hasCompletedTour = useCallback(
    (type: UserTourType) => completedTours.includes(type),
    [completedTours],
  );

  const addCompletedTour = useCallback(
    (type: UserTourType) => {
      logEvent("CompletedTour", { type });

      // Update local state
      setCompletedTours((tours) => [...tours, type]);
      localStorage.setItem(getCompletedTourKey(type), "1");

      if (isLoggedIn) {
        mutation({
          variables: {
            in: {
              type,
            },
          },
        });
      }
    },
    [isLoggedIn, mutation],
  );

  return (
    <TourContext.Provider
      value={{
        selectedPowerbarTab,
        setSelectedPowerbarTab,
        hasCompletedTour,
        addCompletedTour,
      }}
    >
      {children}
    </TourContext.Provider>
  );
}
