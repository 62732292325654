import React, { useMemo } from "react";
import ReactTooltip from "react-tooltip";
import TimeAgo, { Formatter, Suffix, Unit } from "react-timeago";
import { useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import { useSubscription } from "@apollo/client";
import { Execution } from "graphql/schema";
import { BACKTEST_PROGRESS } from "graphql/queries";
import {
  getTranslatedExecutionPositionState,
  getTranslatedExecutionStatus,
} from "helpers/formLabelUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import colors from "styles/colors";

type Props = Readonly<{
  execution: Execution;
}>;

// The tool doesn't handle less than a second ago
const MILLIS_FORMATTER: Formatter = (
  value: number,
  unit: Unit,
  suffix: Suffix,
  epochMiliseconds: number,
  nextFormatter?: Formatter,
) => {
  if (unit === "second" && value === 0) {
    const date = new Date(epochMiliseconds);
    const time =
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds() +
      "." +
      date.getMilliseconds();
    return <span>Now {time}</span>;
  } else {
    // TODO: Eliminate use of non-null assertion
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return nextFormatter!(value, unit, suffix, epochMiliseconds, nextFormatter);
  }
};

function ExecutionStatus({ execution }: Props) {
  const { t } = useTranslation();

  const { data } = useSubscription(BACKTEST_PROGRESS, {
    variables: { id: execution.id },
    skip:
      execution.type !== "BACKTEST" ||
      !!execution.packPercentage ||
      execution.status !== "RUNNING",
  });

  // multiply by 0.82 since the progress circle is filled at 82 for some reason
  const backtestProgress =
    execution.type === "BACKTEST" && execution.status === "RUNNING"
      ? (data?.backtestProgress ?? 0) * 0.82
      : undefined;

  const lastTick = execution.measurements?.lastTick;
  const positionState = execution.measurements?.positionState;
  const status = execution.status;

  const id = "statusTooltip:" + execution.id;

  const displayedStatus = useMemo(() => {
    if (
      status === "RUNNING" &&
      (execution.type === "LIVE" ||
        execution.type === "PAPERTRADE" ||
        execution.type === "SYNDICATION")
    ) {
      return getTranslatedExecutionPositionState(t, positionState);
    } else {
      return getTranslatedExecutionStatus(status, t);
    }
  }, [execution, status, positionState, t]);

  let tooltipContent = null;
  if (!status) {
    return null;
  } else if (status === "FAILED") {
    if (execution.error) {
      tooltipContent = (
        <div
          style={{
            pointerEvents: "auto", // for scrolling
            whiteSpace: "pre", // makes text new lines breaks
            textAlign: "left",
            width: "300px",
            overflow: "scroll",
            height: "300px",
            overflowWrap: "break-word",
            fontSize: "10px",
            color: "red",
          }}
        >
          {execution.error}
        </div>
      );
    }
  } else if (status === "ENDED") {
    if (execution.runtimeSeconds) {
      tooltipContent = (
        <span className={css(styles.statusEnded)}>
          {t("message.completed_in_amount_of_seconds", {
            seconds_amount: execution.runtimeSeconds,
          })}
        </span>
      );
    }
  } else if (status === "RUNNING") {
    if (lastTick) {
      tooltipContent = (
        <span className={css(styles.statusRunning)}>
          {t("message.last_tick")}{" "}
          <TimeAgo formatter={MILLIS_FORMATTER} date={lastTick} />
        </span>
      );
    }
  } else if (
    status === "STOPPING_NEXTCLOSE" ||
    status === "STOPPING_CLOSEMARKET"
  ) {
    return (
      <div className={css(styles.container)}>
        <div
          data-for={id}
          data-tip=""
          className={css(styles.statusCircle, styles.stopping)}
        />
        <ReactTooltip id={id} effect="solid" className={css(styles.tooltip)}>
          <span className={css(styles.statusStopping)}>
            {status === "STOPPING_NEXTCLOSE"
              ? t("message.stopping_on_next_close")
              : t("message.stopping_with_market_close")}
          </span>
        </ReactTooltip>
        {t("common.stopping")}
      </div>
    );
  }

  return (
    <div className={css(styles.container)}>
      {backtestProgress ? (
        <div
          data-for={id}
          data-tip=""
          className={css(styles.statusCircle, styles.runningBacktest)}
        >
          <CircularProgress
            className={css(styles.statusCircle, styles.runningBacktestCircle)}
            // multiply by 0.82 since the progress circle is filled at 82 for some reason
            variant="determinate"
            value={(backtestProgress ?? 0) * 0.82}
          />
        </div>
      ) : (
        <div
          data-for={id}
          data-tip=""
          className={css(
            styles.statusCircle,
            styles[
              status === "PENDING"
                ? "pending"
                : status === "CANCELLED"
                ? "cancelled"
                : status === "RUNNING"
                ? "running"
                : status === "ENDED"
                ? "ended"
                : status === "FAILED"
                ? "failed"
                : status === "LIQUIDATED"
                ? "liquidated"
                : "stopping"
            ],
          )}
        />
      )}
      {tooltipContent && (
        <ReactTooltip id={id} effect="solid" className={css(styles.tooltip)}>
          {tooltipContent}
        </ReactTooltip>
      )}
      {displayedStatus}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    textAlign: "left",
  },
  statusCircle: {
    width: "8px",
    height: "8px",
    borderRadius: "100%",
    margin: "-2px 10px 0 5px",
    top: "6px",
    position: "relative",
    display: "inline-block",
    float: "left",
  },
  runningBacktest: {
    border: `3px solid ${colors.black}`,
    top: "3px",
    backgroundColor: colors.green,
    marginLeft: "2px",
    marginRight: "7px",
  },
  runningBacktestCircle: {
    width: "14px",
    height: "14px",
    position: "absolute",
    top: "-1px",
    left: "-8px",
    color: colors.green,
    transform: "rotate(-90deg)",
  },
  pending: {
    background: colors.statusPending,
  },
  running: {
    background: colors.statusRunning,
  },
  cancelled: {
    background: colors.statusCancelled,
  },
  ended: {
    background: colors.statusEnded,
  },
  failed: {
    background: colors.statusFailed,
  },
  liquidated: {
    background: colors.statusLiquidated,
  },
  stopping: {
    background: colors.statusStopping,
  },
  tooltip: {
    opacity: 1,
    backgroundColor: colors.offBlack,
  },
  statusEnded: {
    color: colors.gray,
  },
  statusRunning: {
    color: colors.green,
  },
  statusStopping: {
    color: colors.darkYellow,
  },
});

export default React.memo(ExecutionStatus);
