import React, { useCallback, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { useMutation } from "@apollo/client";
import { DELETE_MULTIVARIANT_TAG } from "graphql/mutations";
import { ID, Tag } from "graphql/schema";
import { useTagsContext } from "contexts/TagsContext";
import TagsField from "core/tags/TagsField";

interface Props {
  multivariantId: ID;
  multivariantTags?: Tag[];
}

const MultivariantTags = ({ multivariantId, multivariantTags }: Props) => {
  const { tags, addMultivariantTag, loadingMultivariantTag } = useTagsContext();

  // don't show tags that are being deleted
  const [visibleTags, setTags] = useState(
    multivariantTags?.filter((tag) => tags.find((t) => t.id === tag.id)) ?? [],
  );

  const [deleteMultivariantTag] = useMutation(DELETE_MULTIVARIANT_TAG);

  const removeExecutionTag = useCallback(
    (tagId: ID) => {
      try {
        return deleteMultivariantTag({
          variables: {
            multivariantId,
            tagId,
          },
        });
      } catch (e) {
        return Promise.resolve(true);
      }
    },
    [multivariantId, deleteMultivariantTag],
  );

  const onAddMultivariantTagClick = useCallback(
    (tagId: ID) => {
      // to prevent adding the same tag multiple times
      if (!multivariantTags?.find((tag) => tag.id === tagId)) {
        addMultivariantTag(tagId, multivariantId);
      }
    },
    [multivariantId, multivariantTags, addMultivariantTag],
  );

  const onRemoveMultivariantTagClick = useCallback(
    (tagId: ID) => {
      // to prevent the user from attempting to removing the same tag after already removing it
      setTags(visibleTags.filter((tag) => tag.id !== tagId));
      removeExecutionTag(tagId);
    },
    [removeExecutionTag, setTags, visibleTags],
  );

  return (
    <div className={css(styles.container)}>
      <TagsField
        containerType="column"
        loading={loadingMultivariantTag}
        selectedTags={multivariantTags ?? []}
        setSelectedTags={setTags}
        onAddTag={onAddMultivariantTagClick}
        onRemoveTag={onRemoveMultivariantTagClick}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: "100%",
    height: "30px",
  },
});

export default MultivariantTags;
