import React, {
  MouseEvent,
  ReactNode,
  useMemo,
  ReactElement,
  useCallback,
  useEffect,
} from "react";
import { useSubscription, useQuery } from "@apollo/client";
import classnames from "classnames";
import gql from "graphql-tag";
import {
  Execution,
  ExecutionFilter,
  ExecutionSort,
  ExecutionStatus,
  ExecutionType,
  ID,
} from "graphql/schema";
import {
  GetExecutionDetailsData,
  GET_PACK_EXECUTIONS,
  GET_SUBSCRIPTION,
  GetPackExecutionsData,
  GetPackExecutionsVars,
} from "graphql/queries";
import { PackExecutionDataFields } from "graphql/fragments";
import { ActionDefinition } from "./ExecutionActionDefinitions";
import { ColumnDefinition } from "./ExecutionColumnDefinitions";
import imgBoxChecked from "images/box-checked.svg";
import imgBoxCheckedBlue from "images/box-checked-blue.svg";
import imgBoxCheckedGreen from "images/box-checked-green.svg";
import imgBoxUnchecked from "images/box-unchecked.svg";
import LinkButton from "core/forms/LinkButton";
import { ExecutionEndedStatuses } from "helpers/constants";
import LoadingRow from "core/table/LoadingRow";
import {
  CheckedIdRecord,
  useMultiSelectContext,
} from "contexts/MultiSelectContext";
import { useExecutionContext } from "contexts/executions/ExecutionContext";
import { useComposerStateContext } from "contexts/ComposerStateContext";
import ExecutionPackList from "./ExecutionPackList";
import {
  MY_BOTS_SUBSCRIPTIONS_SUBTAB,
  MY_BOTS_SUBTAB,
} from "helpers/navigation";

interface Props {
  executionId: ID;
  active: boolean;
  currentId?: ID;
  checkedList?: CheckedIdRecord;
  onExecutionSelected: (
    exId: string,
    name?: string,
    type?: ExecutionType,
    status?: ExecutionStatus,
  ) => (e: MouseEvent) => void;
  columns: ColumnDefinition[];
  actionDefs?: ActionDefinition[];
  onShowActionModal: (
    name: string,
    action: () => void,
    execution?: Execution,
  ) => void;
  isPackListOpen: boolean;
  onUpdateOpenPacks?: (id: ID, remove: boolean) => void;
  onCustomizerColumnClick: (e: MouseEvent<HTMLDivElement>) => void;
  setHovering: () => void;
  setNotHovering: () => void;
  isCustomizerHovering: boolean;
  isSyndication?: boolean;
}

function SubscriptionWrapper({
  children,
  id,
  visibleColumns,
}: {
  children: ReactNode;
  id: ID;
  visibleColumns: Set<string>;
}) {
  const listSubscription = useMemo(
    () => gql`
    subscription ExecutionListSubscription($executionIds: [ID]!) {
        execution(ids: $executionIds) {
            id
            status
            measurements {
                ${visibleColumns.has("ABSOLUTE_PROFIT") ? "absoluteProfit" : ""}
                ${
                  visibleColumns.has("NUMBER_OF_TRADES") ? "numberOfTrades" : ""
                }
                ${visibleColumns.has("MAX_DRAWDOWN") ? "maxDrawdown" : ""}
                ${
                  visibleColumns.has("PERCENT_PROFITABLE_TRADES")
                    ? "percentProfitableTrades"
                    : ""
                }
                ${visibleColumns.has("PROFITABILITY") ? "profitability" : ""}
                ${visibleColumns.has("BUY_HOLD_RATIO") ? "buyHoldRatio" : ""}
                ${
                  visibleColumns.has("AVG_POSITION_PRICE")
                    ? "avgPositionPrice"
                    : ""
                }
                ${
                  visibleColumns.has("AVG_MONTHLY_PROFIT")
                    ? "avgMonthlyProfit"
                    : ""
                }
                ${visibleColumns.has("AVG_WIN_MONTH") ? "avgWinMonth" : ""}
                ${visibleColumns.has("AVG_LOSE_MONTH") ? "avgLoseMonth" : ""}
                ${
                  visibleColumns.has("PERC_PROFITABLE_MONTHS")
                    ? "percProfitableMonths"
                    : ""
                }
                ${visibleColumns.has("POSITION_AMOUNT") ? "positionAmount" : ""}
                ${
                  visibleColumns.has("POSITION_ABSOLUTE_PROFIT")
                    ? "positionAbsoluteProfit"
                    : ""
                }
                ${
                  visibleColumns.has("POSITION_PROFIT_LOSS")
                    ? "positionProfitLoss"
                    : ""
                }
                ${visibleColumns.has("BALANCE") ? "balance" : ""}
                ${visibleColumns.has("RISK_SCORE") ? "riskScore" : ""}
                avgBarsInTrade
                profitFactor
                avgTradePrice
                lastTick
                positionState
                ${
                  visibleColumns.has("PERC_BUY_HOLD_RATIO")
                    ? "percBuyHoldRatio"
                    : ""
                }
                ${visibleColumns.has("BUY_HOLD_RETURN") ? "buyHoldReturn" : ""}
                ${visibleColumns.has("SHARPE_RATIO") ? "sharpeRatio" : ""}
                ${visibleColumns.has("SORTINO_RATIO") ? "sortinoRatio" : ""}
                ${
                  visibleColumns.has("TOTAL_REALIZED_GAIN")
                    ? "totalRealizedGain"
                    : ""
                }
                ${
                  visibleColumns.has("TOTAL_REALIZED_LOSS")
                    ? "totalRealizedLoss"
                    : ""
                }
                ${
                  visibleColumns.has("CONSISTENCY_SCORE")
                    ? "consistencyScore"
                    : ""
                }
            }
            error
        }
    }`,
    [visibleColumns],
  );
  useSubscription(listSubscription, { variables: { executionIds: [id] } });
  return <>{children}</>;
}

function ExecutionRow({
  active,
  executionId,
  checkedList,
  onExecutionSelected,
  columns,
  actionDefs,
  onShowActionModal,
  currentId,
  isPackListOpen,
  onUpdateOpenPacks,
  onCustomizerColumnClick,
  setHovering,
  setNotHovering,
  isCustomizerHovering,
  isSyndication = false,
}: Props) {
  const {
    isCheckedList,
    toggleChecked,
    exeCheckedType,
    disableCheckedList,
    checkedForSyndication,
    multiCoinPackExchangeAndCurrency,
    setMultiCoinPackExchangeAndCurrency,
    setMultiCoinPackCurrencyPairs,
    canAddToMultiCoinPack,
    updateMultiCoinPackCurrencyPairs,
  } = useMultiSelectContext();
  const { queryVariables, creationPath, syndicationId } = useExecutionContext();
  const {
    displayForPack,
    isDisplayed,
    selected,
    isCopied,
    updatedSyndication,
    setUpdatedSyndication,
    isMultiCoinPack,
  } = useComposerStateContext();

  const visibleColumns = useMemo(
    () => new Set(columns.map((c) => c.column)),
    [columns],
  );
  const listQuery = useMemo(
    () => gql`
    query GetExecutionRow($executionId: ID!) {
        execution(id: $executionId) {
            id
            name
            isPack
            packPercentage
            status
            candleType
            type
            exchange
            currencyPair
            currencyPairDetails {
                id
                exchange
                pair
                base
                quote
                settleCurrency
                positionCurrency
            }
            multiCoinCurrency
            multiCoinPackExecutions {
              currency
              percentage
            }
            ${visibleColumns.has("CANDLE_SIZE") ? "candleSize" : ""}
            measurements {
                ${visibleColumns.has("ABSOLUTE_PROFIT") ? "absoluteProfit" : ""}
                ${
                  visibleColumns.has("NUMBER_OF_TRADES") ? "numberOfTrades" : ""
                }
                ${visibleColumns.has("MAX_DRAWDOWN") ? "maxDrawdown" : ""}
                ${
                  visibleColumns.has("PERCENT_PROFITABLE_TRADES")
                    ? "percentProfitableTrades"
                    : ""
                }
                ${visibleColumns.has("PROFITABILITY") ? "profitability" : ""}
                ${
                  visibleColumns.has("AVG_POSITION_PRICE")
                    ? "avgPositionPrice"
                    : ""
                }
                ${
                  visibleColumns.has("AVG_MONTHLY_PROFIT")
                    ? "avgMonthlyProfit"
                    : ""
                }
                ${visibleColumns.has("AVG_WIN_MONTH") ? "avgWinMonth" : ""}
                ${visibleColumns.has("AVG_LOSE_MONTH") ? "avgLoseMonth" : ""}
                ${
                  visibleColumns.has("PERC_PROFITABLE_MONTHS")
                    ? "percProfitableMonths"
                    : ""
                }
                ${visibleColumns.has("POSITION_AMOUNT") ? "positionAmount" : ""}
                ${
                  visibleColumns.has("POSITION_ABSOLUTE_PROFIT")
                    ? "positionAbsoluteProfit"
                    : ""
                }
                ${
                  visibleColumns.has("POSITION_PROFIT_LOSS")
                    ? "positionProfitLoss"
                    : ""
                }
                ${visibleColumns.has("BALANCE") ? "balance" : ""}
                ${visibleColumns.has("RISK_SCORE") ? "riskScore" : ""}
                avgBarsInTrade
                profitFactor
                avgTradePrice
                lastTick
                positionState
                ${visibleColumns.has("BUY_HOLD_RETURN") ? "buyHoldReturn" : ""}
                ${visibleColumns.has("SHARPE_RATIO") ? "sharpeRatio" : ""}
                ${visibleColumns.has("SORTINO_RATIO") ? "sortinoRatio" : ""}
                ${
                  visibleColumns.has("TOTAL_REALIZED_GAIN")
                    ? "totalRealizedGain"
                    : ""
                }
                ${
                  visibleColumns.has("TOTAL_REALIZED_LOSS")
                    ? "totalRealizedLoss"
                    : ""
                }
                ${
                  visibleColumns.has("CONSISTENCY_SCORE")
                    ? "consistencyScore"
                    : ""
                }
            }
            favorite
            error
            runtimeSeconds
            shareToken
            scriptDetails {
                id
                scriptId
                revisionId
                parameters {
                    fieldName
                    readableName
                }
                name
                ${visibleColumns.has("SCRIPT_VERSION") ? "version" : ""}
                ${visibleColumns.has("SCRIPT_VERSION") ? "isLatest" : ""}
                ${visibleColumns.has("SCRIPT_VERSION") ? "createdAt" : ""}
            }
            ${visibleColumns.has("SCRIPT_NAME") ? "algorithmSettings" : ""}
            ${visibleColumns.has("CREATION_DATE") ? "createdAt" : ""}
            leverage
            leverageShort
            runNumber
            multivariantIterationId
            scriptVersionSequence
            ${
              visibleColumns.has("TAG_ID")
                ? `tags {
                id
                name
                color
            }`
                : ""
            }
            ${visibleColumns.has("ALLOCATION") ? "allocation" : ""}
            ${visibleColumns.has("STARTED_AT") ? "rangeStart" : ""}
            ${visibleColumns.has("STARTED_AT") ? "startedAt" : ""}
            ${visibleColumns.has("ENDED_AT") ? "rangeEnd" : ""}
            ${visibleColumns.has("ENDED_AT") ? "endedAt" : ""}
            ${visibleColumns.has("AUTO_REBALANCE") ? "autoRebalance" : ""}
            stage
            syndicationId
            ${
              isSyndication
                ? `syndicationSettings {
                maxAllocation
                minAllocation
                description
                isVisible
                allowList {
                    nickname
                    canRemove
                }
                accessControl
                shareToken
                ${
                  visibleColumns.has("ACTIVE_SUBSCRIBERS")
                    ? "activeSubscribersCount"
                    : ""
                }
            }`
                : ""
            }
            syndication {
                name
            }
            stopLossPercentage
            stopLossTrailing
            takeProfitPercentage
            takeProfitTrailingPercentage
            notes
            owner {
                nickname
            }
            preloadedScript
            maxBuy
        }
    }`,
    [visibleColumns, isSyndication],
  );

  // Depending on if we receive an execution id or share token, we must run a different GraphQL query
  const exQuery = useMemo(
    () =>
      creationPath === MY_BOTS_SUBSCRIPTIONS_SUBTAB
        ? GET_SUBSCRIPTION
        : listQuery,
    [listQuery, creationPath],
  );
  const exVars = useMemo(
    () =>
      creationPath === MY_BOTS_SUBSCRIPTIONS_SUBTAB
        ? { executionId: executionId, syndicationId }
        : { executionId: executionId },
    [executionId, creationPath, syndicationId],
  );
  const { data, refetch } = useQuery<GetExecutionDetailsData>(exQuery, {
    variables: { ...exVars },
  });
  const execution = (
    creationPath === MY_BOTS_SUBSCRIPTIONS_SUBTAB
      ? (data as any)?.execution?.subscription
      : data?.execution
  ) as Execution | undefined;

  useEffect(() => {
    if (updatedSyndication === executionId) {
      setUpdatedSyndication(undefined);
      refetch();
    }
  }, [executionId, updatedSyndication, setUpdatedSyndication, refetch]);

  const canToggleCheck = useMemo(() => {
    if (checkedList && execution && checkedList[execution.id]) {
      return true;
    }

    if (
      exeCheckedType === "CREATE_PACK" &&
      checkedList &&
      Object.keys(checkedList).filter((id) => checkedList[id]).length >= 51 &&
      execution &&
      !checkedList[execution.id]
    ) {
      return false;
    }

    return (
      exeCheckedType === "SELECT" ||
      (!execution?.isPack &&
        !execution?.syndicationId &&
        exeCheckedType === "CREATE_PACK" &&
        (!isMultiCoinPack ||
          (isMultiCoinPack &&
            execution &&
            canAddToMultiCoinPack(
              execution.exchange,
              execution.currencyPairDetails.settleCurrency,
              execution.currencyPair,
            )))) ||
      (exeCheckedType === "SYNDICATION" &&
        execution?.isPack &&
        execution?.type === "PAPERTRADE" &&
        (!checkedForSyndication ||
          (checkedForSyndication &&
            checkedForSyndication.id === execution?.id)))
    );
  }, [
    exeCheckedType,
    checkedList,
    execution,
    checkedForSyndication,
    isMultiCoinPack,
    canAddToMultiCoinPack,
  ]);

  const onCheckboxClick = useCallback(() => {
    if (!canToggleCheck) return;

    toggleChecked({
      id: executionId,
      exchange: execution?.exchange,
      currencyPair: execution?.currencyPair,
      leverage: execution?.leverage,
      leverageShort: execution?.leverageShort,
      status: execution?.status,
    });

    if (
      execution &&
      isMultiCoinPack &&
      exeCheckedType === "CREATE_PACK" &&
      !multiCoinPackExchangeAndCurrency
    ) {
      setMultiCoinPackExchangeAndCurrency({
        exchange: execution.exchange,
        settleCurrency: execution.currencyPairDetails.settleCurrency,
        initialCurrencyPair: execution.currencyPair,
      });
      setMultiCoinPackCurrencyPairs({
        [execution.currencyPair]: 1,
      });
    } else if (
      execution &&
      isMultiCoinPack &&
      exeCheckedType === "CREATE_PACK" &&
      multiCoinPackExchangeAndCurrency
    ) {
      const checkedListCopy = checkedList ?? {};
      delete checkedListCopy[executionId];

      if (!Object.keys(checkedListCopy).length) {
        setMultiCoinPackExchangeAndCurrency(undefined);
        setMultiCoinPackCurrencyPairs({});
      } else {
        updateMultiCoinPackCurrencyPairs(
          !checkedList?.[executionId] ?? true,
          execution.exchange,
          execution.currencyPairDetails.settleCurrency,
          execution.currencyPair,
        );
      }
    }
  }, [
    canToggleCheck,
    toggleChecked,
    executionId,
    checkedList,
    execution,
    exeCheckedType,
    isMultiCoinPack,
    multiCoinPackExchangeAndCurrency,
    setMultiCoinPackExchangeAndCurrency,
    setMultiCoinPackCurrencyPairs,
    updateMultiCoinPackCurrencyPairs,
  ]);

  const onRowClick = useCallback(
    (isPack: boolean | undefined, id: ID, type: ExecutionType) => {
      if (
        isCheckedList &&
        ((!isPack && exeCheckedType === "CREATE_PACK") ||
          (isPack && exeCheckedType === "SYNDICATION" && type === "PAPERTRADE"))
      ) {
        onCheckboxClick();
      } else {
        if (onUpdateOpenPacks) onUpdateOpenPacks(id, isPackListOpen);

        // if on the settings of another execution, and user clicks on a strategy pack with it's sub-executions hidden
        if (!isPackListOpen && selected && isDisplayed && !isCopied) {
          displayForPack(id);
          disableCheckedList();
        }
      }
    },
    [
      exeCheckedType,
      onCheckboxClick,
      onUpdateOpenPacks,
      isPackListOpen,
      displayForPack,
      selected,
      isDisplayed,
      disableCheckedList,
      isCopied,
      isCheckedList,
    ],
  );

  const toggleBtn = useMemo(
    () =>
      checkedList && (
        <LinkButton
          onClick={onCheckboxClick}
          stopPropagation={true}
          className={classnames("select", {
            disabled:
              !canToggleCheck ||
              (exeCheckedType === "SELECT" && execution?.packPercentage),
            "create-pack":
              exeCheckedType === "CREATE_PACK" &&
              execution &&
              !checkedList[execution?.id],
            "create-syndication":
              exeCheckedType === "SYNDICATION" &&
              execution?.isPack &&
              checkedForSyndication?.id !== executionId,
          })}
        >
          <img
            alt="Checked Box"
            src={
              checkedList[executionId] ||
              (checkedForSyndication?.id === executionId &&
                exeCheckedType === "SYNDICATION")
                ? exeCheckedType === "SELECT"
                  ? imgBoxChecked
                  : exeCheckedType === "CREATE_PACK"
                  ? imgBoxCheckedBlue
                  : imgBoxCheckedGreen
                : imgBoxUnchecked
            }
          />
        </LinkButton>
      ),
    [
      checkedList,
      executionId,
      canToggleCheck,
      onCheckboxClick,
      execution,
      exeCheckedType,
      checkedForSyndication,
    ],
  );

  const { data: packData } = useQuery<
    GetPackExecutionsData,
    GetPackExecutionsVars
  >(GET_PACK_EXECUTIONS, {
    variables: {
      packId: executionId,
      query: {
        fromId: undefined,
        numberOfResults: 50,
      },
      sort: queryVariables.sort as ExecutionSort,
      filters: queryVariables.filters?.filters?.filter(
        (filter) =>
          filter?.filterKey !== "FAVORITE" &&
          filter?.filterKey !== "IS_PACK" &&
          filter?.filterKey !== "IS_MULTI_COIN_PACK" &&
          filter?.filterKey !== "MULTI_COIN_PACK" &&
          filter?.filterKey !== "CURRENCY_PAIR",
      ) as ExecutionFilter[],
    },
    skip: !isPackListOpen,
  });

  const packs = packData?.packExecutions?.length ? (
    <ExecutionPackList
      currentId={currentId}
      executionIds={packData.packExecutions.map(
        (execution: PackExecutionDataFields) => execution.id,
      )}
      checkedList={checkedList}
      onExecutionSelected={onExecutionSelected}
      columns={columns}
      actionDefs={actionDefs}
      onShowActionModal={onShowActionModal}
      canHightlight={exeCheckedType !== "CREATE_PACK" || !isCheckedList}
      onCustomizerColumnClick={onCustomizerColumnClick}
      setHovering={setHovering}
      setNotHovering={setNotHovering}
      isCustomizerHovering={isCustomizerHovering}
    />
  ) : null;

  // NOTE: As the full query still happens in the parent list, by the time this row renders the data should be cached
  if (!execution) return <LoadingRow columnCount={columns.length} />;

  const { type, status, scriptDetails, runNumber } = execution;

  // We can't subscribe to shared executions currently, and we don't want to subscribe to ended executions
  const shouldSubscribe =
    creationPath !== MY_BOTS_SUBSCRIPTIONS_SUBTAB &&
    (ExecutionEndedStatuses.indexOf(status) < 0 ||
      (execution.packPercentage && execution.type !== "BACKTEST"));

  const row: ReactElement =
    !execution.isPack &&
    ((exeCheckedType !== "CREATE_PACK" && exeCheckedType !== "SYNDICATION") ||
      !isCheckedList) ? (
      <tr
        className={classnames("as-link", {
          active,
          "select-enabled": checkedList,
          "not-hovering": isCustomizerHovering,
        })}
        onClick={onExecutionSelected(
          executionId,
          `${scriptDetails?.name ?? ""}_${runNumber}`,
          type,
          status,
        )}
      >
        {checkedList && (
          <td
            className={classnames("table-row-checkbox", {
              hidden: !isCheckedList,
            })}
          >
            {toggleBtn}
          </td>
        )}
        {columns.map(({ column, className, propertyFn }, i) =>
          creationPath !== MY_BOTS_SUBTAB &&
          (column === "VISIBLE_SYNDICATION" ||
            column === "ACTIVE_SUBSCRIBERS") ? null : (
            <td className={className} key={i}>
              {propertyFn(execution, actionDefs, onShowActionModal)}
            </td>
          ),
        )}
        {creationPath !== MY_BOTS_SUBSCRIPTIONS_SUBTAB && (
          <td
            className={classnames("add-column", {
              hover: isCustomizerHovering,
            })}
            onClick={onCustomizerColumnClick}
            onMouseEnter={setHovering}
            onMouseLeave={setNotHovering}
          />
        )}
        <td className="blank" />
      </tr>
    ) : (
      <tr
        className={classnames("as-link", {
          active,
          "select-enabled": checkedList,
          "checked-for-pack":
            exeCheckedType === "CREATE_PACK" &&
            checkedList &&
            !!checkedList[execution.id],
          "not-hovering": isCustomizerHovering,
        })}
        onClick={() =>
          onRowClick(execution.isPack, execution.id, execution.type)
        }
      >
        {checkedList && (
          <td
            className={classnames("table-row-checkbox", {
              hidden: !isCheckedList,
            })}
          >
            {toggleBtn}
          </td>
        )}
        {columns.map(({ column, className, propertyFn }, i) =>
          creationPath !== MY_BOTS_SUBTAB &&
          (column === "VISIBLE_SYNDICATION" ||
            column === "ACTIVE_SUBSCRIBERS") ? null : (
            <td className={className} key={i}>
              {propertyFn(
                execution,
                actionDefs,
                onShowActionModal,
                isPackListOpen,
                onUpdateOpenPacks,
              )}
            </td>
          ),
        )}
        {creationPath !== MY_BOTS_SUBSCRIPTIONS_SUBTAB && (
          <td
            className="add-column"
            onClick={onCustomizerColumnClick}
            onMouseEnter={setHovering}
            onMouseLeave={setNotHovering}
          />
        )}
        <td className="blank" />
      </tr>
    );

  if (shouldSubscribe)
    return (
      <SubscriptionWrapper visibleColumns={visibleColumns} id={executionId}>
        {row}
        {packs}
      </SubscriptionWrapper>
    );
  else
    return (
      <>
        {row}
        {packs}
      </>
    );
}
export default React.memo(ExecutionRow);
