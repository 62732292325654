import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useComposerStateContext } from "../../contexts/ComposerStateContext";
import { useMultiSelectContext } from "../../contexts/MultiSelectContext";
import LinkButton from "../forms/LinkButton";
import Item from "./elements/NavigationItem";
import "./tertiary.scss";
import "./stages.scss";

export interface NavigationItem {
  active?: boolean;
  exact?: boolean;
  action?: () => void;
  path?: string;
  title: React.ReactNode;
  disabled?: boolean;
  stage?: string;
  newTests?: number | null;
  id?: string;
}

export type ITertiaryNavigationProps = Readonly<{
  className?: string;
  items: (NavigationItem | undefined)[];
  buttons?: {
    exact?: boolean;
    path: string;
    title: React.ReactNode;
    to?: string;
    action?: () => void;
    dataFor?: string;
    tooltip?: string;
    className?: string;
  }[];
  hidePanels?: () => void;
  children?: any;
  home?: boolean;
}>;

function TertiaryNavigation({
  className,
  items,
  buttons,
  hidePanels,
  children,
  home = false,
}: ITertiaryNavigationProps) {
  const { t } = useTranslation();
  const { openBacktestCreation, openPaperTradeCreation } =
    useComposerStateContext();
  const {
    isCreateDropdownOpen,
    closeDropdown,
    exeCheckedType,
    disableCheckedList,
  } = useMultiSelectContext();

  const onCreateClick = (path: string) => {
    if (exeCheckedType === "CREATE_PACK") {
      disableCheckedList();
    }

    if (path === "papertrade") {
      openPaperTradeCreation();
      closeDropdown();
    } else {
      openBacktestCreation();
      closeDropdown();
    }
  };

  return (
    <div className={classnames("nav", "nav--tertiary", className)}>
      <ul className="nav__container">
        {(items.filter((item) => item != null) as NavigationItem[]).map(
          (
            {
              disabled,
              active,
              exact,
              title,
              action,
              path = "",
              stage = "",
              newTests,
              id,
            },
            i,
          ) => (
            <Item
              key={i}
              active={active}
              disabled={disabled}
              exact={exact}
              path={path}
              action={action}
              title={title}
              stage={stage}
              newTests={newTests}
              id={id}
            />
          ),
        )}
        <li className="nav__item--divider" />
        {home && children && (
          <>
            {children[0]}
            {children[1]}
          </>
        )}
        <li className="nav__item--right">
          <Switch>
            {buttons &&
              buttons.map(
                (
                  {
                    exact,
                    path,
                    title,
                    action,
                    to,
                    dataFor,
                    tooltip,
                    className,
                  },
                  i,
                ) => (
                  <Route key={i} exact={exact} path={path}>
                    {to !== undefined ? (
                      <Link className="nav__item--button" to={to}>
                        {title}
                      </Link>
                    ) : (
                      <LinkButton
                        title={tooltip}
                        id={dataFor}
                        className={classnames("nav__item--button", className)}
                        onClick={action}
                      >
                        {title}
                      </LinkButton>
                    )}
                    {home && isCreateDropdownOpen && (
                      <ClickAwayListener onClickAway={closeDropdown}>
                        <div className="nav__dropdown">
                          <div
                            className="nav__dropdown--item"
                            id="open-backtest-composer"
                            onClick={() => onCreateClick("simulation")}
                          >
                            {t("common.backtest")}
                          </div>
                          <div
                            className="nav__dropdown--item"
                            id="open-strategy-pack-composer"
                            onClick={() => onCreateClick("papertrade")}
                          >
                            {t("common.paper_trade")}
                          </div>
                        </div>
                      </ClickAwayListener>
                    )}
                  </Route>
                ),
              )}
          </Switch>
        </li>
        {home && children && children[2]}
      </ul>
      {home && children ? children[3] : children}
    </div>
  );
}

export default React.memo(TertiaryNavigation);
