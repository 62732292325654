import React, { ReactNode } from "react";
import { StyleSheet, css } from "aphrodite";
import colors from "styles/colors";

type Props = Readonly<{
  color?:
    | "bronze"
    | "paleOrange"
    | "meteor"
    | "pastelGreen"
    | "pear"
    | "yellow"
    | "offWhite";
  onClick?: () => void;
  children: ReactNode;
}>;

function ColorSpan({ color = "bronze", onClick, children }: Props) {
  return (
    <span
      onClick={onClick}
      className={css(styles[color], onClick && styles.clickable)}
    >
      {children}
    </span>
  );
}

const styles = StyleSheet.create({
  bronze: {
    color: colors.bronze,
  },
  paleOrange: {
    color: colors.paleOrange,
  },
  meteor: {
    color: colors.meteor,
  },
  pastelGreen: {
    color: colors.pastelGreen,
  },
  pear: {
    color: colors.pear,
  },
  yellow: {
    color: colors.yellow,
  },
  offWhite: {
    color: colors.offWhite,
  },
  clickable: {
    cursor: "pointer",
    ":hover": {
      textDecoration: "underline",
    },
  },
});

export default ColorSpan;
