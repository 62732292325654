import React from "react";
import { flexRender, Header } from "@tanstack/react-table";
import { StyleSheet, css } from "aphrodite";
import { SortDirection } from "__generated__/graphql";
import imgSearch from "images/search.svg";
import Tooltip from "@material-ui/core/Tooltip";
import colors from "styles/colors";
import { useBoolean } from "helpers/hooks";

type Props<D> = Readonly<{
  header: Header<D, unknown>;
  sort?: {
    /**
     * Id of column to sort by. This mutch match the `id` field of
     * the column definition.
     */
    sortKey: string;
    sortDirection: SortDirection;
  };
  setSortKey?: (key: string) => void;
  onColumnResize?: (size: number, id: string) => void;
  onColumnFilterClick?: (id: string) => void;
}>;

export default function InfiniteTableHeader<D>(props: Props<D>) {
  const { header, sort, setSortKey, onColumnResize, onColumnFilterClick } =
    props;

  const [isHovering, setIsHovering, setIsNotHovering] = useBoolean(false);

  const sortable = header.column.columnDef.meta?.sortable === true;
  const isSorted = sortable && sort && sort.sortKey === header.id;
  const isRightAligned = header.column.columnDef.meta?.rightAligned === true;
  const canFilter = header.column.columnDef.meta?.canFilter === true;

  const filterIcon = onColumnFilterClick && (
    <img
      className={css(
        styles.filterIcon,
        styles[isHovering ? "visible" : "hidden"],
      )}
      src={imgSearch}
      alt="Filter"
      onClick={(e) => {
        e.stopPropagation();
        onColumnFilterClick(header.column.id);
      }}
      width={13}
      height={13}
    />
  );

  const headerRender = (
    <div
      onClick={() => sortable && setSortKey?.(header.id)}
      className={css(
        styles[isSorted ? "sortedHeaderLabel" : "headerLabel"],
        isRightAligned && styles.rightAligned,
      )}
    >
      {canFilter && isRightAligned && filterIcon}
      <span>
        {flexRender(header.column.columnDef.header, header.getContext())}
        {isSorted && (
          <i
            className={css(
              styles.chevron,
              sort.sortDirection === SortDirection.Asc
                ? styles.asc
                : styles.desc,
            )}
          ></i>
        )}
      </span>
      {canFilter && !isRightAligned && filterIcon}
    </div>
  );

  const tooltip = header.column.columnDef.meta?.tooltip;

  return (
    <th
      colSpan={header.colSpan}
      className={css(styles.th)}
      style={{ width: header.getSize() }}
      onMouseOver={setIsHovering}
      onMouseLeave={setIsNotHovering}
    >
      {tooltip ? (
        <Tooltip
          title={
            <div>
              <div className={css(styles.tooltipTitle)}>
                {header.column.columnDef.header}
              </div>
              <div className={css(styles.tooltipBody)}>{tooltip}</div>
            </div>
          }
          PopperProps={{
            className: css(styles.tooltipPopper),
          }}
        >
          {headerRender}
        </Tooltip>
      ) : (
        headerRender
      )}
      {header.column.getCanResize() && (
        <div
          className={css(styles.resizer)}
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
          onMouseUp={() => onColumnResize?.(header.getSize(), header.column.id)}
        />
      )}
    </th>
  );
}

const styles = StyleSheet.create({
  headerLabel: {
    color: colors.gray,
    ":hover": {
      color: colors.offWhite,
    },
  },
  sortedHeaderLabel: {
    color: colors.offWhite,
  },
  th: {
    minHeight: "16px",
    background: colors.black,
    color: colors.offWhite,
    borderRight: `1px solid ${colors.offBlack}`,
    textAlign: "left",
    padding: "6px 10px 6px 10px",
    position: "sticky",
    fontWeight: 700,
    cursor: "pointer",
  },
  resizer: {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    width: "10px",
    background: "transparent",
    cursor: "col-resize",
    userSelect: "none",
    touchAction: "none",
    ":hover": {
      backgroundColor: colors.charcoal,
    },
  },
  chevron: {
    borderTop: "5px solid transparent",
    borderBottom: "5px solid transparent",
    borderLeft: `5px solid ${colors.offWhite}`,
    display: "inline-block",
    marginTop: "3px",
    marginLeft: "6px",
  },
  asc: {
    transform: "rotate(-90deg)",
    "-webkit-transform": "rotate(-90deg)",
    marginTop: "3px",
  },
  desc: {
    transform: "rotate(90deg)",
    "-webkit-transform": "rotate(90deg)",
  },
  rightAligned: {
    textAlign: "right",
  },
  tooltipTitle: {
    textAlign: "left",
    fontWeight: 600,
    fontSize: "12px",
    padding: "10px 5px",
    color: colors.white,
  },
  tooltipBody: {
    padding: "10px 5px",
    textAlign: "left",
    maxWidth: "280px",
    color: colors.offWhite,
    fontWeight: 500,
    fontSize: "12px",
  },
  tooltipPopper: {
    ":nth-child(1n) > div": {
      backgroundColor: colors.blackTooltip,
    },
  },
  filterIcon: {
    margin: "0 5px",
    opacity: 0.7,
    ":hover": {
      opacity: 1,
    },
  },
  hidden: {
    visibility: "hidden",
  },
  visible: {
    visibility: "visible",
  },
});
