import React from "react";
import { useTranslation } from "react-i18next";
import { getDateTime } from "../../../helpers/dates";

type Props = Readonly<{
  version: number;
  isLatest: boolean;
  createdAt?: string;
}>;

function ExecutionScriptRevision({ version, isLatest, createdAt }: Props) {
  const { t } = useTranslation();

  if (!createdAt) return <div>--</div>;
  const date = isLatest
    ? t("common.current")
    : version === 1
    ? t("common.original")
    : getDateTime(createdAt);
  return (
    <div
      title={`${version} - ${date}`}
      style={{ textOverflow: "ellipsis", overflow: "hidden" }}
    >
      <span>
        {version} - {date}
      </span>
    </div>
  );
}

export default React.memo(ExecutionScriptRevision);
