import React, { createContext, useContext, useEffect, useMemo } from "react";
import invariant from "invariant";
import { useQuery, useSubscription } from "@apollo/client";
import {
  ANNOUNCEMENT,
  GetAnnouncementData,
  GET_ANNOUNCEMENT,
} from "../graphql/queries";
import { Announcement } from "../graphql/schema";
import { useBoolean } from "../helpers/hooks";

interface Context {
  announcement?: Announcement;
  isActive: boolean;
}

export const AnnouncementContext = createContext<Context | undefined>(
  undefined,
);

export function useAnnouncementContext() {
  const context = useContext(AnnouncementContext);
  invariant(
    context != null,
    "Component is not a child of AnnouncementContext provider",
  );
  return context;
}

export function AnnouncementContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { data: announcementData } =
    useQuery<GetAnnouncementData>(GET_ANNOUNCEMENT);
  const { data: announcementUpdateData } =
    useSubscription<GetAnnouncementData>(ANNOUNCEMENT);

  const [isActive, setIsActive, setIsNotActive] = useBoolean(false);

  const announcement: Announcement | undefined = useMemo(
    () =>
      announcementUpdateData?.announcement ?? announcementData?.announcement,
    [announcementUpdateData, announcementData],
  );

  useEffect(() => {
    if (announcement?.isActive) {
      setIsActive();
    } else {
      setIsNotActive();
    }
  }, [announcement, setIsActive, setIsNotActive]);

  // hide the announcement after the current time has passed its end time
  useEffect(() => {
    if (announcement?.endTime) {
      const now = new Date();
      const endTime = new Date(announcement.endTime);
      const duration = endTime.getTime() - now.getTime();
      if (duration > 0) {
        setTimeout(() => {
          setIsNotActive();
        }, duration);
      } else if (duration <= 0) {
        setIsNotActive();
      }
    }
  }, [announcement, setIsNotActive]);

  return (
    <AnnouncementContext.Provider value={{ announcement, isActive }}>
      {children}
    </AnnouncementContext.Provider>
  );
}
