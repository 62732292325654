import React, { useContext, useState, useCallback } from "react";
import classnames from "classnames";
import { useApolloClient } from "@apollo/client";
import { PRESIGNED_UPLOAD_URL } from "../../graphql/queries";
import { User, PublicUser } from "../../graphql/schema";
import { UserContext } from "../../contexts/UserContext";
import { AlertContext } from "../../contexts/AlertContext";
import { COLORS } from "../../contexts/TagsContext";
import { IMAGE_REGEX } from "../../helpers/algorithmUtils";
import { useBoolean } from "../../helpers/hooks";
import { logEvent } from "../../helpers/analytics";
import NewSpinner from "../spinner/NewSpinner";
import PhotoCrop from "./PhotoCrop";
import StaticPhoto from "./StaticPhoto";
import imgCamera from "../../images/camera.svg";
import { useTranslation } from "react-i18next";

function storePhoto(
  url: string,
  photo: File,
  userId: string,
): Promise<Response> {
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": photo.type,
      "Content-Length": photo.size.toString(),
      "x-amz-meta-user": userId,
    },
    body: photo,
  });
}

type Props = Readonly<{
  photo: string | undefined;
  className: string;
  isOwner?: boolean;
  onClick?: () => void;
  user: User | PublicUser;
}>;

function ProfilePhoto({
  photo,
  className,
  isOwner = true,
  onClick,
  user,
}: Props) {
  const { t } = useTranslation();

  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { refetch } = useContext(UserContext)!;
  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { addError, setAlert } = useContext(AlertContext)!;

  const client = useApolloClient();

  const [isCropOpen, openCrop, closeCrop] = useBoolean(false);
  const [loading, setLoading, setNotLoading] = useBoolean(false);
  const [correctPhoto, setCorrectPhoto] = useState<File | undefined>(undefined);
  const [croppedPhoto, setCroppedPhoto] = useState<File | undefined>(undefined);
  const [maxZoom, setMaxZoom] = useState<number>(3);

  const onChangePhoto = useCallback(
    (e) => {
      // TODO: Fix eslint error
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!e.target.files.length) {
        const file = e.target.files[0];
        if (file.type.match(IMAGE_REGEX)) {
          setCorrectPhoto(file);
        } else {
          addError("The file upload must be an image");
        }
      } else {
        addError("Problem loading the file");
      }
    },
    [setCorrectPhoto, addError],
  );

  // load the uploaded photo to a hidden img and check if the dimensions are large enough to be used to crop
  const onLoadCorrectPhoto = useCallback(
    async (e) => {
      // TODO: Eliminate use of non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (correctPhoto!.size / 1024 / 1024 > 2) {
        addError(t("error.photo_size_requirement"));
        logEvent("FailedToUploadProfilePicture", { reason: "larger than 2MB" });
        setCorrectPhoto(undefined);
      } else if (
        e.currentTarget.naturalWidth >= 180 &&
        e.currentTarget.naturalHeight >= 180
      ) {
        const minSize = Math.min(
          e.currentTarget.naturalWidth,
          e.currentTarget.naturalHeight,
        );
        const maxZoom = Math.min(3, minSize / 180);
        setMaxZoom(maxZoom);
        openCrop();
      } else {
        addError(t("error.photo_pixel_requirement"));
        logEvent("FailedToUploadProfilePicture", {
          reason: "less than 180x180px",
        });
        setCorrectPhoto(undefined);
      }
    },
    [addError, openCrop, correctPhoto, setMaxZoom, t],
  );

  // load the uploaded photo to a hidden img and check if the dimensions are large enough to be set to the actual photo
  const onLoadCroppedPhoto = useCallback(
    async (e) => {
      if (loading) {
        return;
        // TODO: Eliminate use of non-null assertion
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      } else if (croppedPhoto!.size / 1024 / 1024 > 2) {
        addError(t("error.photo_size_requirement"));
        logEvent("FailedToUploadProfilePicture", { reason: "larger than 2MB" });
      } else if (
        e.currentTarget.naturalWidth >= 180 &&
        e.currentTarget.naturalHeight >= 180
      ) {
        try {
          await setLoading();

          const { data } = await client.query({
            query: PRESIGNED_UPLOAD_URL,
            variables: {
              // TODO: Eliminate use of non-null assertion
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              contentType: croppedPhoto!.type,
              // TODO: Eliminate use of non-null assertion
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              contentLength: croppedPhoto!.size.toString(),
            },
            fetchPolicy: "no-cache",
          });
          // TODO: Eliminate use of non-null assertion
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          storePhoto(data!.presignedUploadUrl!.url, croppedPhoto!, user!.id)
            .then(async (res) => {
              if (res.ok) {
                setAlert({
                  message: t("message.successfully_uploaded_photo"),
                  severity: "success",
                });
                logEvent("UploadedProfilePicture");
                if (!user?.profilePhotoUrl) await refetch();
              } else {
                addError(t("error.failed_to_upload_photo"));
                logEvent("FailedToUploadProfilePicture", {
                  reason: "upload failed",
                });
              }
              await setCroppedPhoto(undefined);
              setNotLoading();
            })
            .catch(async (err) => {
              addError(t("error.failed_to_upload_photo"));
              logEvent("FailedToUploadProfilePicture", {
                reason: "upload failed",
              });
              await setCroppedPhoto(undefined);
              setNotLoading();
            });
        } catch (e) {
          addError(t("error.failed_to_upload_photo"));
          logEvent("FailedToUploadProfilePicture");
          await setCroppedPhoto(undefined);
        }
      } else {
        addError(t("error.photo_pixel_requirement"));
        logEvent("FailedToUploadProfilePicture", {
          reason: "less than 180x180px",
        });
        await setCroppedPhoto(undefined);
      }
    },
    [
      addError,
      croppedPhoto,
      client,
      setAlert,
      user,
      setLoading,
      setNotLoading,
      loading,
      refetch,
      t,
    ],
  );

  const background = COLORS[(user?.id ? Number(user?.id) : 1) % COLORS.length];
  const emptyPhoto = user?.nickname
    ? user.nickname[0]
    : user?.companyName
    ? user.companyName[0]
    : user?.firstName[0] ?? "";

  if (!isOwner)
    return (
      <StaticPhoto
        photo={photo ? photo + `?v=${Date.now()}` : undefined}
        className={className + " photo"}
        background={background}
        emptyPhoto={emptyPhoto}
        emptyClassName={className}
      />
    );

  return (
    <>
      <form>
        <div
          className={classnames(className, "profile-photo", {
            "down-12": !user?.nickname || !user?.description,
          })}
        >
          <label htmlFor="photo-upload">
            {croppedPhoto && (
              <img
                src={URL.createObjectURL(croppedPhoto)}
                alt="Profile"
                className="hidden"
                onLoad={onLoadCroppedPhoto}
              />
            )}
            {correctPhoto && (
              <img
                src={URL.createObjectURL(correctPhoto)}
                alt="Profile"
                className="hidden"
                onLoad={onLoadCorrectPhoto}
              />
            )}
            {loading ? (
              <div className="loading">
                <NewSpinner />
              </div>
            ) : photo ? (
              <img
                src={photo + `?v=${Date.now()}`}
                alt="Profile"
                className="photo"
              />
            ) : (
              <div style={{ background }}>{emptyPhoto}</div>
            )}
            <img src={imgCamera} alt="Camera" className="camera" />
          </label>
          <input
            id="photo-upload"
            type="file"
            onChange={onChangePhoto}
            onClick={(e) => (e.currentTarget.value = "")}
          />
        </div>
      </form>
      {isCropOpen && correctPhoto && (
        <PhotoCrop
          correctPhoto={correctPhoto}
          closeCrop={closeCrop}
          setCorrectPhoto={setCorrectPhoto}
          setCroppedPhoto={setCroppedPhoto}
          maxZoom={maxZoom}
        />
      )}
    </>
  );
}

export default ProfilePhoto;
