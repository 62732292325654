import React, { MouseEvent, useCallback } from "react";
import { ID } from "../../../graphql/schema";
import { UPDATE_EXECUTION_FAVORITE } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import imgStar from "../../../images/star.svg";
import imgStarFavorite from "../../../images/star-favorite.svg";
import "./favorite.scss";

interface Props {
  id: ID;
  favorite?: boolean;
}

const ExecutionFavorite = ({ id, favorite }: Props) => {
  const [updateFavorite, { loading }] = useMutation(UPDATE_EXECUTION_FAVORITE);

  const onClick = useCallback(
    (e: MouseEvent<HTMLSpanElement>) => {
      updateFavorite({ variables: { id, favorite: !favorite } });
      e.stopPropagation();
    },
    [updateFavorite, id, favorite],
  );

  return (
    <div
      onClick={onClick}
      className="favorite"
      style={{ opacity: loading ? 0 : "unset" }}
    >
      {favorite ? (
        <img src={imgStarFavorite} alt="star-favorite" />
      ) : (
        <img src={imgStar} alt="star" />
      )}
    </div>
  );
};

export default ExecutionFavorite;
