const constant = {
  blue: "#339ef3",
  darkBlue: "#d8d8d8",
  darkerBlue: "#334150",
  paleBlue: "#73cef3",
  boldBlue: "#3185fc",
  peacock: "#0a2835",
  navy: "#0b2029",
  green: "#1a8018",
  darkGreen: "#59870f",
  paleGreen: "#7daf7c",
  lightGreen: "#7db47b",
  flatGreen: "#356933",
  brightGreen: "#25a962",
  boldGreen: "#1df0a9",
  success: "#4cc38e",
  pastelGreen: "#6aec8e",
  pine: "#264e5a",
  pineField: "#193644",
  grayPine: "#252F39",
  pineish: "#1a3741",
  pineBlue: "#0a3e51",
  blueishPine: "#081c25",
  offPine: "#142c39",
  darkPine: "#112129",
  blackPine: "#101c20",
  blackishPine: "#051218",
  darkishPine: "#121b1f",
  jetPine: "#0d1c24",
  jetishPine: "#031b25",
  lightPine: "#316373",
  lightishPine: "#173740",
  sacramento: "#0c171c",
  slate: "#3e526b",
  darkSlate: "#26303b",
  yellow: "#b8953b",
  darkYellow: "#856d2f",
  paleYellow: "#998552",
  lightYellow: "#b3a086",
  brightYellow: "#e5ff47",
  boldYellow: "#f4c95d",
  pear: "#c6e030",
  teal: "#35959b",
  meteor: "#d48324",
  orange: "#cd7272",
  paleOrange: "#dc851f",
  lightOrange: "#ff9330",
  boldOrange: "#f85e00",
  bronze: "#b6782b",
  offBronze: "#382a17",
  lightBronze: "#e39d4c",
  orangeBanner: "#986c2b",
  cantaloupe: "#a59683",
  purple: "#bf99fe",
  flatPurple: "#685dc9",
  boldPurple: "#a800ff",
  red: "#cc6464",
  lightRed: "#d68383",
  darkRed: "#990033",
  redError: "#cc6464",
  redBanner: "#932027",
  error: "#9a5b66",
  mostlyBlack: "#081f28",
  camel: "#baa78f",
  twine: "#8f6c42",

  black: "#131313",
  blackIsh: "#121212",
  blackCurrant: "#29282a",
  darkShade: "#242424",
  offDarkShade: "#2e2e2e",
  offBlack: "#1c1c1e",
  blackTooltip: "#222222",
  dark: "#181818",
  darkVoid: "#18181a",
  darkish: "#161616",
  offDark: "#1d1d1d",
  placeholder: "#13262f",
  soot: "#151d20",
  steel: "#656262",
  offSteel: "#3a3a3a",
  metal: "#6d7579",
  white: "#ffffff",
  whiteIsh: "#ebeff3",
  offWhite: "#a7a7a7",
  pearl: "#bababa",
  silver: "#b1c6c6",
  iron: "#7c7c7c",
  paleGray: "#c4c4c4",
  gray: "#706f6f",
  offGray: "#9d9d9d",
  lightGray: "#e5e5e5",
  offLightGray: "#e0e0e0",
  darkGray: "#262f39",
  charcoal: "#333333",
  copper: "#95bdbb",
  lead: "#1d323c",
  warmGray: "#1a1a1a",
  nero: "#191919",
  bastille: "#2d2d2f",
  mineShaft: "#292929",
  offMineShaft: "#2c2b2b",
  night: "#2c2c2c",

  transparentBlack: "#111111e6",
};

const semantic = {
  textColor: "#ffffff",

  bodyBackground: "#131313",
  oddRowColor: "#1a1e1f",

  boldPine: "#37515d",
  palePine: "#4f7775",
  flatPine: "#183746",
  pineMuted: "#1a2d32",

  statusPending: constant.yellow,
  statusRunning: constant.green,
  statusEnded: constant.gray,
  statusFailed: constant.red,
  statusCancelled: constant.gray,
  statusStopping: constant.darkYellow,
  statusLiquidated: constant.blue,

  readonly: "#88705b",

  formBackground: "#16191b", // form__input-DarkBackgroundColor
  formBorder: constant.darkGray, // form__input-DarkBorderColor

  backgroundPrimary: "#131313", // nav-PrimaryBackgroundColor
  backgroundSecondary: constant.offBlack, // nav-SecondaryBackgroundColor

  borderPrimary: constant.darkGray, // nav-PrimaryBorderColor
  borderSecondary: "#131313", // nav-PrimaryBackgroundColor

  url: "#ebeff3", // nav__linkColor
  urlActive: constant.blue, // nav__link-ActiveColor
  urlActiveBackground: "transparent", // nav__link-ActiveBackgroundColor
  urlBorder: constant.offBlack, // nav__linkBorderColor

  cellContract: constant.paleYellow, // $table__cell--contract
  cellSignal: constant.lightYellow, // $table__cell--signal
};

const colors = { ...constant, ...semantic };

export default colors;
