import React from "react";

interface Props {
  photo: string | undefined | null;
  className: string;
  background: string;
  emptyPhoto: string;
  emptyClassName: string;
}

const StaticPhoto: React.FunctionComponent<Props> = ({
  photo,
  className,
  background,
  emptyPhoto,
  emptyClassName,
}) => (
  <>
    {photo ? (
      <img src={photo} alt="Profile" className={className} />
    ) : (
      <div style={{ background }} className={emptyClassName}>
        {emptyPhoto}
      </div>
    )}
  </>
);

export default StaticPhoto;
