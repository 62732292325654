import * as React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import {
  Context,
  useCreateFormContext,
} from "../../../contexts/CreateFormContext";
import SentryRoute from "../../../components/router/SentryRoute";
import LinkButton from "../../forms/LinkButton";
import imgMinimize from "../../../images/minimize.svg";
import imgTrashbin from "../../../images/trashbin.svg";
import imgDropdown from "../../../images/dropdown-arrow.svg";
import imgPlay from "../../../images/play.svg";
import { useBoolean } from "../../../helpers/hooks";
import { EDITOR_TAB } from "helpers/navigation";
import ListItem from "./ListItem";
import IconButton from "core/button/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import "../tertiary.scss";

type Props = Readonly<{
  hidePanels?: () => void;
}>;

type EditorProps = Readonly<{
  editorHandler?: Context["editorHandler"];
}>;

const NOP = () => {};

function EditorMiddleItems({ editorHandler }: EditorProps) {
  const { t } = useTranslation();

  const isExistingScript = editorHandler?.isExistingScript ?? false;
  const deleteClick = editorHandler?.deleteClick ?? NOP;
  const saveClick = editorHandler?.saveClick ?? NOP;
  const saveAsClick = editorHandler?.saveAsClick ?? NOP;
  const refreshChartClick = editorHandler?.refreshChartClick ?? NOP;
  const deleteBtnDisabled = editorHandler?.deleteBtnDisabled ?? true;
  const saveBtnDisabled = editorHandler?.saveBtnDisabled ?? true;
  const isPublicScript = editorHandler?.isPublicScript ?? false;
  const isPreloadedScript = editorHandler?.isPreloadedScript ?? false;
  const { canPreview } = editorHandler?.canPreview ?? {
    canPreview: false,
  };

  const [isDropdownOpen, openDropdown, closeDropdown] = useBoolean(false);

  const toggleDropdown = React.useCallback(() => {
    if (isDropdownOpen) closeDropdown();
    else openDropdown();
  }, [isDropdownOpen, closeDropdown, openDropdown]);

  // Public script - button doesn't exist
  // Otherwise, display the proper button for the `CanPreview` state
  const refreshBtn = isPublicScript ? null : (
    <ListItem borderStyles={["borderLeft"]}>
      <IconButton
        title={
          canPreview
            ? t("terminal.refresh_performance_preview")
            : t("message.cannot_preview_performance")
        }
        id="editor-preview-strategy-performance"
        src={imgPlay}
        alt="Preview strategy performance"
        paddingStyle="leftPadding"
        disabled={!canPreview}
        onClick={canPreview ? refreshChartClick : undefined}
      />
    </ListItem>
  );

  if (isPublicScript)
    return (
      <li className="nav__item--create">
        <LinkButton className="create-button" onClick={saveAsClick}>
          {t("terminal.fork")}
        </LinkButton>
      </li>
    );

  return (
    <>
      {refreshBtn}
      <ListItem borderStyles={["borderLeft"]}>
        <IconButton
          title={
            isPreloadedScript
              ? t("error.cannot_delete_preloaded_scripts")
              : !isExistingScript || deleteBtnDisabled
              ? t("message.script_cannot_be_deleted")
              : undefined
          }
          id="editor-delete-script-button"
          src={imgTrashbin}
          alt="Delete"
          color="red"
          disabled={deleteBtnDisabled}
          onClick={!deleteBtnDisabled ? deleteClick : undefined}
        />
      </ListItem>
      <li
        className={classnames("nav__item--create", {
          "nav__item--disabled": saveBtnDisabled && !isPreloadedScript,
        })}
      >
        <LinkButton
          className="create-button"
          onClick={isPreloadedScript ? saveAsClick : saveClick}
        >
          {t(isPreloadedScript ? "common.save_as" : "common.save")}
        </LinkButton>
      </li>
      {isExistingScript && !isPreloadedScript && (
        <ClickAwayListener onClickAway={closeDropdown}>
          <li className="nav__item--dropdown">
            <LinkButton className="create-button" onClick={toggleDropdown}>
              <img
                className={classnames("dropdown-icon", {
                  reverse: isDropdownOpen,
                })}
                src={imgDropdown}
                alt="Dropdown"
              />
              {isDropdownOpen && (
                <li className="nav__item--create-as">
                  <LinkButton className="create-button" onClick={saveAsClick}>
                    {t("common.save_as")}
                  </LinkButton>
                </li>
              )}
            </LinkButton>
          </li>
        </ClickAwayListener>
      )}
    </>
  );
}

function MiddleHomeItems({ hidePanels }: Props) {
  const { editorHandler } = useCreateFormContext();

  return (
    <>
      <SentryRoute path={`/${EDITOR_TAB}/:scriptId?`}>
        <EditorMiddleItems editorHandler={editorHandler} />
      </SentryRoute>
      <ListItem borderStyles={["borderLeft"]}>
        <IconButton src={imgMinimize} alt="Minimize" onClick={hidePanels} />
      </ListItem>
    </>
  );
}

export default MiddleHomeItems;
