import React, { ReactNode, useMemo } from "react";
import { match } from "react-router";
import { useLocation } from "react-router-dom";
import { History } from "history";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import { ID } from "graphql/schema";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT,
  ExecutionContextProvider,
} from "./ExecutionContext";
import { FilterContextProvider } from "./FilterContext";
import { loadFilters, saveFilters } from "./FilterUtils";
import { getColumns } from "./ColumnsUtils";
import { COLUMN_DEFINITIONS } from "./ExecutionColumns";
import { makeExecutionsColumnDefMap } from "./ExecutionTableColumns";
import ExecutionsInfiniteTable from "components/executions/list/ExecutionsInfiniteTable";
import { MY_BOTS_SUBSCRIPTIONS_SUBTAB } from "helpers/navigation";

const SYNDICATION_SUBSCRIPTIONS_CONTEXT_PROVIDER_QUERY__DEPRECATED = gql`
  query SyndicationSubscriptionsContextProviderDeprecated(
    $id: ID!
    $first: Int
    $after: String
    $sort: ExecutionSort
    $filters: ExecutionListFilters
  ) {
    execution(id: $id) {
      subscriptionExecutions(
        first: $first
        after: $after
        filters: $filters
        sort: $sort
      ) {
        edges {
          cursor
          node {
            id
          }
        }
        pageInfo {
          hasNextPage
        }
      }
      subscriptionExecutionsCount(filters: $filters)
    }
  }
`;

const SYNDICATION_SUBSCRIPTIONS_CONTEXT_PROVIDER_QUERY = gql`
  query SyndicationSubscriptionsContextProvider(
    $id: ID!
    $first: Int
    $after: String
    $sort: ExecutionSort
    $filters: ExecutionListFilters
    $useCandleSize: Boolean!
    $useAbsoluteProfit: Boolean!
    $useNumberOfTrades: Boolean!
    $useMaxDrawdown: Boolean!
    $usePercentProfitableTrades: Boolean!
    $useProfitability: Boolean!
    $useAvgPositionPrice: Boolean!
    $useAvgMonthlyProfit: Boolean!
    $useAvgWinMonth: Boolean!
    $useAvgLoseMonth: Boolean!
    $usePercProfitableMonths: Boolean!
    $usePositionAmount: Boolean!
    $usePositionAbsoluteProfit: Boolean!
    $usePositionProfitLoss: Boolean!
    $useBalance: Boolean!
    $useRiskScore: Boolean!
    $useBuyHoldReturn: Boolean!
    $useSharpeRatio: Boolean!
    $useSortinoRatio: Boolean!
    $useTotalRealizedGain: Boolean!
    $useTotalRealizedLoss: Boolean!
    $useConsistencyScore: Boolean!
    $useScriptVersion: Boolean!
    $useScriptName: Boolean!
    $useCreatedAt: Boolean!
    $useTags: Boolean!
    $useAllocation: Boolean!
    $useStartedAt: Boolean!
    $useEndedAt: Boolean!
    $useAutoRebalance: Boolean!
    $isBot: Boolean!
    $useActiveSubscribersCount: Boolean!
  ) {
    execution(id: $id) {
      name
      subscriptionExecutions(
        first: $first
        after: $after
        filters: $filters
        sort: $sort
      ) {
        edges {
          cursor
          node {
            ...ExecutionsInfiniteTable_execution
          }
        }
        pageInfo {
          hasNextPage
        }
      }
      subscriptionExecutionsCount(filters: $filters)
    }
  }
  ${ExecutionsInfiniteTable.fragments.execution}
`;

export const SYNDICATION_SUBSCRIPTIONS_COLUMN_DEFS = [
  COLUMN_DEFINITIONS.SCRIPT_NAME,
  COLUMN_DEFINITIONS.SUBSCRIPTION_STATUS,
  COLUMN_DEFINITIONS.TYPE,
  COLUMN_DEFINITIONS.EXCHANGE,
  COLUMN_DEFINITIONS.CURRENCY_PAIR,
  COLUMN_DEFINITIONS.ALLOCATION,
  COLUMN_DEFINITIONS.CANDLE_SIZE,
  COLUMN_DEFINITIONS.PROFITABILITY,
  COLUMN_DEFINITIONS.PERCENT_PROFITABLE_TRADES,
  COLUMN_DEFINITIONS.ABSOLUTE_PROFIT,
  COLUMN_DEFINITIONS.BUY_HOLD_RETURN,
  COLUMN_DEFINITIONS.AVG_POSITION_PRICE,
  COLUMN_DEFINITIONS.MAX_DRAWDOWN,
  COLUMN_DEFINITIONS.NUMBER_OF_TRADES,
  COLUMN_DEFINITIONS.PERC_PROFITABLE_MONTHS,
  COLUMN_DEFINITIONS.AVG_WIN_MONTH,
  COLUMN_DEFINITIONS.AVG_LOSE_MONTH,
  COLUMN_DEFINITIONS.AVG_MONTHLY_PROFIT,
  COLUMN_DEFINITIONS.STARTED_AT,
  COLUMN_DEFINITIONS.ENDED_AT,
  COLUMN_DEFINITIONS.RISK_SCORE,
  COLUMN_DEFINITIONS.SHARPE_RATIO,
  COLUMN_DEFINITIONS.SORTINO_RATIO,
  COLUMN_DEFINITIONS.TOTAL_REALIZED_GAIN,
  COLUMN_DEFINITIONS.TOTAL_REALIZED_LOSS,
  COLUMN_DEFINITIONS.CONSISTENCY_SCORE,
];

type Props = Readonly<{
  children: ReactNode;
  history: History;
  match: match<{ botId: ID }>;
}>;

const STRATEGY_KEY = "SYNDICATION_SUBSCRIPTIONS_FILTERS";

function SyndicationSubscriptionsContextProvider({
  children,
  history,
  match,
}: Props) {
  const { t } = useTranslation();

  const location = useLocation();

  const botId = match.params.botId;

  const columnDefMap = useMemo(() => makeExecutionsColumnDefMap(t), [t]);
  const columns = useMemo(
    () => [
      columnDefMap.SCRIPT_NAME,
      columnDefMap.SUBSCRIPTION_STATUS,
      columnDefMap.TYPE,
      columnDefMap.EXCHANGE,
      columnDefMap.CURRENCY_PAIR,
      columnDefMap.ALLOCATION,
      columnDefMap.CANDLE_SIZE,
      columnDefMap.PROFITABILITY,
      columnDefMap.PERCENT_PROFITABLE_TRADES,
      columnDefMap.ABSOLUTE_PROFIT,
      columnDefMap.BUY_HOLD_RETURN,
      columnDefMap.AVG_POSITION_PRICE,
      columnDefMap.MAX_DRAWDOWN,
      columnDefMap.NUMBER_OF_TRADES,
      columnDefMap.PERC_PROFITABLE_MONTHS,
      columnDefMap.AVG_LOSE_MONTH,
      columnDefMap.AVG_WIN_MONTH,
      columnDefMap.AVG_MONTHLY_PROFIT,
      columnDefMap.STARTED_AT,
      columnDefMap.ENDED_AT,
      columnDefMap.RISK_SCORE,
      columnDefMap.SHARPE_RATIO,
      columnDefMap.SORTINO_RATIO,
      columnDefMap.TOTAL_REALIZED_GAIN,
      columnDefMap.TOTAL_REALIZED_LOSS,
      columnDefMap.CONSISTENCY_SCORE,
    ],
    [columnDefMap],
  );

  return (
    <FilterContextProvider
      columnDefs={SYNDICATION_SUBSCRIPTIONS_COLUMN_DEFS}
      defaultFilters={loadFilters(
        STRATEGY_KEY,
        [],
        SYNDICATION_SUBSCRIPTIONS_COLUMN_DEFS,
        queryString.parse(location.search),
      )}
      onFilterChange={(filters) => saveFilters(STRATEGY_KEY, filters)}
    >
      <ExecutionContextProvider
        creationPath={MY_BOTS_SUBSCRIPTIONS_SUBTAB}
        additionalQueryVariables={{ id: botId }}
        executionsFromResult={(results) =>
          results?.execution?.subscriptionExecutions?.edges?.map(
            (edge: any) => edge?.node,
          ) ?? []
        }
        getExecutionsCount={(results) =>
          results?.execution?.subscriptionExecutionsCount ?? 0
        }
        getLastExecutionCursor={(results) => {
          const length =
            results?.execution?.subscriptionExecutions?.edges?.length;

          if (
            length &&
            results?.execution?.subscriptionExecutions?.pageInfo?.hasNextPage
          ) {
            return results.execution.subscriptionExecutions.edges[length - 1]
              .cursor;
          }
        }}
        selectionUrl={`${MY_BOTS_SUBSCRIPTIONS_SUBTAB}/${botId}`}
        history={history}
        listQuery__DEPRECATED={
          SYNDICATION_SUBSCRIPTIONS_CONTEXT_PROVIDER_QUERY__DEPRECATED
        }
        listQuery={SYNDICATION_SUBSCRIPTIONS_CONTEXT_PROVIDER_QUERY}
        resultsPerPage={DEFAULT_PAGE_SIZE}
        defaultSort={DEFAULT_SORT}
        columnDefintions={getColumns(SYNDICATION_SUBSCRIPTIONS_COLUMN_DEFS)}
        syndicationId={botId}
        tableColumns={columns}
      >
        {children}
      </ExecutionContextProvider>
    </FilterContextProvider>
  );
}

export default SyndicationSubscriptionsContextProvider;
