import React from "react";
import gql from "graphql-tag";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import { useQuery } from "@apollo/client";
import {
  ExecutionSubscriptionStatusInfoQuery,
  ExecutionSubscriptionStatusInfoQueryVariables,
} from "__generated__/graphql";
import { useExecutionContext } from "contexts/executions/ExecutionContext";
import { getTranslatedSyndicationSubscriptionStatus } from "helpers/formLabelUtils";
import colors from "styles/colors";

const QUERY = gql`
  query ExecutionSubscriptionStatusInfo($botId: ID!, $executionId: ID!) {
    execution(id: $botId) {
      subscription(id: $executionId) {
        shareToken
        id
        subscriptionStatus
      }
    }
  }
`;

type Props = Readonly<{
  executionId: string;
}>;

function ExecutionSubscriptionStatusInfo({ executionId }: Props) {
  const { t } = useTranslation();

  const { syndicationId } = useExecutionContext();

  const { data } = useQuery<
    ExecutionSubscriptionStatusInfoQuery,
    ExecutionSubscriptionStatusInfoQueryVariables
  >(QUERY, {
    variables: {
      botId: syndicationId ?? "",
      executionId,
    },
    skip: !syndicationId,
  });

  const subscription = data?.execution?.subscription;

  if (!subscription?.subscriptionStatus) return null;

  const id = "statusTooltip:" + subscription.id;
  const subscriptionStatus =
    subscription.subscriptionStatus === "NOT_SUBSCRIBED"
      ? "Expired"
      : subscription.subscriptionStatus;

  return (
    <div className={css(styles.container)}>
      <div
        data-for={id}
        data-tip=""
        className={css(
          styles.statusCircle,
          styles[
            subscriptionStatus === "ACTIVE"
              ? "active"
              : subscriptionStatus === "CANCELLED"
              ? "cancelled"
              : subscriptionStatus === "Expired"
              ? "expired"
              : "pending"
          ],
        )}
      />
      <ReactTooltip id={id} effect="solid" className={css(styles.tooltip)}>
        {getTranslatedSyndicationSubscriptionStatus(
          subscription.subscriptionStatus,
          t,
        )}
      </ReactTooltip>
      {getTranslatedSyndicationSubscriptionStatus(
        subscription.subscriptionStatus,
        t,
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    textAlign: "left",
  },
  statusCircle: {
    width: "8px",
    height: "8px",
    borderRadius: "100%",
    margin: "auto 10px auto 5px",
    top: "6px",
    position: "relative",
    display: "inline-block",
    float: "left",
  },
  pending: {
    background: colors.statusPending,
  },
  active: {
    background: colors.statusRunning,
  },
  cancelled: {
    background: colors.statusCancelled,
  },
  expired: {
    background: colors.statusFailed,
  },
  tooltip: {
    opacity: 1,
    backgroundColor: colors.offBlack,
  },
});

export default ExecutionSubscriptionStatusInfo;
