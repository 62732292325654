import React from "react";
import { Currency } from "../../../graphql/schema";
import { trimZeroes } from "../../../helpers/numbers";

interface Props {
  value: string | number;
  currency?: Currency;
  colorSign: boolean;
  noGradiant?: boolean;
  hidden?: boolean;
}

const ExecutionValueCurrency = ({
  value,
  currency,
  colorSign,
  noGradiant = false,
  hidden = false,
}: Props) => {
  const trimmedValue = trimZeroes(value);
  if (!trimmedValue || hidden) return <span>--</span>;

  if (colorSign) {
    const percentage = parseFloat(trimmedValue);
    if (percentage === 0) {
      return (
        <span>
          {trimmedValue} {currency ?? ""}
        </span>
      );
    } else if (noGradiant) {
      return (
        <span
          title={`${trimmedValue} ${currency ?? ""}`}
          className={percentage < 0 ? "neg" : "pos"}
        >
          {trimmedValue} {currency ?? ""}
        </span>
      );
    } else {
      const intensity = Math.min(10000, Math.abs(percentage)) / 10000;
      return (
        <span
          title={`${trimmedValue} ${currency ?? ""}`}
          className={percentage < 0 ? "neg" : "pos"}
          style={{ filter: `brightness(${1 + intensity})` }}
        >
          {trimmedValue} {currency ?? ""}
        </span>
      );
    }
  }

  return (
    <span>
      {trimmedValue} {currency ?? ""}
    </span>
  );
};
export default React.memo(ExecutionValueCurrency);
