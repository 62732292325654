import React, { useCallback } from "react";
import classnames from "classnames";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAnnouncementContext } from "contexts/AnnouncementContext";
import { useUserContext } from "contexts/UserContext";
import { useAccountSettingsContext } from "contexts/AccountSettingsContext";
import maintenanceImg from "images/maintenance.svg";
import exclamationImg from "images/exclamationmark.svg";
import { ACCOUNT_BILLING_TAB, goToAccountDashboard } from "helpers/navigation";
import "./userstatusbanner.scss";

type Props = Readonly<{
  outstandingInvoiceCount: number;
}>;

function OutstandingInvoicesBanner({ outstandingInvoiceCount }: Props) {
  const history = useHistory();

  const { setInnerPage } = useAccountSettingsContext();

  const onUpdateClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setInnerPage("");
      goToAccountDashboard(ACCOUNT_BILLING_TAB, history);
    },
    [history, setInnerPage],
  );

  return (
    <div className="user-status-banner user-status-banner--error">
      <img src={exclamationImg} alt="warning" />
      <span>
        <Trans
          i18nKey="message.outstanding_invoice_count"
          values={{
            count: outstandingInvoiceCount,
          }}
          components={[
            <button
              key="outstanding_invoice_count"
              onClick={onUpdateClick}
              className="underlined"
            ></button>,
          ]}
        />
      </span>
    </div>
  );
}

const AnnouncementBanner: React.FunctionComponent = () => {
  const { announcement, isActive } = useAnnouncementContext();
  const { user } = useUserContext();

  if (
    (!announcement || !isActive) &&
    (user?.outstandingInvoiceCount ?? 0) > 0
  ) {
    return (
      <OutstandingInvoicesBanner
        outstandingInvoiceCount={user?.outstandingInvoiceCount ?? 0}
      />
    );
  }

  if (!announcement || !isActive) return null;

  return (
    <div
      className={classnames(
        "user-status-banner user-status-banner--announcement",
        {
          maintenance: announcement.type === "MAINTENANCE",
          outage: announcement.type === "OUTAGE",
        },
      )}
    >
      {(announcement.type === "MAINTENANCE" ||
        announcement.type === "OUTAGE") && (
        <img
          src={
            announcement.type === "MAINTENANCE"
              ? maintenanceImg
              : exclamationImg
          }
          alt="Announcement Type"
        />
      )}
      <span>
        <Trans
          i18nKey="message.announcement"
          values={{
            admin_message: announcement.message,
          }}
          components={[
            <a
              key="announcement"
              href="https://discord.gg/he2vcE42Ky"
              target="_blank"
              rel="noopener noreferrer"
            >
              -
            </a>,
          ]}
        />
      </span>
    </div>
  );
};

export default React.memo(AnnouncementBanner);
