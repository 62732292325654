import React from "react";
import { useBoolean } from "helpers/hooks";
import pictureImg from "images/picture.svg";
import { ClickAwayListener, Tooltip, withStyles } from "@material-ui/core";

const CustomTooltip = withStyles({
  popper: {
    "@media (max-width: 767.98px)": {
      transform: "unset !important",
    },
  },
  tooltip: {
    position: "relative",
    backgroundColor: "unset !important",
    padding: "0",
    maxWidth: "580px",
    "@media (max-width: 767.98px)": {
      maxWidth: "90vw",
      maxHeight: "50vh",
    },
  },
})(Tooltip);

type Props = Readonly<{
  img: string;
}>;

function ExchangeGuideImageTooltip({ img }: Props) {
  const [isOpen, open, close] = useBoolean(false);
  const [isHovering, setIsHovering, setIsNotHovering] = useBoolean(false);

  return (
    <ClickAwayListener onClickAway={close}>
      <CustomTooltip
        title={<img src={img} alt="Guide" width="50%" height="50%" />}
        open={isOpen || isHovering}
      >
        <img
          onClick={() => (isOpen ? close() : open())}
          onMouseOver={setIsHovering}
          onMouseLeave={setIsNotHovering}
          src={pictureImg}
          alt="Info"
          width={17.25}
          height={17.25}
        />
      </CustomTooltip>
    </ClickAwayListener>
  );
}

export default ExchangeGuideImageTooltip;
