import React, { createContext, useContext } from "react";
import invariant from "invariant";
import { StyleSheet, css } from "aphrodite";
import ConnectCredential from "components/credentials/ConnectCredential";
import Modal from "core/modal/Modal";
import { useBoolean } from "helpers/hooks";

type Context = {
  open: () => void;
  close: () => void;
};

const ConnectCredentialContext = createContext<Context | undefined>(undefined);

export function useConnectCredentialModal() {
  const context = useContext(ConnectCredentialContext);
  invariant(
    context != null,
    "Component is not a child of ConnectCredentialModalProvider",
  );
  return context;
}

type Props = {
  children: React.ReactNode;
};

export function ConnectCredentialModalProvider(props: Props) {
  const { children } = props;

  const [isOpen, open, close] = useBoolean(false);

  return (
    <ConnectCredentialContext.Provider value={{ open, close }}>
      <ConnectCredentialModal isOpen={isOpen} onClose={close} />
      {children}
    </ConnectCredentialContext.Provider>
  );
}

type ModalProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
}>;

function ConnectCredentialModal({ isOpen, onClose }: ModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      manualClose={false}
      onRequestClose={onClose}
      className={css(styles.container)}
    >
      <ConnectCredential />
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    borderRadius: 15,
    width: 980,
    height: 935,
    overflow: "hidden",
  },
});
