import React, { useEffect, useCallback } from "react";
import classnames from "classnames";
import { ID } from "../../graphql/schema";
import { useRouteMatch } from "react-router-dom";
import { useComposerStateContext } from "../../contexts/ComposerStateContext";
import { useNavigationContext } from "../../contexts/NavigationContext";
import { NavigationItem as NavItem } from "./TertiaryNavigation";
import NavigationItem from "./elements/NavigationItem";
import LinkButton from "../forms/LinkButton";
import imgMinimize from "../../images/minimize.svg";
import { useBoolean } from "../../helpers/hooks";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ListItem from "./elements/ListItem";
import IconButton from "core/button/IconButton";
import { EDITOR_TAB, LIVE_TAB, MY_BOTS_SUBTAB } from "helpers/navigation";

function SideNavigation() {
  const {
    sideNavItems,
    sideNavIcons,
    sideNavRightItems,
    sideNavClasses,
    sideNavMinimizeCB,
  } = useNavigationContext();
  const { isDisplayed: isComposerOpen } = useComposerStateContext();

  const [isVisible, show, hide] = useBoolean(!!sideNavItems);
  const [isDropdownOpen, open, close] = useBoolean(false);

  const isLiveOrSyndication = useRouteMatch({
    path: [`/${LIVE_TAB}`, `/${MY_BOTS_SUBTAB}`],
  });

  const editorMatch = useRouteMatch<{ scriptId: ID }>({
    path: `/${EDITOR_TAB}`,
  });

  const onToggleDropdown = useCallback(() => {
    if (isDropdownOpen) {
      close();
    } else {
      open();
    }
  }, [isDropdownOpen, open, close]);

  useEffect(() => (sideNavItems ? show() : hide()), [sideNavItems, show, hide]);

  if (!isVisible) return null;

  const navItems = (sideNavItems?.filter((item) => item != null) ??
    []) as NavItem[];

  const navRightItems = (sideNavRightItems?.filter((item) => item != null) ??
    []) as NavItem[];

  return (
    <ul
      className={classnames(
        ["nav__container", "right-nav", ...(sideNavClasses ?? [])],
        { "right-nav-with-composer": isComposerOpen && !isLiveOrSyndication },
      )}
    >
      {navItems?.map(
        ({ disabled, active, exact, title, action, path = "" }, i) => (
          <NavigationItem
            key={i}
            active={active}
            disabled={disabled}
            exact={exact}
            path={path}
            action={action}
            title={title}
          />
        ),
      )}
      {editorMatch && (
        <ClickAwayListener onClickAway={close}>
          <div className="action-menu" onClick={onToggleDropdown}>
            <span className="action-menu-toggler" />
            <div className="action-dropdown">
              {isDropdownOpen &&
                navItems?.map(({ active, title = "", action }, i) => (
                  <LinkButton
                    onClick={action}
                    key={i}
                    className={classnames({ active })}
                  >
                    <span className="action-option">{title}</span>
                  </LinkButton>
                ))}
            </div>
          </div>
        </ClickAwayListener>
      )}
      <li className="nav__item--divider" />
      {navRightItems?.map(
        ({ disabled, active, exact, title, action, path = "" }, i) => (
          <NavigationItem
            key={i}
            active={active}
            disabled={disabled}
            exact={exact}
            path={path}
            action={action}
            title={title}
            className="right-side-item"
          />
        ),
      )}
      {sideNavIcons?.map((item, i) => {
        const { alt, action, iconSrc, path } = item;

        return (
          <ListItem key={`${i}${alt}`} borderStyles={["borderLeft"]}>
            <IconButton
              title={alt}
              src={iconSrc}
              alt={alt ?? ""}
              href={path}
              onClick={action}
            />
          </ListItem>
        );
      })}
      {sideNavMinimizeCB && (
        <ListItem borderStyles={["borderLeft"]}>
          <IconButton
            src={imgMinimize}
            alt="Minimize"
            onClick={() => {
              sideNavMinimizeCB();
              hide();
            }}
          />
        </ListItem>
      )}
    </ul>
  );
}

export default SideNavigation;
