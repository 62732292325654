import React, { useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import LinkButton from "../forms/LinkButton";
import { useBoolean } from "../../helpers/hooks";
import Spinner from "../spinner/Spinner";
import { parseError } from "../../helpers/errorUtils";
import closeImg from "../../images/close.svg";

export type OnConfirmFn = () => Promise<any> | void;

type Props = Readonly<{
  onConfirm?: OnConfirmFn;
  text: React.ReactNode;
  primaryAction: string;
  isOpen: boolean;
  onClose: () => void;
  cancelText?: string;
  destructive?: boolean;
}>;

function ConfirmationModal({
  onConfirm,
  text,
  primaryAction,
  isOpen,
  onClose,
  cancelText,
  destructive,
}: Props) {
  const { t } = useTranslation();

  const [isLoading, startLoading, stopLoading] = useBoolean(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const modalBody = isLoading ? (
    <Spinner />
  ) : (
    <>
      <img className="close" src={closeImg} alt="Close" onClick={onClose} />
      <span className="error error--block">{error}</span>
      <span className="header">{t("message.are_you_sure")}</span>
      <span className="label">{text}</span>
      <div className="button-group">
        <LinkButton className="button button--primary" onClick={onClose}>
          {cancelText ?? t("common.cancel")}
        </LinkButton>
        <button
          disabled={isLoading}
          className={classnames("button", {
            "button--secondary": destructive,
            "button--prime": !destructive,
          })}
        >
          {primaryAction}
          {isLoading && <Spinner />}
        </button>
      </div>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="confirmation-modal"
    >
      <form
        className="form form--dark"
        onSubmit={(e) => {
          e.preventDefault();
          startLoading();
          const confirmed = onConfirm && onConfirm();
          if (confirmed) {
            confirmed.then(
              () => {
                onClose();
                stopLoading();
                setError(undefined);
              },
              (err: Error) => {
                stopLoading();
                const [, message] = parseError(err);
                setError(message || err.message);
              },
            );
          } else {
            stopLoading();
            onClose();
            setError(undefined);
          }
        }}
      >
        {modalBody}
      </form>
    </Modal>
  );
}

export default ConfirmationModal;
