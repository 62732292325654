import React, { useCallback, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { useMutation } from "@apollo/client";
import { DELETE_EXECUTION_TAG } from "graphql/mutations";
import { ID, Tag } from "graphql/schema";
import { useTagsContext } from "contexts/TagsContext";
import TagsField from "core/tags/TagsField";

interface Props {
  executionId: ID;
  executionTags?: Tag[];
}

const ExecutionTags = ({ executionId, executionTags }: Props) => {
  const { tags, addExecutionTag, loadingExecutionTag } = useTagsContext();

  // don't show tags that are being deleted
  const [visibleTags, setTags] = useState(
    executionTags?.filter((tag) => tags.find((t) => t.id === tag.id)) ?? [],
  );
  const [deleteExecutionTag] = useMutation(DELETE_EXECUTION_TAG);

  const removeExecutionTag = useCallback(
    (tagId: ID) => {
      try {
        return deleteExecutionTag({
          variables: {
            executionId,
            tagId,
          },
        });
      } catch (e) {
        return Promise.resolve(true);
      }
    },
    [executionId, deleteExecutionTag],
  );

  const onAddExecutionTagClick = useCallback(
    (tagId: ID) => {
      // to prevent adding the same tag multiple times
      if (!executionTags?.find((tag) => tag.id === tagId)) {
        addExecutionTag(tagId, executionId);
      }
    },
    [executionId, executionTags, addExecutionTag],
  );

  const onRemoveExecutionTagClick = useCallback(
    (tagId: ID) => {
      // to prevent the user from attempting to removing the same tag after already removing it
      setTags(visibleTags.filter((tag) => tag.id !== tagId));
      removeExecutionTag(tagId);
    },
    [removeExecutionTag, setTags, visibleTags],
  );

  return (
    <div className={css(styles.container)}>
      <TagsField
        containerType="column"
        loading={loadingExecutionTag}
        selectedTags={executionTags ?? []}
        setSelectedTags={setTags}
        onAddTag={onAddExecutionTagClick}
        onRemoveTag={onRemoveExecutionTagClick}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: "100%",
    height: "30px",
  },
});

export default React.memo(ExecutionTags);
