import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import queryString from "query-string";
import { Trans, useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import invariant from "invariant";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { StyleSheet, css } from "aphrodite";
import {
  BotsTable_PublicSyndicationFragment,
  ExecutionColumn,
  ExecutionFilter,
  ExecutionSort,
  ExecutionType,
  FilterType,
  SortDirection,
} from "__generated__/graphql";
import {
  BotMeasurementsMode,
  useSelectedExecutionContext,
} from "contexts/SelectedExecutionContext";
import { useFilterContext } from "./FilterContext";
import { EXCHANGES } from "./ExecutionColumns";
import { getSelectedPublicBotColumns } from "./ColumnsUtils";
import {
  BOT_COLUMNS_PRESET_ID_KEY,
  useColumnsPresetsContext,
} from "./ColumnsPresetsContext";
import { getSearchParamsFromFilter } from "./FilterUtils";
import BotCreatorNameField from "components/bots/fields/BotCreatorNameField";
import { ColumnFilterType } from "components/executions/list/ExecutionColumnDefinitions";
import ColoredNumberSpan from "core/element/ColoredNumberSpan";
import ColorSpan from "core/element/ColorSpan";
import {
  formatMoney,
  formatWithCommas,
  percentageValue,
  roundValue,
  trimToTwoDecimals,
  trimZeroes,
} from "helpers/numbers";
import { formatExchange, isSpotExchange } from "helpers/formLabelUtils";
import { getDateTime, getRuntime } from "helpers/dates";
import { getFirstString } from "helpers/urlParameters";
import { fromStringValue } from "helpers/strings";

export type BotTab = "COMMUNITY";

function getMeasurements(
  bot: BotsTable_PublicSyndicationFragment | undefined | null,
  mode: BotMeasurementsMode,
) {
  switch (mode) {
    case "backtest":
      return bot?.backtestMeasurements;
    case "papertrade":
      return bot?.measurements;
  }
}

export type CommunityBotColumnDefinition = {
  column: BotColumn;
  getLabels: (t: TFunction) => { header: string; tooltip?: ReactNode };
  propertyFn: (
    bot: BotsTable_PublicSyndicationFragment | undefined | null,
    mode: BotMeasurementsMode,
    t: TFunction,
  ) => ReactNode;
  filterType?: ColumnFilterType;
  filterOptions?: string[];
  sortable: boolean;
  width: string;
  className?: string;
  hidden?: boolean;
  canMove?: boolean;
  canToggle?: boolean;
};

export type BotColumn = Exclude<
  ExecutionColumn,
  | "ABSOLUTE_PROFIT"
  | "ALLOCATION"
  | "ARCHIVED"
  | "AVG_POSITION_PRICE"
  | "BALANCE"
  | "BUY_HOLD_RATIO"
  | "CANDLE_SIZE"
  | "COMPOUND_DAILY_RETURNS"
  | "CREATION_DATE"
  | "ENDED_AT"
  | "ENDED_AT_DATE"
  | "EXECUTION_NUMBER"
  | "FAVORITE"
  | "IS_BASKET"
  | "IS_PACK"
  | "IS_PRELOADED_SCRIPT"
  | "IS_MULTI_COIN_PACK"
  | "IS_SUBSCRIPTION"
  | "LEVERAGE_SHORT"
  | "MULTI_COIN_PACK"
  | "NAME"
  | "PERC_BUY_HOLD_RATIO"
  | "PRELOADED_SCRIPT"
  | "POSITION_ABSOLUTE_PROFIT"
  | "POSITION_AMOUNT"
  | "POSITION_PROFIT_LOSS"
  | "STATUS"
  | "SUBSCRIPTION_STATUS"
  | "SYNDICATION_ID"
  | "TAG_ID"
  | "TOTAL_REALIZED_GAIN"
  | "TOTAL_REALIZED_LOSS"
  | "TYPE"
  | "VISIBLE_SYNDICATION"
>;

const columnHelper = createColumnHelper<BotsTable_PublicSyndicationFragment>();

function makeColumnDefMap(
  mode: BotMeasurementsMode,
  t: TFunction,
): Record<BotColumn, ColumnDef<BotsTable_PublicSyndicationFragment>> {
  return {
    ALLOCATION_FEE_PERC: {
      ...(columnHelper.accessor(
        (data) => data.syndicationSettings?.allocationFeePerc ?? "0",
        {
          id: ExecutionColumn.AllocationFeePerc,
          header: () => t("common.allocation_fee"),
          cell: (ctx) => (
            <span>{trimZeroes(ctx.getValue() as string) ?? "0"}%</span>
          ),
          meta: {
            sortable: true,
            rightAligned: true,
            tooltip: t("message.allocation_fee_billing_cycle"),
            canFilter: true,
          },
        },
      ) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    AUTO_REBALANCE: {
      ...(columnHelper.accessor((data) => data.autoRebalance, {
        id: ExecutionColumn.AutoRebalance,
        header: () => t("common.rebalancing"),
        cell: (ctx) => <span>{ctx.getValue() ? "On" : "--"}</span>,
        meta: {
          sortable: true,
          tooltip: t("message.auto_rebalance_information"),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    AVG_LOSE_MONTH: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.AvgLoseMonth,
        header: () => t("common.average_lose_month"),
        cell: (ctx) => (
          <span>
            {percentageValue(
              getMeasurements(ctx.getValue(), mode)?.avgLoseMonth,
            ) ?? "--"}
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.average_loss_for_unprofitable_months"),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    AVG_WIN_MONTH: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.AvgWinMonth,
        header: () => t("common.average_win_month"),
        cell: (ctx) => (
          <span>
            {percentageValue(
              getMeasurements(ctx.getValue(), mode)?.avgWinMonth,
            ) ?? "--"}
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.average_gain_for_profitable_months"),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    AVG_MONTHLY_PROFIT: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.AvgMonthlyProfit,
        header: () => t("common.average_monthly_profit"),
        cell: (ctx) => (
          <span>
            {percentageValue(
              getMeasurements(ctx.getValue(), mode)?.avgMonthlyProfit,
            ) ?? "--"}
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    BUY_HOLD_RETURN: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.BuyHoldReturn,
        header: () => t("common.buy_and_hold_return"),
        cell: (ctx) => {
          const bot = ctx.getValue();

          return bot?.multiCoinCurrency ? (
            "--"
          ) : (
            <ColoredNumberSpan
              number={getMeasurements(bot, mode)?.buyHoldReturn}
              signedColor="negative"
              transformer={percentageValue}
            />
          );
        },
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.holding_profit_loss"),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    CONSISTENCY_SCORE: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.ConsistencyScore,
        header: () => t("terminal.consistency_score"),
        cell: (ctx) => {
          const consistencyScore = getMeasurements(
            ctx.getValue(),
            mode,
          )?.consistencyScore;

          return (
            <ColorSpan
              color={
                !consistencyScore
                  ? "offWhite"
                  : consistencyScore >= 60
                  ? "pastelGreen"
                  : consistencyScore >= 35
                  ? "pear"
                  : consistencyScore >= 25
                  ? "meteor"
                  : "offWhite"
              }
            >
              {consistencyScore ?? "--"}
            </ColorSpan>
          );
        },
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: (
            <div>
              <div>{t("message.consistency_score_information")}</div>
              <div>
                <p>
                  <Trans
                    i18nKey="message.consistency_score_low_percentage_information"
                    components={[
                      <ColorSpan
                        key="consistency_score_low_percentage_information"
                        color="meteor"
                      >
                        -
                      </ColorSpan>,
                    ]}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="message.consistency_score_medium_percentage_information"
                    components={[
                      <ColorSpan
                        key="consistency_score_medium_percentage_information"
                        color="pear"
                      >
                        -
                      </ColorSpan>,
                    ]}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="message.consistency_score_high_percentage_information"
                    components={[
                      <ColorSpan
                        key="consistency_score_high_percentage_information"
                        color="pastelGreen"
                      >
                        -
                      </ColorSpan>,
                    ]}
                  />
                </p>
              </div>
            </div>
          ),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    CREATOR_NAME: {
      ...(columnHelper.accessor((data) => data.owner, {
        id: ExecutionColumn.CreatorName,
        header: () => t("common.creator"),
        cell: (ctx) => (
          <BotCreatorNameField
            companyName={ctx.getValue().companyName}
            nickname={ctx.getValue().nickname}
          />
        ),
        meta: {
          sortable: true,
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    CURRENCY_PAIR: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.CurrencyPair,
        header: () => t("common.trading_pair"),
        cell: (ctx) => {
          const bot = ctx.getValue();

          return (
            <>
              <span
                data-for={`syndication-${bot?.id}-multi-coin-currencies`}
                data-tip=""
              >
                {bot?.multiCoinCurrency
                  ? `Multiple / ${bot?.multiCoinCurrency}`
                  : bot?.currencyPair?.replace("_", " / ")}
              </span>
              {bot?.multiCoinCurrency && bot?.multiCoinPackExecutions && (
                <ReactTooltip
                  id={`syndication-${bot?.id}-multi-coin-currencies`}
                  effect="solid"
                  className="reactTooltip white bold"
                  place="right"
                >
                  {bot.multiCoinPackExecutions.map((execution) => (
                    <div key={execution?.currency}>
                      {execution?.currency} / {bot.multiCoinCurrency}
                    </div>
                  ))}
                </ReactTooltip>
              )}
            </>
          );
        },
        meta: {
          sortable: true,
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    EXCHANGE: {
      ...(columnHelper.accessor((data) => data.exchange, {
        id: ExecutionColumn.Exchange,
        header: () => t("common.exchange"),
        cell: (ctx) => (
          <span title={formatExchange(ctx.getValue())}>
            {formatExchange(ctx.getValue())}
          </span>
        ),
        meta: {
          sortable: true,
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    LEVERAGE: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.Leverage,
        header: () => t("common.leverage"),
        cell: (ctx) => {
          const bot = ctx.getValue();

          if (isSpotExchange(bot?.exchange)) {
            return <span>{t("common.no")}</span>;
          }

          return (
            <span>
              {bot?.leverageInfo?.minLeverage === bot?.leverageInfo?.maxLeverage
                ? roundValue(bot?.leverageInfo?.avgLeverage ?? 0, 2)
                : `${roundValue(
                    bot?.leverageInfo?.avgLeverage,
                    2,
                  )}x (${trimZeroes(bot?.leverageInfo?.minLeverage)} -
                        ${trimZeroes(bot?.leverageInfo?.maxLeverage)}x)`}
            </span>
          );
        },
        meta: {
          sortable: false,
          rightAligned: true,
          tooltip: t("message.leverage_information"),
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    MAX_DRAWDOWN: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.MaxDrawdown,
        header: () => t("common.mdd"),
        cell: (ctx) => (
          <span>
            {percentageValue(
              getMeasurements(ctx.getValue(), mode)?.maxDrawdown,
            ) ?? "--"}
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.mdd_information"),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    MIN_ALLOCATION: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.MinAllocation,
        header: () => t("common.minimum_allocation"),
        cell: (ctx) => {
          const bot = ctx.getValue();

          return (
            <span>
              {trimZeroes(bot?.syndicationSettings?.minAllocation) ?? "0"}{" "}
              {bot?.currencyPairDetails?.settleCurrency}
            </span>
          );
        },
        meta: {
          sortable: true,
          rightAligned: true,
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    MONTHLY_FEE_USD: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.MonthlyFeeUsd,
        header: () => t("common.monthly_fee"),
        cell: (ctx) => (
          <span>
            $
            {formatMoney(
              ctx.getValue().syndicationSettings?.monthlyFeeUsd ?? "0",
            )}
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.monthly_fee_billing_cycle"),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    SCRIPT_NAME: {
      ...(columnHelper.accessor((data) => data.name, {
        id: ExecutionColumn.ScriptName,
        header: () => t("common.name"),
        cell: (ctx) => (
          <span className={css(styles.span)}>{ctx.getValue() ?? "--"}</span>
        ),
        meta: {
          sortable: true,
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    NUMBER_OF_TRADES: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.NumberOfTrades,
        header: () => t("common.number_trades"),
        cell: (ctx) => (
          <span>
            {getMeasurements(ctx.getValue(), mode)?.numberOfTrades ?? "0"}
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.total_amount_of_trades"),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    PERC_PROFITABLE_MONTHS: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.PercProfitableMonths,
        header: () => t("common.profitable_months"),
        cell: (ctx) => (
          <span>
            {percentageValue(
              getMeasurements(ctx.getValue(), mode)?.percProfitableMonths,
            ) ?? "--"}
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.percent_profitable_months"),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    PERCENT_PROFITABLE_TRADES: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.PercentProfitableTrades,
        header: () => t("common.profitable_trades"),
        cell: (ctx) => (
          <span>
            {percentageValue(
              getMeasurements(ctx.getValue(), mode)?.percentProfitableTrades,
            ) ?? "--"}
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.profitable_trades_information"),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    PROFITABILITY: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.Profitability,
        header: () => t("common.profit_loss_percent"),
        cell: (ctx) => (
          <ColorSpan>
            {percentageValue(
              getMeasurements(ctx.getValue(), mode)?.profitability,
            ) ?? "--"}
          </ColorSpan>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    PROFIT_FACTOR: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.ProfitFactor,
        header: () => t("common.profit_factor"),
        cell: (ctx) => (
          <span>
            {trimToTwoDecimals(
              getMeasurements(ctx.getValue(), mode)?.profitFactor,
            ) ?? "--"}
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.gross_profits_divided_by_gross_losses"),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    RISK_SCORE: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.RiskScore,
        header: () => t("common.risk_score"),
        cell: (ctx) => (
          <span>
            {percentageValue(
              getMeasurements(ctx.getValue(), mode)?.riskScore,
            ) ?? "--"}
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: (
            <div>
              <div className={css(styles.tooltipHeader)}>
                {t("message.average_monthly_profit_for_mdd")}
              </div>
              <Trans
                i18nKey="message.risk_score_information"
                components={[
                  <div
                    key="multiplication"
                    className={css(styles.tooltipMultiplication)}
                  >
                    x
                  </div>,
                  <div key="squared" className={css(styles.tooltipSquared)}>
                    2
                  </div>,
                ]}
              />
            </div>
          ),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    SHARPE_RATIO: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.SharpeRatio,
        header: () => t("terminal.sharpe_ratio"),
        cell: (ctx) => (
          <span>
            {trimToTwoDecimals(
              getMeasurements(ctx.getValue(), mode)?.sharpeRatio,
            ) ?? "--"}
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.sharpe_ratio_information"),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    SORTINO_RATIO: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.SortinoRatio,
        header: () => t("terminal.sortino_ratio"),
        cell: (ctx) => (
          <span>
            {trimToTwoDecimals(
              getMeasurements(ctx.getValue(), mode)?.sortinoRatio,
            ) ?? "--"}
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.sortino_ratio_information"),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    STARTED_AT: {
      ...(columnHelper.accessor((data) => data.startedAt, {
        id: ExecutionColumn.StartedAt,
        header: () => t("common.days_running"),
        cell: (ctx) => (
          <span
            title={t("common.days_amount", {
              count: getRuntime(ctx.getValue() ?? ""),
            })}
          >
            {t("common.days_amount", {
              count: getRuntime(ctx.getValue() ?? ""),
            })}
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    STARTED_AT_DATE: {
      ...(columnHelper.accessor((data) => data.startedAt, {
        id: ExecutionColumn.StartedAtDate,
        header: () => t("common.run_start_date"),
        cell: (ctx) => {
          const startedAt = ctx.getValue();

          return (
            <span title={startedAt ? getDateTime(startedAt) : "--"}>
              {startedAt ? getDateTime(startedAt) : "--"}
            </span>
          );
        },
        meta: {
          sortable: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    SUCCESS_FEE_PERC: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.SuccessFeePerc,
        header: () => t("common.success_fee"),
        cell: (ctx) => (
          <span>
            {trimZeroes(ctx.getValue().syndicationSettings?.successFeePerc) ??
              "0"}
            %
          </span>
        ),
        meta: {
          sortable: true,
          rightAligned: true,
          tooltip: t("message.success_fee_billing_cycle"),
          canFilter: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
    TOTAL_ALLOCATED: {
      ...(columnHelper.accessor((data) => data, {
        id: ExecutionColumn.TotalAllocated,
        header: () => t("common.total_allocation"),
        cell: (ctx) => {
          const bot = ctx.getValue();

          return (
            <span>
              {bot?.currencyPairDetails?.settleCurrency.includes("USD")
                ? trimToTwoDecimals(
                    getMeasurements(bot, mode)?.subscriptionAllocation,
                    true,
                  )
                : formatWithCommas(
                    getMeasurements(bot, mode)?.subscriptionAllocation,
                  )}
              {" / " +
                formatWithCommas(bot?.syndicationSettings?.maxAllocation)}{" "}
              {bot?.currencyPairDetails?.settleCurrency}
            </span>
          );
        },
        meta: {
          sortable: false,
          rightAligned: true,
        },
      }) as ColumnDef<BotsTable_PublicSyndicationFragment, unknown>),
    },
  };
}

export const BOT_COLUMN_DEFINITIONS: Record<
  BotColumn,
  CommunityBotColumnDefinition
> = {
  ALLOCATION_FEE_PERC: {
    column: ExecutionColumn.AllocationFeePerc,
    getLabels: (t: TFunction) => ({
      header: t("common.allocation_fee"),
      tooltip: t("message.allocation_fee_billing_cycle"),
    }),
    propertyFn: (bot) => (
      <span>
        {trimZeroes(bot?.syndicationSettings?.allocationFeePerc) ?? "0"}%
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "110px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  AUTO_REBALANCE: {
    column: ExecutionColumn.AutoRebalance,
    getLabels: (t: TFunction) => ({
      header: t("common.rebalancing"),
      tooltip: t("message.auto_rebalance_information"),
    }),
    propertyFn: (bot) => <span>{bot?.autoRebalance ? "On" : "--"}</span>,
    filterType: "boolean",
    sortable: true,
    width: "90px",
    canMove: true,
    canToggle: true,
    hidden: true,
  },
  AVG_LOSE_MONTH: {
    column: ExecutionColumn.AvgLoseMonth,
    getLabels: (t: TFunction) => ({
      header: t("common.average_lose_month"),
      tooltip: t("message.average_loss_for_unprofitable_months"),
    }),
    propertyFn: (bot, mode) => (
      <span>
        {percentageValue(getMeasurements(bot, mode)?.avgLoseMonth) ?? "--"}
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "145px",
    className: "align-right",
    canMove: true,
    canToggle: true,
    hidden: true,
  },
  AVG_WIN_MONTH: {
    column: ExecutionColumn.AvgWinMonth,
    getLabels: (t: TFunction) => ({
      header: t("common.average_win_month"),
      tooltip: t("message.average_gain_for_profitable_months"),
    }),
    propertyFn: (bot, mode) => (
      <span>
        {percentageValue(getMeasurements(bot, mode)?.avgWinMonth) ?? "--"}
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "140px",
    className: "align-right",
    canMove: true,
    canToggle: true,
    hidden: true,
  },
  AVG_MONTHLY_PROFIT: {
    column: ExecutionColumn.AvgMonthlyProfit,
    getLabels: (t: TFunction) => ({
      header: t("common.average_monthly_profit"),
    }),
    propertyFn: (bot, mode) => (
      <span>
        {percentageValue(getMeasurements(bot, mode)?.avgMonthlyProfit) ?? "--"}
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "175px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  BUY_HOLD_RETURN: {
    column: ExecutionColumn.BuyHoldReturn,
    getLabels: (t: TFunction) => ({
      header: t("common.buy_and_hold_return"),
      tooltip: t("message.holding_profit_loss"),
    }),
    propertyFn: (bot, mode) =>
      bot?.multiCoinCurrency ? (
        "--"
      ) : (
        <ColoredNumberSpan
          number={getMeasurements(bot, mode)?.buyHoldReturn}
          signedColor="negative"
          transformer={percentageValue}
        />
      ),
    filterType: "number",
    sortable: true,
    width: "125px",
    className: "align-right",
    canMove: true,
    canToggle: true,
    hidden: true,
  },
  CONSISTENCY_SCORE: {
    column: ExecutionColumn.ConsistencyScore,
    getLabels: (t: TFunction) => ({
      header: t("terminal.consistency_score"),
      tooltip: (
        <div>
          <div>{t("message.consistency_score_information")}</div>
          <div>
            <p>
              <Trans
                i18nKey="message.consistency_score_low_percentage_information"
                components={[
                  <ColorSpan
                    key="consistency_score_low_percentage_information"
                    color="meteor"
                  >
                    -
                  </ColorSpan>,
                ]}
              />
            </p>
            <p>
              <Trans
                i18nKey="message.consistency_score_medium_percentage_information"
                components={[
                  <ColorSpan
                    key="consistency_score_medium_percentage_information"
                    color="pear"
                  >
                    -
                  </ColorSpan>,
                ]}
              />
            </p>
            <p>
              <Trans
                i18nKey="message.consistency_score_high_percentage_information"
                components={[
                  <ColorSpan
                    key="consistency_score_high_percentage_information"
                    color="pastelGreen"
                  >
                    -
                  </ColorSpan>,
                ]}
              />
            </p>
          </div>
        </div>
      ),
    }),
    propertyFn: (bot, mode) => {
      const consistencyScore = getMeasurements(bot, mode)?.consistencyScore;
      return (
        <ColorSpan
          color={
            !consistencyScore
              ? "offWhite"
              : consistencyScore >= 60
              ? "pastelGreen"
              : consistencyScore >= 35
              ? "pear"
              : consistencyScore >= 25
              ? "meteor"
              : "offWhite"
          }
        >
          {consistencyScore ?? "--"}
        </ColorSpan>
      );
    },
    filterType: "number",
    sortable: true,
    width: "135px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  CREATOR_NAME: {
    column: ExecutionColumn.CreatorName,
    getLabels: (t: TFunction) => ({ header: t("common.creator") }),
    propertyFn: (bot) => (
      <BotCreatorNameField
        companyName={bot?.owner?.companyName}
        nickname={bot?.owner?.nickname}
      />
    ),
    filterType: "string",
    sortable: true,
    width: "125px",
  },
  CURRENCY_PAIR: {
    column: ExecutionColumn.CurrencyPair,
    getLabels: (t: TFunction) => ({ header: t("common.trading_pair") }),
    propertyFn: (bot) => (
      <>
        <span
          data-for={`syndication-${bot?.id}-multi-coin-currencies`}
          data-tip=""
        >
          {bot?.multiCoinCurrency
            ? `Multiple / ${bot?.multiCoinCurrency}`
            : bot?.currencyPair?.replace("_", " / ")}
        </span>
        {bot?.multiCoinCurrency && bot?.multiCoinPackExecutions && (
          <ReactTooltip
            id={`syndication-${bot?.id}-multi-coin-currencies`}
            effect="solid"
            className="reactTooltip white bold"
            place="right"
          >
            {bot.multiCoinPackExecutions.map((execution) => (
              <div key={execution?.currency}>
                {execution?.currency} / {bot.multiCoinCurrency}
              </div>
            ))}
          </ReactTooltip>
        )}
      </>
    ),
    filterType: "string",
    sortable: true,
    width: "95px",
    canMove: true,
    canToggle: true,
  },
  EXCHANGE: {
    column: ExecutionColumn.Exchange,
    getLabels: (t: TFunction) => ({ header: t("common.exchange") }),
    propertyFn: (bot) => (
      <span title={formatExchange(bot?.exchange)}>
        {formatExchange(bot?.exchange)}
      </span>
    ),
    filterType: "options",
    filterOptions: EXCHANGES,
    sortable: true,
    width: "150px",
    canMove: true,
    canToggle: true,
  },
  LEVERAGE: {
    column: ExecutionColumn.Leverage,
    getLabels: (t: TFunction) => ({
      header: t("common.leverage"),
      tooltip: t("message.leverage_information"),
    }),
    propertyFn: (bot, _, t) => {
      if (isSpotExchange(bot?.exchange)) {
        return <span>{t("common.no")}</span>;
      }

      return (
        <span>
          {bot?.leverageInfo?.minLeverage === bot?.leverageInfo?.maxLeverage
            ? roundValue(bot?.leverageInfo?.avgLeverage ?? 0, 2)
            : `${roundValue(bot?.leverageInfo?.avgLeverage, 2)}x (${trimZeroes(
                bot?.leverageInfo?.minLeverage,
              )} -
                    ${trimZeroes(bot?.leverageInfo?.maxLeverage)}x)`}
        </span>
      );
    },
    sortable: false,
    width: "100px",
    className: "align-right",
    canMove: true,
    canToggle: true,
    hidden: true,
  },
  MAX_DRAWDOWN: {
    column: ExecutionColumn.MaxDrawdown,
    getLabels: (t: TFunction) => ({
      header: t("common.mdd"),
      tooltip: t("message.mdd_information"),
    }),
    propertyFn: (bot, mode) => (
      <span>
        {percentageValue(getMeasurements(bot, mode)?.maxDrawdown) ?? "--"}
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "65px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  MIN_ALLOCATION: {
    column: ExecutionColumn.MinAllocation,
    getLabels: (t: TFunction) => ({ header: t("common.minimum_allocation") }),
    propertyFn: (bot) => (
      <span>
        {trimZeroes(bot?.syndicationSettings?.minAllocation) ?? "0"}{" "}
        {bot?.currencyPairDetails?.settleCurrency}
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "150px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  MONTHLY_FEE_USD: {
    column: ExecutionColumn.MonthlyFeeUsd,
    getLabels: (t: TFunction) => ({
      header: t("common.monthly_fee"),
      tooltip: t("message.monthly_fee_billing_cycle"),
    }),
    propertyFn: (bot) => (
      <span>
        ${formatMoney(bot?.syndicationSettings?.monthlyFeeUsd ?? "0")}
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "100px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  SCRIPT_NAME: {
    column: ExecutionColumn.ScriptName,
    getLabels: (t: TFunction) => ({ header: t("common.name") }),
    propertyFn: (bot) => <span>{bot?.name ?? "--"}</span>,
    filterType: "string",
    sortable: true,
    width: "300px",
  },
  NUMBER_OF_TRADES: {
    column: ExecutionColumn.NumberOfTrades,
    getLabels: (t: TFunction) => ({
      header: t("common.number_trades"),
      tooltip: t("message.total_amount_of_trades"),
    }),
    propertyFn: (bot, mode) => (
      <span>{getMeasurements(bot, mode)?.numberOfTrades ?? "0"}</span>
    ),
    filterType: "number",
    sortable: true,
    width: "80px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  PERC_PROFITABLE_MONTHS: {
    column: ExecutionColumn.PercProfitableMonths,
    getLabels: (t: TFunction) => ({
      header: t("common.profitable_months"),
      tooltip: t("message.percent_profitable_months"),
    }),
    propertyFn: (bot, mode) => (
      <span>
        {percentageValue(getMeasurements(bot, mode)?.percProfitableMonths) ??
          "--"}
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "135px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  PERCENT_PROFITABLE_TRADES: {
    column: ExecutionColumn.PercentProfitableTrades,
    getLabels: (t: TFunction) => ({
      header: t("common.profitable_trades"),
      tooltip: t("message.profitable_trades_information"),
    }),
    propertyFn: (bot, mode) => (
      <span>
        {percentageValue(getMeasurements(bot, mode)?.percentProfitableTrades) ??
          "--"}
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "125px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  PROFITABILITY: {
    column: ExecutionColumn.Profitability,
    getLabels: (t: TFunction) => ({ header: t("common.profit_loss_percent") }),
    propertyFn: (bot, mode) => (
      <ColorSpan>
        {percentageValue(getMeasurements(bot, mode)?.profitability) ?? "--"}
      </ColorSpan>
    ),
    filterType: "number",
    sortable: true,
    width: "105px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  PROFIT_FACTOR: {
    column: ExecutionColumn.ProfitFactor,
    getLabels: (t: TFunction) => ({
      header: t("common.profit_factor"),
      tooltip: t("message.gross_profits_divided_by_gross_losses"),
    }),
    propertyFn: (bot, mode) => (
      <span>
        {trimToTwoDecimals(getMeasurements(bot, mode)?.profitFactor) ?? "--"}
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "100px",
    className: "align-right",
    canMove: true,
    canToggle: true,
    hidden: true,
  },
  RISK_SCORE: {
    column: ExecutionColumn.RiskScore,
    getLabels: (t: TFunction) => ({
      header: t("common.risk_score"),
      tooltip: (
        <div>
          <div className={css(styles.tooltipHeader)}>
            {t("message.average_monthly_profit_for_mdd")}
          </div>
          <Trans
            i18nKey="message.risk_score_information"
            components={[
              <div
                key="multiplication"
                className={css(styles.tooltipMultiplication)}
              >
                x
              </div>,
              <div key="squared" className={css(styles.tooltipSquared)}>
                2
              </div>,
            ]}
          />
        </div>
      ),
    }),
    propertyFn: (bot, mode) => (
      <span>
        {percentageValue(getMeasurements(bot, mode)?.riskScore) ?? "--"}
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "100px",
    className: "align-right",
    canMove: true,
    canToggle: true,
    hidden: true,
  },
  SHARPE_RATIO: {
    column: ExecutionColumn.SharpeRatio,
    getLabels: (t: TFunction) => ({
      header: t("terminal.sharpe_ratio"),
      tooltip: t("message.sharpe_ratio_information"),
    }),
    propertyFn: (bot, mode) => (
      <span>
        {trimToTwoDecimals(getMeasurements(bot, mode)?.sharpeRatio) ?? "--"}
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "115px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  SORTINO_RATIO: {
    column: ExecutionColumn.SortinoRatio,
    getLabels: (t: TFunction) => ({
      header: t("terminal.sortino_ratio"),
      tooltip: t("message.sortino_ratio_information"),
    }),
    propertyFn: (bot, mode) => (
      <span>
        {trimToTwoDecimals(getMeasurements(bot, mode)?.sortinoRatio) ?? "--"}
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "115px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  STARTED_AT: {
    column: ExecutionColumn.StartedAt,
    getLabels: (t: TFunction) => ({ header: t("common.days_running") }),
    propertyFn: (bot, _, t) => (
      <span
        title={t("common.days_amount", {
          count: getRuntime(bot?.startedAt ?? ""),
        })}
      >
        {t("common.days_amount", { count: getRuntime(bot?.startedAt ?? "") })}
      </span>
    ),
    filterType: "runtime",
    sortable: true,
    width: "110px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  STARTED_AT_DATE: {
    column: ExecutionColumn.StartedAtDate,
    getLabels: (t: TFunction) => ({ header: t("common.run_start_date") }),
    propertyFn: (bot) => (
      <span title={bot?.startedAt ? getDateTime(bot.startedAt) : "--"}>
        {bot?.startedAt ? getDateTime(bot.startedAt) : "--"}
      </span>
    ),
    sortable: true,
    width: "135px",
    canMove: true,
    canToggle: true,
  },
  SUCCESS_FEE_PERC: {
    column: ExecutionColumn.SuccessFeePerc,
    getLabels: (t: TFunction) => ({
      header: t("common.success_fee"),
      tooltip: t("message.success_fee_billing_cycle"),
    }),
    propertyFn: (bot) => (
      <span>
        {trimZeroes(bot?.syndicationSettings?.successFeePerc) ?? "0"}%
      </span>
    ),
    filterType: "number",
    sortable: true,
    width: "110px",
    className: "align-right",
    canMove: true,
    canToggle: true,
  },
  // max allocation
  TOTAL_ALLOCATED: {
    column: ExecutionColumn.TotalAllocated,
    getLabels: (t: TFunction) => ({ header: t("common.total_allocation") }),
    propertyFn: (bot, mode) => (
      <span>
        {bot?.currencyPairDetails?.settleCurrency.includes("USD")
          ? trimToTwoDecimals(
              getMeasurements(bot, mode)?.subscriptionAllocation,
              true,
            )
          : formatWithCommas(
              getMeasurements(bot, mode)?.subscriptionAllocation,
            )}
        {" / " + formatWithCommas(bot?.syndicationSettings?.maxAllocation)}{" "}
        {bot?.currencyPairDetails?.settleCurrency}
      </span>
    ),
    sortable: false,
    width: "250px",
    className: "align-right",
    canMove: true,
    canToggle: true,
    hidden: true,
  },
};

export const BOT_COLUMNS: CommunityBotColumnDefinition[] = [
  BOT_COLUMN_DEFINITIONS.SCRIPT_NAME,
  BOT_COLUMN_DEFINITIONS.EXCHANGE,
  BOT_COLUMN_DEFINITIONS.CURRENCY_PAIR,
  BOT_COLUMN_DEFINITIONS.AVG_MONTHLY_PROFIT,
  BOT_COLUMN_DEFINITIONS.CONSISTENCY_SCORE,
  BOT_COLUMN_DEFINITIONS.PROFITABILITY,
  BOT_COLUMN_DEFINITIONS.MAX_DRAWDOWN,
  BOT_COLUMN_DEFINITIONS.PERC_PROFITABLE_MONTHS,
  BOT_COLUMN_DEFINITIONS.STARTED_AT,
  BOT_COLUMN_DEFINITIONS.STARTED_AT_DATE,
  BOT_COLUMN_DEFINITIONS.NUMBER_OF_TRADES,
  BOT_COLUMN_DEFINITIONS.PERCENT_PROFITABLE_TRADES,
  BOT_COLUMN_DEFINITIONS.SHARPE_RATIO,
  BOT_COLUMN_DEFINITIONS.SORTINO_RATIO,
  BOT_COLUMN_DEFINITIONS.MIN_ALLOCATION,
  BOT_COLUMN_DEFINITIONS.CREATOR_NAME,
  BOT_COLUMN_DEFINITIONS.MONTHLY_FEE_USD,
  BOT_COLUMN_DEFINITIONS.ALLOCATION_FEE_PERC,
  BOT_COLUMN_DEFINITIONS.SUCCESS_FEE_PERC,
  BOT_COLUMN_DEFINITIONS.TOTAL_ALLOCATED,
  BOT_COLUMN_DEFINITIONS.LEVERAGE,
  BOT_COLUMN_DEFINITIONS.AUTO_REBALANCE,
  BOT_COLUMN_DEFINITIONS.AVG_WIN_MONTH,
  BOT_COLUMN_DEFINITIONS.AVG_LOSE_MONTH,
  BOT_COLUMN_DEFINITIONS.BUY_HOLD_RETURN,
  BOT_COLUMN_DEFINITIONS.PROFIT_FACTOR,
  BOT_COLUMN_DEFINITIONS.RISK_SCORE,
];

export const DEFAULT_SORT: ExecutionSort = {
  sortKey: ExecutionColumn.StartedAt,
  sortDirection: SortDirection.Desc,
};

interface Context {
  tab: BotTab;
  tabUrl: string;
  sort: ExecutionSort;
  toggleSort: (sortKey: ExecutionColumn) => void;
  filters: ExecutionFilter[];
  exchangeFilter?: ExecutionFilter;
  onChangeExchangeFilter: (exchange?: string) => void;
  columnDefs__DEPRECATED: CommunityBotColumnDefinition[];
  setColumnDefs__DEPRECATED: (columns: CommunityBotColumnDefinition[]) => void;
  columns: ColumnDef<BotsTable_PublicSyndicationFragment>[];
}

type Props = Readonly<{
  children: React.ReactNode;
  tab: BotTab;
  tabUrl: string;
  columns__DEPRECATED: CommunityBotColumnDefinition[];
}>;

export const CommunityBotsContext = createContext<Context | undefined>(
  undefined,
);

export function useCommunityBotsContext() {
  const context = useContext(CommunityBotsContext);
  invariant(
    context != null,
    "Component is not a child of CommunityBotsContext provider",
  );
  return context;
}

export function CommunityBotsContextProvider({
  children,
  tab,
  tabUrl,
  columns__DEPRECATED,
}: Props) {
  const { t } = useTranslation();

  const history = useHistory();
  const searchParams = queryString.parse(history.location.search);

  const { selectedBotColumnsPreset } = useColumnsPresetsContext();
  const { filters: userFilters } = useFilterContext();
  const { botMeasurementsMode } = useSelectedExecutionContext();

  const [exchangeFilter, setExchangeFilter] = useState<
    ExecutionFilter | undefined
  >(
    getFirstString(searchParams["BOT_EXCHANGE"]) ||
      localStorage.getItem("bots-exchange-filter")
      ? {
          filterKey: ExecutionColumn.Exchange,
          filterType: FilterType.Equals,
          value:
            getFirstString(searchParams["BOT_EXCHANGE"]) ??
            localStorage.getItem("bots-exchange-filter") ??
            "",
        }
      : undefined,
  );
  const onChangeExchangeFilter = useCallback((exchange?: string) => {
    if (!exchange) {
      localStorage.removeItem("bots-exchange-filter");
      setExchangeFilter(undefined);
      return;
    }

    localStorage.setItem("bots-exchange-filter", exchange);
    setExchangeFilter({
      filterKey: ExecutionColumn.Exchange,
      filterType: FilterType.Equals,
      value: exchange,
    });
  }, []);

  const filters: ExecutionFilter[] = useMemo(() => {
    const executionFilters = [
      ...userFilters,
      {
        filterKey: ExecutionColumn.Status,
        filterType: FilterType.Equals,
        value: "RUNNING",
      },
      {
        filterKey: ExecutionColumn.IsBasket,
        filterType: FilterType.IsFalse,
        value: "",
      },
    ] as ExecutionFilter[];

    if (exchangeFilter) {
      executionFilters.push(exchangeFilter);
    }

    if (botMeasurementsMode === "backtest") {
      executionFilters.push({
        filterKey: ExecutionColumn.Type,
        filterType: FilterType.Equals,
        value: ExecutionType.SyndicationBacktest,
      });
    }

    return executionFilters;
  }, [userFilters, exchangeFilter, botMeasurementsMode]);

  useEffect(() => {
    if (userFilters.length || exchangeFilter) {
      const searchParams = getSearchParamsFromFilter(userFilters);

      if (exchangeFilter) {
        searchParams["BOT_EXCHANGE"] = exchangeFilter.value;
      }

      const search = queryString.stringify(searchParams);

      if (search !== history.location.search) {
        history.push({ search });
      }
    }
  }, [userFilters, exchangeFilter, history]);

  const [sort, toggleSort] = useReducer(
    (previousSort: ExecutionSort, sortKey: ExecutionColumn): ExecutionSort => {
      if (
        previousSort.sortDirection === SortDirection.Asc ||
        previousSort.sortKey !== sortKey
      ) {
        localStorage.setItem(
          `${tab}-BotSort`,
          `${sortKey}:${SortDirection.Desc}`,
        );

        searchParams["sort"] = `${sortKey}:${SortDirection.Desc}`;
        history.replace({ search: queryString.stringify(searchParams) });

        return { sortKey: sortKey, sortDirection: SortDirection.Desc };
      } else if (previousSort.sortDirection === SortDirection.Desc) {
        localStorage.setItem(
          `${tab}-BotSort`,
          `${sortKey}:${SortDirection.Asc}`,
        );

        searchParams["sort"] = `${sortKey}:${SortDirection.Asc}`;
        history.replace({ search: queryString.stringify(searchParams) });

        return { sortKey: sortKey, sortDirection: SortDirection.Asc };
      } else {
        searchParams[
          "sort"
        ] = `${DEFAULT_SORT.sortKey}:${DEFAULT_SORT.sortDirection}`;
        history.replace({ search: queryString.stringify(searchParams) });

        return DEFAULT_SORT;
      }
    },
    DEFAULT_SORT,
    () => {
      const urlSort = getFirstString(searchParams["sort"]);
      const cacheSort = localStorage.getItem(`${tab}-BotSort`);

      if (urlSort) {
        const [sortKeyString, sortDirectionString] = urlSort.split(":");

        const sortKey = fromStringValue(ExecutionColumn, sortKeyString);
        const sortDirection = fromStringValue(
          SortDirection,
          sortDirectionString,
        );

        if (!sortKey || !sortDirection) {
          return DEFAULT_SORT;
        }

        localStorage.setItem(`${tab}-BotSort`, `${sortKey}:${sortDirection}`);

        return {
          sortKey,
          sortDirection,
        };
      } else if (cacheSort) {
        const [sortKeyString, sortDirectionString] = cacheSort.split(":");

        const sortKey = fromStringValue(ExecutionColumn, sortKeyString);
        const sortDirection = fromStringValue(
          SortDirection,
          sortDirectionString,
        );

        if (!sortKey || !sortDirection) {
          return DEFAULT_SORT;
        }

        searchParams["sort"] = `${sortKey}:${sortDirection}`;

        history.replace({ search: queryString.stringify(searchParams) });

        return {
          sortKey,
          sortDirection,
        };
      }

      return DEFAULT_SORT;
    },
  );

  const [columnDefs__DEPRECATED, setColumnDefsInner] =
    useState(columns__DEPRECATED);
  const setColumnDefs__DEPRECATED = useCallback(
    (columns: CommunityBotColumnDefinition[], isPreset?: boolean) => {
      setColumnDefsInner(columns);
      if (!isPreset) {
        localStorage.removeItem(BOT_COLUMNS_PRESET_ID_KEY);
      }
      localStorage.setItem("publicBotColumns", JSON.stringify(columns));
    },
    [],
  );

  useEffect(() => {
    if (selectedBotColumnsPreset) {
      setColumnDefs__DEPRECATED(
        getSelectedPublicBotColumns(selectedBotColumnsPreset.columns),
        true,
      );
    }
  }, [selectedBotColumnsPreset, setColumnDefs__DEPRECATED]);

  const columnDefMap = useMemo(
    () => makeColumnDefMap(botMeasurementsMode, t),
    [botMeasurementsMode, t],
  );
  const columns = useMemo(
    () => [
      columnDefMap.SCRIPT_NAME,
      columnDefMap.CREATOR_NAME,
      columnDefMap.EXCHANGE,
      columnDefMap.CURRENCY_PAIR,
      columnDefMap.AVG_MONTHLY_PROFIT,
      columnDefMap.CONSISTENCY_SCORE,
      columnDefMap.PROFITABILITY,
      columnDefMap.MAX_DRAWDOWN,
      columnDefMap.PERC_PROFITABLE_MONTHS,
      columnDefMap.STARTED_AT,
      columnDefMap.STARTED_AT_DATE,
      columnDefMap.NUMBER_OF_TRADES,
      columnDefMap.PERCENT_PROFITABLE_TRADES,
      columnDefMap.SHARPE_RATIO,
      columnDefMap.SORTINO_RATIO,
      columnDefMap.MIN_ALLOCATION,
      columnDefMap.MONTHLY_FEE_USD,
      columnDefMap.ALLOCATION_FEE_PERC,
      columnDefMap.SUCCESS_FEE_PERC,
      columnDefMap.TOTAL_ALLOCATED,
      columnDefMap.LEVERAGE,
      columnDefMap.AUTO_REBALANCE,
      columnDefMap.AVG_WIN_MONTH,
      columnDefMap.AVG_LOSE_MONTH,
      columnDefMap.BUY_HOLD_RETURN,
      columnDefMap.PROFIT_FACTOR,
      columnDefMap.RISK_SCORE,
    ],
    [columnDefMap],
  );

  return (
    <CommunityBotsContext.Provider
      value={{
        tab,
        tabUrl,
        sort,
        toggleSort,
        filters,
        exchangeFilter,
        onChangeExchangeFilter,
        columnDefs__DEPRECATED,
        setColumnDefs__DEPRECATED,
        columns,
      }}
    >
      {children}
    </CommunityBotsContext.Provider>
  );
}

const styles = StyleSheet.create({
  span: {
    display: "flex",
    padding: "6px 0",
  },
  tooltipHeader: {
    display: "inline-block",
  },
  tooltipMultiplication: {
    display: "inline-block",
    transform: "scale(0.7)",
  },
  tooltipSquared: {
    display: "inline-block",
    transform: "scale(0.7) translateY(-5px)",
  },
});
