import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
/**
 * (1/3) Uncomment relevant lines below in local dev to replace
 * i18next strings with emojis. Useful to detect
 * strings that have not been localized
 */
// @ts-ignore Not ts lib available
// import emoji from "i18next-emoji-postprocessor";

export const CONFIG = Object.freeze({
  // zh_tw: { nativeName: "繁體中文", font: "noto" }, // chinese (traditional)
  // de: { nativeName: "Deutsch", font: "roboto" }, // german
  en: { nativeName: "English", font: "roboto" },
  es: { nativeName: "Español", font: "roboto" },
  // fr: { nativeName: "Français", font: "roboto" },
  // id: { nativeName: "Bahasa", font: "roboto" }, // indonesian
  // it: { nativeName: "Italiano", font: "roboto" },
  // ja: { nativeName: "日本語", font: "noto" }, // japanese
  // ko: { nativeName: "한국어", font: "noto" }, // korean
  // nl: { nativeName: "Nederlands", font: "roboto" }, // dutch
  // pl: { nativeName: "Polski", font: "roboto" }, // polish
  pt: { nativeName: "Português", font: "roboto" },
  // ru: { nativeName: "Русский", font: "roboto" }, // russian
  // tl: { nativeName: "Tagalog", font: "roboto" }, // tagalog
  // tr: { nativeName: "Türkçe", font: "roboto" }, // turkish
  // uk: { nativeName: "Українська", font: "roboto" }, // ukranian
});

export type Locale = keyof typeof CONFIG;

const enabled = process.env.REACT_APP_ENABLE_LOCALIZATION === "true";

const lookup = "locale";

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // TODO: Host strings in CloudFront
  .use(HttpBackend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // TODO: Create custom language detector plugin to fetch default from api
  // Disable language detection until we're ready to ship. Hack: add empty logger plugin so TS doesn't complain.
  .use(
    enabled
      ? new I18nextBrowserLanguageDetector(undefined, {
          order: ["cookie", "localStorage", "sessionStorage"],
          caches: ["cookie", "localStorage", "sessionStorage"],
          lookupCookie: lookup,
          lookupLocalStorage: lookup,
          lookupSessionStorage: lookup,
        })
      : { type: "logger" },
  )
  // (2/3) Uncomment in local dev to detect non-localized strings
  // .use(emoji)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // fallbackLng also supports per-language fallbacks if needed
    fallbackLng: "en",
    debug: process.env.NODE_ENV === "development",
    supportedLngs: Object.keys(CONFIG),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // (3/3) Uncomment in local dev to detect non-localized strings
    // postProcess: "emoji",
    react: {
      useSuspense: true,
    },
    saveMissing: true,
    missingKeyHandler: (
      _lngs,
      _ns,
      key,
      _fallbackValue,
      _updateMissing,
      _options,
    ) => {
      if (process.env.NODE_ENV === "development") {
        throw Error("Undefined i18next key: " + key);
      }
    },
  });

export default i18n;
