import React, { useCallback, useContext } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { ID } from "../graphql/schema";
import ComposerStateContext from "../contexts/ComposerStateContext";

interface Props extends React.DetailedHTMLProps<any, any> {
  algorithmSettings: string;
  readableAlgoNames?: Record<string, string>;
  extraAlgoSettings?: Record<string, string>;
  multivariantId?: ID;
}

function AlgoValuesTable({
  algorithmSettings,
  readableAlgoNames,
  extraAlgoSettings,
  multivariantId,
  ...props
}: Props) {
  const { t } = useTranslation();

  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { displayForMVT } = useContext(ComposerStateContext)!;

  let algoSettings = JSON.parse(algorithmSettings);
  if (
    Object.entries(algoSettings).length === 0 &&
    algoSettings.constructor === Object
  ) {
    algoSettings = { NaN: "" };
  }

  const onAlgoSettingRowClick = useCallback(
    (strategySettingsField: string) => {
      if (multivariantId) {
        displayForMVT(multivariantId, strategySettingsField);
      }
    },
    [multivariantId, displayForMVT],
  );

  return (
    <table {...props} onClick={(e) => e.stopPropagation()}>
      <tbody>
        <tr className="label">
          <td>{t("terminal.strategy_settings")}</td>
        </tr>
        {Object.entries(algoSettings).map(([key, val]: [string, any]) =>
          val.constructor === Object ? null : (
            <tr
              key={key}
              className={classnames({ clickable: !!multivariantId })}
              onClick={() => onAlgoSettingRowClick(key)}
            >
              <td>
                {readableAlgoNames && readableAlgoNames[key]
                  ? readableAlgoNames[key]
                  : key}
              </td>
              <td>{Array.isArray(val) ? val.join(",") : val.toString()}</td>
            </tr>
          ),
        )}
        {extraAlgoSettings && !!Object.keys(extraAlgoSettings).length && (
          <>
            <tr className="label">
              <td>{t("terminal.position_settings")}</td>
            </tr>
            {Object.entries(extraAlgoSettings).map(
              ([key, val]: [string, string]) =>
                val.constructor === Object ? null : (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{val}</td>
                  </tr>
                ),
            )}
          </>
        )}
      </tbody>
    </table>
  );
}

export default React.memo(AlgoValuesTable);
