import React, { createContext, useContext, useState } from "react";
import invariant from "invariant";
import { Credential, ScriptRevisionStatus } from "../graphql/schema";
import { useStateForCB } from "../helpers/hooks";

type Callback = () => void;
type ClickCB = (e: React.MouseEvent) => void;
type ClickOrCallback = (e?: React.MouseEvent) => void;

// A status representing whether or not a preview can be triggered.
// If not, an optional message can be provided.
export type CanPreview = { canPreview: boolean; message?: string };

interface EditorButtons {
  isExistingScript?: boolean;
  isPublicScript?: boolean;
  isDirty?: boolean;
  isPreviewLoading?: boolean;
  deleteClick?: ClickCB;
  deleteBtnDisabled?: boolean;
  saveClick?: ClickCB;
  saveAsClick?: ClickCB;
  saveBtnDisabled?: boolean;
  refreshChartClick?: ClickOrCallback;
  status?: ScriptRevisionStatus;
  showSummary?: boolean;
  canPreview?: CanPreview;
  isPreloadedScript?: boolean;
}

export interface Context {
  submitHandler?: Callback;
  setSubmitHandler: (cb?: Callback) => void;
  buttonName: string;
  setButtonName: any;
  editorHandler?: EditorButtons;
  setEditorHandler: (eb?: EditorButtons) => void;
  executionId?: string | number;
  setExecutionId: (eId?: string | number) => void;
  credentials: Credential[] | undefined;
  setCredentials: (cr?: Credential[]) => void;
}

export const CreateFormContext = createContext<Context | undefined>(undefined);

export function useCreateFormContext() {
  const context = useContext(CreateFormContext);
  invariant(
    context != null,
    "Component is not a child of CreateFormContext provider",
  );
  return context;
}

export const CreateFormContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [submitHandler, setSubmitHandler] = useStateForCB<Callback | undefined>(
    undefined,
  );
  const [buttonName, setButtonName] = useState("Create");
  const [editorHandler, setEditorHandler] = useState<EditorButtons | undefined>(
    undefined,
  );
  const [executionId, setExecutionId] = useState<string | number | undefined>(
    -1,
  );
  const [credentials, setCredentials] = useState<Credential[] | undefined>(
    undefined,
  );

  return (
    <CreateFormContext.Provider
      value={{
        submitHandler,
        setSubmitHandler,
        buttonName,
        setButtonName,
        editorHandler,
        setEditorHandler,
        executionId,
        setExecutionId,
        credentials,
        setCredentials,
      }}
    >
      {children}
    </CreateFormContext.Provider>
  );
};
