import React, { useCallback, useEffect, useState } from "react";
import { CommunityBotColumnDefinition } from "contexts/executions/CommunityBotsContext";
import { useBoolean } from "helpers/hooks";
import { ColumnDefinition } from "components/executions/list/ExecutionColumnDefinitions";

export type ResizeColumn = CommunityBotColumnDefinition | ColumnDefinition;

type Hook = {
  onResizeDragStart: (
    e: React.MouseEvent<HTMLDivElement>,
    column: ResizeColumn,
  ) => void;
  setDefaultWidth: (column: ResizeColumn, cacheColumnKey: string) => void;
  isDragging: boolean;
};

function useColumnsResizing(
  columns: ResizeColumn[],
  onCheckColumnSize: (width: number, column?: ResizeColumn) => number,
  getWidth: (column: ResizeColumn) => string,
): Hook {
  const [resize, setResize] = useState(0);
  const [resizeOrigPos, setResizeOrigPos] = useState(0);
  const [resizeOrigWidth, setResizeOrigWidth] = useState(0);
  const [resizeColumn, setColumn] = useState<ResizeColumn>();
  const [isDragging, setDragging, setNotDragging] = useBoolean(false);

  const onResizeDrag = useCallback(
    (e) => {
      setResize(e.clientX);
    },
    [setResize],
  );

  const onResizeDragEnd = useCallback(
    (
      e: MouseEvent,
      startPos: number,
      initialWidth: number,
      column: ResizeColumn,
    ) => {
      column.width = `${onCheckColumnSize(
        initialWidth - (startPos - e.clientX),
      )}px`;
      localStorage.setItem("publicBotColumns", JSON.stringify(columns));
      setNotDragging();
      window.removeEventListener("mousemove", onResizeDrag);
    },
    [columns, setNotDragging, onResizeDrag, onCheckColumnSize],
  );

  const onResizeDragStart = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, column: ResizeColumn) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging();
      const startPos = e.clientX;
      const initialWidth = parseInt(column.width as string);
      setColumn(column);
      setResizeOrigPos(startPos);
      setResizeOrigWidth(initialWidth);
      window.addEventListener("mousemove", onResizeDrag);
      window.addEventListener(
        "mouseup",
        (event) => {
          onResizeDragEnd(event, startPos, initialWidth, column);
        },
        { once: true },
      );
    },
    [setDragging, onResizeDragEnd, onResizeDrag, setResizeOrigWidth],
  );

  useEffect(() => {
    if (resizeColumn && isDragging && resize > 0) {
      resizeColumn.width = `${onCheckColumnSize(
        resizeOrigWidth - (resizeOrigPos - resize),
      )}px`;
    }
  }, [
    isDragging,
    resize,
    resizeColumn,
    resizeOrigPos,
    resizeOrigWidth,
    onCheckColumnSize,
  ]);

  const setDefaultWidth = useCallback(
    (column: ResizeColumn, cacheColumnKey: string) => {
      column.width = getWidth(column);
      localStorage.setItem(cacheColumnKey, JSON.stringify(columns));
      setResize(resize !== -1 ? -1 : 0);
    },
    [columns, setResize, resize, getWidth],
  );

  return { onResizeDragStart, setDefaultWidth, isDragging };
}

export default useColumnsResizing;
