import React, { MouseEvent, useCallback } from "react";
import { UPDATE_MULTIVARIANT_FAVORITE } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import imgStar from "../../images/star.svg";
import imgStarFavorite from "../../images/star-favorite.svg";

interface Props {
  multivariant: any;
}

const MultivariantFavorite = ({ multivariant }: Props) => {
  const [updateFavorite, { loading }] = useMutation(
    UPDATE_MULTIVARIANT_FAVORITE,
  );

  const onClick = useCallback(
    (e: MouseEvent<HTMLSpanElement>) => {
      updateFavorite({
        variables: { id: multivariant.id, favorite: !multivariant.favorite },
      });
      e.stopPropagation();
    },
    [updateFavorite, multivariant],
  );

  return (
    <div
      onClick={onClick}
      className="favorite"
      style={{ opacity: loading ? 0 : "unset" }}
    >
      {multivariant.favorite ? (
        <img src={imgStarFavorite} alt="star-favorite" />
      ) : (
        <img src={imgStar} alt="star" />
      )}
    </div>
  );
};

export default MultivariantFavorite;
