import React from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import LinkButton from "core/forms/LinkButton";
import imgClose from "images/close.svg";

// to be able to go back to the last page when navigating to the account dashboard
export const PREVIOUS_URL_STORAGE_KEY = "previous-url";

type BreadcrumbsItem = {
  name: string;
  action: () => void;
};

interface Props {
  breadcrumbs: BreadcrumbsItem[];
}

const SettingsHeader: React.FunctionComponent<Props> = ({ breadcrumbs }) => {
  const history = useHistory();

  return (
    <div className="settings__header">
      <Breadcrumbs className="settings__header--breadcrumbs">
        {breadcrumbs.map((item, i) => (
          <div key={i} onClick={item.action} className="breadcrumb-item">
            {item.name}
          </div>
        ))}
      </Breadcrumbs>
      <LinkButton
        className="icon"
        onClick={() =>
          history.push(localStorage.getItem(PREVIOUS_URL_STORAGE_KEY) ?? "/")
        }
      >
        <img src={imgClose} alt="Close" />
      </LinkButton>
    </div>
  );
};

export default SettingsHeader;
