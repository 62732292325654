import Decimal from "decimal.js";
import { DECIMAL_MVT_REGEX } from "./algorithmUtils";

const ONE_HUNDRED = new Decimal("100");

type Numericish = number | string | undefined | null;

export function noValue(val: Numericish): boolean {
  return val === undefined || val === null || isNaN(Number(val));
}

export function roundValue(
  val: Numericish,
  places: number = 5,
): string | number {
  if (noValue(val)) {
    return "";
  }

  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return new Decimal(val!).toDecimalPlaces(places).toString();
}

export function formatMoney(val: Numericish): string {
  if (noValue(val)) return "";
  return Number(val).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function percentageValue(
  val: Numericish,
  decimals: number = 2,
): string | undefined {
  if (noValue(val)) {
    return undefined;
  }

  return (
    // TODO: Eliminate use of non-null assertion
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    Number(new Decimal(val!).times(ONE_HUNDRED)).toLocaleString("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }) + "%"
  );
}

export function trimToTwoDecimals(
  val: Numericish,
  noMin: boolean = false,
): string | undefined {
  if (noValue(val)) {
    return undefined;
  }

  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return Number(new Decimal(val!)).toLocaleString("en-US", {
    ...(!noMin && { minimumFractionDigits: 2 }),
    maximumFractionDigits: 2,
  });
}

export function normalizedPercentageValue(val: Numericish): string | undefined {
  if (noValue(val)) {
    return undefined;
  }

  return percentageValue(Number(val) - 1);
}

export function trimZeroes(val: Numericish): string | undefined {
  if (noValue(val)) {
    return undefined;
  }
  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return val!.toString().replace(/(\.[0-9]*[1-9])0+$|\.0*$/, "$1");
}

export function trimToFourDecimalPlaces(val: Numericish): string | undefined {
  if (noValue(val)) {
    return undefined;
  }

  return val?.toString().replace(/(\.\d{4})\d+$/, "$1");
}

export function trimToFiveDecimalPlaces(val: Numericish): string | undefined {
  if (noValue(val)) {
    return undefined;
  }

  return val?.toString().replace(/(\.\d{5})\d+$/, "$1");
}

export function trimToSixDecimalPlaces(val: Numericish): string | undefined {
  if (noValue(val)) {
    return undefined;
  }

  return val?.toString().replace(/(\.\d{6})\d+$/, "$1");
}

export function trimToTwelveDecimalPlaces(val: Numericish): string | undefined {
  if (noValue(val)) {
    return undefined;
  }

  return val?.toString().replace(/(\.\d{12})\d+$/, "$1");
}

export function absoluteValue(val: Numericish): string | undefined {
  if (noValue(val)) {
    return undefined;
  }
  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const stringValue = val!.toString();
  return stringValue.length > 0 && stringValue.charAt(0) === "-"
    ? stringValue.substr(1)
    : stringValue;
}

export function isNegative(val: Numericish) {
  if (noValue(val)) {
    return false;
  }
  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const stringValue = val!.toString();
  return stringValue.length > 0 && stringValue.charAt(0) === "-";
}

export function isNoValueOrZero(val: Numericish) {
  return noValue(val) || trimZeroes(val) === "0";
}

export function nearestDollarValue(val: Numericish) {
  return noValue(val) ? "" : Math.round(Number(val)).toLocaleString("en-US");
}

export function addNumbers(
  val1: Numericish,
  val2: Numericish,
  plus: boolean = true,
): number | undefined {
  if (noValue(val1) || noValue(val2)) {
    return undefined;
  }
  return plus
    ? // TODO: Eliminate use of non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Number(new Decimal(val1!).plus(val2!))
    : // TODO: Eliminate use of non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Number(new Decimal(val1!).minus(val2!));
}

export function exponentialToNumber(val: Numericish): string | undefined {
  if (noValue(val)) {
    return undefined;
  }

  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return trimZeroes(new Decimal(val!).toFixed(20));
}

export function formatWithCommas(val: Numericish): string | undefined {
  const strVal = trimZeroes(val);
  if (noValue(strVal)) {
    return undefined;
  }

  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const decimalPlace = strVal!.indexOf(".");
  return decimalPlace < 0
    ? // TODO: Eliminate use of non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Number(strVal!).toLocaleString("en-US")
    : // TODO: Eliminate use of non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Number(strVal!.substring(0, decimalPlace)).toLocaleString("en-US") + // TODO: Eliminate use of non-null assertion
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        strVal!.substring(decimalPlace);
}

export function isNotPositive(value: string): boolean {
  return isNaN(Number(value)) || Number(value) < 0;
}

export function addZeroToFrontOfDecimal(val: Numericish): Numericish {
  if (noValue(val) || (val && isNaN(Number(val)))) {
    return val;
  }

  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const stringValue = val!.toString().trim();

  if (
    !isNaN(Number(stringValue)) &&
    stringValue.length &&
    (stringValue[0] === "." ||
      (stringValue.length > 2 &&
        stringValue[0] === "-" &&
        stringValue[1] === "."))
  ) {
    if (stringValue[0] === ".") {
      return "0" + stringValue;
    } else {
      return "-0" + stringValue.substring(1);
    }
  } else {
    return val;
  }
}

export function addZeroToFrontOfDecimals(val: Numericish): Numericish {
  if (!val || (val && !DECIMAL_MVT_REGEX.test(val.toString()))) {
    return val;
  }

  // TODO: Eliminate use of non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const stringValue = val!.toString().trim();
  const newValue = addZeroToFrontOfDecimal(stringValue);

  if (newValue !== stringValue) {
    return newValue;
  } else if (stringValue.includes(",")) {
    return stringValue.split(",").reduce((prev, current, i) => {
      const curr = current.trim();
      if (i === 1) {
        return (
          addZeroToFrontOfDecimal(prev) + ", " + addZeroToFrontOfDecimal(curr)
        );
      } else {
        return prev + ", " + addZeroToFrontOfDecimal(curr);
      }
    });
  } else if (stringValue.includes("_") && stringValue.includes(":")) {
    const underscoreIndex = stringValue.indexOf("_");
    const colonIndex = stringValue.indexOf(":");
    const firstNumber = stringValue.substring(0, underscoreIndex).trim();
    const secondNumber = stringValue
      .substring(underscoreIndex + 1, colonIndex)
      .trim();
    const thirdNumber = stringValue.substring(colonIndex + 1).trim();
    return (
      addZeroToFrontOfDecimal(firstNumber) +
      "_" +
      addZeroToFrontOfDecimal(secondNumber) +
      ":" +
      addZeroToFrontOfDecimal(thirdNumber)
    );
  } else {
    return val;
  }
}

export function trimBasedOnAmount(val: Numericish): string | undefined {
  if (noValue(val)) {
    return undefined;
  }

  if (Number(val) >= 10) {
    return trimToTwoDecimals(val);
  } else if (Number(val) >= 1) {
    return trimToFourDecimalPlaces(val);
  } else if (Number(val) >= 0.01) {
    return trimToSixDecimalPlaces(val);
  } else {
    return trimToTwelveDecimalPlaces(val);
  }
}
