import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import Button from "core/button/Button";
import checkmarkImg from "images/checkmark-large-yellow-gradient.svg";
import colors from "styles/colors";

type Props = Readonly<{
  onClick: () => void;
}>;

function ConnectCredentialSuccess({ onClick }: Props) {
  const { t } = useTranslation();

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.body)}>
        <img
          className={css(styles.checkmarkIcon)}
          src={checkmarkImg}
          alt="Checkmark"
          width={142}
          height={142}
        />
        <div className={css(styles.title)}>
          {t("message.exchange_successfully_connected")}
        </div>
        <div className={css(styles.subtitle)}>
          {t("message.start_using_exchange_right_away")}
        </div>
      </div>
      <div className={css(styles.footer)}>
        <Button onClick={onClick}>{t("message.start_using_exchange")}</Button>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    margin: "auto",
  },
  checkmarkIcon: {
    marginBottom: "60px",
    "@media (max-width: 767.98px)": {
      marginBottom: "20px",
    },
  },
  title: {
    fontWeight: 600,
    fontSize: "34px",
    color: colors.lightGray,
    maxWidth: "480px",
    marginBottom: "15px",
    "@media (max-width: 767.98px)": {
      fontSize: "24px",
    },
  },
  subtitle: {
    fontWeight: 400,
    fontSize: "14px",
    color: colors.offWhite,
  },
  footer: {
    width: "385px",
    margin: "0 auto",
    "@media (max-width: 767.98px)": {
      width: "100%",
    },
  },
});

export default ConnectCredentialSuccess;
